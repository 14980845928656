import React, { useState } from 'react';
import { Form } from 'ui/form';
import { Modal } from 'ui/modal';
import { TextAreaField } from 'ui/form/fields';
import {
  Token,
  useGetUserTokenReportQuery,
  useReportTokenMutation,
} from 'generated/graphql';
import Button from 'ui/button';
import { isNotNullOrUndefined } from 'technical/utils/is-not-null-or-undefined';
import { Spacer } from 'ui/spacer';
import * as yup from 'yup';
import { notification, Typography } from 'antd';
import { useTranslation } from 'react-i18next';
import { TokenThumbnail } from 'business/token/components/token-thumbnail';
import { useAppContext } from 'business/app-bootstrapper';
import classes from './use-report-token-modal.module.scss';

const commentConfig = {
  min: 30,
  max: 1000,
};

interface ReportTokenFormValues {
  comment: string;
}

const reportTokenSchema: yup.SchemaOf<ReportTokenFormValues> = yup.object({
  comment: yup
    .string()
    .min(commentConfig.min)
    .max(commentConfig.max)
    .required(),
});

type ReportToken = Pick<
  Token,
  'id' | 'name' | 'symbol' | 'reference' | 'coinGeckoImage'
>;

export function useReportTokenModal(initialToken?: ReportToken | null) {
  const { t } = useTranslation(['token']);
  const [isVisible, setIsVisible] = useState(false);
  const [token, setToken] = useState(initialToken);
  const { user } = useAppContext();
  const { data, loading: loadingUserTokenReport } = useGetUserTokenReportQuery({
    // we skip the execution until we have the data
    variables: { userId: user?.id!, tokenId: token?.id! },
    skip: !user || !token,
  });
  const [reportToken, { loading }] = useReportTokenMutation({
    refetchQueries: ['GetUserTokenReport'],
    awaitRefetchQueries: true,
    onCompleted: () => {
      setIsVisible(false);
      notification.success({
        message: t('token:report.notification'),
      });
    },
  });

  const { comment, createdAt } = data?.report?.[0] ?? {};
  const alreadySubmitted = isNotNullOrUndefined(data?.report?.[0]);

  return {
    showReportTokenModal: (newToken?: ReportToken | null) => {
      if (isNotNullOrUndefined(newToken)) {
        setToken(newToken);
      }

      setIsVisible(true);
    },
    ReportTokenModal: () => (
      <Modal
        closable
        onCancel={() => setIsVisible(false)}
        open={!loadingUserTokenReport && isVisible}
        title={
          <Spacer align="baseline" direction="horizontal" space={1}>
            <Typography.Title level={3}>
              {t('token:report.title')}
            </Typography.Title>
            {token && <TokenThumbnail token={token} />}
          </Spacer>
        }
        footer={null}
      >
        {alreadySubmitted && createdAt ? (
          <Spacer direction="vertical" space={2}>
            <Typography.Text>
              {t('token:report.already-submitted', {
                createdAt: new Date(createdAt).toLocaleDateString(),
              })}
            </Typography.Text>
            <Typography.Paragraph className={classes.commentParagraph}>
              {comment}
            </Typography.Paragraph>
            <Button onClick={() => setIsVisible(false)}>
              {t('token:report.close')}
            </Button>
          </Spacer>
        ) : (
          <Form<ReportTokenFormValues>
            layout="vertical"
            initialValues={{ comment: data?.report[0]?.comment ?? '' }}
            validationSchema={reportTokenSchema}
            onSubmit={(values) => {
              if (!token) {
                return;
              }
              reportToken({ variables: { tokenId: token.id, ...values } });
            }}
          >
            {() => (
              <Spacer direction="vertical" space={2}>
                <TextAreaField<ReportTokenFormValues>
                  id="report-token-comment"
                  name="comment"
                  showCount
                  rows={3}
                  minLength={commentConfig.min}
                  maxLength={commentConfig.max}
                  label={t('token:report.comment-label')}
                />
                <Spacer direction="horizontal" space={1} justify="end">
                  <Button loading={loading} onClick={() => setIsVisible(false)}>
                    {t('token:report.cancel')}
                  </Button>
                  <Button type="primary" htmlType="submit" loading={loading}>
                    {t('token:report.confirm')}
                  </Button>
                </Spacer>
              </Spacer>
            )}
          </Form>
        )}
      </Modal>
    ),
  };
}
