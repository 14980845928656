import authService from 'business/user/services/authentication.gql';
import classnames from 'classnames';
import Routes from 'business/router/routes';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import Link from 'ui/link';
import AuthCard from '../auth-card';
import { Values } from '../auth-form';

interface Props {
  className?: string;
}

export default function LoginCard({ className }: Props) {
  const [signInError, setSignInError] = useState<string>('');
  const { t } = useTranslation(['common']);

  const onSubmitClick = async (values: Values) => {
    setSignInError('');
    try {
      await authService.signIn(values.login, values.password);
      window.localStorage.removeItem('coming-from-brave');
    } catch (error) {
      setSignInError(error.message);
    }
  };

  return (
    <AuthCard
      className={classnames(className)}
      submitButton={{
        label: t('notConnected.login.submit'),
        onClick: onSubmitClick,
      }}
      authError={{ key: 'errors.signIn', context: signInError }}
      passwordHint={
        <Link color="tundora" to={Routes.ForgotPassword}>
          {t('pages.forgotPassword.title', {
            context: 'question',
          })}
        </Link>
      }
      bottomHint={
        <Link
          to={Routes.SignUp}
          prefix={t('common:notConnected.login.signUp.prefix')}
          align="center"
        >
          {t('common:notConnected.login.signUp.cta')}
        </Link>
      }
    />
  );
}
