import React from 'react';
import { Typography, Spin } from 'antd';
import { Spacer } from 'ui/spacer';
import { Token, TokenAbout, TokenMarkets } from 'generated/graphql';
import { defaultTokenEmptyValue } from 'business/token/common-values';
import variables from 'ui/theme/variables.module.scss';
import Tag from 'ui/tag';
import { ReactComponent as ExportIcon } from 'assets/illustrations/export-icon.svg';
import { CoinGeckoTokenImage } from 'ui/coin-gecko-token-image';
import classes from './index.module.scss';
import { TokenSocialNetwork } from '../token-social-network';

export interface TokenOverviewProps
  extends Pick<TokenMarkets, 'image' | 'price' | 'h24Low' | 'h24High'> {
  token?: Pick<Token, 'symbol' | 'name' | 'id' | 'reference'> | null;
  loading?: boolean;
  about: Omit<TokenAbout, '__typename'> | undefined | null;
}

export function TokenOverview({
  loading = false,
  token,
  image,
  about,
}: TokenOverviewProps) {
  if (loading) {
    return <Spin size="large" />;
  }

  const { name, symbol } = token ?? {
    name: defaultTokenEmptyValue,
    symbol: defaultTokenEmptyValue,
  };

  return (
    <Spacer
      direction="horizontal"
      space={1}
      align="center"
      justify="start"
      className={classes.token}
      wrap="wrap"
    >
      <CoinGeckoTokenImage
        className={classes.logo}
        coinGeckoImage={image}
        size="small"
      />
      <Typography.Text strong className={classes.name}>
        {name}
      </Typography.Text>
      <Tag
        color={variables.primaryColorSlightlyTransparent}
        className={classes.tag}
      >
        <Typography.Text className={classes.tag}>
          {symbol.toUpperCase()}
        </Typography.Text>
      </Tag>
      {about?.website ? (
        <a href={about.website} target="_blank" rel="noreferrer">
          <Tag
            color={variables.primaryColorSlightlyTransparent}
            className={classes.tag}
          >
            <ExportIcon
              width={13}
              height={13}
              fill={variables.primaryColor2}
              stroke={variables.primaryColor2}
            />
          </Tag>
        </a>
      ) : null}
      <div>
        <TokenSocialNetwork size="small" {...about} />
      </div>
    </Spacer>
  );
}
