import {
  GetLeaderboardQuery,
  GetSessionLeaderboardBySessionIdQuery,
  LeaderboardBySession,
  Maybe,
  Rank,
  User as GqlUser,
  UserProfile,
} from 'generated/graphql';

export interface User {
  id: any;
  firstName: string | null;
  lastName: string | null;
  defaultWallet?: {
    id: string;
    address: string;
  };
}

export type UserRole = 'user' | 'anonymous';

export interface Me {
  id: string;
  email: string;
  displayName: string;
  roles: string[];
  defaultWallet: {
    id: string;
    address: string;
  } | null;
  rank: {
    id: string;
    name: string;
    icon: string;
  } | null;
  contributionCredit: {
    credit: number;
    numberOfReviewNeededBeforeNextCredit: number;
  };
}

export enum UserInfoTabs {
  ranking = 'ranking',
  portfolios = 'portfolios',
  researchHistory = 'research-history',
}

type DisplayableLeaderboardRow = {
  position: NonNullable<LeaderboardBySession['position']>;
} & {
  user: Pick<GqlUser, 'id'> & {
    ranking?: Maybe<{
      currentRank: Pick<Rank, 'name' | 'icon'>;
    }>;
    profile?: Maybe<Pick<UserProfile, 'displayName'>>;
  };
};
export const isDisplayableLeaderboardRow = (
  row:
    | GetSessionLeaderboardBySessionIdQuery['leaderboardBySession'][0]
    | GetLeaderboardQuery['leaderboard'][0],
): row is DisplayableLeaderboardRow => {
  return !!row.position && !!row.user && !!row.user?.id;
};
