import { Form, Input } from 'antd';
import {
  formikErrorMessage,
  generateStyleOnErrors,
} from 'business/user/services/user';
import { email, password } from 'business/user/validations/string';
import classnames from 'classnames';
import { FormikHelpers, useFormik } from 'formik';
import React from 'react';
import { useTranslation } from 'react-i18next';
import Button from 'ui/button';
import { Card } from 'ui/cards';
import InputError from 'ui/form/input-error';
import PasswordInput from 'ui/form/password-input';
import { Spacer } from 'ui/spacer';
import * as yup from 'yup';
import styles from './index.module.scss';

export interface Values {
  login: string;
  password: string;
}

interface Props {
  submitButton: {
    label: string;
    onClick: (
      values: Values,
      formikHelpers: FormikHelpers<Values>,
    ) => void | Promise<any>;
  };
  errorResetPassword: string;
  title?: string;
  description?: string;
  bottomHint?: React.ReactNode;
  className?: string;
  inputType: 'email' | 'password';
}

const initialValues: Values = { login: '', password: '' };

export default function ResetPasswordCard({
  submitButton,
  errorResetPassword,
  title,
  description,
  bottomHint,
  className,
  inputType,
}: Props) {
  const { t } = useTranslation(['common']);

  const formik = useFormik({
    initialValues,
    validateOnMount: true,
    validationSchema: yup.object().shape(
      inputType === 'email'
        ? {
            login: email(t),
          }
        : {
            password: password(t),
          },
    ),
    onSubmit: submitButton.onClick,
  });

  return (
    <Card className={classnames(className)}>
      <Form
        className={styles.form}
        onFinish={formik.handleSubmit}
        layout="vertical"
      >
        <Spacer direction="vertical" align="center">
          {title ? <h1 className={styles.title}>{title}</h1> : null}
          <p className={styles.description}>{description}</p>
          <Spacer direction="vertical" space={2}>
            {inputType === 'email' && (
              <Form.Item label={t('notConnected.login.email')}>
                <Input
                  data-test-id="email-input"
                  id="login"
                  onChange={(e) => {
                    formik.setTouched({ ...formik.touched, login: undefined });
                    formik.handleChange(e);
                  }}
                  onBlur={formik.handleBlur}
                  style={generateStyleOnErrors(formik, 'login')}
                />
                <InputError error={formikErrorMessage(formik, 'login')} />
              </Form.Item>
            )}
            {inputType === 'password' && (
              <PasswordInput
                label={t('notConnected.login.resetPassword')}
                id="password"
                dataTestId="password-input"
                className={styles.passwordInput}
                onChange={(e) => {
                  formik.setTouched({ ...formik.touched, password: undefined });
                  formik.handleChange(e);
                }}
                onBlur={formik.handleBlur}
                style={generateStyleOnErrors(formik, 'password')}
                error={formikErrorMessage(formik, 'password')}
              />
            )}
            {errorResetPassword && <InputError error={errorResetPassword} />}
          </Spacer>
          <Button
            id="submit-button"
            data-test-id="submit-button"
            type="primary"
            htmlType="submit"
            block
          >
            {submitButton.label}
          </Button>

          {bottomHint && <p>{bottomHint}</p>}
        </Spacer>
      </Form>
    </Card>
  );
}
