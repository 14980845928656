import React from 'react';
import { Spacer } from 'ui/spacer';
import { Typography } from 'antd';

import {
  PeerReviewAnswerMultiChoice,
  PeerReviewAnswerSingleChoice,
  PeerReviewAnswerTag,
  PeerReviewAnswerToken,
  PeerReviewQuestion,
} from 'business/peer-review/types';
import { match } from 'technical/utils/match';
import { PeerReviewTags } from './tags';
import { PeerReviewTokens } from './tokens';
import { useTranslation } from 'react-i18next';

// TODO: yes/no arn't colored yet.)

export interface PeerReviewQuestionComponentProps {
  question: PeerReviewQuestion;
  choices: {
    multiChoices: PeerReviewAnswerMultiChoice[];
    singleChoice?: PeerReviewAnswerSingleChoice;
    tokens: PeerReviewAnswerToken[];
    tags: PeerReviewAnswerTag[];
  };
  type?: 'default' | 'well';
}

export function PeerReviewQuestionComponent({
  question,
  choices,
  type = 'default',
}: PeerReviewQuestionComponentProps) {
  const { t } = useTranslation('form-wizard');
  return (
    <Spacer direction="vertical" space={1}>
      <Typography.Text strong>{question.question}</Typography.Text>
      {match(question.type.type, {
        single_choice: (
          <PeerReviewTags
            tags={[
              {
                value: choices.singleChoice?.choice.value,
                order: 0,
              },
            ]}
            type={type}
          />
        ),
        multi_choice: (
          <PeerReviewTags
            tags={choices.multiChoices.map((choice) => {
              return { value: choice.choice.value, order: choice.choice.order };
            })}
            type={type}
          />
        ),
        tag: (
          <PeerReviewTags
            tags={choices.tags.map((choice, index) => {
              return { value: choice.value, order: index };
            })}
            type={type}
          />
        ),
        token: <PeerReviewTokens tokens={choices.tokens} type={type} />,
      })}
    </Spacer>
  );
}
