import { Result } from 'antd';
import Routes from 'business/router/routes';
import React, { PropsWithChildren } from 'react';
import errorReporting from 'technical/error-reporting';
import logger from 'technical/logger';
import { i18n } from 'translations';
import { LinkAsButton } from 'ui/button';

interface Props {}
interface State {
  hasError: false;
  error?: Error;
}

class ErrorBoundaryComponent extends React.Component<
  PropsWithChildren<Props>,
  State
> {
  constructor(props: Props) {
    super(props);
    this.state = { hasError: false };
  }

  static getDerivedStateFromError(error: Error) {
    return { hasError: true, error };
  }

  componentDidCatch(error: Error) {
    logger.error(error);
    errorReporting.warning(error);
  }

  render() {
    const { children } = this.props;
    const { hasError } = this.state;

    if (hasError) {
      return (
        <Result
          status="warning"
          title={i18n.t('errors.generic')}
          extra={
            <LinkAsButton to={Routes.Home} type="primary">
              {i18n.t('pages.common.goToHome')}
            </LinkAsButton>
          }
        />
      );
    }

    return children;
  }
}

const ErrorBoundary = ErrorBoundaryComponent;

export default ErrorBoundary;
