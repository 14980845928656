import { Typography } from 'antd';
import { RatingSelectField } from 'business/rating/components/select-field';
import { TokenPrices } from 'business/token/components/token-prices';
import { ReactComponent as EditIcon } from 'assets/illustrations/edit-icon.svg';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { format } from 'technical/date-fns';
import { Card } from 'ui/cards';
import { Form } from 'ui/form';
import { PredictionField } from 'ui/form/fields/prediction';
import { TextAreaField } from 'ui/form/fields/texts';
import { Spacer } from 'ui/spacer';
import { addMonths } from 'date-fns';
import * as yup from 'yup';
import Button from 'ui/button';
import variables from 'ui/theme/variables.module.scss';
import { ResearchFormHelperProps } from './types';

import classes from './step-3.module.scss';

type ResearchFormFinalThoughts = {
  targetPrice: number;
  userOpinionRatingId: string | null;
  comment: string | null;
};

const validationSchema = yup
  .object({
    targetPrice: yup.number().min(-1).required(),
    userOpinionRatingId: yup.string().uuid(),
    comment: yup.string().min(100).max(2500),
  })
  .required();

export function Step3({ state, send }: ResearchFormHelperProps) {
  const { t } = useTranslation(['form-wizard']);

  return (
    <div>
      <Card.Header
        level={3}
        title={t('form-wizard:titles_addingPrediction', {
          tokenName: state.context.token.name,
        })}
      />
      <Spacer space={4} direction="vertical">
        <Spacer space={4} direction="vertical">
          <Typography.Text>
            {t('form-wizard:subtitle_addingPrediction', {
              tokenName: state.context.token.name,
            })}
          </Typography.Text>
          <Card type="well">
            <TokenPrices
              {...state.context.token}
              {...state.context.token.infos}
            />
          </Card>
          <Form<ResearchFormFinalThoughts>
            initialValues={{
              comment: state.context.draft.comment ?? null,
              userOpinionRatingId:
                state.context.draft.userOpinionRatingId ?? null,
              targetPrice:
                state.context.draft.targetPrice ??
                state.context.token.infos?.price ??
                0.0,
            }}
            validationSchema={validationSchema}
            onSubmit={async (values) => {
              return send({ type: 'update final thoughts', ...values });
            }}
          >
            {() => {
              return (
                <Card type="well">
                  <Spacer space={1} direction="vertical">
                    <Form.Autosave />

                    <PredictionField<ResearchFormFinalThoughts>
                      id="final-thoughts-targetPrice"
                      name="targetPrice"
                      label={t('form-wizard:prediction.fields.target', {
                        date: format(addMonths(new Date(), 1), 'PP'),
                        tokenName: state.context.token.name,
                      })}
                      currentPrice={state.context.token.infos?.price ?? 0}
                    />
                    <RatingSelectField<ResearchFormFinalThoughts>
                      id="final-thoughts-userOpinionRatingId"
                      name="userOpinionRatingId"
                      size="middle"
                      label={t('form-wizard:prediction.fields.rating')}
                      tokenRating={{ type: 'text', size: 'small' }}
                    />

                    <TextAreaField<ResearchFormFinalThoughts>
                      id="final-thoughts-comment"
                      name="comment"
                      label={t('form-wizard:prediction.fields.commentTitle')}
                      placeholder={t('form-wizard:prediction.fields.comment')}
                      aria-label={t('form-wizard:prediction.fields.comment')}
                      autoSize={{ minRows: 4, maxRows: 10 }}
                      maxLength={2500}
                      minLength={100}
                      labelCol={{ span: 24 }}
                    />
                  </Spacer>
                </Card>
              );
            }}
          </Form>
        </Spacer>
        <Spacer space={1} justify="end">
          <Button
            type="default"
            onClick={() => send({ type: 'go to step 2' })}
            className={classes.button}
            icon={
              <EditIcon
                width={16}
                height={16}
                fill={variables.primaryColor2}
                stroke={variables.primaryColor2}
              />
            }
          >
            {t('form-wizard:category-analysis.actions.previous', {
              context: '',
            })}
          </Button>
          <Button type="ghost" onClick={() => send({ type: 'finish later' })}>
            {t('form-wizard:prediction.actions.save-and-finish-later')}
          </Button>
          <Button
            type="primary"
            disabled={!state.can('publish') || state.matches('leave')}
            loading={state.matches('leave')}
            onClick={() => send({ type: 'publish' })}
          >
            {t('form-wizard:category-analysis.actions.next', {
              context: 'addingPrediction',
            })}
          </Button>
        </Spacer>
      </Spacer>
    </div>
  );
}
