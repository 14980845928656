import React from 'react';
import * as yup from 'yup';
import { useTranslation } from 'react-i18next';
import { Modal } from 'ui/modal';
import { Form } from 'ui/form';
import { TextField } from 'ui/form/fields';
import classes from './index.module.scss';

interface FormCreationProps {
  name: string;
}

interface PortfolioCreationModalProps {
  visible: boolean;
  onCancel: () => void;
  onSubmit: (values: FormCreationProps) => void;
}

const initialValues = { name: '' };

const validationSchema = yup.object({
  name: yup.string().required(),
});

export const PortfolioCreationModal: React.VFC<PortfolioCreationModalProps> = ({
  visible,
  onCancel,
  onSubmit,
}) => {
  const { t } = useTranslation(['portfolio']);

  return (
    <Form<FormCreationProps>
      initialValues={initialValues}
      onSubmit={onSubmit}
      validationSchema={validationSchema}
    >
      {({ handleSubmit }) => {
        return (
          <Modal
            title={t('portfolio:creation.title')}
            open={visible}
            destroyOnClose
            onCancel={onCancel}
            onOk={() => handleSubmit()}
            okText={t('portfolio:creation.cta')}
            cancelText={t('portfolio:creation.cancel')}
          >
            <TextField<FormCreationProps>
              id="name"
              name="name"
              label={t('portfolio:creation.name')}
              required
              className={classes.inputs}
            />
          </Modal>
        );
      }}
    </Form>
  );
};
