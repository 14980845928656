import React, { createContext, useContext } from 'react';
import { Spin } from 'antd';
import logger from 'technical/logger';
import { Spacer } from 'ui/spacer';
import useUserData from './user/provider/user';
import { Me } from './user/types/user';

export interface AppContextAttributes {
  user: Me | undefined;
  isConnected: boolean;
  appBootstraped: boolean;
  requestRebootstrap: () => Promise<void>;
  refetchCurrentUser: () => Promise<void>;
}

const AppContext = createContext<AppContextAttributes>({
  user: undefined,
  isConnected: false,
  appBootstraped: false,
  requestRebootstrap: () => Promise.resolve(),
  refetchCurrentUser: () => Promise.resolve(),
});

export const useAppContext = () => useContext(AppContext);

interface Props {
  children: React.ReactElement;
}

function AppBootstrapper({ children }: Props) {
  const { appBootstraped } = useAppContext();
  return appBootstraped ? (
    children
  ) : (
    <Spacer
      align="center"
      justify="center"
      style={{ flex: 1, height: '100vh' }}
    >
      <Spin size="large" />
    </Spacer>
  );
}

export function AppProvider({ children }: Props) {
  const { isBootstraped, ...userData } = useUserData();
  logger.info('AppProvider', userData);

  return (
    <AppContext.Provider
      value={{
        ...userData,
        appBootstraped: isBootstraped,
      }}
    >
      <AppBootstrapper>{children}</AppBootstrapper>
    </AppContext.Provider>
  );
}
