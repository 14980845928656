import React from 'react';
import { Image } from 'antd';
import classes from './index.module.scss';

interface UserRankIconProps {
  icon: string;
}

export function UserRankIcon({ icon }: UserRankIconProps) {
  return (
    <Image
      src={`/ranks${icon}`}
      height={100}
      width={100}
      preview={false}
      className={classes['user-rank-icon']}
    />
  );
}
