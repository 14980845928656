import React, { useState } from 'react';
import { Select, SelectProps, Spin, Typography } from 'antd';
import { SearchOutlined } from '@ant-design/icons';
import { UserProfile, useSearchAuthorsPortfolioQuery } from 'generated/graphql';
import { useDebounce } from 'technical/hooks/use-debounce';
import { DefaultOptionType } from 'antd/lib/select';
import { SelectValue } from 'technical/form/types';
import { selectValueToList } from 'technical/form/select-value-to-list';

const generateOptions = (
  authors: Pick<UserProfile, 'userId' | 'displayName'>[],
) => {
  return authors.map(({ displayName, userId }) => ({
    key: userId,
    value: userId,
    title: displayName,
    selectedLabel: <Typography.Text>{displayName}</Typography.Text>,
    label: <Typography.Text>{displayName}</Typography.Text>,
  }));
};

export interface AuthorSelectProps
  extends Omit<SelectProps<SelectValue>, 'mode'> {
  mode?: 'multiple';
}

export const PortfolioAuthorSelect: React.VFC<AuthorSelectProps> = ({
  value,
  onChange,
  mode,
  ...otherProps
}) => {
  const [searchText, setSearchText] = useState('');
  const debouncedValue = useDebounce(searchText);

  const authorFields = selectValueToList(value);

  const { data, previousData, loading } = useSearchAuthorsPortfolioQuery({
    variables: {
      searchText: `%${debouncedValue}%`,
      searchResultFilter: { userId: { _nin: authorFields } },
      authorFilter: { userId: { _in: authorFields } },
    },
  });

  const searchResults =
    data?.searchResults ?? previousData?.searchResults ?? [];
  const authors = data?.authors ?? previousData?.authors ?? [];

  return (
    <Select<SelectValue>
      showSearch
      mode={mode}
      optionLabelProp="selectedLabel"
      defaultActiveFirstOption={false}
      filterOption={false}
      allowClear
      loading={loading}
      onChange={(
        newValue: SelectValue,
        option: DefaultOptionType | DefaultOptionType[],
      ) => {
        setSearchText('');
        onChange?.(newValue, option);
      }}
      value={authors.length && value ? value : undefined}
      options={generateOptions([...authors, ...searchResults])}
      onSearch={(str) => setSearchText(str)}
      notFoundContent={loading ? <Spin /> : null}
      suffixIcon={<SearchOutlined />}
      {...otherProps}
    />
  );
};
