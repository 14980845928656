import { ValuesOf } from 'business/types/basic';

export interface OnboardingState {
  currentStep: number;
  isAllComplete: boolean;
  loading: boolean;
  error: any;

  refreshOnboarding: () => void;

  register: OnboardingStepEnumType;
  joinSocialsMedia: OnboardingStepEnumType;
  interest: OnboardingStepEnumType;
  wallet: OnboardingStepEnumType;
  profile: OnboardingStepEnumType;
  access: OnboardingStepEnumType;
  hasSeenDoc: OnboardingStepEnumType;
}

export const OnboardingStepEnum = {
  unbegun: 'unbegun',
  skipped: 'skipped',
  done: 'done',
} as const;

export type OnboardingStepEnumType = ValuesOf<typeof OnboardingStepEnum>;
