import React from 'react';
import { Tag } from 'antd';
import { PortfolioVisibilityEnumValues } from 'business/portfolio/types';
import { useTranslation } from 'react-i18next';
import classnames from 'classnames';
import classes from './index.module.scss';

interface PortfolioStatusTagProps {
  status: PortfolioVisibilityEnumValues;
}
export const PortfolioStatusTag: React.VFC<PortfolioStatusTagProps> = ({
  status,
}) => {
  const { t } = useTranslation(['portfolio']);

  return (
    <Tag className={classnames([classes.portfolioStatusTag, classes[status]])}>
      {t('portfolio:visibility.status', { context: status })}
    </Tag>
  );
};
