import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { Card } from 'ui/cards';
import {
  Onboarding,
  useOnboardingState,
} from 'business/onboarding/components/onboarding';
import { AllTokens } from 'business/home/components/all-tokens';
import { TrendingCards } from 'business/home/components/trending-cards';
import { HotTopicArticlesCard } from 'business/home/components/hot-topic-articles';
import { LinkAsButton } from 'ui/button';
import Routes from 'business/router/routes';
import { Spacer } from 'ui/spacer';
import { Article } from 'business/article/components/article';
import { RegularArticles } from 'business/article/components/regular-articles';
import config from 'config';
import { PromotedTokensCard } from 'business/home/components/promoted-tokens';
import classes from './index.module.scss';
import { useGetLeaderboardSessionsQuery } from 'generated/graphql';
import { SessionLeaderboard } from 'business/user/components/leaderboard/session-leaderboard';
import { PowerSearchPreview } from 'business/token/components/power-search-preview';
import Tag from 'ui/tag';
import variables from 'ui/theme/variables.module.scss';
import classNames from 'classnames';
import { GlobalLeaderboard } from 'business/user/components/leaderboard/global-leaderboard';

const Home = () => {
  const { t } = useTranslation(['home']);
  const { isAllComplete } = useOnboardingState();
  const leaderboardSessionQuery = useGetLeaderboardSessionsQuery();

  return (
    <div
      className={classNames(
        classes.grid,
        config.features.isPowerSearchActivated &&
          classes['with-power-search-preview'],
      )}
    >
      <div className={classes.trending}>
        <TrendingCards />
      </div>
      <div className={classes.articles}>
        {config.features.replaceArticlesWithBounties ? (
          <PromotedTokensCard fallback={<HotTopicArticlesCard />} />
        ) : (
          <HotTopicArticlesCard />
        )}
      </div>
      <div className={classes.list}>
        <AllTokens />
      </div>
      <div className={classes.onboarding}>
        {(() => {
          if (config.features.isInHibernation) {
            return (
              <Card>
                <Card.Header
                  level={2}
                  title={t('home:leaderboard')}
                  actions={
                    <LinkAsButton type="link" to={`${Routes.Leaderboard}`}>
                      {t('home:see-more')}
                    </LinkAsButton>
                  }
                />
                <GlobalLeaderboard compact />
              </Card>
            );
          }

          return isAllComplete ? (
            <Card>
              <Card.Header
                level={2}
                title={t('home:leaderboard')}
                actions={
                  <LinkAsButton
                    type="link"
                    to={`${Routes.Leaderboard}?tab=session-leaderboard`}
                  >
                    {t('home:see-more')}
                  </LinkAsButton>
                }
              />
              <SessionLeaderboard
                compact
                loading={leaderboardSessionQuery.loading}
                sessionId={
                  leaderboardSessionQuery.data?.leaderboardSession[0]?.id
                }
              />
            </Card>
          ) : (
            <Card>
              <Card.Header
                level={2}
                title={t('home:onboarding')}
                className={classes.onboardingTitle}
              />
              <Onboarding />
            </Card>
          );
        })()}
      </div>
      <div className={classes['power-search-preview']}>
        <Card>
          <Card.Header
            level={2}
            title="🔎 Power Search Preview"
            actions={<Tag color={variables.ratingMedium}>New</Tag>}
          />
          <PowerSearchPreview />
        </Card>
      </div>
      <div className={classes.lesson}>
        <div>
          <Article
            link="https://docs.kryptview.com/"
            thumbnail="/articles/getting-started-guide.jpg"
          />
        </div>
      </div>
      <div className={classes.discover}>
        <Card>
          <Spacer direction="vertical" space={2}>
            <Card.Header
              level={2}
              title={t('home:latest-articles')}
              className={classes.discoverTitle}
            />
            <RegularArticles />
          </Spacer>
        </Card>
      </div>
      {/* <div className={classes.ad}>
        <Adsense slot="homepage" format="auto" responsive />
      </div> */}
    </div>
  );
};

export function BraveHomepage() {
  React.useLayoutEffect(() => {
    window.localStorage.setItem('coming-from-brave', 'true');
  });

  return <Home />;
}

export default Home;
