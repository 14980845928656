import React from 'react';
import { Spacer } from 'ui/spacer';
import { Typography } from 'antd';
import { keys } from 'xstate/lib/utils';
import { PeerReviewSource } from 'business/peer-review/types';
import { isNotNullOrUndefined } from 'technical/utils/is-not-null-or-undefined';
import { PeerReviewLink } from './link';

interface PeerReviewSourcesProps {
  sources: PeerReviewSource[];
  type?: 'default' | 'well';
}

export function PeerReviewSources({
  sources,
  type = 'default',
}: PeerReviewSourcesProps) {
  const sourcesByType = sources.reduce<Record<string, PeerReviewSource[]>>(
    (record, source) => {
      const { label } = source.sourceConfig;
      if (isNotNullOrUndefined(record[label])) {
        return {
          ...record,
          [label]: [...record[label], source],
        };
      }
      return { ...record, [label]: [source] };
    },
    {},
  );

  return (
    <Spacer direction="vertical" space={1}>
      {keys(sourcesByType).map((sourceType) => {
        return (
          <div key={sourceType}>
            <Typography.Text strong>{sourceType}</Typography.Text>
            <Spacer direction="vertical" space={1}>
              {sourcesByType[sourceType].map((source) => {
                return (
                  <PeerReviewLink
                    source={source.content}
                    key={source.id}
                    type={type}
                  />
                );
              })}
            </Spacer>
          </div>
        );
      })}
    </Spacer>
  );
}
