import { ColProps, Radio } from 'antd';
import classNames from 'classnames';
import { DraftChoice } from 'generated/graphql';
import React from 'react';
import { RecursiveFormNames } from 'ui/form/types';
import { RadioField, RadioFieldProps } from '../radio/radio';
import { SelectField, SelectFieldProps } from '../select';
import classes from './index.module.scss';

type SingleChoiceGenericT<FormValues> = Omit<
  SelectFieldProps<FormValues> | RadioFieldProps<FormValues>,
  'onChange'
> & {
  choices: DraftChoice[];
  label: React.ReactNode;
  name: RecursiveFormNames<FormValues>;
  id: string;
  labelCol?: ColProps;
  required?: boolean;
  onChange?: () => void;
};

// use generic type FormValues pssed when SingleChoice is called
export function SingleChoice<FormValues>({
  choices,
  ...otherProps
}: SingleChoiceGenericT<FormValues>) {
  const isOptionsTotalTextTooLong =
    choices.map((c) => c.value).join().length > 20;

  if (choices.length <= 3) {
    return (
      <RadioField<FormValues>
        {...otherProps}
        buttonStyle="solid"
        size="large"
        className={classNames(classes.radioGroup, {
          [classes.longOptions]: isOptionsTotalTextTooLong,
        })}
      >
        {choices.map((choice) => (
          <Radio.Button key={choice.id} value={choice.id}>
            {choice.value}
          </Radio.Button>
        ))}
      </RadioField>
    );
  }

  return (
    <SelectField<FormValues> // SelectFields must respect FormValues type
      {...otherProps}
      showSearch
      optionFilterProp="label"
      options={choices.map((choice) => ({
        key: choice.id,
        label: choice.value,
        value: choice.id,
      }))}
    />
  );
}
