import config from 'config';
import { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import logger from 'technical/logger';

type ITBWidgetOptions = {
  id?: string;
  apiKey: string;
  language: string;
  options: {
    tokenId: number;
    loader: boolean;
  };
};

declare const itbWidgetInit:
  | undefined
  | ((options: ITBWidgetOptions) => Promise<any>);

export function useITBWidget() {
  const { i18n } = useTranslation();
  const [status, setStatus] = useState<'idle' | 'script-loaded' | 'error'>(
    'idle',
  );

  useEffect(function loadTradingView() {
    // we load the script like that because when using <script src='...' />
    // script is prevented to load by the browser
    const script = document.createElement('script');
    script.async = true;
    script.src = 'https://app.intotheblock.com/widget.js';
    script.type = 'text/javascript';
    script.id = 'itb-summary-script';
    script.onerror = () => {
      setStatus('error');
    };
    script.onload = () => {
      setStatus('script-loaded');
    };
    document.head.appendChild(script);
    return () => {
      document.head.removeChild(script);
    };
  }, []);

  const renderWidget = useCallback(
    function render(id: string, options: { token: number }) {
      // after loading the script the TradingView object should be accessible
      if (typeof itbWidgetInit === 'undefined') {
        logger.error(
          'tried to access itbWidgetInit global, but it was not found',
        );
        setStatus('error');
        return;
      }

      itbWidgetInit({
        id,
        apiKey: config.itb.key,
        language: i18n.resolvedLanguage,
        options: {
          tokenId: options.token,
          loader: true,
        },
      });
    },
    [setStatus],
  );

  return {
    status,
    renderWidget: status === 'script-loaded' ? renderWidget : undefined,
  } as const;
}
