import variables from 'ui/theme/variables.module.scss';
import { RatingType } from 'business/rating/types';

export const ratingTagColors: Record<
  RatingType,
  { basic: string; transparent: string }
> = {
  junk: {
    basic: variables.ratingJunk,
    transparent: variables.ratingJunkSlightlyTransparent,
  },
  weak: {
    basic: variables.ratingWeak,
    transparent: variables.ratingWeakSlightlyTransparent,
  },
  medium: {
    basic: variables.ratingMedium,
    transparent: variables.ratingMediumSlightlyTransparent,
  },
  strong: {
    basic: variables.ratingStrong,
    transparent: variables.ratingStrongSlightlyTransparent,
  },
  reference: {
    basic: variables.ratingReference,
    transparent: variables.ratingReferenceSlightlyTransparent,
  },
  unknown: {
    basic: variables.ratingUnknown,
    transparent: variables.ratingJunkSlightlyTransparent,
  },
};
