import React from 'react';
import { Tabs, TabPane } from 'ui/tabs';
import { Card } from 'ui/cards';
import { PendingCommunityResearchesTab } from 'business/research/pages/сommunity-researches/pending-community-researches-tab';
import { ValidatedCommunityResearchesTab } from 'business/research/pages/сommunity-researches/validated-community-researches-tab';
import { Values } from 'technical/types';
import { useCurrentTab } from 'technical/router/url-param/use-current-tab';
import { useTranslation } from 'react-i18next';

const CommunityResearchTabsEnum = {
  pendingReview: 'pending-review',
  validatedReview: 'validated-review',
} as const;

type ValuesOfCommunityResearchTabsEnum = Values<
  typeof CommunityResearchTabsEnum
>;

export function CommunityResearchesPage() {
  const { t } = useTranslation(['token', 'research']);
  const [currentTab, setTab] = useCurrentTab<typeof CommunityResearchTabsEnum>({
    defaultTab: CommunityResearchTabsEnum.pendingReview,
    tabs: CommunityResearchTabsEnum,
  });

  return (
    <Card>
      <Tabs
        activeKey={currentTab}
        onChange={(tab) => setTab(tab as ValuesOfCommunityResearchTabsEnum)}
      >
        <TabPane
          tab={t('token:pending-community-researches.title')}
          key={CommunityResearchTabsEnum.pendingReview}
        >
          <PendingCommunityResearchesTab />
        </TabPane>

        <TabPane
          tab={t('research:validated-community-researches.title')}
          key={CommunityResearchTabsEnum.validatedReview}
        >
          <ValidatedCommunityResearchesTab />
        </TabPane>
      </Tabs>
    </Card>
  );
}
