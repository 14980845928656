import { EyeOutlined, QuestionCircleOutlined } from '@ant-design/icons';
import { Typography } from 'antd';
import { PublicRating, useFundamentalRatingQuery } from 'generated/graphql';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { BlurredFeature } from 'ui/blurred-feature';
import Button from 'ui/button';
import { Spacer } from 'ui/spacer';
import { Empty } from 'ui/empty-state';
import { ArrowDownFilled, ArrowUpFilled } from 'ui/icons';
import { RatingHeader } from 'business/token/components/rating-header';
import classes from './index.module.scss';
import config from 'config';

interface RatingHistoryProps {
  history: Array<{
    rating: Pick<PublicRating, 'name' | 'type'>;
    ratedAt: string;
    evolution?: number | null;
  }>;
}
function RatingHistory({ history }: RatingHistoryProps) {
  const { t } = useTranslation(['token']);

  return (
    <Spacer direction="vertical" space={1} className={classes.history}>
      <Typography.Title level={3} className={classes.title}>
        {t('token:fundamental-ratings.rating-history.last-updates')}
      </Typography.Title>
      {history.map((entry) => {
        let context: 'start' | 'up' | 'down' = 'start';

        if (entry.evolution && entry.evolution > 0) {
          context = 'up';
        }
        if (entry.evolution && entry.evolution < 0) {
          context = 'down';
        }

        return (
          <Spacer
            key={`${entry.rating.name}-${entry.ratedAt}`}
            space={1}
            className={classes.event}
            justify="between"
            align="center"
          >
            {context === 'start' ? (
              <>
                <Spacer space={1}>
                  <Typography.Text
                    strong
                    type="secondary"
                    className={classes.date}
                  >
                    {new Date(entry.ratedAt).toLocaleDateString()}
                  </Typography.Text>
                  <Typography.Text>
                    {t('token:rating.history.start', {
                      rating: entry.rating.name,
                    })}
                  </Typography.Text>
                </Spacer>
                <EyeOutlined style={{ visibility: 'hidden' }} />
              </>
            ) : null}
            {context === 'up' ? (
              <>
                <Spacer space={1}>
                  <Typography.Text
                    strong
                    type="secondary"
                    className={classes.date}
                  >
                    {new Date(entry.ratedAt).toLocaleDateString()}
                  </Typography.Text>

                  <Typography.Text>
                    {t('token:rating.history.up', {
                      rating: entry.rating.name,
                    })}
                  </Typography.Text>
                </Spacer>
                <ArrowUpFilled className={classes.arrowUp} />
              </>
            ) : null}
            {context === 'down' ? (
              <>
                <Spacer space={1}>
                  <Typography.Text
                    strong
                    type="secondary"
                    className={classes.date}
                  >
                    {new Date(entry.ratedAt).toLocaleDateString()}
                  </Typography.Text>

                  <Typography.Text strong>
                    {t('token:rating.history.down', {
                      rating: entry.rating.name,
                    })}
                  </Typography.Text>
                </Spacer>

                <ArrowDownFilled className={classes.arrowDown} />
              </>
            ) : null}
          </Spacer>
        );
      })}
    </Spacer>
  );
}

export interface FundamentalRatingProps {
  tokenReference: string;
}

export function FundamentalRating({ tokenReference }: FundamentalRatingProps) {
  const { t } = useTranslation(['token']);
  const { data, loading } = useFundamentalRatingQuery({
    variables: { tokenReference },
  });

  const ratings = data?.fundamental_rating.ratings ?? [];
  const currentRating =
    ratings && ratings.length > 0 ? ratings[0].rating : undefined;

  return (
    <BlurredFeature blurred={!loading && !data?.fundamental_rating.authorized}>
      <Spacer direction="vertical" space={2} align="start">
        {!loading && ratings.length === 0 ? (
          <Empty
            className={classes.empty}
            description={t('token:rating.no-data')}
          />
        ) : null}
        {ratings.length > 0 ? (
          <>
            <RatingHeader rating={currentRating} />
            <RatingHistory history={ratings} />
            <Button
              icon={<QuestionCircleOutlined />}
              size="small"
              type="link"
              htmlType="button"
              className={classes.questionButton}
              href={config.links.ratingScale}
              referrerPolicy="no-referrer"
              target="_blank"
              rel="noopener noreferrer"
            >
              {t('token:rating.scale.learn-more')}
            </Button>
          </>
        ) : null}
      </Spacer>
    </BlurredFeature>
  );
}
