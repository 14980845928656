import React from 'react';
import { Typography } from 'antd';
import { Card } from 'ui/cards';
import classes from './index.module.scss';

export interface PeerReviewTagProps {
  tags: { value: string | undefined; order: number }[];
  type?: 'default' | 'well';
}

export function PeerReviewTags({ tags, type = 'default' }: PeerReviewTagProps) {
  tags.sort((a, b) => {
    return a.order - b.order;
  });

  return (
    <Card className={classes.valueContainer} type={type}>
      {tags.map((tag) => {
        return (
          <Card className={classes.value} key={`${tag.value}-${tag.order}`}>
            <Typography.Text strong className={classes.text}>
              {tag.value}
            </Typography.Text>
          </Card>
        );
      })}
    </Card>
  );
}
