import React, { SVGProps } from 'react';

interface RedditLogoSVGComponentProps extends SVGProps<SVGSVGElement> {}

export function RedditLogoSVGComponent(props: RedditLogoSVGComponentProps) {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M6.26889 7.80588C5.83831 7.80588 5.48682 8.17771 5.48682 8.6332C5.48682 9.08869 5.83831 9.46052 6.26889 9.46052C6.69947 9.46052 7.05097 9.08869 7.05097 8.6332C7.05097 8.17771 6.69947 7.80588 6.26889 7.80588Z"
        fill="current"
      />
      <path
        d="M8 11.4219C8.29877 11.4219 9.3181 11.3847 9.85413 10.8177C9.93322 10.734 9.93322 10.6039 9.87171 10.5109C9.79262 10.4273 9.66081 10.4273 9.58172 10.5109C9.23902 10.8642 8.52724 10.9943 8.00879 10.9943C7.49033 10.9943 6.76977 10.8642 6.43585 10.5109C6.35677 10.4273 6.22496 10.4273 6.14587 10.5109C6.06678 10.5946 6.06678 10.734 6.14587 10.8177C6.67311 11.3754 7.70123 11.4219 8 11.4219Z"
        fill="current"
      />
      <path
        d="M8.93146 8.6332C8.93146 9.08869 9.28295 9.46052 9.71353 9.46052C10.1441 9.46052 10.4956 9.08869 10.4956 8.6332C10.4956 8.17771 10.1441 7.80588 9.71353 7.80588C9.28295 7.80588 8.93146 8.17771 8.93146 8.6332Z"
        fill="current"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M8 16C12.4183 16 16 12.4183 16 8C16 3.58172 12.4183 0 8 0C3.58172 0 0 3.58172 0 8C0 12.4183 3.58172 16 8 16ZM11.9016 6.64392C12.5079 6.64392 13 7.16448 13 7.80588C13 8.27996 12.7276 8.68898 12.3673 8.87489C12.3849 8.98644 12.3937 9.09799 12.3937 9.21883C12.3937 11.0036 10.4341 12.4444 8.00879 12.4444C5.58348 12.4444 3.6239 11.0036 3.6239 9.21883C3.6239 9.09799 3.63269 8.97714 3.65026 8.86559C3.26362 8.67968 3 8.27996 3 7.80588C3 7.16448 3.49209 6.64392 4.09842 6.64392C4.3884 6.64392 4.66081 6.77406 4.85413 6.96927C5.60984 6.38364 6.65554 6.02111 7.82425 5.98392L8.37786 3.2138C8.39543 3.15803 8.42179 3.11155 8.46573 3.08366C8.50967 3.05577 8.56239 3.04648 8.61511 3.05577L10.4341 3.46479C10.5571 3.18591 10.8207 3 11.1283 3C11.5589 3 11.9104 3.37183 11.9104 3.82732C11.9104 4.28281 11.5589 4.65464 11.1283 4.65464C10.7065 4.65464 10.3638 4.3014 10.3462 3.8645L8.72056 3.50197L8.21968 5.98392C9.36204 6.0304 10.3989 6.40223 11.1459 6.96927C11.3392 6.76476 11.6028 6.64392 11.9016 6.64392Z"
        fill="current"
      />
    </svg>
  );
}
