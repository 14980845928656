import React from 'react';
import { Values } from 'technical/types';
import colors from 'ui/theme/variables.module.scss';
import { ReactComponent as TeamAdvisor } from 'assets/illustrations/categories/team-advisor.svg';
import { ReactComponent as Project } from 'assets/illustrations/categories/project.svg';
import { ReactComponent as TractionCommunity } from 'assets/illustrations/categories/traction-community.svg';
import { ReactComponent as Token } from 'assets/illustrations/categories/token.svg';
import { ReactComponent as EcosystemCompetition } from 'assets/illustrations/categories/ecosystem-competition.svg';
import { ReactComponent as SocialSustainability } from 'assets/illustrations/categories/social-sustainability.svg';

export const CategoriesEnum = {
  teamAdvisors: 'teamAdvisors',
  project: 'project',
  tractionCommunity: 'tractionCommunity',
  token: 'token',
  ecosystemCompetition: 'ecosystemCompetition',
  socialSustainability: 'socialSustainability',
  unknown: 'unknown',
} as const;
export type CategoriesEnumValues = Values<typeof CategoriesEnum>;

export const categoriesIds: { [key: string]: CategoriesEnumValues } = {
  '44ab64d9-cd47-4921-91cd-9f2c332454d0': CategoriesEnum.teamAdvisors,
  'd8008be5-e8e5-4650-9acf-957c19cd0d73': CategoriesEnum.project,
  'bdeae55c-d868-4338-a4a9-882d5c6c379a': CategoriesEnum.tractionCommunity,
  '2d59e22d-a274-4aff-9864-3d5a474f4e81': CategoriesEnum.token,
  'd8514dd5-94c2-46a7-b166-ae2eabc5315e': CategoriesEnum.ecosystemCompetition,
  '59dedb9d-44de-4d8d-8010-f5ab36c65994': CategoriesEnum.socialSustainability,
} as const;

export const categoriesColors: {
  [key in CategoriesEnumValues]: string;
} = {
  [CategoriesEnum.teamAdvisors]: colors.redCategory,
  [CategoriesEnum.project]: colors.orangeCategory,
  [CategoriesEnum.tractionCommunity]: colors.blueCategory,
  [CategoriesEnum.token]: colors.pinkCategory,
  [CategoriesEnum.ecosystemCompetition]: colors.purpleCategory,
  [CategoriesEnum.socialSustainability]: colors.greenCategory,
  [CategoriesEnum.unknown]: 'red', // TODO define unknown category color
};

export const categoriesIcons: {
  [key in CategoriesEnumValues]: React.FunctionComponent<
    React.SVGProps<SVGSVGElement> & { title?: string }
  >;
} = {
  [CategoriesEnum.teamAdvisors]: TeamAdvisor,
  [CategoriesEnum.project]: Project,
  [CategoriesEnum.tractionCommunity]: TractionCommunity,
  [CategoriesEnum.token]: Token,
  [CategoriesEnum.ecosystemCompetition]: EcosystemCompetition,
  [CategoriesEnum.socialSustainability]: SocialSustainability, // TODO change this one, need another icon must be discussed in figma
  [CategoriesEnum.unknown]: Token, // TODO define unkonown cat icon, probably a question mark
};
