import React from 'react';
import { useTranslation } from 'react-i18next';
import { UsernameDisplayName } from 'business/user/components/profile-banner/username-display-name';
import { useAppContext } from 'business/app-bootstrapper';
import { useSelectUserProfileQuery } from 'generated/graphql';

export function UserName() {
  const { t } = useTranslation(['user']);
  const { user } = useAppContext();
  const { data } = useSelectUserProfileQuery({
    variables: {
      // @ts-expect-error
      userId: user?.id,
    },
  });
  const displayName = data?.userProfile?.displayName;

  return (
    <UsernameDisplayName
      displayName={displayName}
      noUsernameText={t('user:private-profile-banner.no-username')}
    />
  );
}
