import React, { useState } from 'react';
import { useAppContext } from 'business/app-bootstrapper';
import { Card, CardProps } from 'ui/cards';
import Button from 'ui/button';
import { Typography } from 'antd';
import { useTranslation } from 'react-i18next';
import { Spacer } from 'ui/spacer';
import LoginModal from 'business/user/components/login-modal';

interface LoggedCardProps extends CardProps {
  minContentHeight?: number;
}
export const LoggedCard: React.FC<LoggedCardProps> = ({
  minContentHeight = 400,
  children,
  ...props
}) => {
  const { isConnected } = useAppContext();
  const { t } = useTranslation(['common']);
  const [isOpen, setIsOpen] = useState(false);

  if (isConnected) {
    return <>{children}</>;
  }

  return (
    <>
      <Card {...props}>
        <Spacer
          direction="vertical"
          justify="center"
          style={{ minHeight: minContentHeight }}
        >
          <Spacer direction="vertical" space={2} align="center">
            <Typography.Text>
              {t('common:signIn.notAnUserYet.cta')}
            </Typography.Text>
            <Button onClick={() => setIsOpen(true)}>
              {t('common:signIn.cta')}
            </Button>
          </Spacer>
        </Spacer>
      </Card>
      <LoginModal isOpen={isOpen} setIsOpen={setIsOpen} />
    </>
  );
};
