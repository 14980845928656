import React from 'react';
import { Spacer } from 'ui/spacer';
import classNames from 'classnames';
import { ArticlesQuery, useArticlesQuery } from 'generated/graphql';
import { Article } from 'business/article/components/article';
import { ArticleTypesEnum } from 'business/article/types';
import classes from './index.module.scss';

export const skeletonArticles: ArticlesQuery['articles'] = [
  {
    id: '1',
    title: '',
    origin: '',
    link: '',
    thumbnail: '',
    type: '',
  },
  {
    id: '2',
    title: '',
    origin: '',
    link: '',
    thumbnail: '',
    type: '',
  },
  {
    id: '3',
    title: '',
    origin: '',
    link: '',
    thumbnail: '',
    type: '',
  },
  {
    id: '4',
    title: '',
    origin: '',
    link: '',
    thumbnail: '',
    type: '',
  },
];

interface HotTopicArticlesProps {
  className?: string;
}

export const HotTopicArticles: React.VFC<HotTopicArticlesProps> = ({
  className,
}) => {
  const { data, loading } = useArticlesQuery({
    variables: { types: [ArticleTypesEnum.hot] },
  });

  const articles = loading ? skeletonArticles : data?.articles ?? [];

  return (
    <Spacer space={2} className={classNames(classes.articles, className)}>
      {articles.map((article) => (
        <Article loading={loading} key={article.id} {...article} />
      ))}
    </Spacer>
  );
};
