import React, { useMemo } from 'react';
import {
  ResearchFormAnswerChoice,
  ResearchFormAnswerRepartition,
  ResearchFormAnswerRepartitionQuestionType,
  ResearchFormQuestionAnswerRepartition,
} from 'generated/graphql';
import { scaleBand, scaleLinear, scaleOrdinal } from '@visx/scale';
import { Group } from '@visx/group';
import { BarStackHorizontal } from '@visx/shape';
import { AxisLeft } from '@visx/axis';
import { Spacer } from 'ui/spacer';
import { formatPercent } from 'technical/currency/formatters';
import colors from 'ui/theme/variables.module.scss';
import { Text } from '@visx/text';

export type ChoiceAnswerRepartition = Pick<
  ResearchFormAnswerRepartition,
  'answerRankByPercentage' | 'percentage'
> & {
  choice: Pick<ResearchFormAnswerChoice, 'id' | 'value'>;
};

type SimpleChoiceAnswerRepartitionVisualisationProps = {
  question: Pick<ResearchFormQuestionAnswerRepartition, 'id'> & {
    type: Pick<ResearchFormAnswerRepartitionQuestionType, 'type'>;
    answersRepartition: Array<ChoiceAnswerRepartition>;
  };
};

// todo define colors properly factorise with simple ?
const barsColors = [
  '#4457FFFF', // 100
  // '#4457ffE6', // 90
  '#4457ffCC', // 80
  // '#4457ffB3', // 70
  '#4457ff99', // 60
  // '#4457ff80', // 50
  '#4457ff66', // 40
  // '#4457ff4D', // 30
  '#4457ff33', // 20
  // '#4457ff1A', // 10
];

const lineHeight = 15; // one line height (bar only)
const sizeBreakPoint = 500; // width which we switch to small visualisation
const desktopMargins = { left: 160, right: 50 };
const mobileMargins = { left: 0, right: 50 };
const paddings = { small: 0.65, desktop: 0.5 };
const labelOffsetRatio = { small: 1.4, desktop: 0 };

/**
 * Generate a dictionary from choices list
 * { [choiceId]: choice object }
 * @param filteredAnswersRepartition
 */
function getChoiceDictionary(
  filteredAnswersRepartition: ChoiceAnswerRepartition[],
) {
  return filteredAnswersRepartition.reduce(
    (previousValue, { choice, percentage }) => {
      // eslint-disable-next-line no-param-reassign
      previousValue[choice.id] = { value: choice.value, percentage };
      return previousValue;
    },
    {} as { [key: string]: { value: string; percentage: number } },
  );
}

/**
 * function to compute and format bars
 * we want only to display one data/stack per line
 * @param filteredAnswersRepartition
 */
function getComputedMultipleChoiceData(
  filteredAnswersRepartition: ChoiceAnswerRepartition[],
) {
  return filteredAnswersRepartition.map((answerRepartition) => ({
    id: answerRepartition.choice.id,
    [answerRepartition.choice.id]: answerRepartition.percentage,
  }));
}

/**
 * function to compute and format
 *    - keys (choice id, to differentiate each options)
 *    - choice dictionary, to get choice value using its id
 *    - grouped choice repartition in one stack
 * @param answersRepartition
 */
function getComputedDataForMultiChoice(
  answersRepartition: Array<ChoiceAnswerRepartition>,
) {
  return () => {
    // we could filter small percentage here
    // make sure we have distinct colors for each bar
    const filteredAnswersRepartition = answersRepartition.slice(
      0,
      barsColors.length,
    );

    const keys = filteredAnswersRepartition.map(
      (answerRepartition) => answerRepartition.choice.id,
    );

    const rangeColors = barsColors.slice(0, filteredAnswersRepartition.length);

    const choiceDictionary = getChoiceDictionary(filteredAnswersRepartition);

    const data = getComputedMultipleChoiceData(filteredAnswersRepartition);

    return { keys, choiceDictionary, data, rangeColors };
  };
}

function getTooltipY(
  choiceBarYPosition: number,
  choiceLabelOffsetRatio: number,
) {
  return (
    choiceBarYPosition + lineHeight / 2 - lineHeight * choiceLabelOffsetRatio
  );
}

export const MultiChoiceAnswerRepartitionVisualisation: React.VFC<
  Pick<
    SimpleChoiceAnswerRepartitionVisualisationProps['question'],
    'answersRepartition'
  > & { width: number }
> = ({ answersRepartition, width }) => {
  // keys have the values for the x-axis ticks.
  const {
    keys,
    choiceDictionary,
    data,
    rangeColors,
  } = useMemo(getComputedDataForMultiChoice(answersRepartition), [
    answersRepartition,
  ]);

  const isSmall = width < sizeBreakPoint;
  const margins = isSmall ? mobileMargins : desktopMargins;
  const padding = isSmall ? paddings.small : paddings.desktop; // padding of line, percentage svg height
  const linePaddingsHeight = lineHeight / (1 - padding) - lineHeight; // top + bottom line paddings
  const height =
    lineHeight * data.length + linePaddingsHeight * (data.length + 1);
  const choiceLabelOffsetRatio = isSmall
    ? labelOffsetRatio.small
    : labelOffsetRatio.desktop;

  // x scale
  const xMax = width - margins.left - margins.right;
  const yMax = height;
  const percentageScale = scaleLinear<number>({
    domain: [0, 1],
    range: [0, xMax],
  });

  const yScale = scaleBand({
    domain: answersRepartition.map((ar) => ar.choice.id),
    range: [0, yMax],
    padding,
  });

  const ordinalColorScale = scaleOrdinal({
    domain: keys,
    range: rangeColors,
  });

  return (
    <Spacer direction="vertical" space={1} align="center">
      <svg width={width} height={height}>
        <Group left={margins.left}>
          <BarStackHorizontal
            data={data}
            keys={keys}
            xScale={percentageScale}
            color={ordinalColorScale}
            yScale={yScale}
            y={(yDomain) => yDomain.id}
          >
            {(barStacks) =>
              barStacks.map((barStack) =>
                barStack.bars
                  .filter((bar) => !Number.isNaN(bar.bar[1]))
                  .map((bar) => (
                    <Group
                      id={JSON.stringify({ bar, index: barStack.index })}
                      key={`choice-percentage-use-barstack-horizontal-group-${barStack.index}-${bar.index}`}
                    >
                      <rect
                        x={bar.x}
                        y={bar.y}
                        rx={bar.height / 2}
                        width={bar.width}
                        height={bar.height}
                        fill={bar.color}
                      />
                      <Text
                        x={bar.x + bar.width + 12}
                        y={bar.y + bar.height / 2}
                        verticalAnchor="middle"
                        fill={colors.textColor}
                        fontSize={14}
                      >
                        {formatPercent(
                          choiceDictionary[bar.bar.data.id].percentage,
                        )}
                      </Text>
                    </Group>
                  )),
              )
            }
          </BarStackHorizontal>

          <AxisLeft
            hideAxisLine
            hideTicks
            scale={yScale}
            tickFormat={(choiceId) => choiceDictionary[choiceId]?.value}
            tickLabelProps={(choiceId) => ({
              fill: colors.textColor,
              fontSize: 14,
              textAnchor: 'start',
              y: getTooltipY(yScale(choiceId) ?? 0, choiceLabelOffsetRatio),
              x: -margins.left,
              verticalAnchor: 'middle',
            })}
          />
        </Group>
      </svg>
    </Spacer>
  );
};
