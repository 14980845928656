import { gql } from 'graphql-tag';

export const RESEARCH_DRAFT = gql`
  query ResearchDraft($token: ID!) {
    draft: get_research_draft(token: $token) {
      id
      targetPrice
      userOpinionRatingId
      comment
      categories {
        id
        name
        isValid
        suggestions {
          id
          title
        }
        questions {
          ... on MultiChoiceQuestion {
            id
            title
            question
            mandatory
            description
            tip
            isValid
            metadata
            type
            sources {
              content {
                id
                type
                value
              }
              accepted
              configId
              label
              required
              when
            }
            comments {
              configId
              content {
                id
                value
              }
              label
              required
              when
            }
            choices {
              id
              order
              value
              comment
            }
            choiceIds {
              choiceId
              id
            }
          }
          ... on SingleChoiceQuestion {
            id
            title
            question
            mandatory
            description
            tip
            isValid
            metadata
            type
            sources {
              content {
                id
                type
                value
              }
              accepted
              configId
              label
              required
              when
            }
            comments {
              configId
              content {
                id
                value
              }
              label
              required
              when
            }
            choices {
              id
              order
              value
              comment
            }
            choiceId
          }
          ... on TagQuestion {
            id
            title
            question
            mandatory
            description
            tip
            isValid
            metadata
            type
            sources {
              content {
                id
                type
                value
              }
              accepted
              configId
              label
              required
              when
            }
            comments {
              configId
              content {
                id
                value
              }
              label
              required
              when
            }
            tags {
              value
              id
            }
          }
          ... on TokenQuestion {
            id
            title
            question
            mandatory
            description
            tip
            isValid
            metadata
            type
            sources {
              content {
                id
                type
                value
              }
              accepted
              configId
              label
              required
              when
            }
            comments {
              configId
              content {
                id
                value
              }
              label
              required
              when
            }
            tokenIds {
              tokenId
              id
            }
          }
        }
      }
    }
  }
`;

export const TOKEN_RESEARCH_INFORMATIONS = gql`
  query TokenResearchInformations($tokenId: uuid!) {
    token: token_by_pk(id: $tokenId) {
      id
      name
      symbol
      reference
      isCovered
      coinGeckoImage
      infos {
        h24High
        h24Low
        price
      }
    }
  }
`;

export const SAVE_SELECTED_CATEGORIES = gql`
  mutation SaveSelectedCategories(
    $researchId: ID!
    $selectedCategories: [ID!]!
  ) {
    save_selected_categories(
      researchId: $researchId
      selectedCategories: $selectedCategories
    ) {
      categoryId
      isSelected
      researchId
    }
  }
`;

export const SAVE_ANSWER = gql`
  mutation SaveAnswer($researchId: ID!, $answer: PartialAnswer!) {
    save_answer(answer: $answer, researchId: $researchId) {
      answerId
    }
  }
`;

export const UPDATE_FINAL_THOUGHTS = gql`
  mutation UpdateFinalThoughts(
    $researchId: ID!
    $targetPrice: Float
    $comment: String
    $userOpinionRatingId: String
  ) {
    save_prediction(
      researchId: $researchId
      targetPrice: $targetPrice
      comment: $comment
      userOpinionRatingId: $userOpinionRatingId
    ) {
      researchId
    }
  }
`;

export const PUBLISH_RESEARCH = gql`
  mutation PublishResearch($researchId: ID!) {
    publish_research(researchId: $researchId) {
      researchId
    }
  }
`;

export const GetUserLastResearchOnToken = 'GetUserLastResearchOnToken';
export const GET_USER_LAST_RESEARCH_ON_TOKEN = gql`
  query GetUserLastResearchOnToken($userId: uuid!, $tokenId: uuid!) {
    research: research_research(
      where: { authorId: { _eq: $userId }, tokenId: { _eq: $tokenId } }
      limit: 1
      order_by: { createdAt: desc_nulls_last }
    ) {
      id
      authorId
      tokenId
      status
      publishedAt
      token {
        hoursBetweenResearches
      }
    }
  }
`;

// TODO: do this in a resolver ?
export const PendingResearchForToken = 'PendingResearchForToken';
export const PENDING_RESEARCH_FOR_TOKEN = gql`
  query PendingResearchForToken($tokenId: uuid!) {
    researches: research_research(
      where: { status: { _neq: draft }, tokenId: { _eq: $tokenId } }
      order_by: { publishedAt: desc }
    ) {
      id
      tokenId
      authorId
      status
      publishedAt
      comment
      targetPrice
      hasEnoughReviews: hasEnoughPeerReview
      selectedCategories(
        where: { isSelected: { _eq: true } }
        order_by: { category: { order: asc } }
      ) {
        questionCategory: category {
          id
          name
        }
      }
      token {
        id
        name
        symbol
        reference
        coinGeckoImage
      }
      rating {
        name
        type
      }
      reviews: peerReviews {
        id
        status
        outcome
      }
    }
  }
`;

// we force some update columns even though we never set comment here to be able to always get the returned values
export const CREATE_REVIEW = gql`
  mutation CreateReview($researchId: uuid!) {
    insert_research_peerReview_one(
      object: { researchId: $researchId }
      on_conflict: {
        constraint: peerReview_reviewerId_researchId_key
        update_columns: [comment]
      }
    ) {
      id
      researchId
      status
    }
  }
`;

export const GetPendingDayRankedResearches = 'GetPendingDayRankedResearches';
export const GET_PENDING_DAY_RANKED_RESEARCHES = gql`
  query GetPendingDayRankedResearches(
    $page: Int!
    $pageSize: Int!
    $tokens: [String!]
    $hasEnoughReviews: Boolean
  ) {
    getPendingDayRankedResearches(
      page: $page
      pageSize: $pageSize
      filters: { tokenRefs: $tokens, hasEnoughReviews: $hasEnoughReviews }
    ) {
      success
      message
      count
      researches {
        id
        tokenId
        authorId
        status
        created_at
        updated_at
        publishedAt
        comment
        targetPrice
        hasEnoughReviews
        rating {
          name
          type
        }
        selectedCategories {
          questionCategory {
            id
            name
          }
        }
        token {
          id
          name
          symbol
          reference
          coinGeckoImage
        }
        reviews {
          id
          status
          outcome
        }
      }
    }
  }
`;

export const getValidatedResearches = 'GetValidatedResearches';
export const GET_VALIDATED_RESEARCHES = gql`
  query GetValidatedResearches(
    $page: Int!
    $pageSize: Int!
    $displayNameOrder: OrderByLite
    $rankOrder: OrderByLite
    $publishedAtOrder: OrderByLite
    $tokenOrder: OrderByLite
    $tokens: [String!]
  ) {
    getValidatedResearches(
      page: $page
      pageSize: $pageSize
      orderBy: {
        author: {
          profile: { displayName: $displayNameOrder }
          ranking: { totalXP: $rankOrder }
        }
        publishedAt: $publishedAtOrder
        token: { name: $tokenOrder }
      }
      filters: { tokenRefs: $tokens }
    ) {
      success
      message
      count
      researches {
        id
        status
        updated_at
        publishedAt
        targetPrice
        hasEnoughReviews
        rating {
          name
          type
        }

        author {
          id
          ranking {
            currentRank {
              name
              icon
            }
          }
          profile {
            displayName
          }
        }

        selectedCategories {
          questionCategory {
            name
            id
          }
        }

        token {
          id
          name
          symbol
          reference
          coinGeckoImage
        }
      }
    }
  }
`;
export const READONLY_RESEARCH = gql`
  query ReadOnlyResearch($researchId: uuid!) {
    research: research_research_by_pk(id: $researchId) {
      categories: selectedCategories(
        where: { isSelected: { _eq: true } }
        order_by: { category: { order: asc } }
      ) {
        infos: category {
          name
          id
        }
        rating {
          name
          type
        }
      }
      author {
        ranking {
          currentRank {
            id
            name
            icon
          }
        }
        profile {
          displayName
        }
      }
      rating {
        name
        type
      }
      comment
      targetPrice
      answers {
        id
        sources {
          content
          id
          sourceConfig {
            id
            label
          }
        }
        comments {
          content
          id
          commentConfig {
            label
            id
          }
        }
        answerTypeMultiChoices {
          id
          choice {
            id
            order
            value
          }
        }
        answerTypeSingleChoice {
          id
          choice {
            id
            value
          }
        }
        answerTypeTokens {
          id
          token {
            id
            name
            coinGeckoImage
          }
        }
        answerTypeTags {
          id
          value
        }
        question {
          category {
            name
            order
            id
          }
          title
          question
          id
          order
          tip
          description
          type {
            type
          }
          choices {
            id
            value
            comment
          }
        }
      }
      token {
        id
        name
        symbol
        reference
        coinGeckoImage
      }
    }
  }
`;
export const GET_TOKEN_RESEARCH_CATEGORIES = gql`
  query GetTokenResearchCategories($tokenReference: String!) {
    tokenResearchCategories(tokenReference: $tokenReference) {
      categories {
        id
        name
        lastResearchedTime
        rating {
          name
          type
        }
      }
    }
  }
`;
export const GET_USER_TOKEN_PENDING_RESEARCHES = gql`
  query GetUserTokenPendingResearches($authorId: uuid!, $tokenId: uuid!) {
    research: research_research(
      where: {
        authorId: { _eq: $authorId }
        tokenId: { _eq: $tokenId }
        status: { _eq: published }
      }
    ) {
      id
      authorId
    }
  }
`;

export const UPDATE_RESEARCH_BY_PK = gql`
  mutation UpdateResearchByPk(
    $researchId: uuid!
    $targetPrice: numeric!
    $feelRatingId: uuid
    $comment: String!
  ) {
    research: update_research_research_by_pk(
      pk_columns: { id: $researchId }
      _set: {
        targetPrice: $targetPrice
        ratingId: $feelRatingId
        comment: $comment
      }
    ) {
      id
    }
  }
`;
