import React from 'react';

/**
 * @param load dynamic import function. this should import a `*.theme.less` file
 * @returns an object containing a React.lazy component that injects the style into the page, and a preload function that can be use to prefetch the theme file before switching theme.
 */
function createTheme(load: () => Promise<any>) {
  let preloadedStylesheet: any;
  let pendingPromise: Promise<void> | undefined;

  // we manually create a factory function for React.lazy
  async function lazyStylesheetLoader() {
    const stylesheet = preloadedStylesheet ?? (await load());

    function StylesheetLoader() {
      // we use a layout effect to inject style before painting
      React.useLayoutEffect(() => {
        // dynamic theme files exposes a `use` and `unuse` function that injet or remove the style dynamically
        stylesheet.use();
        return () => stylesheet.unuse();
      }, [stylesheet]);

      return null;
    }

    return {
      default: StylesheetLoader,
    };
  }

  function preload() {
    if (!pendingPromise) {
      pendingPromise = load().then((module) => {
        preloadedStylesheet = module.default;
      });
    }

    return pendingPromise;
  }

  return {
    preload,
    component: React.lazy(lazyStylesheetLoader),
  };
}

const themes = {
  light: createTheme(() => import('./light.theme.less')),
  dark: createTheme(() => import('./dark.theme.less')),
} as const;

export default themes;
