import React, { PropsWithChildren } from 'react';
import { Card } from 'ui/cards';
import classes from 'business/portfolio/components/portfolio-container/portfolio-container.module.scss';
import classNames from 'classnames';

interface PortfolioContainerProps {
  classsname?: string;
}

export const PortfolioContainer: React.FC<
  PropsWithChildren<PortfolioContainerProps>
> = ({ children, classsname }) => {
  return (
    <Card className={classNames(classsname, classes.portfolioFormCard)}>
      {children}
    </Card>
  );
};
