import * as yup from 'yup';
import { Segmented } from 'antd';
import { FieldArray, useField } from 'formik';
import { SearchCondition, SearchCriteria } from 'generated/graphql';
import React from 'react';
import Button, { LinkAsButton } from 'ui/button';
import { Form } from 'ui/form';
import { SelectField } from 'ui/form/fields';
import { Spacer } from 'ui/spacer';
import classes from './index.module.scss';
import { DeleteFilled } from '@ant-design/icons';
import { Card } from 'ui/cards';
import config from 'config';
import { criteriaSchema } from 'business/token/services/advanced-search-schema';

export type QuestionList = Array<{
  id: string;
  title: string;
  prompt: string;
  category: string;
  choices: Array<{
    id: string;
    value: string;
  }>;
}>;

type CriteriaProps = {
  id: string;
  name: string;
  remove?: () => void;
  questions: QuestionList;
  loading?: boolean;
};
function Criteria({
  id,
  name,
  questions,
  remove,
  loading = false,
}: CriteriaProps) {
  const [{ value }, , { setValue, setTouched }] = useField<
    SearchCondition | undefined
  >({
    name,
  });

  const selection = questions.find(
    (question) => value?.questionId === question.id,
  ) || {
    choices: [],
  };
  const groupedQuestions = questions.reduce((map, question) => {
    const list = map.get(question.category) || [];
    list.push(question);
    map.set(question.category, list);
    return map;
  }, new Map<string, QuestionList>());

  return (
    <Card type="well" className={classes['no-padding']}>
      <Spacer
        direction="vertical"
        space={'none'}
        className={classes.criteria}
        data-loading={loading}
      >
        {remove !== undefined ? (
          <div className={classes.trash}>
            <Button
              htmlType="button"
              type="text"
              icon={<DeleteFilled />}
              onClick={remove}
              size="large"
            />
          </div>
        ) : null}
        <SelectField<any>
          id={`${id}-question-id`}
          name={`${name}.questionId`}
          label="Question"
          showSearch
          optionFilterProp="label"
          onChange={(questionId) => {
            setValue(
              { questionId: `${questionId}` ?? '', choiceId: '' },
              false,
            );
            setTouched(false);
          }}
          options={Array.from(groupedQuestions.entries()).map(
            ([category, list]) => {
              return {
                label: category,
                options: list.map((question) => ({
                  label: question.prompt,
                  value: question.id,
                })),
              };
            },
          )}
        ></SelectField>
        <SelectField<any>
          id={`${id}-choice-id`}
          name={`${name}.choiceId`}
          label="Answer"
          showSearch
          optionFilterProp="label"
          options={selection.choices.map((choice) => ({
            value: choice.id,
            label: choice.value,
          }))}
        ></SelectField>
      </Spacer>
    </Card>
  );
}

type GroupProps = {
  id: string;
  name: string;
  remove?: () => void;
  questions: QuestionList;
  depth?: number;
  loading?: boolean;
};
function Group({
  id,
  name,
  remove,
  questions,
  depth = 0,
  loading = false,
}: GroupProps) {
  const [{ value }, , { setValue }] = useField<SearchCriteria>({ name });
  const type = Object.keys(value)[0] as keyof SearchCriteria;

  if (type === 'condition') {
    throw new Error(
      "<Group/> was rendered with type 'condition', it should be either 'or'/'and'",
    );
  }

  const filters = value[type]?.concat() ?? [];
  if (filters.length === 0) {
    filters.push({ condition: { questionId: '', choiceId: '' } });
  }

  return (
    <div className={classes.group}>
      {remove !== undefined ? (
        <div className={classes.trash}>
          <Button
            htmlType="button"
            type="text"
            icon={<DeleteFilled />}
            onClick={remove}
            size="large"
          />
        </div>
      ) : null}
      <label htmlFor={`${id}-type`} hidden>
        Filter type
      </label>
      <Segmented
        className={classes.segmented}
        id={`${id}-type`}
        size="middle"
        value={type}
        // hidden={filters.length === 1}
        options={[
          {
            label: 'Or',
            value: 'or',
          },
          {
            label: 'And',
            value: 'and',
          },
        ]}
        onChange={(newType) => {
          if (newType === 'or' || newType === 'and') {
            setValue({
              [newType]: value[type],
            });
          }
        }}
      />
      <FieldArray name={`${name}.${type}`}>
        {(list) => {
          return (
            <Spacer direction={'vertical'} space={2}>
              {filters.map((filter, index) => {
                const currentFilterType = Object.keys(
                  filter,
                )[0] as keyof SearchCriteria;

                const isFirstFilterOfGroup = index === 0;
                const groupHasOnlyOnFilter = filters.length === 1;
                const currentFilterIsNotAGroup =
                  currentFilterType === 'condition';
                const criteriaWasNotTouched =
                  !filter.condition?.questionId && !filter.condition?.choiceId;

                const removeChild =
                  isFirstFilterOfGroup &&
                  groupHasOnlyOnFilter &&
                  currentFilterIsNotAGroup &&
                  criteriaWasNotTouched
                    ? undefined
                    : () => list.remove(index);

                switch (currentFilterType) {
                  case 'condition':
                    return (
                      <Criteria
                        key={`${id}-${type}-${index}`}
                        id={`${id}-${type}-${index}`}
                        name={`${name}.${type}.${index}.condition`}
                        remove={removeChild}
                        questions={questions}
                        loading={loading}
                      />
                    );
                  case 'and':
                  case 'or':
                    return (
                      <Group
                        key={`${id}-${type}-${index}`}
                        id={`${id}-${type}-${index}`}
                        name={`${name}.${type}.${index}`}
                        remove={removeChild}
                        questions={questions}
                        depth={depth + 1}
                        loading={loading}
                      />
                    );
                }
              })}
              <Spacer space={1} align="center">
                <Button
                  htmlType="button"
                  type="ghost"
                  size="small"
                  block
                  onClick={() =>
                    list.push({
                      condition: { questionId: null, choiceId: null },
                    })
                  }
                >
                  Add filter
                </Button>
                {depth < 2 ? (
                  <Button
                    htmlType="button"
                    type="ghost"
                    size="small"
                    block
                    onClick={() =>
                      list.push({
                        and: [
                          { condition: { questionId: null, choiceId: null } },
                          { condition: { questionId: null, choiceId: null } },
                        ],
                      })
                    }
                  >
                    Add group
                  </Button>
                ) : (
                  <Button
                    htmlType="button"
                    type="ghost"
                    size="small"
                    block
                    href={config.links.discord}
                  >
                    Contact us for more filters
                  </Button>
                )}
              </Spacer>
            </Spacer>
          );
        }}
      </FieldArray>
    </div>
  );
}

type FormValues = {
  criteria: SearchCriteria;
};

export type MultiCriteriaSelectorProps = {
  loading?: boolean;
  criteria: SearchCriteria;
  updateCriteria: (newCriteria: SearchCriteria) => void;
  questions: QuestionList;
};

export function MultiCriteriaSelector({
  questions,
  criteria,
  updateCriteria,
  loading = false,
}: MultiCriteriaSelectorProps) {
  return (
    <Form<FormValues>
      onSubmit={(value) => {
        updateCriteria(value.criteria);
      }}
      initialValues={{
        criteria,
      }}
      validationSchema={yup.object({ criteria: criteriaSchema })}
    >
      {({ resetForm }) => {
        return (
          <Spacer
            direction="vertical"
            space={2}
            className={classes['move-right']}
          >
            <Group
              id={'root2'}
              name="criteria"
              questions={questions}
              loading={loading}
            />
            <Spacer direction="horizontal" justify="end">
              <Button
                htmlType="button"
                type="text"
                onClick={() => {
                  const resetCriteria = {
                    and: [],
                  };
                  updateCriteria(resetCriteria);
                  resetForm({
                    values: {
                      criteria: resetCriteria,
                    },
                  });
                }}
              >
                Reset
              </Button>
              <Button htmlType="submit" type="primary">
                Search
              </Button>
            </Spacer>
          </Spacer>
        );
      }}
    </Form>
  );
}
