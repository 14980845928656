import React, { FC } from 'react';
import { categoriesIds } from 'business/research/constants/category';
import { CategoryIcon } from 'business/research/components/category-icon';
import { Spacer } from 'ui/spacer';

interface TokenCategoriesIconsProps {
  categoriesIconsIds: Array<string>;
}

export const TokenCategoriesIcons: FC<TokenCategoriesIconsProps> = ({
  categoriesIconsIds,
}) => {
  const date = new Date().getTime();

  return (
    <Spacer space={0}>
      {Object.keys(categoriesIds).map((id) => {
        const key = date + id;

        return (
          <CategoryIcon
            disabled={!categoriesIconsIds.includes(id)}
            key={key}
            category={id}
          />
        );
      })}
    </Spacer>
  );
};
