import React, { useState } from 'react';
import { Radio, Segmented } from 'antd';
import { Card } from 'ui/cards';
import { Spacer } from 'ui/spacer';
import { useTranslation } from 'react-i18next';
import {
  Token,
  TokenMarketChartPeriods,
  useMarketChartQuery,
} from 'generated/graphql';
import { ParentSize } from '@visx/responsive';
import Select from 'ui/select';
import classes from './index.module.scss';
import { TradingViewCharts } from './tradingview-charts';
import { HistoricalChart } from '../historical-chart';

const EXCHANGES = [
  { value: 'BINANCE', label: 'Binance' },
  { value: 'COINBASE', label: 'Coinbase' },
];

const defaultExchange = EXCHANGES[0].value;

const PERIODS: Array<{
  name: string;
  value: TokenMarketChartPeriods;
}> = [
  { name: '1D', value: TokenMarketChartPeriods.Day },
  { name: '1M', value: TokenMarketChartPeriods.Month },
  { name: '3M', value: TokenMarketChartPeriods.Month3 },
  { name: '1Y', value: TokenMarketChartPeriods.Year },
  { name: '5Y', value: TokenMarketChartPeriods.Year5 },
  { name: 'ALL', value: TokenMarketChartPeriods.All },
];

const defaultPeriod = PERIODS[0].value;

export interface PriceEvolutionChartsProps {
  token: Pick<Token, 'symbol' | 'reference'>;
}
export function PriceEvolutionCharts({ token }: PriceEvolutionChartsProps) {
  const { t } = useTranslation(['token']);
  const [chart, setChart] = useState<'kryptview' | 'tradingview'>('kryptview');
  const [period, setPeriod] = useState(defaultPeriod);
  const [exchange, setExchange] = useState<string>(defaultExchange);
  const { data, previousData, loading } = useMarketChartQuery({
    variables: {
      reference: token.reference,
      period,
    },
  });

  return (
    <div>
      <Card.Header
        level={2}
        title={t('token:trading-view.title')}
        actions={
          <Spacer space={1} align="center">
            {/* TODO: do we want to be able to switch exchange ? */}
            {chart === 'tradingview' ? (
              <Select
                value={exchange}
                handleChange={setExchange}
                options={EXCHANGES}
                className={classes['shallow-select']}
              />
            ) : null}
            <Segmented
              className={classes.segmented}
              options={[
                {
                  label: t('token:trading-view.charts.simple'),
                  value: 'kryptview',
                },
                {
                  label: t('token:trading-view.charts.ticker'),
                  value: 'tradingview',
                },
              ]}
              onChange={(value) => {
                if (value === 'tradingview' || value === 'kryptview') {
                  setChart(value);
                }
              }}
            />
          </Spacer>
        }
      />

      {chart === 'tradingview' ? (
        <TradingViewCharts
          symbol={token.symbol}
          exchange={exchange}
          type="ticker"
        />
      ) : null}
      {chart === 'kryptview' ? (
        <ParentSize>
          {({ width }) => (
            <HistoricalChart
              data={
                (data ?? previousData)?.getTokenMarketChart.chart?.data ?? []
              }
              loading={loading}
              periods={PERIODS}
              width={width}
              height={426}
              selectedPeriod={period}
              onPeriodChange={(newPeriod) => {
                setPeriod(newPeriod);
              }}
            />
          )}
        </ParentSize>
      ) : null}
    </div>
  );
}
