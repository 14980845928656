import React from 'react';
import { Image, Typography } from 'antd';
import classes from './index.module.scss';

export interface SparseUserRankingProps {
  icon: string;
  position: number | string;
}
export function CompactUserRanking({ icon, position }: SparseUserRankingProps) {
  return (
    <>
      <Image
        src={`/ranks${icon}`}
        height={85}
        width={127}
        preview={false}
        style={{ objectFit: 'cover' }}
      />
      <Typography.Text strong className={classes.rank}>
        #{position}
      </Typography.Text>
    </>
  );
}
