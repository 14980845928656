type ExtractReturnValues<T> = {
  [Key in keyof T]: T[Key] extends (...args: any[]) => infer ReturnValue
    ? ReturnValue
    : T[Key];
}[keyof T];

export function match<
  TValue extends string | number,
  TArgs extends any[],
  TLookUp extends Record<
    TValue,
    | string
    | null
    | undefined
    | boolean
    | number
    | object
    | ((...args_0: TArgs) => any)
  >
>(
  value: TValue,
  lookup: TLookUp,
  ...args: TArgs
): ExtractReturnValues<TLookUp> {
  if (value in lookup) {
    const returnValue = lookup[value];
    return typeof returnValue === 'function'
      ? returnValue(...args)
      : returnValue;
  }

  throw new Error(
    `Tried to handle "${value}" but there is no handler defined. Only defined handlers are: ${Object.keys(
      lookup,
    )
      .map((key) => `"${key}"`)
      .join(', ')}.`,
  );
}
