import { Empty, Typography } from 'antd';
import {
  Maybe,
  ResearchFormAnswerChoice,
  ResearchFormAnswerRepartition,
  ResearchFormQuestion,
} from 'generated/graphql';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { Card } from 'ui/cards';
import { Spacer } from 'ui/spacer';
import { CategoryIcon } from 'business/research/components/category-icon';
import SeeMoreLink from 'ui/see-more-link';
import Tag from 'ui/tag';
import variables from 'ui/theme/variables.module.scss';
import classes from './category-preview.module.scss';

type QuestionPreview = Pick<
  ResearchFormQuestion,
  'id' | 'title' | 'question'
> & {
  type: { type: string };
  answersRepartition?: Maybe<
    Array<
      Pick<
        ResearchFormAnswerRepartition,
        'answerRankByPercentage' | 'percentage'
      > & {
        choice?: Maybe<Pick<ResearchFormAnswerChoice, 'id' | 'value'>>;
      }
    >
  >;
};

export interface CategoryPreviewProps {
  name: string;
  id: string;
  questions: QuestionPreview[];
  selectCategory: () => void;
  canSeeMore?: boolean;
}
export function CategoryPreview({
  name,
  id,
  questions,
  selectCategory,
  canSeeMore = false,
}: CategoryPreviewProps) {
  const { t } = useTranslation(['token']);

  return (
    <Card>
      <Spacer direction="vertical" space={1} className={classes.cardContent}>
        <Spacer space={1} align="center" direction="horizontal">
          <CategoryIcon category={id} />
          <Typography.Title level={4} className={classes.cardTitle}>
            {name}
          </Typography.Title>
        </Spacer>
        {questions.length > 0 ? (
          questions.map((question) => {
            const answerRepartition = question.answersRepartition?.length
              ? question.answersRepartition[0]
              : null;
            if (answerRepartition) {
              return (
                <Spacer key={question.id} space={1} align="center">
                  <Typography.Text strong>{question.title}:</Typography.Text>
                  <Typography.Text>
                    {answerRepartition.choice?.value}
                  </Typography.Text>
                  <Tag color={variables.grey200}>
                    <Typography.Text strong>
                      {t('token:research-answers.category-preview.answer', {
                        percentage: (
                          answerRepartition.percentage * 100
                        ).toFixed(2),
                      })}
                    </Typography.Text>
                  </Tag>
                </Spacer>
              );
            }

            return null;
          })
        ) : (
          <Empty
            className={classes.empty}
            description={t(
              'token:research-answers.category-preview.no-answer-in-category',
            )}
          />
        )}
        {questions.length > 0 && canSeeMore ? ( // only show see more button if there is at least one answer and user is logged
          <SeeMoreLink
            onClick={selectCategory}
            className={classes['see-more']}
          />
        ) : null}
      </Spacer>
    </Card>
  );
}
