import authService from 'business/user/services/authentication.gql';
import Routes from 'business/router/routes';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';
import ResetPasswordCard, {
  Values,
} from 'business/user/components/reset-password-card';
import PageCenteredContent from '../../../../ui/layouts/page-centered-content';

interface ResetPasswordRouteProps {
  token?: string; // Generated by the back when user request a change of password
}

function ResetPasswordPage() {
  const [errorResetPassword, setErrorResetPassword] = useState<string>('');
  const params = useParams<'*'>();
  const navigate = useNavigate();

  const { t } = useTranslation(['common']);

  const token = params['*'] || null;

  const handleSubmitClick = async (values: Values) => {
    setErrorResetPassword('');
    try {
      await authService.resetPassword(token, values.password, values.password);
      navigate(Routes.SignIn);
    } catch (error) {
      setErrorResetPassword(error.message);
    }
  };

  return (
    <PageCenteredContent>
      <ResetPasswordCard
        submitButton={{
          label: t('pages.resetPassword.submit'),
          onClick: handleSubmitClick,
        }}
        errorResetPassword={errorResetPassword}
        description={t('pages.resetPassword.description')}
        inputType="password"
      />
    </PageCenteredContent>
  );
}

export default ResetPasswordPage;
