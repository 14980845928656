import React, { SVGProps } from 'react';
import variables from 'ui/theme/variables.module.scss';

interface FooterKryptviewSVGComponentProps extends SVGProps<SVGSVGElement> {}

export function FooterKryptviewSVGComponent(
  props: FooterKryptviewSVGComponentProps,
) {
  return (
    <svg
      width="140"
      height="29"
      viewBox="0 0 140 29"
      xmlns="http://www.w3.org/2000/svg"
      fill={variables.textColor}
      {...props}
    >
      <path
        d="M38.0137 21.819V6.93123H40.5446V13.3755H43.905C44.4438 13.3755 44.9329 13.2762 45.3725 13.0777C45.8262 12.8651 46.1807 12.5602 46.4359 12.1632C46.7053 11.7662 46.84 11.2912 46.84 10.7382V6.93123H49.3709V10.717C49.3709 11.6953 49.1299 12.5531 48.6478 13.2904C48.1657 14.0277 47.5276 14.602 46.7336 15.0131L48.6052 19.0966C48.6761 19.2668 48.7683 19.3873 48.8817 19.4582C48.9952 19.5291 49.144 19.5645 49.3284 19.5645H50.5832V21.819H48.9455C48.2791 21.819 47.7262 21.6772 47.2866 21.3936C46.8612 21.11 46.5351 20.7201 46.3083 20.2239L44.1814 15.5874C44.0255 15.6016 43.8766 15.6157 43.7348 15.6299C43.593 15.6299 43.4371 15.6299 43.2669 15.6299H40.5446V21.819H38.0137Z"
        fill="var(--text-color)"
      />
      <path
        d="M51.9387 21.819V14.0561C51.9387 13.0919 52.2081 12.3404 52.7469 11.8016C53.2999 11.2487 54.0584 10.9722 55.0226 10.9722H57.6386V13.0565H55.4692C55.1289 13.0565 54.8595 13.1557 54.661 13.3542C54.4767 13.5527 54.3845 13.8221 54.3845 14.1624V21.819H51.9387Z"
        fill="var(--text-color)"
      />
      <path
        d="M63.0141 26.1364V21.9678C62.1917 21.8119 61.4828 21.5212 60.8873 21.0959C60.2918 20.6563 59.831 20.1033 59.5049 19.4369C59.1787 18.7705 59.0157 18.0332 59.0157 17.225V10.9722H61.4615V17.2038C61.4615 17.7567 61.5821 18.2459 61.8231 18.6713C62.0783 19.0825 62.4115 19.4086 62.8227 19.6496C63.2481 19.8765 63.7089 19.9899 64.2051 19.9899C64.7014 19.9899 65.1551 19.8765 65.5663 19.6496C65.9917 19.4086 66.3249 19.0825 66.5659 18.6713C66.8211 18.2459 66.9487 17.7567 66.9487 17.2038V10.9722H69.3946V17.225C69.3946 18.0332 69.2315 18.7634 68.9054 19.4157C68.5793 20.0679 68.1185 20.6209 67.523 21.0746C66.9416 21.5141 66.2469 21.8048 65.4387 21.9466V26.1364H63.0141Z"
        fill="var(--text-color)"
      />
      <path
        d="M77.5611 10.717C78.4401 10.717 79.2271 10.8588 79.9218 11.1423C80.6166 11.4117 81.205 11.8087 81.6871 12.3334C82.1833 12.858 82.5591 13.4818 82.8143 14.205C83.0837 14.9139 83.2184 15.7008 83.2184 16.5657C83.2184 17.6575 82.9915 18.6217 82.5378 19.4582C82.0983 20.2806 81.4815 20.9257 80.6875 21.3936C79.9076 21.8473 79.0002 22.0742 77.9652 22.0742C77.4547 22.0742 76.9726 21.9962 76.5189 21.8402C76.0652 21.6843 75.654 21.4645 75.2854 21.1809C74.9167 20.8974 74.619 20.5641 74.3921 20.1813H74.3708V26.498H71.925V16.5232C71.925 15.318 72.1589 14.2829 72.6268 13.418C73.1089 12.5531 73.7753 11.8867 74.626 11.4188C75.4768 10.9509 76.4551 10.717 77.5611 10.717ZM77.5611 12.8013C76.9514 12.8013 76.4055 12.9572 75.9234 13.2692C75.4413 13.5669 75.0656 13.9852 74.7962 14.524C74.5268 15.0628 74.3921 15.6866 74.3921 16.3956C74.3921 17.0903 74.5268 17.7142 74.7962 18.2672C75.0656 18.806 75.4413 19.2313 75.9234 19.5433C76.4055 19.841 76.9585 19.9899 77.5823 19.9899C78.2062 19.9899 78.7521 19.8339 79.22 19.522C79.7021 19.2101 80.0778 18.7847 80.3472 18.2459C80.6166 17.7071 80.7513 17.0903 80.7513 16.3956C80.7513 15.6866 80.6166 15.0628 80.3472 14.524C80.0778 13.9852 79.7021 13.5669 79.22 13.2692C78.7379 12.9572 78.1849 12.8013 77.5611 12.8013Z"
        fill="var(--text-color)"
      />
      <path
        d="M88.5399 21.819C87.5758 21.819 86.8172 21.5425 86.2642 20.9895C85.7254 20.4365 85.456 19.6851 85.456 18.7351V8.24986H87.8806V18.6287C87.8806 18.9549 87.9799 19.2243 88.1784 19.4369C88.3769 19.6354 88.6392 19.7347 88.9653 19.7347H91.241V21.819H88.5399ZM83.5844 13.0565V10.9722H91.2623V13.0565H83.5844Z"
        fill="var(--text-color)"
      />
      <path
        d="M97.9003 22.0742C97.3331 22.0742 96.8227 21.9182 96.369 21.6063C95.9294 21.2944 95.6104 20.8761 95.4119 20.3515L92.0941 10.9722H94.7526L97.5812 19.3944C97.6238 19.4936 97.6734 19.5645 97.7301 19.6071C97.7868 19.6496 97.8506 19.6709 97.9215 19.6709C97.9924 19.6709 98.0562 19.6496 98.113 19.6071C98.1838 19.5645 98.2335 19.4936 98.2618 19.3944L101.112 10.9722H103.728L100.389 20.3515C100.204 20.8761 99.8853 21.2944 99.4316 21.6063C98.9779 21.9182 98.4674 22.0742 97.9003 22.0742Z"
        fill="var(--text-color)"
      />
      <path
        d="M105.198 21.819V10.9722H107.644V21.819H105.198ZM106.411 9.46215C105.985 9.46215 105.617 9.30618 105.305 8.99425C104.993 8.68231 104.837 8.31366 104.837 7.8883C104.837 7.46294 104.993 7.09429 105.305 6.78235C105.617 6.47042 105.985 6.31445 106.411 6.31445C106.85 6.31445 107.219 6.47042 107.517 6.78235C107.829 7.09429 107.985 7.46294 107.985 7.8883C107.985 8.31366 107.829 8.68231 107.517 8.99425C107.219 9.30618 106.85 9.46215 106.411 9.46215Z"
        fill="var(--text-color)"
      />
      <path
        d="M115.746 21.819C114.527 21.819 113.456 21.5921 112.534 21.1384C111.627 20.6705 110.918 20.0324 110.408 19.2243C109.897 18.4019 109.642 17.459 109.642 16.3956C109.642 15.2755 109.869 14.29 110.323 13.4393C110.776 12.5886 111.4 11.9222 112.194 11.4401C112.988 10.958 113.903 10.717 114.938 10.717C116.1 10.717 117.057 10.9651 117.809 11.4614C118.56 11.9576 119.113 12.624 119.468 13.4606C119.837 14.2971 120.021 15.2329 120.021 16.268C120.021 16.4098 120.014 16.5728 120 16.7571C119.985 16.9415 119.964 17.0833 119.936 17.1825H112.237C112.322 17.7497 112.527 18.2246 112.853 18.6075C113.18 18.9761 113.591 19.2597 114.087 19.4582C114.583 19.6425 115.136 19.7347 115.746 19.7347H118.66V21.819H115.746ZM112.215 15.5236H117.575C117.561 15.2542 117.518 14.9848 117.447 14.7154C117.391 14.446 117.291 14.1908 117.15 13.9497C117.022 13.7087 116.852 13.5031 116.639 13.333C116.441 13.1486 116.2 13.0068 115.916 12.9076C115.633 12.7942 115.306 12.7375 114.938 12.7375C114.512 12.7375 114.137 12.8225 113.811 12.9927C113.484 13.1486 113.208 13.3613 112.981 13.6307C112.754 13.8859 112.577 14.1837 112.449 14.524C112.336 14.8501 112.258 15.1833 112.215 15.5236Z"
        fill="var(--text-color)"
      />
      <path
        d="M125.814 22.0742C125.106 22.0742 124.46 21.9395 123.879 21.6701C123.298 21.3865 122.837 20.9612 122.497 20.394C122.156 19.8127 121.986 19.0754 121.986 18.1821V10.9722H124.432V18.5649C124.432 18.8485 124.496 19.1037 124.623 19.3306C124.751 19.5433 124.914 19.7063 125.113 19.8198C125.325 19.9332 125.559 19.9899 125.814 19.9899C126.055 19.9899 126.282 19.9332 126.495 19.8198C126.708 19.7063 126.878 19.5433 127.005 19.3306C127.133 19.1037 127.197 18.8485 127.197 18.5649V14.5665C127.197 13.73 127.353 13.0281 127.665 12.461C127.991 11.8796 128.438 11.4472 129.005 11.1636C129.586 10.8658 130.245 10.717 130.983 10.717C131.734 10.717 132.393 10.8658 132.961 11.1636C133.528 11.4472 133.974 11.8796 134.3 12.461C134.627 13.0281 134.79 13.73 134.79 14.5665V18.5649C134.79 18.8485 134.853 19.1037 134.981 19.3306C135.109 19.5433 135.279 19.7063 135.491 19.8198C135.704 19.9332 135.938 19.9899 136.193 19.9899C136.434 19.9899 136.654 19.9332 136.853 19.8198C137.065 19.7063 137.235 19.5433 137.363 19.3306C137.491 19.1037 137.554 18.8485 137.554 18.5649V10.9722H140V18.1821C140 19.0754 139.83 19.8127 139.49 20.394C139.15 20.9612 138.689 21.3865 138.107 21.6701C137.526 21.9395 136.888 22.0742 136.193 22.0742C135.499 22.0742 134.861 21.9395 134.279 21.6701C133.698 21.3865 133.23 20.9612 132.875 20.394C132.521 19.8127 132.344 19.0754 132.344 18.1821V14.2262C132.344 13.9143 132.28 13.6591 132.152 13.4606C132.039 13.2479 131.876 13.0848 131.663 12.9714C131.465 12.858 131.238 12.8013 130.983 12.8013C130.756 12.8013 130.536 12.858 130.323 12.9714C130.111 13.0848 129.94 13.2479 129.813 13.4606C129.685 13.6591 129.621 13.9143 129.621 14.2262V18.1821C129.621 19.0754 129.444 19.8127 129.09 20.394C128.749 20.9612 128.289 21.3865 127.707 21.6701C127.14 21.9395 126.509 22.0742 125.814 22.0742Z"
        fill="var(--text-color)"
      />
      <circle
        cx="14.216"
        cy="14.216"
        r="12.9822"
        stroke="var(--logo-color)"
        fill="none"
        strokeWidth="2.46772"
      />
      <path
        d="M5.38797 13.8849C4.99629 14.3136 5.02629 14.9786 5.45497 15.3703C5.88365 15.762 6.54869 15.732 6.94037 15.3033L5.38797 13.8849ZM18.7137 2.41765C19.1054 1.98897 19.0754 1.32393 18.6467 0.932256C18.218 0.540578 17.553 0.570577 17.1613 0.99926L18.7137 2.41765ZM6.94037 15.3033L18.7137 2.41765L17.1613 0.99926L5.38797 13.8849L6.94037 15.3033Z"
        fill="var(--logo-color)"
      />
      <path
        d="M13.3352 12.671C12.9459 13.1017 12.9794 13.7666 13.4102 14.156C13.841 14.5453 14.5059 14.5118 14.8952 14.081L13.3352 12.671ZM22.6839 5.4638C23.0733 5.03302 23.0397 4.36815 22.6089 3.97879C22.1782 3.58942 21.5133 3.623 21.1239 4.05378L22.6839 5.4638ZM14.8952 14.081L22.6839 5.4638L21.1239 4.05378L13.3352 12.671L14.8952 14.081Z"
        fill="var(--logo-color)"
      />
      <path
        d="M14.8732 12.774C14.4783 12.3483 13.8131 12.3234 13.3874 12.7183C12.9617 13.1132 12.9367 13.7784 13.3316 14.2041L14.8732 12.774ZM21.9864 23.5341C22.3813 23.9598 23.0466 23.9848 23.4723 23.5899C23.898 23.195 23.923 22.5298 23.5281 22.1041L21.9864 23.5341ZM13.3316 14.2041L21.9864 23.5341L23.5281 22.1041L14.8732 12.774L13.3316 14.2041Z"
        fill="var(--logo-color)"
      />
      <path
        d="M5.01978 21.6829C4.62805 22.1116 4.65798 22.7766 5.08662 23.1683C5.51525 23.5601 6.18029 23.5301 6.57202 23.1015L5.01978 21.6829ZM14.1767 22.9239C14.5715 23.3498 15.2367 23.375 15.6626 22.9803C16.0884 22.5856 16.1137 21.9203 15.719 21.4945L14.1767 22.9239ZM10.3344 17.426L9.55832 16.7167L10.3344 17.426ZM10.514 17.4254L9.74289 18.1401L10.514 17.4254ZM6.57202 23.1015L11.1106 18.1353L9.55832 16.7167L5.01978 21.6829L6.57202 23.1015ZM9.74289 18.1401L14.1767 22.9239L15.719 21.4945L11.2851 16.7107L9.74289 18.1401ZM11.1106 18.1353C10.7438 18.5366 10.1124 18.5388 9.74289 18.1401L11.2851 16.7107C10.8185 16.2072 10.0214 16.21 9.55832 16.7167L11.1106 18.1353Z"
        fill="var(--logo-color)"
      />
    </svg>
  );
}
