import React from 'react';
import { QuestionCircleFilled } from '@ant-design/icons';
import { Tooltip } from 'antd';

interface QuestionTooltipProps {
  tooltip?: string | React.ReactNode;
  size?: number;
}

const QuestionTooltip: React.VFC<QuestionTooltipProps> = ({
  tooltip,
  size = 14,
}) => {
  return (
    <Tooltip
      title={tooltip}
      placement="right"
      overlayStyle={{ width: 'auto', maxWidth: 800 }}
    >
      <QuestionCircleFilled hidden={!tooltip} size={size} />
    </Tooltip>
  );
};

export default QuestionTooltip;
