import React from 'react';
import { useTranslation } from 'react-i18next';
import { Modal } from 'ui/modal';
import { Divider, Result, Spin, Typography } from 'antd';
import { Tabs, TabPane } from 'ui/tabs';
import {
  ResearchFormCategory,
  useGetResearchCategoryAnswersRepartitionQuery,
} from 'generated/graphql';
import { Spacer } from 'ui/spacer';
import { CategoryIcon } from 'business/research/components/category-icon';
import { CategoryCommunityScore } from 'business/token/components/category-community-score';
import { CategoryTab } from 'business/research/components/category-tab';
import { CategoryTabs } from 'business/research/components/category-tabs';
import { AnswerRepartitionVisualisation } from './answers-visualisation';
import classes from './index.module.scss';

interface QuestionsAnswersRepartitionDetails {
  id: string;
  tokenReference: string;
  onCloseModal?: () => void;
}

const QuestionsAnswers: React.VFC<QuestionsAnswersRepartitionDetails> = ({
  id,
  tokenReference,
  onCloseModal,
}) => {
  const { t } = useTranslation(['token']);

  const {
    data,
    loading,
    error,
  } = useGetResearchCategoryAnswersRepartitionQuery({
    variables: {
      tokenReference,
      categoryId: id,
      answerRankByPercentage: 10, // we required the 10 more use response to a given question
      // for example rank 1 answer is the most used option to a question
      // 10 is an arbitrary number, and it must be specified more precisely in next ticket
    },
  });

  if (loading) {
    return (
      <Spacer justify="center" align="center">
        <Spin size="large" />
      </Spacer>
    );
  }
  if (error) {
    return (
      <Spacer justify="center" align="center">
        <Result
          status="error"
          title={t(
            'token:research-answers.answers-repartition-modal.data-error.title',
          )}
        />
      </Spacer>
    );
  }
  if (!data) {
    return (
      <Spacer justify="center" align="center">
        <Result
          status="warning"
          title={t(
            'token:research-answers.answers-repartition-modal.no-data.title',
          )}
        />
      </Spacer>
    );
  }

  const {
    questions,
    rating,
  } = data.ResearchCategoryAnswersRepartition.category;

  return (
    <Spacer direction="vertical" space={4}>
      <Spacer justify="end">
        {rating && <CategoryCommunityScore rating={rating} />}
      </Spacer>
      {questions?.map((question) => (
        <Spacer key={question.id} direction="vertical" space="none">
          <Typography.Title className={classes.title} level={4}>
            {question.title}
          </Typography.Title>
          <Divider className={classes.divider} />
          <Spacer
            direction={{ default: 'vertical', md: 'horizontal' }}
            align="center"
            space={4}
          >
            <Typography.Text strong className={classes.question}>
              {question.question}:
            </Typography.Text>
            <AnswerRepartitionVisualisation
              question={question}
              onCloseModal={onCloseModal}
            />
          </Spacer>
        </Spacer>
      ))}
    </Spacer>
  );
};

interface CategoriesAnswersRepartitionModalProps {
  defaultSelectedCategoryId: string | undefined;
  tokenReference: string;
  categories: Pick<ResearchFormCategory, 'id' | 'name'>[];
  visible: boolean;
  setSelectedCategoryId?: (id: string) => void;
  onCancel: () => void;
}

export const CategoriesAnswerRepartitionModal: React.VFC<CategoriesAnswersRepartitionModalProps> = ({
  categories,
  visible,
  tokenReference,
  onCancel,
  defaultSelectedCategoryId,
  setSelectedCategoryId,
}) => {
  const { t } = useTranslation(['token']);

  return (
    <Modal
      title={
        <Typography.Title level={2}>
          {t('token:research-answers.answers-repartition-modal.title')}
        </Typography.Title>
      }
      wrapClassName={classes.modal}
      open={visible}
      width={1024}
      footer={null}
      focusTriggerAfterClose={false}
      onCancel={onCancel}
    >
      <CategoryTabs
        defaultActiveKey={defaultSelectedCategoryId}
        activeKey={defaultSelectedCategoryId}
        onChange={setSelectedCategoryId}
        destroyInactiveTabPane={false}
      >
        {categories.map((cat) => (
          <TabPane
            id={cat.id}
            key={cat.id}
            tabKey={cat.id}
            className={classes.tab}
            tab={
              <CategoryTab
                category={cat.id}
                name={cat.name}
                active={cat.id === defaultSelectedCategoryId}
              />
            }
          >
            <QuestionsAnswers
              id={cat.id}
              tokenReference={tokenReference}
              onCloseModal={onCancel}
            />
          </TabPane>
        ))}
      </CategoryTabs>
    </Modal>
  );
};
