import { TablePaginationConfig } from 'antd';
import { FilterValue } from 'antd/lib/table/interface';
import { researchPageOptions } from 'business/token/common-values';
import { useFilters } from 'technical/filters/use-filters-hook';
import {
  pageFilter,
  pageSizeFilter,
  stringArrayFilter,
  booleanFilter,
} from '../../../technical/filters/table-filters';

export const useResearchTableFilters = () =>
  useFilters({
    page: pageFilter,
    pageSize: pageSizeFilter,
    tokens: stringArrayFilter,
    hasEnoughReviews: booleanFilter,
  });

export type ResearchTableFilters = ReturnType<typeof useResearchTableFilters>;

export function researchTableOnChange(
  { current: page, pageSize: newPageSize }: TablePaginationConfig,
  { token, hasEnoughReviews }: Record<string, FilterValue | null>,
): ResearchTableFilters['filtersValues'] {
  return {
    page: page ?? researchPageOptions.defaultPage,
    pageSize: newPageSize ?? researchPageOptions.defaultPageSize,
    tokens: token?.map(String) ?? null,
    hasEnoughReviews:
      (hasEnoughReviews?.length ?? 0) === 0
        ? null
        : hasEnoughReviews?.[0] === 'true',
  };
}
