import classNames from 'classnames';
import React, { ReactElement } from 'react';
import { useTranslation } from 'react-i18next';
import styles from './index.module.scss';

interface UserInfoLabelProps {
  icon?: ReactElement;
  value: number | string;
  label: string;
  className?: string;
}

const UserInfoLabel = ({
  icon,
  value,
  label,
  className,
}: UserInfoLabelProps) => {
  const { t } = useTranslation(['common']);

  return (
    <div className={classNames(className, styles.labelWrapper)}>
      {icon}
      <span className={styles.text}>
        {value} {label}
      </span>
    </div>
  );
};

export default UserInfoLabel;
