import React, { useState } from 'react';
import { Card } from 'ui/cards';
import { useTranslation } from 'react-i18next';
import Avatar from 'antd/lib/avatar/avatar';
import { Button, Progress, Typography } from 'antd';
import { UserOutlined } from '@ant-design/icons';
import { useAppContext } from 'business/app-bootstrapper';
import {
  useSelectUserProfileQuery,
  useGetUserWalletsQuery,
  useUserRankingQuery,
} from 'generated/graphql';
import { Spacer } from 'ui/spacer';
import { ProfileUpdateModal } from 'business/user/components/profile-update-modal';
import { Description } from 'business/user/components/profile-banner/description';

import { LinkAsButton } from 'ui/button';
import Routes from 'business/router/routes';
import { generatePath } from 'react-router-dom';
import { SimpleSocialButtons } from 'business/social-network/components/simple-social-buttons';
import { LongSocialButtons } from 'business/social-network/components/long-social-buttons';
import { SocialNetwork } from 'business/social-network/types';
import { AvatarPlaceHolder } from 'business/user/avatar';
import classes from './index.module.scss';
import { UserName } from './user-name';
import { UserRankIcon } from './user-rank';
import { UserStatistic } from './user-statistic';

export function DashboardProfile() {
  const { t } = useTranslation(['user', 'common', 'dashboard']);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const { user } = useAppContext();
  const { refetch } = useSelectUserProfileQuery({
    variables: {
      // @ts-expect-error
      userId: user?.id,
    },
  });

  const { data: walletData } = useGetUserWalletsQuery({
    // @ts-expect-error
    variables: { id: user?.id },
  });

  const { data } = useSelectUserProfileQuery({
    variables: {
      // @ts-expect-error
      userId: user?.id,
    },
  });

  const { data: rankData } = useUserRankingQuery({
    variables: {
      // @ts-expect-error
      userId: user?.id,
    },
  });

  if (!data || !rankData?.ranks) {
    return null;
  }

  const { ranks } = rankData;
  const {
    description,
    discord,
    reddit,
    telegram,
    twitter,
    youtube,
    contributions,
    validatedScorings,
    rejectedScorings,
  } = data.userProfile ?? {};

  const noSocialNetworksText = t(
    'user:private-profile-banner.no-social-networks',
  );
  const noDescriptionText = t('user:private-profile-banner.no-description');

  const socialNetworks: SocialNetwork[] = [
    { key: 'discord', value: discord, isUsername: true },
    { key: 'reddit', value: reddit },
    { key: 'telegram', value: telegram },
    { key: 'twitter', value: twitter },
    { key: 'youtube', value: youtube },
  ].filter((sn): sn is SocialNetwork => !!sn.value);

  const onClickEdit = () => setIsModalVisible(true);
  const wallets = walletData?.user_by_pk?.wallets;
  return (
    <>
      <Card>
        <Spacer space={2} direction="vertical">
          <Spacer
            align="center"
            justify="start"
            space={1}
            direction={{ default: 'vertical', sm: 'horizontal' }}
          >
            <AvatarPlaceHolder />

            <Spacer
              align="center"
              direction="vertical"
              justify={{ default: 'center', sm: 'start' }}
              space={1}
            >
              <UserName />
              <Typography>
                {wallets
                  ? wallets.map(({ address }) => address)
                  : t('common:wallet.no-wallet-connected')}
              </Typography>

              <Spacer
                space={2}
                align="center"
                direction={{ default: 'vertical', sm: 'horizontal' }}
              >
                <LongSocialButtons
                  socialNetworks={socialNetworks}
                  size="small"
                />
                <Spacer
                  direction="horizontal"
                  align="center"
                  space={2}
                  justify={{ default: 'center', sm: 'start' }}
                >
                  <SimpleSocialButtons
                    socialNetworks={socialNetworks}
                    size="small"
                  />
                </Spacer>
              </Spacer>
            </Spacer>
            <div>
              <Spacer align="center" direction="vertical">
                <UserRankIcon icon={ranks.current.icon} />
                <Progress
                  percent={ranks.progress ?? undefined}
                  showInfo={false}
                />
                <Typography.Text type="secondary" strong>
                  {ranks.progress} %
                </Typography.Text>
              </Spacer>
            </div>
          </Spacer>
          <UserStatistic
            contributions={contributions ?? 0}
            validatedScorings={validatedScorings ?? 0}
            rejectedScorings={rejectedScorings ?? 0}
            position={ranks?.leaderboard?.position ?? 0}
          />

          <Typography.Title level={5}>
            {t('dashboard:description')}
          </Typography.Title>
          <Card type="well" className={classes.reward}>
            <Description
              description={description}
              noDescriptionText={noDescriptionText}
            />
          </Card>

          <Spacer space={1}>
            <Button onClick={onClickEdit} className={classes['edit-button']}>
              {t('dashboard:edit')}
            </Button>
            <LinkAsButton
              // @ts-expect-error
              to={generatePath(Routes.PublicProfile, { id: user?.id })}
            >
              {t('dashboard:see-public-profile')}
            </LinkAsButton>
          </Spacer>
        </Spacer>
      </Card>

      <ProfileUpdateModal
        open={isModalVisible}
        setIsVisible={setIsModalVisible}
        onCompleted={refetch}
      />
    </>
  );
}
