import React, { useState } from 'react';
import { useAppContext } from 'business/app-bootstrapper';
import { Button, Drawer } from 'antd';
import { HeaderMenu } from 'business/user/components/menu-header';
import { NavigationMenu } from 'ui/new-header/navigation-menu';
import { useTranslation } from 'react-i18next';
import { Spacer } from 'ui/spacer';
import LoginModal, {
  LoginModalFormTabs,
} from 'business/user/components/login-modal';
import { ReactComponent as SignOutSVG } from 'assets/icons/menu/sign-out.svg';
import authService from 'business/user/services/authentication.gql';
import classes from './index.module.scss';
import ThemeSwitcherSegmented from '../theme-switcher-segmented';

interface MobileMenuProps {
  visible: boolean;
  onCloseMenu: () => void;
}

export function MobileMenu({ onCloseMenu, visible }: MobileMenuProps) {
  const { t } = useTranslation(['common']);
  const { user, isConnected } = useAppContext();
  const [loginModalIsOpen, setLoginModalIsOpen] = useState(false);
  const [loginModalType, setLoginModalType] = useState<LoginModalFormTabs>();

  return (
    <>
      <Drawer
        // to avoid default ant drawer to be displayed use header style display none or closable false
        // headerStyle={{ display: 'none' }}
        closable={false}
        bodyStyle={{ padding: 0 }}
        width="100%"
        visible={visible}
        footer={<ThemeSwitcherSegmented />}
        className={classes.drawer}
      >
        <Spacer direction="vertical" className={classes.container}>
          <HeaderMenu
            action={<HeaderMenu.Close onClick={onCloseMenu} />}
            onItemClick={onCloseMenu}
          />
          <Spacer
            direction="vertical"
            className={classes.body}
            space={1}
            justify="between"
          >
            <NavigationMenu
              onClick={onCloseMenu}
              types={['main', 'profileSection']}
            />
            <Spacer direction="vertical" space={1} className={classes.buttons}>
              {isConnected ? (
                <Button
                  onClick={() => {
                    onCloseMenu();
                    authService.logout();
                  }}
                  size="large"
                  className={classes.button}
                >
                  <Spacer
                    direction="horizontal"
                    space={1}
                    align="center"
                    justify="center"
                  >
                    {t('header.account-menu.sign-out')}
                    <SignOutSVG className={classes.icon} />
                  </Spacer>
                </Button>
              ) : (
                <>
                  <Button
                    type="primary"
                    onClick={() => {
                      onCloseMenu();
                      setLoginModalType(LoginModalFormTabs.signin);
                      setLoginModalIsOpen(true);
                    }}
                    size="large"
                  >
                    {t('pages.signIn.submit')}
                  </Button>
                  <Button
                    onClick={() => {
                      setLoginModalType(LoginModalFormTabs.signup);
                      setLoginModalIsOpen(true);
                      onCloseMenu();
                    }}
                    size="large"
                  >
                    {t('pages.signUp.form.submit')}
                  </Button>
                </>
              )}
            </Spacer>
          </Spacer>
        </Spacer>
      </Drawer>
      <LoginModal
        isOpen={loginModalIsOpen} // type is boolean or undefined but we should have default value
        setIsOpen={setLoginModalIsOpen}
        startTab={loginModalType}
      />
    </>
  );
}
