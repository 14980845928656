import React, { CSSProperties, FC, ReactNode } from 'react';
import { Tag as TagUI } from 'antd';
import classNames from 'classnames';

import styles from './index.module.scss';

enum TagColors {
  success = 'success',
  error = 'error',
  warning = 'warning',
}

interface TagProps {
  closeIcon?: ReactNode;
  closable?: boolean;
  onClose?: () => void;
  color?: TagColors | string;
  icon?: ReactNode;
  children: ReactNode;
  className?: string;
  style?: CSSProperties;
}

const Tag: FC<TagProps> = ({
  color,
  closeIcon,
  children,
  className,
  ...rest
}) => (
  <TagUI
    className={classNames(styles.default, className, {
      [styles[`ant-tag-${color}`]]:
        color && Object.values(TagColors).includes(color as TagColors),
      [styles[`ant-tag-close-icon`]]: closeIcon,
    })}
    color={color}
    closeIcon={closeIcon}
    {...rest}
  >
    {children}
  </TagUI>
);

export default Tag;
