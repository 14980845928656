import React from 'react';
import { Image, ImageProps } from 'antd';

interface CoinGeckoTokenImageProps
  extends Pick<ImageProps, 'className' | 'width' | 'height' | 'alt'> {
  coinGeckoImage: string | undefined | null;
  size?: 'thumb' | 'small' | 'large';
  fallback?: string;
}

export function CoinGeckoTokenImage({
  coinGeckoImage,
  size = 'small',
  fallback,
  ...props
}: CoinGeckoTokenImageProps) {
  return (
    <Image
      src={coinGeckoImage?.replace('{{size}}', size) ?? fallback}
      preview={false}
      {...props}
    />
  );
}
