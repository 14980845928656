import React, { SVGProps } from 'react';

interface YoutubeLogoSVGComponentProps extends SVGProps<SVGSVGElement> {}

export function YoutubeLogoSVGComponent(props: YoutubeLogoSVGComponentProps) {
  return (
    <svg
      width="16"
      height="12"
      viewBox="0 0 16 12"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M14.2602 0.741105C14.9456 0.925618 15.4859 1.46598 15.6704 2.15131C16.0131 3.40337 15.9999 6.01291 15.9999 6.01291C15.9999 6.01291 15.9999 8.60928 15.6704 9.86133C15.4859 10.5467 14.9456 11.087 14.2602 11.2715C13.0082 11.601 7.99997 11.601 7.99997 11.601C7.99997 11.601 3.00493 11.601 1.7397 11.2584C1.05436 11.0738 0.514001 10.5335 0.329488 9.84815C0 8.60927 0 5.99973 0 5.99973C0 5.99973 0 3.40337 0.329488 2.15131C0.514001 1.46598 1.06754 0.912439 1.7397 0.727925C2.99175 0.398438 7.99997 0.398438 7.99997 0.398438C7.99997 0.398438 13.0082 0.398438 14.2602 0.741105ZM10.571 6.00023L6.40625 8.39891V3.60156L10.571 6.00023Z"
        fill="current"
      />
    </svg>
  );
}
