import { AllocatedTokensResponse, Token } from 'generated/graphql';
import { Values } from 'technical/types';
import { NumericValueType } from '../../ui/numeric';

export const PortfolioVisibilityEnum = {
  public: 'public',
  private: 'private',
} as const;

export type PortfolioVisibilityEnumValues = Values<
  typeof PortfolioVisibilityEnum
>;

export const isPortfolioVisibilityEnum = (
  tagValue: string | PortfolioVisibilityEnumValues,
): tagValue is PortfolioVisibilityEnumValues => {
  return Object.values(PortfolioVisibilityEnum).includes(tagValue as any);
};

export interface Portfolios {
  id: string;
  name: string;
  visibility: PortfolioVisibilityEnumValues;

  nextActionDueDateInDay: number; // will probably be changed to Date format
}

export interface PortfolioFormValues {
  id: string;
  name: string;
  visibility: string; // would prefer 'public' | 'private'; todo create enum, if possible shared between custom server and front
  updateFrequencyInDays: number;
  lastUpdateDate?: Date; // last portfolioUpdate creation
  lastPublicationDate?: Date;
  // token repartition of the last portfolioUpdate
  d7Perf?: number | null;
  lastUpdatePerf?: number | null;
  allocatedTokens: PortfolioFormAllocatedToken[];
}

export interface PortfolioFormToken
  extends Pick<
    Token,
    'id' | 'symbol' | 'name' | 'reference' | 'coinGeckoImage'
  > {
  price?: NumericValueType;
}

export type PortfolioFormAllocatedToken = Pick<
  AllocatedTokensResponse,
  'percentage' | 'price' | 'h24PriceChange'
> & {
  token: PortfolioFormToken;
};
