import React from 'react';
import { Image, Typography } from 'antd';
import { Trans, useTranslation } from 'react-i18next';
import { LinkAsButton } from 'ui/button';
import { Spacer } from 'ui/spacer';
import { Card } from 'ui/cards';
import { useAppContext } from 'business/app-bootstrapper';
import {
  Portfolio,
  PortfolioResponse,
  useGetUserPortfoliosQuery,
} from 'generated/graphql';
import { addDays, differenceInDays } from 'date-fns';
import chartImg from 'assets/illustrations/chart.png';
import styles from './index.module.scss';
import { PortfolioVisibilityEnum } from '../../types';

interface WantToEarnMoreSectionProps {
  portfolios: Pick<
    PortfolioResponse,
    | 'id'
    | 'name'
    | 'visibility'
    | 'updateFrequencyInDays'
    | 'lastPublicationDate'
  >[];
  to: string;
}

const getPortfolioNextActionDueDateInDays = (
  portfolio: Pick<Portfolio, 'lastPublicationDate' | 'updateFrequencyInDays'>,
) => {
  if (!portfolio.lastPublicationDate) {
    return 0;
  }
  const nbOfDayBeforeNextRequiredActions = differenceInDays(
    addDays(
      new Date(portfolio.lastPublicationDate),
      portfolio.updateFrequencyInDays,
    ),
    new Date(),
  );

  // if required Date is passed return 0 to indicate now
  return nbOfDayBeforeNextRequiredActions >= 0
    ? nbOfDayBeforeNextRequiredActions
    : 0;
};

export const WantToEarnMoreSection: React.FC<WantToEarnMoreSectionProps> = ({
  portfolios,
  to,
}) => {
  const { t } = useTranslation(['common']);

  const sharedPortfolios = portfolios
    .filter(
      (portfolio) => portfolio.visibility === PortfolioVisibilityEnum.public,
    )
    .map((sharedPortfolio) => ({
      ...sharedPortfolio,
      // eslint-disable-next-line prettier/prettier
      nextActionDueDateInDay:
        getPortfolioNextActionDueDateInDays(sharedPortfolio),
    }));

  return (
    <Card>
      <Card.Header
        level={2}
        title={t('common:portfolio.wantToEarnMore.title')}
      />
      <Spacer direction="vertical" space={2}>
        <Spacer justify="center">
          <Image src={chartImg} alt="Chart image" />
        </Spacer>
        <Typography.Title level={2}>
          <Trans
            t={t}
            i18nKey="common:portfolio.wantToEarnMore.sharedPortfolio"
            values={{
              sharedPortfolios: sharedPortfolios.length,
              totalPortfolios: portfolios.length,
            }}
            components={[
              <Typography.Text
                key="shared-portfolio"
                className={styles.highlight}
              />,
            ]}
          />
        </Typography.Title>
        {sharedPortfolios.map(({ id, nextActionDueDateInDay, name }) => (
          <Card type="well" key={id}>
            <Card.Header title={name} level={5} />
            <Typography.Text>
              <Trans
                t={t}
                i18nKey="common:portfolio.wantToEarnMore.nextActionDueDate"
                count={nextActionDueDateInDay}
                components={{ danger: <Typography.Text type="danger" /> }}
              />
            </Typography.Text>
          </Card>
        ))}
        <Typography.Paragraph>
          {t('common:portfolio.wantToEarnMore.description')}
        </Typography.Paragraph>

        <LinkAsButton to={to} type="primary">
          {t('common:portfolio.wantToEarnMore.goToMyPortfolio')}
        </LinkAsButton>
      </Spacer>
    </Card>
  );
};

const WantToEarnMoreSectionConnected = (
  props: Omit<WantToEarnMoreSectionProps, 'portfolios'>,
) => {
  const { user } = useAppContext();
  const { data } = useGetUserPortfoliosQuery({
    // @ts-expect-error
    variables: { authorId: user?.id },
  });

  const portfolios = data?.getUserPortfolios?.portfolios ?? [];

  return <WantToEarnMoreSection portfolios={portfolios} {...props} />;
};

export default WantToEarnMoreSectionConnected;
