import { PeerReviewAnswersQuestion } from 'business/peer-review/types';
import { QuestionHelperToolTip } from 'business/research/components/research-form/questions/question-helper-tool-tip';
import { Card } from 'ui/cards';
import { Spacer } from 'ui/spacer';
import { PeerReviewComments } from '../comments';
import { PeerReviewQuestionComponent } from '../question';
import { PeerReviewSources } from '../sources';
import classes from './index.module.scss';

export interface CardPeerReviewProps {
  answer: PeerReviewAnswersQuestion;
  actionButtons?: (answerId: string, answerHasSources: boolean) => JSX.Element;
  type?: 'default' | 'well';
}

export function CardPeerReview({
  answer,
  actionButtons,
  type = 'well',
}: CardPeerReviewProps) {
  const answerHasSources = answer.sources.length > 0;

  return (
    <Card
      className={classes.cardPeerReview}
      type={type}
      data-testid={'peerReviewCard'}
    >
      <Card.Header
        level={5}
        title={answer.question.title}
        tooltip={<QuestionHelperToolTip question={answer.question} />}
        actions={
          <div className={classes.opinionButtonsTop}>
            {actionButtons ? actionButtons(answer.id, answerHasSources) : null}
          </div>
        }
      />
      <Spacer direction="vertical">
        <Card className={classes.cardPeerReview} type={type}>
          <PeerReviewQuestionComponent
            question={answer.question}
            choices={{
              singleChoice: answer.answerTypeSingleChoice,
              multiChoices: answer.answerTypeMultiChoices,
              tokens: answer.answerTypeTokens,
              tags: answer.answerTypeTags,
            }}
            type={type === 'default' ? 'well' : 'default'}
          />
        </Card>
        {answer.sources.length > 0 && (
          <Card className={classes.cardPeerReview} type={type}>
            <PeerReviewSources
              sources={answer.sources}
              type={type === 'default' ? 'well' : 'default'}
            />
          </Card>
        )}
        {answer.comments.length > 0 && (
          <Card className={classes.cardPeerReview} type={type}>
            <PeerReviewComments
              comments={answer.comments}
              type={type === 'default' ? 'well' : 'default'}
            />
          </Card>
        )}
        <div className={classes.opinionButtonsBottom}>
          {actionButtons ? actionButtons(answer.id, answerHasSources) : null}
        </div>
      </Spacer>
    </Card>
  );
}
