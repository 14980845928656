import React from 'react';
import { Progress, Typography } from 'antd';
import { Spacer } from 'ui/spacer';
import { TokenMarkets } from 'generated/graphql';
import { useTranslation } from 'react-i18next';
import { NumericValue, NumberFormats } from 'ui/numeric';
import styles from './index.module.scss';

interface TokenProgressBarProps
  extends Pick<TokenMarkets, 'price' | 'h24Low' | 'h24High'> {}

function getProgress({ price, h24Low, h24High }: TokenProgressBarProps) {
  if (!price || !h24Low || !h24High) {
    return 0;
  }

  return ((price - h24Low) / (h24High - h24Low)) * 100;
}

export function TokenProgressBar(props: TokenProgressBarProps) {
  const { t } = useTranslation(['token']);
  const progress = getProgress(props);

  const { h24Low, h24High } = props;

  return (
    <Spacer space={1} className={styles.text}>
      <Typography.Text>{t('token:prices.low')}</Typography.Text>
      <NumericValue format={NumberFormats.currency} value={h24Low} />
      <Progress
        size="small"
        showInfo={false}
        percent={progress}
        style={{ flex: '1' }}
      />
      <Typography.Text>{t('token:prices.max')}</Typography.Text>
      <NumericValue format={NumberFormats.currency} value={h24High} />
      {/* TODO: add the period select component here when it will be developped */}
    </Spacer>
  );
}
