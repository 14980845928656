import React, { useState } from 'react';
import SearchBar from 'ui/search-bar';
import { useTranslation } from 'react-i18next';
import { Token, useSearchTokenQuery } from 'generated/graphql';
import { useDebounce } from 'technical/hooks/use-debounce';
import { generatePath, Link, useNavigate } from 'react-router-dom';
import Routes from 'business/router/routes';
import { Empty } from 'ui/empty-state';
import { Typography } from 'antd';
import styles from 'ui/search-bar/index.module.scss';
import { Spacer } from 'ui/spacer';
import { CoinGeckoTokenImage } from 'ui/coin-gecko-token-image';
import { LabeledValue } from 'antd/es/select';

type SearchBarToken = Pick<
  Token,
  'id' | 'name' | 'symbol' | 'reference' | 'coinGeckoImage'
>;
export type TokenOption = SearchBarToken & LabeledValue;

const TokenSearchOption: React.VFC<SearchBarToken> = ({
  coinGeckoImage,
  name,
  reference,
  symbol,
}) => (
  <Link to={generatePath(Routes.token, { reference })}>
    <Typography className={styles.tokenRow}>
      <Spacer direction="horizontal" space={1} align="center">
        <CoinGeckoTokenImage
          width={20}
          size="thumb"
          coinGeckoImage={coinGeckoImage}
        />
        <Typography.Text>{name}</Typography.Text>
      </Spacer>
      <Typography.Text style={{ flex: 'none' }}>
        {symbol.toUpperCase()}
      </Typography.Text>
    </Typography>
  </Link>
);

const TokenSearchBar: React.FC = () => {
  const { t } = useTranslation(['common']);
  const [searchText, setSearchText] = useState('');
  const navigate = useNavigate();
  const debouncedValue = useDebounce(searchText);
  const {
    data,
    loading: isLoading,
    previousData,
    called,
  } = useSearchTokenQuery({
    variables: { searchText: `%${debouncedValue}%` },
    skip: debouncedValue === '',
  });
  const tokens = data?.token ?? previousData?.token ?? [];

  const handleChange = (inputTxt: string = '') => {
    setSearchText(inputTxt);
  };

  const handleSelect = (id?: string) => {
    // history change to value page
    if (id) {
      const token = data?.token.find((tk) => tk.id === id);
      if (token) {
        navigate(generatePath(Routes.token, { reference: token.reference }));
      }
    }
    setSearchText('');
  };

  const values: TokenOption[] =
    debouncedValue === ''
      ? []
      : tokens.map((token) => ({
          ...token,
          key: token.id,
          value: token.id,
          // title is displayed for the selected value, no link
          title: token.name,
          // is displayed in dropdown
          label: <TokenSearchOption key={token.id} {...token} />,
        }));

  const showNotFound =
    !isLoading && debouncedValue !== '' && debouncedValue.length >= 1 && called;

  return (
    <SearchBar
      isLoading={isLoading}
      options={values}
      onSearch={setSearchText}
      onChange={handleChange}
      onSelect={handleSelect}
      placeholder={t('pages.token.tokenSearchBar.placeholder')}
      searchValue={searchText}
      value={searchText}
      notFoundContent={
        showNotFound ? (
          <Empty
            description={t('common:pages.token.tokenSearchBar.noResult')}
          />
        ) : undefined
      }
    />
  );
};

export default TokenSearchBar;
