import { CloseCircleOutlined } from '@ant-design/icons';
import { Typography, Carousel } from 'antd';
import React from 'react';
import { useLocaleStorage } from 'technical/storage/use-storage';
import { isInternalLink } from 'technical/utils/is-internal-link';
import Button, { LinkAsButton } from 'ui/button';
import { Card } from 'ui/cards';
import { Spacer } from 'ui/spacer';
import classes from './index.module.scss';

interface HeadBandItemProps {
  id: number;
  message: string;
  ctaLabel: string;
  link: string;
}

function HeadBandItem({ message, ctaLabel, link }: HeadBandItemProps) {
  return (
    <Spacer
      direction={{ default: 'vertical', md: 'horizontal' }}
      space={{ default: 1, md: 2 }}
      justify="center"
      align="center"
    >
      <Typography.Text>{message}</Typography.Text>
      {isInternalLink(link) ? (
        <LinkAsButton type="primary" to={link}>
          {ctaLabel}
        </LinkAsButton>
      ) : (
        <Button type="primary" href={link} target="_blank" rel="noreferrer">
          {ctaLabel}
        </Button>
      )}
    </Spacer>
  );
}

interface HeadBandProps {
  items: HeadBandItemProps[];
  isVisible?: boolean;
  onClose: () => void;
}

export function HeadBandComponent({
  items,
  isVisible = false,
  onClose,
}: HeadBandProps) {
  if (!isVisible || items.length === 0) {
    return null;
  }

  return (
    <Card type="info" className={classes.mainContainer}>
      <Carousel
        autoplay
        autoplaySpeed={9000} // 9 seconds
        infinite
        pauseOnDotsHover
        pauseOnFocus
        dots={items.length > 1}
        className={classes.carouselContainer}
      >
        {items.map(({ id, message, ctaLabel, link }) => (
          <HeadBandItem
            key={id}
            id={id}
            message={message}
            ctaLabel={ctaLabel}
            link={link}
          />
        ))}
      </Carousel>
      <Button
        type="link"
        onClick={onClose}
        className={classes.closeButton}
        icon={<CloseCircleOutlined />}
      />
    </Card>
  );
}
