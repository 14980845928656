import React from 'react';
import { Spacer } from 'ui/spacer';
import { Token, TokenMarkets } from 'generated/graphql';
import { NumericValue, NumberFormats } from 'ui/numeric';
import { useTranslation } from 'react-i18next';
import { Typography } from 'antd';
import styles from './index.module.scss';
import { TokenProgressBar } from '../token-progress-bar';
import { PercentageValue } from '../../../../ui/percentage';
import { CoinGeckoTokenImage } from '../../../../ui/coin-gecko-token-image';

type TokenPricesProps = Pick<TokenMarkets, 'price' | 'h24Low' | 'h24High'> &
  Pick<Token, 'name' | 'coinGeckoImage'>;

export function TokenPrices(props: TokenPricesProps) {
  const { price, name, coinGeckoImage } = props;
  const { t } = useTranslation(['form-wizard']);
  return (
    <Spacer direction="vertical" space={2} className={styles.tokenPrices}>
      <Spacer space={1}>
        <CoinGeckoTokenImage coinGeckoImage={coinGeckoImage} size="thumb" />
        <Typography.Text className={styles.price}>
          {t('form-wizard:tokenPrice', { tokenName: name })}
        </Typography.Text>
        <NumericValue
          format={NumberFormats.currency}
          value={price}
          className={styles.number}
        />
        <PercentageValue value={12} />
      </Spacer>
      <TokenProgressBar {...props} />
    </Spacer>
  );
}
