import React from 'react';
import { useTranslation } from 'react-i18next';
import { Card } from 'ui/cards';
import { Spacer } from 'ui/spacer';
import { TokenCard } from 'business/token/components/token-card';
import { generatePath, useNavigate } from 'react-router-dom';
import Routes from 'business/router/routes';
import { useGetThreeMostTrendingTokensDetailsQuery } from 'generated/graphql';
import { Result, Skeleton } from 'antd';
import classnames from 'classnames';
import classes from './index.module.scss';

const SkeletonCard = () => (
  <Card type="well">
    <Spacer direction="vertical" space={4} className={classes.skeletonCard}>
      <Skeleton
        className={classes.skeleton}
        avatar
        round
        paragraph={{ rows: 2, width: [70, 60] }}
        title={false}
        active
      />
      <Skeleton
        className={classnames(classes.skeleton, classes.skeletonFooter)}
        round
        paragraph={{ rows: 3, width: [70, 80, 60] }}
        title={false}
        active
      />
    </Spacer>
  </Card>
);

export function TrendingCards() {
  const { t } = useTranslation(['home']);
  const navigate = useNavigate();
  const { data, loading } = useGetThreeMostTrendingTokensDetailsQuery();

  const onTokenCardClick = (reference: string) =>
    navigate(
      generatePath(Routes.token, {
        reference,
      }),
    );

  const { success, tokens } = data?.getThreeMostTrendingTokensDetails ?? {};
  const showError = !loading && !success && (!tokens || tokens?.length === 0);

  return (
    <Card>
      <Spacer justify="between" align="baseline">
        <Card.Header level={2} title={`🚀 ${t('home:trending.title')}`} />
        {/* TODO return when path is ready */}
        {/* <Button type="link" onClick={moreTrendsClickHandler}> */}
        {/*  {t('home:see-more')} */}
        {/* </Button> */}
      </Spacer>
      <Spacer space={2} className={classes.cardsContainer}>
        {showError && (
          <Result status="error" subTitle={t('home:trending.error')} />
        )}
        {loading && (
          <Spacer space={2}>
            <SkeletonCard />
            <SkeletonCard />
            <SkeletonCard />
          </Spacer>
        )}
        {!loading &&
          tokens &&
          tokens.map((token) => (
            <TokenCard
              onClick={onTokenCardClick}
              key={token.identity.id}
              {...token.identity}
              {...token.market}
            />
          ))}
      </Spacer>
    </Card>
  );
}
