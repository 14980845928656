import React from 'react';
import * as yup from 'yup';
import { useURLSearchParams } from 'technical/router/url-param/use-u-r-l-search-params';

export interface UseCategoriesAnswersRepartitionModalProps {
  defaultSelectedCategoryId: string | undefined;
}

const useCategoriesAnswerRepartitionModal = ({
  defaultSelectedCategoryId,
}: UseCategoriesAnswersRepartitionModalProps) => {
  const [selectedCategoryId, setSelectedCategoryId] = useURLSearchParams({
    defaultValue: defaultSelectedCategoryId,
    schema: yup.string(),
    key: 'answer-repartition-tab',
  });
  const isVisible = !!selectedCategoryId;

  return {
    setSelectedCategoryId,
    isVisible,
    selectedCategoryId,
  };
};

export default useCategoriesAnswerRepartitionModal;
