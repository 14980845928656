import {
  DraftQuestion,
  SingleChoiceQuestion,
  MultiChoiceQuestion,
  TagQuestion,
  TokenQuestion,
} from 'generated/graphql';
import { isNullOrUndefined } from 'technical/utils/is-null-or-undefined';

export function hasAnswer(question: DraftQuestion) {
  return Boolean(
    (question as SingleChoiceQuestion).choiceId ||
      (question as MultiChoiceQuestion).choiceIds?.length ||
      (question as TagQuestion).tags?.length ||
      (question as TokenQuestion).tokenIds?.length,
  );
}

export function areChoiceIdsMatching(values: {
  choiceId?: string | null;
  choiceIds?: Array<string> | null;
}) {
  return (entry: { when?: string | null }) => {
    return (
      isNullOrUndefined(entry.when) ||
      values.choiceId === entry.when ||
      values.choiceIds?.some((choiceId) => choiceId === entry.when)
    );
  };
}
