import React from 'react';
import { Empty as AntEmpty, EmptyProps } from 'antd';
import classnames from 'classnames';
import classes from './index.module.scss';

export const Empty = ({ className, ...otherProps }: EmptyProps) => {
  return (
    <AntEmpty
      className={classnames(classes.empty, className)}
      {...otherProps}
    />
  );
};

Empty.PRESENTED_IMAGE_DEFAULT = AntEmpty.PRESENTED_IMAGE_DEFAULT;
Empty.PRESENTED_IMAGE_SIMPLE = AntEmpty.PRESENTED_IMAGE_SIMPLE;
