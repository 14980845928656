import gql from 'graphql-tag';

export const MUTATION_ADD_PORTFOLIO_UPDATE = gql`
  mutation AddPortfolioUpdate(
    $id: ID!
    $visibility: String!
    $name: String!
    $allocatedTokens: [PartialAllocatedToken!]
  ) {
    addPortfolioUpdate(
      id: $id
      visibility: $visibility
      name: $name
      allocatedTokens: $allocatedTokens
    ) {
      success
    }
  }
`;

export const GetUserPortfolios = 'GetUserPortfolios'; // must be the same name used in query to ensure refetch using query name
export const GET_USER_PORTFOLIOS = gql`
  query GetUserPortfolios($authorId: ID!, $withPrivate: Boolean! = false) {
    getUserPortfolios(authorId: $authorId, withPrivate: $withPrivate) {
      portfolios {
        id
        createdAt
        name
        visibility
        updateFrequencyInDays
        lastPublicationDate
        d7Perf
        lastUpdatePerf
        lastPublishedUpdate {
          id
          createdAt
          updatedAt
          allocatedTokens {
            id
            percentage
            createdAt
            price
            h24PriceChange
            token {
              id
              symbol
              name
              reference
              coinGeckoImage
            }
          }
        }
        lastUpdate {
          id
          createdAt
          updatedAt
          allocatedTokens {
            id
            percentage
            createdAt
            price
            h24PriceChange
            token {
              id
              symbol
              name
              reference
              coinGeckoImage
            }
          }
        }
      }
    }
  }
`;

export const QueryPublicPortfolios = 'QueryPublicPortfolios';
export const QUERY_PUBLIC_PORTFOLIOS = gql`
  query queryPublicPortfolios(
    $page: Int!
    $tokenRefs: [String!]
    $authorIds: [ID!]
    $orderBy: QueryPublicPortfoliosOrderBy
  ) {
    queryPublicPortfolios(
      page: $page
      tokenRefs: $tokenRefs
      authorIds: $authorIds
      orderBy: $orderBy
    ) {
      success
      authorized
      count
      pageSize
      portfolios {
        id
        name
        visibility
        updateFrequencyInDays
        lastPublicationDate
        d7Perf
        lastUpdatePerf
        lastPublishedUpdate {
          id
          createdAt
          updatedAt
          allocatedTokens {
            id
            percentage
            createdAt
            price
            h24PriceChange
            token {
              id
              symbol
              name
              reference
              coinGeckoImage
            }
          }
        }
        user {
          id
          ranking {
            currentRank {
              name
              icon
            }
          }
          profile {
            displayName
          }
        }
      }
    }
  }
`;

export const CREATE_USER_PORTFOLIO = gql`
  mutation CreatePortfolio($name: String!) {
    createPortfolio(name: $name) {
      success
    }
  }
`;

// no need to add where public as it' handled in portfolio table permissions
export const QUERY_PORTFOLIO_BY_PK = gql`
  query QueryPortfolioByPk($id: uuid!) {
    portfolio_by_pk(id: $id) {
      id
      name
      visibility
      createdAt
      updateFrequencyInDays
      lastPublicationDate
      user {
        id
        profile {
          displayName
        }
      }
      lastPublishedUpdate {
        id
        allocatedTokens {
          id
          percentage
          token {
            id
            symbol
            name
            reference
            coinGeckoImage
          }
        }
      }
    }
  }
`;

export const SEARCH_AUTHORS_PORTFOLIO = gql`
  query SearchAuthorsPortfolio(
    $searchText: String!
    $searchResultFilter: userProfile_bool_exp = {}
    $authorFilter: userProfile_bool_exp = {}
  ) {
    searchResults: search_portfolio_authors(
      args: { search: $searchText }
      where: $searchResultFilter
    ) {
      userId
      displayName
    }

    authors: userProfile(where: $authorFilter) {
      userId
      displayName
    }
  }
`;
