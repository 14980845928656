import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

function isLocalizedYupError(
  error: unknown,
): error is { key: string; options?: any } {
  if (typeof error === 'object' && error !== null) {
    return 'key' in error;
  }
  return false;
}

export type R = string | Array<R>;

export function useFieldTranslation() {
  // set NS here ta avoid `form:${error.key}` as it's not parsed by i18n-scanner anyway
  const i18n = useTranslation(['forms']);
  return useMemo(() => {
    function t(error: unknown): R {
      if (Array.isArray(error)) {
        return error.map((e) => t(e));
      }
      if (typeof error === 'string') {
        return i18n.t(error as any);
      }
      if (isLocalizedYupError(error)) {
        return i18n.t(error.key, error.options) as any;
      }
      return i18n.t('forms:default-error');
    }
    return { ...i18n, t };
  }, []);
}
