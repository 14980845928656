import * as yup from 'yup';

const filterSchema = yup
  .object({
    questionId: yup.string().uuid().required(),
    choiceId: yup.string().uuid().required(),
  })
  .default(undefined);

type AnyObject = Record<string, any>;
export const criteriaSchema = yup.object({
  or: yup.lazy(
    (): yup.ArraySchema<any, AnyObject, any[] | null | undefined> =>
      yup.array(criteriaSchema).nullable().optional(),
  ),
  and: yup.lazy(
    (): yup.ArraySchema<any, AnyObject, any[] | null | undefined> =>
      yup.array(criteriaSchema).nullable().optional(),
  ),
  condition: filterSchema,
});

const paginationSchema = yup.object({
  page: yup.number().integer().positive().required(),
  size: yup.number().integer().positive().required(),
});

const sortingSchema = yup.object({
  communityScore: yup.mixed<'desc' | 'asc'>().oneOf(['desc', 'asc']).optional(),
  validatedResearchCount: yup
    .mixed<'desc' | 'asc'>()
    .oneOf(['desc', 'asc'])
    .optional(),
});

export const searchSchema = yup.object({
  pagination: paginationSchema,
  search: criteriaSchema,
  sorting: sortingSchema,
});

export const powerSearchPreviewSchema = filterSchema;
