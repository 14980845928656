import React from 'react';
import { ratingColors } from 'business/rating/services/utils';
import { TokenRating } from 'ui/rating';
import { Rating } from 'generated/graphql';
import { Spacer } from 'ui/spacer';
import classes from './index.module.scss';

export interface TokenRatingHeaderProps {
  rating?: Pick<Rating, 'name' | 'type'> | null;
}

export function RatingHeader({ rating }: TokenRatingHeaderProps) {
  return (
    <Spacer
      justify="center"
      className={classes.tokenRatingBackground}
      style={{
        backgroundColor:
          ratingColors[rating?.type || 'unknown']?.transparent ??
          ratingColors.unknown.transparent,
      }}
    >
      <TokenRating size="large" rating={rating} />
    </Spacer>
  );
}
