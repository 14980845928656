import React from 'react';
import { useField } from 'formik';
import { Form } from 'antd';
import TokenSelect, {
  TokenSelectProps,
} from 'business/token/components/token-select';
import { SelectValue } from 'technical/form/types';
import { RecursiveFormNames } from '../types';
import { useFieldTranslation } from '../translations';

export type TokenSelectFieldProps<FormValues> = Omit<
  TokenSelectProps,
  'children' | 'options'
> & {
  label: React.ReactNode;
  name: RecursiveFormNames<FormValues>;
  id: string;
  required?: boolean;
  defaultMessage?: string | null;
};

export function TokenSelectField<FormValues>({
  label,
  name,
  id,
  required = false,
  defaultMessage = '\u00A0',
  ...rest
}: TokenSelectFieldProps<FormValues>) {
  const { t } = useFieldTranslation();
  const [{ value }, meta, helpers] = useField<SelectValue>({
    name,
    multiple: !!rest.mode,
  });

  const status = meta.touched && meta.error ? 'error' : 'success';
  const message = status === 'error' ? t(meta.error as any) : defaultMessage;

  return (
    <Form.Item
      htmlFor={id}
      label={label}
      validateStatus={status}
      help={message}
      required={required}
    >
      <TokenSelect
        {...rest}
        id={id}
        value={value}
        onChange={(newValue, ...args) => {
          rest.onChange?.(newValue, ...args);
          helpers.setValue(newValue);
        }}
        onBlur={(...args) => {
          rest.onBlur?.(...args);
          helpers.setTouched(true);
        }}
      />
    </Form.Item>
  );
}
