import { Checkbox, Form, Input, Typography } from 'antd';
import {
  useDefaultReferralCode,
  useUTMCampaign,
} from 'business/user/services/hooks';
import {
  formikErrorMessage,
  generateStyleOnErrors,
} from 'business/user/services/user';
import { email, password } from 'business/user/validations/string';
import { FormikHelpers, useFormik } from 'formik';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import Button from 'ui/button';
import InputError from 'ui/form/input-error';
import PasswordInput from 'ui/form/password-input';
import { Spacer } from 'ui/spacer';
import * as yup from 'yup';

import { CardInfo } from 'ui/cards/card-info';
import { ResendEmailCard } from 'business/user/components/auth-form/resend-email-card';
import classes from './index.module.scss';
import SupportContactButtons from '../support-contact-buttons';

interface LoginInfoErrorProps {
  signUpError: string;
  login: string;

  isVisible?: boolean;
}

const LoginInfoError: React.VFC<LoginInfoErrorProps> = ({
  signUpError,
  login,
  isVisible,
}) => {
  const { t } = useTranslation(['common']);

  if (!isVisible || signUpError === '') {
    return null;
  }

  switch (signUpError) {
    case '':
      return null;
    case 'email-not-verified':
      return <ResendEmailCard email={login} />;
    default:
      return (
        <CardInfo
          title={t('pages.signIn.form.contact-support.title')}
          description={t('pages.signIn.form.contact-support.description')}
          childrens={<SupportContactButtons />}
        />
      );
  }
};

export interface Values {
  login: string;
  password: string;
  registeredFromCampaign?: string;
  referredFromCode?: string;
  hasAgreedToCommercialOffers?: boolean;
  hasCertifiedAgeMajority?: boolean;
}

export interface AuthFormProps {
  submitButton: {
    label: string;
    onClick: (
      values: Values,
      formikHelpers: FormikHelpers<Values>,
    ) => void | Promise<any>;
  };
  authError: { key: string; context: string };
  hint?: React.ReactNode;
  passwordHint?: React.ReactNode;
  bottomHint?: React.ReactNode;
  showSignUpInput?: boolean;
}

export default function AuthForm({
  submitButton,
  authError,
  hint,
  passwordHint,
  bottomHint,
  showSignUpInput,
}: AuthFormProps) {
  const utmCampaign = useUTMCampaign();
  const defaultReferralCode = useDefaultReferralCode();
  const { t } = useTranslation(['common']);
  const [loading, setLoading] = useState<boolean>(true);

  const initialValues: Values = {
    login: '',
    password: '',
    referredFromCode: defaultReferralCode,
    registeredFromCampaign: utmCampaign,
    hasAgreedToCommercialOffers: false,
    hasCertifiedAgeMajority: false,
  };

  const common = yup.object().shape({
    login: email(t),
    password: password(t),
  });

  const schema = showSignUpInput
    ? common.shape({
        registeredFromCampaign: yup.string().optional(),
        referredFromCode: yup.string().optional(),
        hasAgreedToCommercialOffers: yup.boolean().optional(),
        hasCertifiedAgeMajority: yup
          .boolean()
          .isTrue(t('errors.signUp', { context: 'has-not-certified-majority' }))
          .required(),
      })
    : common;

  const formik = useFormik({
    initialValues,
    validateOnMount: true,
    validationSchema: schema,
    onSubmit: submitButton.onClick,
  });

  useEffect(() => {
    if (formik.errors) {
      setLoading(false);
    }
  }, [formik]);

  const handleSubmit = () => {
    setLoading(true);
    formik.setFieldValue('registeredFromCampaign', utmCampaign);
    formik.handleSubmit();
  };

  // todo use custom field, will be merged with KRYPT-69
  return (
    <Form onFinish={handleSubmit} layout="vertical">
      <Spacer direction="vertical" align="center" space={2}>
        <LoginInfoError
          signUpError={authError.context}
          login={formik.values.login}
          isVisible={!showSignUpInput}
        />
        <Spacer direction="vertical" space={2}>
          <Form.Item htmlFor="login" label={t('notConnected.login.email')}>
            <Input
              className={classes.inputs}
              data-test-id="login-input"
              id="login"
              onChange={(e) => {
                formik.setTouched({ ...formik.touched, login: undefined });
                formik.handleChange(e);
              }}
              onBlur={formik.handleBlur}
              style={generateStyleOnErrors(formik, 'login')}
            />
            <InputError error={formikErrorMessage(formik, 'login')} />
          </Form.Item>
          <Spacer direction="vertical">
            <PasswordInput
              className={classes.inputs}
              label={t('notConnected.login.password')}
              id="password"
              dataTestId="password-input"
              onChange={(event) => {
                formik.setTouched({ ...formik.touched, password: undefined });
                formik.handleChange(event);
              }}
              onBlur={formik.handleBlur}
              style={generateStyleOnErrors(formik, 'password')}
              passwordHint={passwordHint}
              error={formikErrorMessage(formik, 'password')}
            />
            {showSignUpInput && (
              <Typography.Text>
                {t('common:pages.signUp.form.passwordRequirements')}
              </Typography.Text>
            )}
          </Spacer>
          {showSignUpInput && (
            <>
              <Form.Item
                htmlFor="referredFromCode"
                label={t('common:notConnected.login.referralCode')}
              >
                <Input
                  className={classes.inputs}
                  id="referredFromCode"
                  defaultValue={defaultReferralCode}
                  onChange={(e) => {
                    formik.setTouched({
                      ...formik.touched,
                      referredFromCode: undefined,
                    });
                    formik.handleChange(e);
                  }}
                  onBlur={formik.handleBlur}
                  style={generateStyleOnErrors(formik, 'referredFromCode')}
                />
                <InputError
                  error={formikErrorMessage(formik, 'referredFromCode')}
                />
              </Form.Item>
              <Form.Item htmlFor="hasCertifiedAgeMajority">
                <Checkbox
                  id="hasCertifiedAgeMajority"
                  onChange={(e) => {
                    formik.setTouched({ ...formik.touched, login: undefined });
                    formik.handleChange(e);
                  }}
                  style={generateStyleOnErrors(
                    formik,
                    'hasCertifiedAgeMajority',
                  )}
                >
                  <div className={classes.hint}>
                    <Typography.Text>
                      {t('common:pages.signUp.certifyMajorityStart')}
                    </Typography.Text>
                    <a
                      href="https://corporate.kryptview.com/terms-of-use/"
                      rel="noopener noreferrer"
                      target="_blank"
                    >
                      {t('common:pages.signUp.termsOfUse')}
                    </a>
                    <Typography.Text>
                      {t('common:pages.signUp.certifyMajorityEnd')}
                    </Typography.Text>
                  </div>
                </Checkbox>
                <InputError
                  error={formikErrorMessage(formik, 'hasCertifiedAgeMajority')}
                />
              </Form.Item>
              <Form.Item htmlFor="hasAgreedToCommercialOffers">
                <Checkbox
                  id="hasAgreedToCommercialOffers"
                  onChange={(e) => {
                    formik.setTouched({ ...formik.touched, login: undefined });
                    formik.handleChange(e);
                  }}
                  style={generateStyleOnErrors(
                    formik,
                    'hasAgreedToCommercialOffers',
                  )}
                >
                  {t('common:pages.signUp.agreeToCommercialOffer')}
                </Checkbox>
                <InputError
                  error={formikErrorMessage(
                    formik,
                    'hasAgreedToCommercialOffers',
                  )}
                />
              </Form.Item>
            </>
          )}
          <InputError
            error={
              authError.context
                ? t(authError.key as any, { context: authError.context })
                : undefined
            }
          />
          {hint}
        </Spacer>

        <Button
          id="submit-button"
          data-test-id="submit-button"
          type="primary"
          htmlType="submit"
          block
          loading={loading}
        >
          {submitButton.label}
        </Button>
        {bottomHint}
      </Spacer>
    </Form>
  );
}
