import React, { FC } from 'react';
import { Image, Tooltip, Typography } from 'antd';
import { Spacer } from 'ui/spacer';
import classes from './index.module.scss';

interface RankingProps {
  rank?: {
    name: string;
    icon: string;
  } | null;
  compact?: boolean;
}

export const Ranking: FC<RankingProps> = ({ rank, compact }) => {
  if (rank) {
    return (
      <Spacer space="none" align="center" className={classes.rankWrapper}>
        <Tooltip
          visible={compact ? undefined : false}
          placement="top"
          title={rank.name}
        >
          <Image
            src={`/ranks${rank.icon}`}
            height={40}
            width={40}
            preview={false}
            style={{ objectFit: 'cover' }}
          />
        </Tooltip>
        {!compact && (
          <Typography.Text strong className={classes.rankLevel}>
            {rank.name}
          </Typography.Text>
        )}
      </Spacer>
    );
  }

  return (
    <Typography.Text strong className={classes.rankLevel}>
      -
    </Typography.Text>
  );
};
