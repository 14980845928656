import { notification } from 'antd';
import { useAppContext } from 'business/app-bootstrapper';
import {
  useAnswerReviewReasonsQuery,
  useCreatePeerReviewMutation,
  useInsertAnswerReviewMutation,
  usePeerReviewPageQuery,
  useSubmitPeerReviewMutation,
} from 'generated/graphql';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import { isNotNullOrUndefined } from 'technical/utils/is-not-null-or-undefined';
import { isNullOrUndefined } from 'technical/utils/is-null-or-undefined';
import {
  IsCategorySelectedById,
  PeerReviewAnswersQuestionsByCategoryName,
  PeerReviewOpinion,
  PeerReviewOpinionAndReasonByAnswerId,
} from '../types';

export function usePeerReviewResearch(researchId: string) {
  const { refetchCurrentUser } = useAppContext();
  const navigate = useNavigate();
  const { t } = useTranslation(['review']);
  const [
    createPeerReview,
    { loading: creatingPeerReview },
  ] = useCreatePeerReviewMutation({
    refetchQueries: ['PeerReviewPage'],
    awaitRefetchQueries: true,
  });

  const { data, loading } = usePeerReviewPageQuery({
    variables: { researchId },
    onCompleted: ({ research }) => {
      if (isNullOrUndefined(research)) {
        return;
      }

      if (research.peerReviews.length === 0) {
        createPeerReview({
          variables: { researchId },
        });
      }
    },
  });

  const research = data?.research ?? undefined;

  // create map to know if a category (with the ID) is selected or not
  const isCategorySelected =
    research?.selectedCategories.reduce<IsCategorySelectedById>(
      (record, category) => {
        return {
          ...record,
          [category.categoryId]: category.isSelected,
        };
      },
      {},
    ) ?? {};

  // create the record category => questions
  const answersQuestionsByCategoryName =
    research?.answers.reduce<PeerReviewAnswersQuestionsByCategoryName>(
      (record, answer) => {
        const { category } = answer.question;

        // if the category is not selected, ignore the answer
        if (!isCategorySelected[category.id]) {
          return { ...record };
        }

        if (isNotNullOrUndefined(record[category.name])) {
          return {
            ...record,
            [category.name]: {
              order: answer.question.category.order,
              categoryId: answer.question.category.id,
              questions: [...record[category.name].questions, answer],
            },
          };
        }
        return {
          ...record,
          [category.name]: {
            order: answer.question.category.order,
            categoryId: answer.question.category.id,
            questions: [answer],
          },
        };
      },
      {},
    ) ?? {};

  const [submitPeerReview] = useSubmitPeerReviewMutation({
    refetchQueries: ['PeerReviewPage'],
    awaitRefetchQueries: true,
    onCompleted() {
      refetchCurrentUser().catch(() => {
        notification.error({ message: t('user:profile-update.error-refetch') });
      });
    },
    onError(error) {
      const apolloErrorCode = error.graphQLErrors[0]?.extensions?.code;

      if (apolloErrorCode) {
        notification.error({
          message: t('review:toast.submission.server-error', {
            context: apolloErrorCode,
          }),
        });
      } else {
        notification.error({
          message: t('review:toast.submission.error', { context: error.name }),
        });
      }
    },
  });

  const peerReview = research?.peerReviews[0];

  // create a record answerId => Opinion
  const opinionAndReasonByAnswerId =
    peerReview?.answerReviews.reduce<PeerReviewOpinionAndReasonByAnswerId>(
      (record, answerReview) => {
        return {
          ...record,
          [answerReview.answerId]: {
            opinion: answerReview.opinion,
            reason: answerReview.reason,
          },
        };
      },
      {},
    ) ?? {};

  const [setAnswerReviewOpinion] = useInsertAnswerReviewMutation({
    refetchQueries: ['PeerReviewPage'],
    awaitRefetchQueries: true,
  });

  const setApproval = (
    answerId: string,
    peerReviewId: string,
    opinion: PeerReviewOpinion,
    reason: string | null | undefined,
  ) => {
    setAnswerReviewOpinion({
      variables: { answerId, peerReviewId, opinion, reason },
    });
  };

  // create a category List
  const categories = Object.keys(answersQuestionsByCategoryName).map(
    (category) => {
      return {
        category,
        order: answersQuestionsByCategoryName[category].order,
        id: answersQuestionsByCategoryName[category].categoryId,
      };
    },
  );
  categories.sort((a, b) => a.order - b.order);

  const alreadySubmitted: boolean = research?.peerReviews[0]
    ? isNotNullOrUndefined(research?.peerReviews[0].status) &&
      research?.peerReviews[0].status !== 'draft'
    : false;

  const canPublish = Object.values(answersQuestionsByCategoryName)
    .flatMap((answers) => answers.questions)
    .every(({ id }) => isNotNullOrUndefined(opinionAndReasonByAnswerId[id]));

  const publish = () => {
    if (!peerReview) {
      return; // TODO: display error message ?
    }
    submitPeerReview({
      variables: { peerReviewId: peerReview?.id },
      onCompleted: () => {
        notification.success({
          message: t('review:toast.submission.success'),
        });
        navigate(-1);
      },
    });
  };

  const { data: reasonsData } = useAnswerReviewReasonsQuery();

  const comment = research?.comment ?? '';
  const prediction = research?.targetPrice ?? 0;

  return {
    loading: loading || creatingPeerReview,
    answersQuestionsByCategoryName,
    opinionAndReasonByAnswerId,
    token: research?.token,
    peerReview: research?.peerReviews[0],
    setApproval,
    categories: categories.flatMap((category) => {
      return { name: category.category, id: category.id };
    }),
    alreadySubmitted,
    canPublish,

    publish,
    comment,
    prediction,
    reasons: reasonsData?.research_answerReviewReason ?? [],
  };
}
