import React, { useState } from 'react';
import { PortfolioTable } from 'business/portfolio/components/portfolio-table';
import { Card } from 'ui/cards';
import { useTranslation } from 'react-i18next';
import WantToEarnMore from 'business/portfolio/components/want-to-earn-more';
import Routes from 'business/router/routes';
import {
  PublicPortfolioResponse,
  useQueryPublicPortfoliosQuery,
} from 'generated/graphql';
import { PortfolioDetailsModal } from 'business/portfolio/components/portfolio-details-modal';
import { LoggedCard } from 'business/user/components/logged-card';
import { Typography } from 'antd';
import { Spacer } from 'ui/spacer';
import { TokenSelectField } from 'ui/form/fields/token-select';
import { Form } from 'ui/form';
import {
  portfolioTableOnChange,
  usePortfolioTableFilters,
} from 'business/portfolio/services/use-portfolio-table-filters';
import { PortfolioAuthorSelectField } from 'ui/form/fields/portfolio-authors-select';
import classes from './index.module.scss';
import config from 'config';

type FilterFormValues = {
  tokenRefs: string[] | null;
  authorIds: string[] | null;
};

export const CommunityPortfoliosPage: React.VFC = () => {
  const { t } = useTranslation(['portfolio']);
  const { filtersValues, setFiltersValues } = usePortfolioTableFilters();
  const {
    page,
    tokenRefs,
    authorIds,
    orderBy = { lastPublicationDate: 'desc' },
  } = filtersValues;

  const handleFilterChange = (values: FilterFormValues) => {
    setFiltersValues({
      ...filtersValues,
      tokenRefs: values.tokenRefs,
      authorIds: values.authorIds,
    });
  };

  const [
    currentPortfolio,
    setCurrentPortfolio,
  ] = useState<PublicPortfolioResponse>(); // todo mv this to navigation param
  const { data, loading, previousData } = useQueryPublicPortfoliosQuery({
    variables: {
      page: page - 1,
      tokenRefs: tokenRefs?.length ? tokenRefs : null,
      authorIds: authorIds?.length ? authorIds : null,
      orderBy,
    },
  });

  const portfolios =
    data?.queryPublicPortfolios?.portfolios ??
    previousData?.queryPublicPortfolios?.portfolios ??
    [];
  const total = data?.queryPublicPortfolios?.count;
  const pageSize = data?.queryPublicPortfolios?.pageSize;
  const authorized = data?.queryPublicPortfolios?.authorized;
  return (
    <>
      <Typography.Title className={classes.title}>
        {t('portfolio:communityPortfolio.title')}
      </Typography.Title>
      <div className={classes.grid}>
        <Card className={classes.portfolios}>
          <Card.Header
            title={t('portfolio:communityPortfolio.tableTitle')}
            level={2}
          />
          <Spacer className={classes.table} direction="vertical" space={3}>
            <Card type="well">
              <Form<FilterFormValues>
                initialValues={{ tokenRefs, authorIds }}
                onSubmit={handleFilterChange}
              >
                {({ submitForm }) => (
                  <Spacer
                    direction={{ default: 'vertical', lg: 'horizontal' }}
                    space={2}
                  >
                    <Spacer direction="vertical">
                      <PortfolioAuthorSelectField<FilterFormValues>
                        label={
                          <Typography.Text strong>
                            {t(
                              'portfolio:communityPortfolio.filters.authors.label',
                            )}
                          </Typography.Text>
                        }
                        placeholder={t(
                          'portfolio:communityPortfolio.filters.authors.placeholder',
                        )}
                        id="authorIds"
                        mode="multiple"
                        name="authorIds"
                        onSelect={submitForm}
                        onDeselect={submitForm}
                        defaultMessage={null}
                        allowClear
                      />
                    </Spacer>
                    <Spacer direction="vertical">
                      <TokenSelectField<FilterFormValues>
                        label={
                          <Typography.Text strong>
                            {t(
                              'portfolio:communityPortfolio.filters.token.label',
                            )}
                          </Typography.Text>
                        }
                        placeholder={t(
                          'portfolio:communityPortfolio.filters.token.placeholder',
                        )}
                        id="tokenRefs"
                        name="tokenRefs"
                        mode="multiple"
                        field="reference"
                        onSelect={submitForm}
                        onDeselect={submitForm}
                        defaultMessage={null}
                        allowClear
                      />
                    </Spacer>
                  </Spacer>
                )}
              </Form>
            </Card>
            <PortfolioTable
              pagination={{
                showSizeChanger: false,
                current: page,
                pageSize,
                total,
              }}
              orderBy={orderBy}
              onChange={(pagination, filters, sorter) => {
                setFiltersValues({
                  ...filtersValues,
                  ...portfolioTableOnChange(pagination, filters, sorter),
                });
              }}
              isAuthorized={authorized}
              portfolios={portfolios}
              loading={loading}
              onDetailsClick={(id) =>
                setCurrentPortfolio(portfolios.find((p) => p.id === id))
              }
            />
          </Spacer>
        </Card>
        <div
          hidden={config.features.isInHibernation}
          className={classes.myPortfolios}
        >
          <LoggedCard minContentHeight={0}>
            <WantToEarnMore to={Routes.PersonalPortfolio} />
          </LoggedCard>
        </div>
      </div>
      <PortfolioDetailsModal
        portfolio={currentPortfolio}
        open={currentPortfolio !== undefined}
        onCancel={() => setCurrentPortfolio(undefined)}
      />
    </>
  );
};
