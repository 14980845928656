import { useTopResearchesQuery } from 'generated/graphql';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { BlurredFeature } from 'ui/blurred-feature';
import { Empty } from 'ui/empty-state';
import { Spacer } from 'ui/spacer';
import { TopResearchCard } from './top-research-card';
import classes from './index.module.scss';

export interface TopResearchesProps {
  tokenReference: string;
}
export function TopResearches({ tokenReference }: TopResearchesProps) {
  const { t } = useTranslation(['token']);
  const { data, loading } = useTopResearchesQuery({
    variables: { tokenReference },
  });

  return (
    <BlurredFeature
      blurred={!loading && data?.top_researches.authorized === false}
    >
      <Spacer space={1} direction="vertical">
        {data?.top_researches.topResearches.length === 0 ? (
          <Empty
            className={classes.empty}
            description={t('token:top-researches.no-data')}
          />
        ) : null}
        {data?.top_researches.topResearches.map(
          ({ author, targetPrice, id, comment, publishedAt }) => (
            <TopResearchCard
              key={id}
              id={id}
              author={author}
              targetPrice={targetPrice}
              comment={comment}
              publishedAt={publishedAt}
            />
          ),
        )}
      </Spacer>
    </BlurredFeature>
  );
}
