import React, { useState } from 'react';
import { Card } from 'ui/cards';
import { Button, Image, Typography } from 'antd';
import { Spacer } from 'ui/spacer';
import { useTranslation } from 'react-i18next';
import tokenImg from 'assets/illustrations/token4.png';
import { useGetUserReferralCodeAndInfoQuery } from 'generated/graphql';
import { useAppContext } from 'business/app-bootstrapper';
import classes from './index.module.scss';

export function InviteAndEarn() {
  const { t } = useTranslation(['dashboard']);
  const [isCopied, setCopied] = useState(false);
  const { user } = useAppContext();
  const { data } = useGetUserReferralCodeAndInfoQuery({
    // @ts-expect-error
    variables: { userId: user?.id },
  });

  const code: string =
    data?.userReferralCode[0]?.code ?? t('dashboard:no-code');
  const peersCounter = data?.referredUsers.aggregate?.count ?? 0;
  const peersWithRank = data?.confirmedReferredUser.aggregate?.count ?? 0;

  const copyCode = async () => {
    try {
      await navigator.clipboard.writeText(code);
      setCopied(true);
      setTimeout(() => setCopied(false), 2000);
    } catch (error) {
      setCopied(false);
    }
  };

  // !TODO Fix to real data
  const priceInviteFriend = 20;

  return (
    <Card>
      <Spacer direction="vertical">
        <Typography.Title level={5}>
          {t('dashboard:invite_and_earn')}
        </Typography.Title>

        <Spacer className={classes.description}>
          <Card type="well" className={classes['token-card']}>
            <Image
              src={tokenImg}
              height={65}
              width={65}
              preview={false}
              className={classes['token-logo']}
            />
          </Card>

          <Spacer
            direction="vertical"
            className={classes.text}
            justify="center"
          >
            <Spacer direction="horizontal" className={classes.text}>
              <Typography.Text className={classes.title}>
                {t('dashboard:invite_you_friend')}
                <span className={classes.counter}>
                  {`${priceInviteFriend} USDT`}
                </span>
                {t('dashboard:each')}
              </Typography.Text>
            </Spacer>

            <div>
              <Typography.Text>{t('dashboard:share_the_code')}</Typography.Text>
              <a
                href="https://learn.kryptview.com/kryptview-referral-contest-invite-your-friends"
                rel="noopener noreferrer"
                target="_blank"
              >
                {t('dashboard:more-info')}
              </a>
            </div>
          </Spacer>
        </Spacer>

        <Spacer className={classes['invite-description']}>
          <Card type="well" className={classes['invite-card']}>
            <Spacer direction="vertical" justify="center" align="center">
              <Typography.Text>
                {t('dashboard:sign-up-with-referral-code')}
              </Typography.Text>

              <Typography.Text className={classes.counter}>
                {`${peersCounter} ${t('dashboard:friends')}`}
              </Typography.Text>
            </Spacer>
          </Card>

          <Card type="well" className={classes['peers-invite-card']}>
            <Spacer direction="vertical" justify="center" align="center">
              <Typography.Text>
                {t('dashboard:friends-with-rank')}
                <span className={classes['gold-price']}>
                  {t('dashboard:gold')}
                </span>
                {t('dashboard:friends-with-or-above')}
              </Typography.Text>

              <Typography.Text className={classes.counter}>
                {peersWithRank}
              </Typography.Text>
            </Spacer>
          </Card>
        </Spacer>

        <div className={classes['code-wrapper']}>
          <div className={classes.code}>
            {isCopied ? t('dashboard:code-copied') : code}
          </div>
          <Button
            type="primary"
            onClick={copyCode}
            className={classes['copy-code-button']}
          >
            {t('dashboard:copy-code')}
          </Button>
        </div>
      </Spacer>
    </Card>
  );
}
