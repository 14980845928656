import React, { PropsWithChildren } from 'react';
import { chain, createClient, WagmiConfig, Connector } from 'wagmi';
import '@rainbow-me/rainbowkit/styles.css';
import config from 'config';
import logger from 'technical/logger';
import {
  getDefaultWallets,
  darkTheme,
  lightTheme,
  RainbowKitProvider,
} from '@rainbow-me/rainbowkit';
import { providers } from 'ethers';
import { useThemes } from 'ui/theme';

// Pick chains
const defaultChain = Object.values(chain).find(
  ({ id }) => id === config.smartContracts.chainId,
);
if (defaultChain === undefined) {
  logger.error({
    message: `chain id ${config.smartContracts.chainId} is not supported`,
    severity: 'extreme',
    app: 'could not start',
  });
  throw new Error(`chain id ${config.smartContracts.chainId} is not supported`);
}
const chains = [defaultChain];

// Set up providers
type ProviderConfig = { chainId?: number; connector?: Connector };
const isChainSupported = (chainId?: number) =>
  chains.some((x) => x.id === chainId);

// Set up providers
export const provider = ({ chainId }: ProviderConfig) =>
  providers.getDefaultProvider(
    isChainSupported(chainId) ? chainId : defaultChain.id,
  );

const { connectors } = getDefaultWallets({
  appName: 'Kryptview',
  chains,
});

const wagmiClient = createClient({
  autoConnect: true,
  connectors,
  provider,
});

export function WagmiProvider({ children }: PropsWithChildren<{}>) {
  const [{ current }] = useThemes();
  return (
    <WagmiConfig client={wagmiClient}>
      <RainbowKitProvider
        chains={chains}
        theme={current === 'light' ? lightTheme() : darkTheme()}
      >
        {children}
      </RainbowKitProvider>
    </WagmiConfig>
  );
}
