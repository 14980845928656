import { EyeInvisibleOutlined, EyeTwoTone } from '@ant-design/icons';
import { Form, Input } from 'antd';
import React from 'react';
import InputError from 'ui/form/input-error';
import styles from './index.module.scss';

interface Props {
  label?: string;
  onChange?: React.ChangeEventHandler<HTMLInputElement>;
  onBlur?: React.FocusEventHandler<HTMLInputElement>;
  style?: React.CSSProperties;
  error?: string;
  id?: string;
  dataTestId?: string;
  className?: string;
  passwordHint?: React.ReactNode;
}

export default function PasswordInput({
  label,
  onChange,
  onBlur,
  style,
  error,
  id,
  dataTestId,
  className,
  passwordHint,
}: Props) {
  return (
    <Form.Item htmlFor={id} label={label}>
      <Input.Password
        className={className}
        id={id}
        data-test-id={dataTestId}
        onChange={onChange}
        onBlur={onBlur}
        style={style}
        iconRender={(visible) =>
          visible ? <EyeTwoTone /> : <EyeInvisibleOutlined />
        }
      />
      <InputError error={error} />
      <p className={styles.passwordHint}>{passwordHint}</p>
    </Form.Item>
  );
}
