import React, { useState } from 'react';
import { Typography } from 'antd';
import { Card } from 'ui/cards';
import { useTranslation } from 'react-i18next';
import { Spacer } from 'ui/spacer';
import Button from 'ui/button';
import { useLoginModal } from 'business/user/services/hooks';
import { useAppContext } from 'business/app-bootstrapper';
import classes from './index.module.scss';
import LoginModal, { LoginModalFormTabs } from '../login-modal';
import Routes from 'business/router/routes';
import { useLocation } from 'react-router-dom';

export function ConnectionCard() {
  const { t } = useTranslation(['common']);
  const location = useLocation();
  const { isConnected } = useAppContext();
  const { loginModalIsOpen, setLoginModalIsOpen } = useLoginModal();
  const [loginModalType, setLoginModalType] = useState<LoginModalFormTabs>();

  if (isConnected || location.pathname === Routes.Home) {
    return null;
  }

  return (
    <>
      <Card className={classes.card}>
        <Spacer direction="vertical" space={2}>
          <div>
            <Typography.Title level={3}>
              {t('common:connection-mobile-card.title')}
            </Typography.Title>
            <Typography.Text>
              {t('common:connection-mobile-card.description')}
            </Typography.Text>
          </div>
          <Spacer direction="horizontal" space={1} justify="center">
            <Button
              type="default"
              loading={loginModalIsOpen}
              size="large"
              className={classes.button}
              onClick={() => {
                setLoginModalType(LoginModalFormTabs.signin);
                setLoginModalIsOpen(true);
              }}
            >
              {t('notConnected.login.submit')}
            </Button>
            <Button
              type="primary"
              loading={loginModalIsOpen}
              size="large"
              className={classes.button}
              onClick={() => {
                setLoginModalType(LoginModalFormTabs.signup);
                setLoginModalIsOpen(true);
              }}
            >
              {t('pages.signUp.form.submit')}
            </Button>
          </Spacer>
        </Spacer>
      </Card>
      <LoginModal
        isOpen={loginModalIsOpen} // type is boolean or undefined but we should have default value
        setIsOpen={setLoginModalIsOpen}
        startTab={loginModalType}
      />
    </>
  );
}
