import React, { PropsWithChildren } from 'react';
import Button from 'ui/button';
import classnames from 'classnames';
import { CheckCircleOutlined } from '@ant-design/icons';
import styles from './index.module.scss';

interface SelectableBlockProps {
  isSelected: boolean;
  onClick?: () => void;
}

const Index: React.FC<PropsWithChildren<SelectableBlockProps>> = ({
  isSelected,
  onClick,
  children,
}) => {
  return (
    <Button
      type="default"
      className={classnames([styles.default], {
        [styles.selected]: isSelected,
      })}
      onClick={onClick}
    >
      {isSelected ? (
        <CheckCircleOutlined className={styles.selectedIcon} />
      ) : null}
      {children}
    </Button>
  );
};

export default Index;
