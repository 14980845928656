import React from 'react';
import { Typography } from 'antd';
import { defaultTokenEmptyValue } from 'business/token/common-values';
import { TypographyProps } from 'antd/lib/typography/Typography';
import { isNotNullOrUndefined } from 'technical/utils/is-not-null-or-undefined';
import { i18n } from 'translations';

export enum NumberFormats {
  currency = 'currency',
}

const currencyOptions: Intl.NumberFormatOptions = {
  currency: 'USD',
  style: NumberFormats.currency,
  currencyDisplay: 'symbol',
  maximumFractionDigits: 8,
};

export type NumericValueType = number | Number | null;

interface NumericValueProps extends TypographyProps<any> {
  format?: NumberFormats.currency;
  value?: NumericValueType;
  emptyValue?: string;
  prefix?: string;
  strong?: boolean;
  colored?: boolean;
  options?: Intl.NumberFormatOptions;
  className?: string;
}

/**
 * To display a number or currency.
 *
 * If the format is set to currency, currency format will be rendered (USD 9,000.54)
 *
 * If prop **format** is omitted, value will be rendered as a plain number according to options
 */
export const NumericValue: React.VFC<NumericValueProps> = ({
  format,
  value,
  emptyValue = defaultTokenEmptyValue,
  colored = false,
  options = {},
  className,
  ...rest
}) => {
  const allOptions = {
    ...(format ? currencyOptions : {}),
    ...options,
  };

  let formattedValue = emptyValue;
  if (isNotNullOrUndefined(value)) {
    formattedValue = value.toLocaleString(i18n.language, allOptions);
  }

  let color: undefined | 'success' | 'danger';

  if (colored && value) {
    color = value < 0 ? 'danger' : 'success';
  }

  return (
    <Typography.Text className={className} type={color} {...rest}>
      {formattedValue}
    </Typography.Text>
  );
};
