import * as yup from 'yup';
import { useLocation, useNavigate } from 'react-router-dom';
import { isNotNullOrUndefined } from 'technical/utils/is-not-null-or-undefined';
import {
  parseWithSchema,
  safeJsonParse,
} from 'technical/utils/parse-with-schema';

export function useURLSearchParams<Schema extends yup.AnySchema>({
  defaultValue,
  key,
  schema,
}: {
  key: string;
  defaultValue: yup.InferType<Schema>;
  schema: Schema;
}): readonly [
  Exclude<yup.Asserts<Schema>, null | undefined>,
  (value: yup.Asserts<Schema>) => void,
] {
  const location = useLocation();
  const navigate = useNavigate();

  const rawValue = safeJsonParse(new URLSearchParams(location.search).get(key));
  const parsedValue = parseWithSchema(rawValue, schema);
  const currentValue = parsedValue ?? defaultValue;

  function setValue(next: yup.InferType<Schema>) {
    const params = new URLSearchParams(location.search);
    if (isNotNullOrUndefined(next)) {
      params.set(key, JSON.stringify(next));
      navigate({ search: params.toString() }, { replace: true });
    } else {
      params.delete(key);
      navigate({ search: params.toString() }, { replace: true });
    }
  }

  return [currentValue, setValue] as const;
}
