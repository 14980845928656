import React from 'react';
import classnames from 'classnames';
import { getCategoryColor } from 'business/research/utils/get-category-color';
import { getCategoryIcons } from 'business/research/utils/get-category-icons';
import { Spacer } from 'ui/spacer';
import colors from 'ui/theme/variables.module.scss';
import { CategoriesEnumValues } from 'business/research/constants/category';
import { Typography } from 'antd';
import classes from './index.module.scss';

interface CategoryTabProps {
  active?: boolean;
  category: string;
  name: string;
}

export const CategoryTab: React.VFC<CategoryTabProps> = ({
  active,
  category,
  name,
}) => {
  const Icon = getCategoryIcons(category);
  const backgroundColor = active
    ? getCategoryColor(category)
    : colors.greyBackground;

  return (
    <Spacer
      style={{
        backgroundColor,
      }}
      justify="center"
      align="center"
      space={1}
      className={classes.tab}
    >
      <Icon
        height={20}
        width={20}
        fill={!active ? getCategoryColor(category) : colors.textWhite}
      />
      <Typography.Text
        className={classnames(classes.name, { [classes.active]: active })}
      >
        {name}
      </Typography.Text>
    </Spacer>
  );
};
