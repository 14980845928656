import { ResearchFormCategoryRatingQuestionsAnswersRepartition } from 'generated/graphql';
import { Spacer } from 'ui/spacer';
import { Typography } from 'antd';
import RatingTag from 'ui/rating-tag';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { isOfRatingType } from 'business/rating/types';

import classnames from 'classnames';
import classes from './index.module.scss';

interface CategoryCommunityScoreProps {
  rating: Pick<
    ResearchFormCategoryRatingQuestionsAnswersRepartition,
    'type' | 'name'
  >;
}

export function CategoryCommunityScore({
  rating,
}: CategoryCommunityScoreProps) {
  const { t } = useTranslation(['token', 'research']);

  const type = isOfRatingType(rating.type) ? rating.type : 'unknown';
  const name = rating.name ?? t('research:unknown-rating');
  return (
    <div className={classnames(classes.container, classes[type])}>
      <Spacer align="center" justify="start" space={2}>
        <Typography.Text>{t('token:rating.generalSentiment')}</Typography.Text>
        <RatingTag rating={name} type={type} />
      </Spacer>
    </div>
  );
}
