import React, { useState } from 'react';
import { Spin, Typography } from 'antd';
import {
  PriceChangePercentages as PriceChangePercentagesType,
  TokenIdentity,
  TokenMarkets,
} from 'generated/graphql';
import { Trans, useTranslation } from 'react-i18next';
import { Spacer } from 'ui/spacer';
import { Card } from 'ui/cards';
import { ReactComponent as EvolutionIcon } from 'assets/illustrations/evolution.svg';
import Select from 'ui/select';
import TagPercentage from 'ui/tag-percentage';
import variables from 'ui/theme/variables.module.scss';
import classes from './index.module.scss';
import { TFunction } from 'i18next';

interface PriceChangePercentagesProps {
  loading?: boolean;
  name: TokenIdentity['name'];
  priceChangePercentages: TokenMarkets['priceChangePercentages'];
}

type PriceChangePercentageKey = keyof Omit<
  PriceChangePercentagesType,
  '__typename'
>;

const changeKeys: PriceChangePercentageKey[] = [
  'h1',
  'h24',
  'd7',
  'd14',
  'd30',
  'd200',
  'y1',
];

type Option = {
  label: string;
  value: PriceChangePercentageKey;
};

function NoPricesAvailable({ t }: { t: TFunction<'token'> }) {
  return <Trans t={t} i18nKey="token:evolution.no-data" />;
}

function PercentageCard({
  percentage,
  text,
}: {
  percentage?: number | null;
  text: string;
}) {
  return (
    <Card type="well" className={classes.elevationCard}>
      <Spacer direction="vertical" space="none" align="center">
        <TagPercentage value={percentage} />
        <Typography className={classes.cardText}>{text}</Typography>
      </Spacer>
    </Card>
  );
}

export function PriceChangePercentages({
  name,
  priceChangePercentages,
  loading = false,
}: PriceChangePercentagesProps) {
  const { t } = useTranslation(['token']);
  const [
    selectedOption,
    setSelectedOption,
  ] = useState<PriceChangePercentageKey>();

  if (loading) {
    return <Spin size="large" />;
  }

  if (!priceChangePercentages) {
    return <NoPricesAvailable t={t} />;
  }

  const options: Option[] = changeKeys
    .filter((key) => !!priceChangePercentages[key])
    .map((key) => ({
      label: t('token:evolution.option', { context: key }),
      value: key,
    }));

  if (options.length === 0) {
    return <NoPricesAvailable t={t} />;
  }

  return (
    <Spacer direction="vertical" space={1}>
      <Spacer direction="horizontal" justify="between" align="start">
        <Card.Header
          level={2}
          title={t('token:evolution.prefix')}
          icon={
            <EvolutionIcon
              width={16}
              height={12}
              fill={variables.primaryColor2}
            />
          }
        />

        <Select<PriceChangePercentageKey>
          value={selectedOption ?? options[0].value}
          handleChange={setSelectedOption}
          options={options}
        />
      </Spacer>
      <Spacer direction="horizontal" space={1}>
        <PercentageCard
          percentage={
            priceChangePercentages[selectedOption ?? options[0].value]
          }
          text={name}
        />
        {/* TODO Find a way to obtain global market data in percentage */}
        <PercentageCard
          percentage={undefined}
          text={t('token:evolution.market')}
        />
      </Spacer>
    </Spacer>
  );
}
