import React from 'react';
import {
  ConfigProvider,
  Table as AntTable,
  TableProps as AntTableProps,
} from 'antd';
import { useTranslation } from 'react-i18next';
import { Empty } from 'ui/empty-state';

function Table<RecordType extends object>({
  pagination,
  ...rest
}: AntTableProps<RecordType>) {
  const { t } = useTranslation(['common']);

  return (
    <ConfigProvider
      renderEmpty={() => <Empty description={t('common.noData')} />}
    >
      <AntTable<RecordType>
        pagination={{ position: ['bottomCenter'], ...pagination }}
        {...rest}
      />
    </ConfigProvider>
  );
}

export default Table;
