import React from 'react';
import classes from './account-menu.module.scss';

export const AccountIcon = () => {
  return (
    <svg
      className={classes.accountIcon}
      width="16"
      height="17"
      viewBox="0 0 16 17"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_602_7776)">
        <path d="M11 6.5C11 7.29565 10.6839 8.05871 10.1213 8.62132C9.55871 9.18393 8.79565 9.5 8 9.5C7.20435 9.5 6.44129 9.18393 5.87868 8.62132C5.31607 8.05871 5 7.29565 5 6.5C5 5.70435 5.31607 4.94129 5.87868 4.37868C6.44129 3.81607 7.20435 3.5 8 3.5C8.79565 3.5 9.55871 3.81607 10.1213 4.37868C10.6839 4.94129 11 5.70435 11 6.5Z" />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M0 8.5C0 6.37827 0.842855 4.34344 2.34315 2.84315C3.84344 1.34285 5.87827 0.5 8 0.5C10.1217 0.5 12.1566 1.34285 13.6569 2.84315C15.1571 4.34344 16 6.37827 16 8.5C16 10.6217 15.1571 12.6566 13.6569 14.1569C12.1566 15.6571 10.1217 16.5 8 16.5C5.87827 16.5 3.84344 15.6571 2.34315 14.1569C0.842855 12.6566 0 10.6217 0 8.5ZM8 1.5C6.68178 1.50007 5.39037 1.87236 4.2744 2.57403C3.15844 3.27569 2.26328 4.27821 1.69196 5.46619C1.12065 6.65418 0.896386 7.97934 1.045 9.28916C1.19361 10.599 1.70905 11.8402 2.532 12.87C3.242 11.726 4.805 10.5 8 10.5C11.195 10.5 12.757 11.725 13.468 12.87C14.2909 11.8402 14.8064 10.599 14.955 9.28916C15.1036 7.97934 14.8794 6.65418 14.308 5.46619C13.7367 4.27821 12.8416 3.27569 11.7256 2.57403C10.6096 1.87236 9.31822 1.50007 8 1.5Z"
        />
      </g>
      <defs>
        <clipPath id="clip0_602_7776">
          <rect width="16" height="16" transform="translate(0 0.5)" />
        </clipPath>
      </defs>
    </svg>
  );
};

export const AccountMenuArrow = () => {
  return (
    <svg
      className={classes.accountBtnArrow}
      width="10"
      height="7"
      viewBox="0 0 10 7"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M1 1.5L4.54246 5.04246C4.79515 5.29515 5.20485 5.29515 5.45754 5.04246L9 1.5"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export const CloseIcon = () => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 25 25"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M1.00353 1.00353C1.16273 0.843915 1.35186 0.71728 1.56009 0.630877C1.76831 0.544474 1.99154 0.5 2.21698 0.5C2.44241 0.5 2.66564 0.544474 2.87386 0.630877C3.08209 0.71728 3.27122 0.843915 3.43043 1.00353L12.5004 10.077L21.5705 1.00353C21.7298 0.844173 21.919 0.717767 22.1272 0.631526C22.3354 0.545285 22.5586 0.500897 22.7839 0.500897C23.0093 0.500897 23.2324 0.545285 23.4406 0.631526C23.6488 0.717767 23.838 0.844173 23.9974 1.00353C24.1567 1.16288 24.2831 1.35206 24.3694 1.56026C24.4556 1.76846 24.5 1.99162 24.5 2.21698C24.5 2.44233 24.4556 2.66549 24.3694 2.87369C24.2831 3.08189 24.1567 3.27107 23.9974 3.43043L14.9239 12.5004L23.9974 21.5705C24.1567 21.7298 24.2831 21.919 24.3694 22.1272C24.4556 22.3354 24.5 22.5586 24.5 22.7839C24.5 23.0093 24.4556 23.2324 24.3694 23.4406C24.2831 23.6488 24.1567 23.838 23.9974 23.9974C23.838 24.1567 23.6488 24.2831 23.4406 24.3694C23.2324 24.4556 23.0093 24.5 22.7839 24.5C22.5586 24.5 22.3354 24.4556 22.1272 24.3694C21.919 24.2831 21.7298 24.1567 21.5705 23.9974L12.5004 14.9239L3.43043 23.9974C3.27107 24.1567 3.08189 24.2831 2.87369 24.3694C2.66549 24.4556 2.44233 24.5 2.21698 24.5C1.99162 24.5 1.76846 24.4556 1.56026 24.3694C1.35206 24.2831 1.16288 24.1567 1.00353 23.9974C0.844173 23.838 0.717767 23.6488 0.631526 23.4406C0.545285 23.2324 0.500897 23.0093 0.500897 22.7839C0.500897 22.5586 0.545285 22.3354 0.631526 22.1272C0.717767 21.919 0.844173 21.7298 1.00353 21.5705L10.077 12.5004L1.00353 3.43043C0.843915 3.27122 0.71728 3.08209 0.630877 2.87386C0.544474 2.66564 0.5 2.44241 0.5 2.21698C0.5 1.99154 0.544474 1.76831 0.630877 1.56009C0.71728 1.35186 0.843915 1.16273 1.00353 1.00353Z"
        fill="#4457FF"
      />
    </svg>
  );
};

export const BurgerMenuIcon = () => {
  return (
    <svg
      className={classes.burgerMenu}
      width="29"
      height="24"
      viewBox="0 0 29 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M27.8633 0H1.13601C0.986009 0 0.863281 0.122727 0.863281 0.272727V2.45455C0.863281 2.60455 0.986009 2.72727 1.13601 2.72727H27.8633C28.0133 2.72727 28.136 2.60455 28.136 2.45455V0.272727C28.136 0.122727 28.0133 0 27.8633 0ZM27.8633 21.2727H1.13601C0.986009 21.2727 0.863281 21.3955 0.863281 21.5455V23.7273C0.863281 23.8773 0.986009 24 1.13601 24H27.8633C28.0133 24 28.136 23.8773 28.136 23.7273V21.5455C28.136 21.3955 28.0133 21.2727 27.8633 21.2727ZM27.8633 10.6364H1.13601C0.986009 10.6364 0.863281 10.7591 0.863281 10.9091V13.0909C0.863281 13.2409 0.986009 13.3636 1.13601 13.3636H27.8633C28.0133 13.3636 28.136 13.2409 28.136 13.0909V10.9091C28.136 10.7591 28.0133 10.6364 27.8633 10.6364Z"
        fill="#4457FF"
      />
    </svg>
  );
};

export const SearchIcon = () => {
  return (
    <svg
      width="45"
      height="44"
      viewBox="0 0 45 44"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect x="2" y="2.5" width="40" height="40" rx="7" fill="#E6EAF2" />
      <path
        d="M33.5392 32.3348L26.0032 24.7989C27.1727 23.2871 27.8052 21.4386 27.8052 19.4944C27.8052 17.1672 26.897 14.985 25.2546 13.3397C23.6122 11.6944 21.4242 10.7891 19.0999 10.7891C16.7756 10.7891 14.5876 11.6973 12.9452 13.3397C11.2999 14.9821 10.3945 17.1672 10.3945 19.4944C10.3945 21.8188 11.3028 24.0067 12.9452 25.6491C14.5876 27.2944 16.7727 28.1998 19.0999 28.1998C21.0441 28.1998 22.8896 27.5672 24.4015 26.4007L31.9374 33.9337C31.9595 33.9558 31.9857 33.9734 32.0146 33.9853C32.0435 33.9973 32.0744 34.0035 32.1057 34.0035C32.137 34.0035 32.1679 33.9973 32.1968 33.9853C32.2257 33.9734 32.2519 33.9558 32.274 33.9337L33.5392 32.6714C33.5613 32.6493 33.5788 32.6231 33.5908 32.5942C33.6028 32.5653 33.6089 32.5344 33.6089 32.5031C33.6089 32.4719 33.6028 32.4409 33.5908 32.412C33.5788 32.3832 33.5613 32.3569 33.5392 32.3348ZM23.6963 24.0908C22.466 25.3183 20.8352 25.9944 19.0999 25.9944C17.3646 25.9944 15.7338 25.3183 14.5035 24.0908C13.276 22.8605 12.5999 21.2297 12.5999 19.4944C12.5999 17.7592 13.276 16.1254 14.5035 14.898C15.7338 13.6705 17.3646 12.9944 19.0999 12.9944C20.8352 12.9944 22.4689 13.6676 23.6963 14.898C24.9238 16.1283 25.5999 17.7592 25.5999 19.4944C25.5999 21.2297 24.9238 22.8634 23.6963 24.0908Z"
        fill="#768098"
      />
    </svg>
  );
};

export const AccountMobileBtnIcon = () => {
  return (
    <svg
      width="68"
      height="42"
      viewBox="0 0 66 40"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect y="0.5" width="66" height="39" rx="7" fill="#4457FF" />
      <circle cx="16" cy="20.5" r="6" fill="#25C377" />
      <g clipPath="url(#clip0_1532_13038)">
        <path
          d="M47.875 17.25C47.875 18.5429 47.3614 19.7829 46.4471 20.6971C45.5329 21.6114 44.2929 22.125 43 22.125C41.7071 22.125 40.4671 21.6114 39.5529 20.6971C38.6386 19.7829 38.125 18.5429 38.125 17.25C38.125 15.9571 38.6386 14.7171 39.5529 13.8029C40.4671 12.8886 41.7071 12.375 43 12.375C44.2929 12.375 45.5329 12.8886 46.4471 13.8029C47.3614 14.7171 47.875 15.9571 47.875 17.25Z"
          fill="white"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M30 20.5C30 17.0522 31.3696 13.7456 33.8076 11.3076C36.2456 8.86964 39.5522 7.5 43 7.5C46.4478 7.5 49.7544 8.86964 52.1924 11.3076C54.6304 13.7456 56 17.0522 56 20.5C56 23.9478 54.6304 27.2544 52.1924 29.6924C49.7544 32.1304 46.4478 33.5 43 33.5C39.5522 33.5 36.2456 32.1304 33.8076 29.6924C31.3696 27.2544 30 23.9478 30 20.5ZM43 9.125C40.8579 9.12511 38.7593 9.73009 36.9459 10.8703C35.1325 12.0105 33.6778 13.6396 32.7494 15.5701C31.821 17.5005 31.4566 19.6539 31.6981 21.7824C31.9396 23.9108 32.7772 25.9278 34.1145 27.6012C35.2682 25.7423 37.8081 23.75 43 23.75C48.1919 23.75 50.7301 25.7406 51.8855 27.6012C53.2228 25.9278 54.0604 23.9108 54.3019 21.7824C54.5434 19.6539 54.179 17.5005 53.2506 15.5701C52.3222 13.6396 50.8675 12.0105 49.0541 10.8703C47.2407 9.73009 45.1421 9.12511 43 9.125Z"
          fill="white"
        />
      </g>
      <defs>
        <clipPath id="clip0_1532_13038">
          <rect
            width="26"
            height="26"
            fill="white"
            transform="translate(30 7.5)"
          />
        </clipPath>
      </defs>
    </svg>
  );
};
