import React from 'react';
import { RatingHeader } from 'business/token/components/rating-header';
import { Typography } from 'antd';
import { Spacer } from 'ui/spacer';
import { useTranslation } from 'react-i18next';

export const BitcoinScoring: React.VFC = () => {
  const { t } = useTranslation(['token']);
  return (
    <Spacer direction="vertical" space={1}>
      <RatingHeader rating={{ type: 'reference', name: 'Reference' }} />
      <Typography.Paragraph>
        {t('token:reference-token.notation.no-score-history')}
      </Typography.Paragraph>
    </Spacer>
  );
};
