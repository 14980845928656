import { TFunction } from 'i18next';

const pr = new Intl.PluralRules('en-US', { type: 'ordinal' });

export const formatOrdinals = (n: number, t: TFunction<['common']>) => {
  const suffixes = new Map([
    ['one', t('common:ordinalsFormat.first')],
    ['two', t('common:ordinalsFormat.second')],
    ['few', t('common:ordinalsFormat.third')],
    ['other', t('common:ordinalsFormat.other')],
  ]);

  const rule = pr.select(n);
  const suffix = suffixes.get(rule);
  return `${n}${suffix}`;
};
