enum Routes {
  LoginCallback = '/login/callback',

  Soon = '/coming-soon',
  BraveLanding = '/brave',
  Home = '/',

  SignUp = '/signup',
  SignIn = '/signin',
  ForgotPassword = '/forgot-password',
  ResetPassword = '/reset-password/*',
  ConnectedResetPassword = '/reset-password',

  ValidateEmail = '/email-verif/callback/*',
  EmailVerified = '/email-verified',
  EmailAlreadyVerified = '/email-already-verified',
  ChangeEmail = '/change-email',

  BitcoinToken = '/token/bitcoin',
  BitcoinTokenResearches = '/token/bitcoin/research',
  BitcoinResearchHistory = '/research/bitcoin',

  token = '/token/:reference', // keep sync with BitcoinToken
  tokenResearches = '/token/:reference/research', // keep sync with BitcoinTokenResearches
  ResearchHistory = '/research/history',
  ResearchToken = '/research/:reference', // keep sync with BitcoinResearchToken
  Research = '/to-delete',
  CommunityResearches = '/community-researches',
  PeerReviewPage = '/peer-review/:researchId',

  PowerSearch = '/power-search',

  CommunityPortfolios = '/community-portfolios',
  PersonalPortfolio = '/personal-portfolios',

  PublicProfile = '/profile/:id',

  Leaderboard = '/leaderboard',
  Dashboard = '/dashboard',
}

export default Routes;
