import React from 'react';
import { useGetTokenIdentityQuery } from 'generated/graphql';
import { useParams } from 'react-router-dom';
import { Card } from 'ui/cards';
import { useTranslation } from 'react-i18next';
import { PendingCommunityResearchesTab } from 'business/research/pages/сommunity-researches/pending-community-researches-tab';

export function TokenResearchesPage() {
  const { t } = useTranslation(['token']);
  const { reference } = useParams<'reference'>();
  if (reference === undefined) {
    throw new Error('reference param is not defined');
  }
  const query = useGetTokenIdentityQuery({
    variables: { reference },
    skip: !reference,
  });
  const token = query.data?.getTokenInfos?.identity;

  return (
    <Card>
      <PendingCommunityResearchesTab
        tokenRefs={token?.reference}
        title={t('token:research.pending.for-token.title', {
          symbol: token?.symbol.toUpperCase(),
          token: token?.name,
        })}
      />
    </Card>
  );
}
