import { Typography, Tooltip } from 'antd';
import React from 'react';
import { CoinGeckoTokenImage } from 'ui/coin-gecko-token-image';
import { Spacer } from 'ui/spacer';
import Link from 'ui/link';
import { Token } from 'generated/graphql';
import { generatePath } from 'react-router-dom';
import Routes from 'business/router/routes';
import { truncateCenter } from 'technical/utils/truncate-center';
import classes from './index.module.scss';

interface TokenIconAndNameProps {
  token: Pick<Token, 'name' | 'symbol' | 'coinGeckoImage' | 'reference'>;
}

export const TokenIconAndName = ({
  token: { name, symbol, coinGeckoImage, reference },
}: TokenIconAndNameProps) => {
  const charsAllowed = 11;
  const nameTruncated = truncateCenter(name, charsAllowed);
  const symbolTruncated = truncateCenter(symbol, charsAllowed);

  return (
    <Link underline={false} to={generatePath(Routes.token, { reference })}>
      <Spacer
        direction="horizontal"
        space={1}
        align="center"
        className={classes.tokenContainer}
      >
        <CoinGeckoTokenImage
          size="thumb"
          coinGeckoImage={coinGeckoImage}
          className={classes.img}
        />
        <Spacer direction="vertical">
          {name.length > charsAllowed ? (
            <Tooltip placement="top" title={name}>
              <Typography.Text strong>{nameTruncated}</Typography.Text>
            </Tooltip>
          ) : (
            <Typography.Text strong>{name}</Typography.Text>
          )}

          {symbol.length > charsAllowed ? (
            <Tooltip placement="top" title={symbol}>
              <Typography.Text strong className={classes.tokenNameShort}>
                {symbolTruncated.toUpperCase()}
              </Typography.Text>
            </Tooltip>
          ) : (
            <Typography.Text strong className={classes.tokenNameShort}>
              {symbol.toUpperCase()}
            </Typography.Text>
          )}
        </Spacer>
      </Spacer>
    </Link>
  );
};
