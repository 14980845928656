import { Navigate } from 'react-router-dom';
import { MatchingTokenTable } from 'business/token/components/matching-token-table';
import { MultiCriteriaSelector } from 'business/token/components/multi-criteria-selector';
import {
  usePowerSearchQuestionListQuery,
  useMatchingTokenQuery,
} from 'generated/graphql';
import { useURLSearchParams } from 'technical/router/url-param/use-u-r-l-search-params';
import { Card } from 'ui/cards';
import classes from './index.module.scss';
import { searchSchema } from 'business/token/services/advanced-search-schema';
import { useAppContext } from 'business/app-bootstrapper';
import config from 'config';
import Routes from 'business/router/routes';
import { notification } from 'antd';

export function PowerSearchPage() {
  const questionListQuery = usePowerSearchQuestionListQuery();
  const { user } = useAppContext();
  const [
    { pagination, search, sorting },
    updateSearchQuery,
  ] = useURLSearchParams({
    key: 'q',
    defaultValue: {
      // i tried to make it work without this escape hatch, but no solution were ok
      // without comprimising a lot on DX and on maintenability
      // @ts-expect-error
      search: { and: [] },
      pagination: { page: 1, size: 25 },
      // @ts-expect-error
      sorting: { communityScore: 'desc' },
    },
    schema: searchSchema,
  });

  const matchingTokenQuery = useMatchingTokenQuery({
    variables: { criteria: search, pagination, sorting },
    onError() {
      notification.error({ message: 'Something went wrong with your search' });
    },
  });

  const loading = matchingTokenQuery.loading || questionListQuery.loading;
  const { tokens = [], count = 0 } =
    matchingTokenQuery.data?.result ??
    matchingTokenQuery.previousData?.result ??
    {};

  const isAuthorized =
    !!user &&
    (user.email.endsWith('kryptview.com') ||
      user.email === 'jonathangao000@gmail.com');

  if (!config.features.isPowerSearchActivated && !isAuthorized) {
    return <Navigate to={Routes.Home} replace />;
  }

  return (
    <div className={classes.grid}>
      <div className={classes['search-module']}>
        <Card>
          <Card.Header level={2} title="🔎 Power Search" />
          <MultiCriteriaSelector
            loading={questionListQuery.loading}
            questions={questionListQuery.data?.questions ?? []}
            criteria={search}
            // i tried to make it work without this escape hatch, but no solution were ok
            // without comprimising a lot on DX and on maintenability
            updateCriteria={(s) => {
              updateSearchQuery({
                // @ts-expect-error
                search: s,
                pagination: { page: 1, size: pagination.size },
                sorting,
              });
            }}
          />
        </Card>
      </div>
      <div className={classes['token-list']}>
        <Card>
          <Card.Header
            level={2}
            title={`Found ${count.toLocaleString()} Matching Tokens`}
          />
          <MatchingTokenTable
            loading={loading}
            count={count}
            list={tokens}
            page={pagination.page}
            pageSize={pagination.size}
            sorting={sorting}
            onChange={(p, s) => {
              updateSearchQuery({ search, pagination: p, sorting: s as any });
            }}
          />
        </Card>
      </div>
    </div>
  );
}
