import React from 'react';
import { Typography } from 'antd';
import { PeerReviewAnswerToken } from 'business/peer-review/types';
import { Spacer } from 'ui/spacer';
import { CoinGeckoTokenImage } from 'ui/coin-gecko-token-image';
import { Card } from 'ui/cards';
import classes from './index.module.scss';

export interface PeerReviewTokensProps {
  tokens: PeerReviewAnswerToken[];
  type?: 'default' | 'well';
}

export function PeerReviewTokens({
  tokens,
  type = 'default',
}: PeerReviewTokensProps) {
  return (
    <Card className={classes.valueContainer} type={type}>
      {tokens.map((token) => {
        return (
          <Card key={token.token.name} className={classes.value}>
            <Spacer direction="horizontal" space={1} align="center">
              <CoinGeckoTokenImage
                coinGeckoImage={token.token.coinGeckoImage}
                size="thumb"
              />
              <Typography.Text strong className={classes.text}>
                {token.token.name}
              </Typography.Text>
            </Spacer>
          </Card>
        );
      })}
    </Card>
  );
}
