import React from 'react';
import { Token } from 'generated/graphql';
import { Spacer } from 'ui/spacer';
import { generatePath } from 'react-router-dom';
import Routes from 'business/router/routes';
import { CoinGeckoTokenImage } from 'ui/coin-gecko-token-image';
import Link from 'ui/link';
import { Typography } from 'antd';
import classes from './index.module.scss';
import {
  NumberFormats,
  NumericValue,
  NumericValueType,
} from '../../../../ui/numeric';
import { PercentageValue } from '../../../../ui/percentage';

interface TokenThumbnailProps {
  token: Pick<Token, 'name' | 'symbol' | 'reference' | 'coinGeckoImage'>;
  secondLinePrefix?: React.ReactNode;
  price?: NumericValueType | undefined;
  h24PriceChange?: NumericValueType | undefined;
}

export function TokenThumbnail({
  token: { name, reference, coinGeckoImage },
  secondLinePrefix,
  price,
  h24PriceChange,
}: TokenThumbnailProps) {
  return (
    <Spacer className={classes.container} space={1} align="center">
      <CoinGeckoTokenImage
        size="small"
        className={classes.image}
        coinGeckoImage={coinGeckoImage}
      />
      <Link underline={false} to={generatePath(Routes.token, { reference })}>
        <Spacer direction="vertical">
          <Typography.Text className={classes.link}>{name}</Typography.Text>
          <Spacer align="center">
            {secondLinePrefix && (
              <Spacer className={classes.secondLinePrefixContainer}>
                {secondLinePrefix}
              </Spacer>
            )}
            <NumericValue
              className={classes.price}
              format={NumberFormats.currency}
              value={price}
            />
            <PercentageValue value={h24PriceChange} />
          </Spacer>
        </Spacer>
      </Link>
    </Spacer>
  );
}
