import { Rating } from 'generated/graphql';

export type RatingSelectFieldType = Pick<Rating, 'id' | 'name'>;

export const ratingsTypes = [
  'strong',
  'medium',
  'weak',
  'junk',
  'unknown',
  'reference',
] as const;

export type RatingType = typeof ratingsTypes[number];

export function isOfRatingType(value: string): value is RatingType {
  return ratingsTypes.includes(value as RatingType);
}
