/* eslint-disable react/no-danger */
import React, { useRef } from 'react';
import { TokenAbout } from 'generated/graphql';
import { Typography } from 'antd';
import { Spacer } from 'ui/spacer';
import { useIntersectionObserver } from 'technical/hooks/use-intersection-observer';
import classnames from 'classnames';
import classes from './index.module.scss';

export interface TokenAboutTabProps extends TokenAbout {
  className?: string;
}

export function TokenAboutTab({ description, className }: TokenAboutTabProps) {
  const descriptionRef = useRef(null);
  const bottomRef = useRef<HTMLDivElement>(null);
  const isScrolledToBottom = useIntersectionObserver(bottomRef, {
    root: descriptionRef.current,
  });

  return (
    <Spacer direction="vertical" space={2}>
      <div
        ref={descriptionRef}
        className={classnames(classes.about, className)}
      >
        <Typography.Paragraph>
          <div
            style={{ whiteSpace: 'pre-line' }}
            dangerouslySetInnerHTML={{
              __html: description ?? '',
            }}
          />
          <div ref={bottomRef} />
        </Typography.Paragraph>
        <div
          hidden={isScrolledToBottom}
          className={classes['scroll-indicator']}
        />
      </div>
    </Spacer>
  );
}
