import gql from 'graphql-tag';

export const SIGN_UP_USER = gql`
  mutation SignUp(
    $email: String!
    $password: String!
    $passwordConfirmation: String!
    $registeredFromCampaign: String
    $referredFromCode: String
    $hasAgreedToCommercialOffers: Boolean
    $hasCertifiedAgeMajority: Boolean
  ) {
    signUp(
      email: $email
      password: $password
      passwordConfirmation: $passwordConfirmation
      registeredFromCampaign: $registeredFromCampaign
      referredFromCode: $referredFromCode
      hasAgreedToCommercialOffers: $hasAgreedToCommercialOffers
      hasCertifiedAgeMajority: $hasCertifiedAgeMajority
    ) {
      success
      message
    }
  }
`;

export const SIGN_IN_USER = gql`
  mutation SignIn($email: String!, $password: String!) {
    signIn(email: $email, password: $password) {
      success
      idToken
      message
    }
  }
`;

export const RENEW_TOKEN = gql`
  mutation RefreshToken {
    queryRefreshToken {
      success
      idToken
      message
    }
  }
`;

export const LOGOUT_USER = gql`
  mutation LogoutUser {
    logout {
      success
    }
  }
`;

export const ASK_FOR_PASSWORD_RESET = gql`
  mutation AskForPasswordReset($email: String!) {
    askForPasswordReset(email: $email) {
      success
    }
  }
`;

export const ASK_FOR_EMAIL_VALIDATION = gql`
  mutation AskForEmailVerification($email: String!) {
    askForEmailVerification(email: $email) {
      success
      message
    }
  }
`;

export const VALIDATE_EMAIL = gql`
  mutation ValidateEmail($token: String!) {
    validateEmail(token: $token) {
      success
      idToken
      message
    }
  }
`;

export const RESET_PASSWORD = gql`
  mutation ResetPassword(
    $resetPasswordToken: String
    $password: String!
    $passwordConfirmation: String!
  ) {
    resetPassword(
      resetPasswordToken: $resetPasswordToken
      password: $password
      passwordConfirmation: $passwordConfirmation
    ) {
      success
      message
      __typename
    }
  }
`;

export const LINK_WALLET = gql`
  mutation LinkWallet($address: String!) {
    link_wallet(address: $address) {
      id
      address
      userId
    }
  }
`;

export const SET_IS_EMAIL_NOTIFICATION_ENABLED_USER = gql`
  mutation SetIsEmailNotificationEnabledUser(
    $id: uuid!
    $isEmailNotificationEnabled: Boolean!
  ) {
    user: update_user_by_pk(
      pk_columns: { id: $id }
      _set: { isEmailNotificationEnabled: $isEmailNotificationEnabled }
    ) {
      isEmailNotificationEnabled
    }
  }
`;

export const IsEmailNotificationEnabledUser = 'IsEmailNotificationEnabledUser';
export const IS_NOTIFIED_USER = gql`
  query IsEmailNotificationEnabledUser($id: uuid!) {
    user: user_by_pk(id: $id) {
      isEmailNotificationEnabled
    }
  }
`;

export const UPDATE_USER_EMAIL = gql`
  mutation UpdateUserEmail($userId: ID!, $email: String!) {
    updateUserEmail(userId: $userId, email: $email) {
      success
      message
    }
  }
`;

export const SelectUserProfile = 'SelectUserProfile';
export const SELECT_USER_PROFILE = gql`
  query SelectUserProfile($userId: ID!) {
    userProfile: selectUserProfile(userId: $userId) {
      displayName
      description
      discord
      reddit
      telegram
      twitter
      youtube

      contributions
      validatedScorings
      rejectedScorings

      success
      message
      authorized
    }
  }
`;

export const SELECT_USER_ACHIEVEMENTS = gql`
  query SelectUserAchievements($userId: String) {
    user_achievements(userId: $userId) {
      achievements {
        id
        img
        status
        title
        attributionDate
      }
    }
  }
`;

export const UPSERT_USER_PROFILE = gql`
  mutation UpsertUserProfile(
    $userId: uuid!
    $displayName: String
    $description: String = null
    $discord: String = null
    $reddit: String = null
    $telegram: String = null
    $twitter: String = null
    $youtube: String = null
  ) {
    userProfile: insert_userProfile_one(
      object: {
        displayName: $displayName
        description: $description
        discord: $discord
        reddit: $reddit
        telegram: $telegram
        twitter: $twitter
        youtube: $youtube
      }
      on_conflict: {
        constraint: userProfile_pkey
        update_columns: [
          displayName
          description
          discord
          reddit
          telegram
          twitter
          youtube
        ]
      }
    ) {
      displayName
      description
      discord
      reddit
      telegram
      twitter
      youtube
    }
    onboarding: update_onboarding_by_pk(
      pk_columns: { userId: $userId }
      _set: { profile: "done" }
    ) {
      profile
    }
  }
`;

export const USER_RANKING = gql`
  query UserRanking($userId: uuid!) {
    ranks: ranking_by_pk(userId: $userId) {
      current: currentRank {
        icon
        name
      }
      target: nextRank {
        icon
        name
      }
      progress
      leaderboard {
        position
      }
    }
  }
`;

export const USER_DRAFT_SOFT_DELETE = gql`
  mutation userDraftSoftDelete($id: ID!) {
    delete_draft_research(researchId: $id) {
      success
    }
  }
`;

export const SELECT_USER_RESEARCHES = gql`
  query SelectUserResearches(
    $authorId: uuid!
    $includeStatus: [research_researchStatusEnum_enum!]!
    $_and: [research_research_bool_exp!] = {}
    $limit: Int!
    $offset: Int!
  ) {
    researches: research_research(
      where: {
        authorId: { _eq: $authorId }
        status: { _in: $includeStatus }
        _and: $_and
      }
      order_by: { publishedAt: desc }
      limit: $limit
      offset: $offset
    ) {
      id
      authorId
      tokenId
      createdAt
      updatedAt
      publishedAt
      status
      comment
      targetPrice
      hasEnoughReviews: hasEnoughPeerReview
      rating {
        name
        type
      }
      selectedCategories(where: { isSelected: { _eq: true } }) {
        questionCategory: category {
          id
          name
        }
      }
      token {
        id
        name
        symbol
        reference
        coinGeckoImage
      }
    }
    research_aggregate: research_research_aggregate(
      where: {
        authorId: { _eq: $authorId }
        status: { _in: $includeStatus }
        _and: $_and
      }
    ) {
      aggregate {
        count
      }
    }
  }
`;
export const REPORT_TOKEN = gql`
  mutation ReportToken($tokenId: uuid!, $comment: String!) {
    insert_tokenReport(
      objects: { tokenId: $tokenId, new_tokenId: $tokenId, comment: $comment }
    ) {
      affected_rows
    }
  }
`;

export const GET_USER_TOKEN_REPORT = gql`
  query GetUserTokenReport($userId: uuid!, $tokenId: uuid!) {
    report: tokenReport(
      where: { userId: { _eq: $userId }, tokenId: { _eq: $tokenId } }
    ) {
      comment
      createdAt
    }
  }
`;

export const REMOVE_USER_DEFAULT_WALLET = gql`
  mutation RemoveUserDefaultWallet($id: uuid!) {
    update_user_by_pk(
      pk_columns: { id: $id }
      _set: { defaultWalletId: null }
    ) {
      id
      defaultWalletId
    }
  }
`;

export const GET_USER_WALLETS = gql`
  query GetUserWallets($id: uuid!) {
    user_by_pk(id: $id) {
      defaultWallet {
        id
      }
      wallets {
        address
        id
      }
    }
  }
`;

export const GET_USER_REFERRAL_CODE_AND_INFO = gql`
  query GetUserReferralCodeAndInfo($userId: uuid!) {
    userReferralCode(where: { userId: { _eq: $userId } }) {
      code
    }

    referredUsers: userReferralStatus_aggregate(
      where: { referrerUserId: { _eq: $userId } }
    ) {
      aggregate {
        count
      }
    }

    confirmedReferredUser: userReferralStatus_aggregate(
      where: {
        referrerUserId: { _eq: $userId }
        isRewardThresholdReached: { _eq: true }
      }
    ) {
      aggregate {
        count
      }
    }
  }
`;

export const GET_LEADERBOARD_SESSIONS = gql`
  query getLeaderboardSessions($fromStartDate: timestamptz = "now()") {
    leaderboardSession(
      order_by: { startDate: desc }
      where: { startDate: { _lte: $fromStartDate } }
      limit: 10
    ) {
      id
      startDate
      endDate
    }
  }
`;

export const GET_SESSION_LEADERBOARD_BY_SESSION_ID = gql`
  query getSessionLeaderboardBySessionId($sessionId: Int!, $limit: Int) {
    leaderboardBySession(
      order_by: { position: asc }
      where: { sessionId: { _eq: $sessionId } }
      limit: $limit
    ) {
      user {
        id
        ranking {
          currentRank {
            name
            icon
          }
        }
        profile {
          displayName
        }
      }
      position
    }
  }
`;

export const USER_LEADERBOARD_SESSION_POSITION = gql`
  query userLeaderboardSessionPosition($userId: uuid!, $sessionId: Int!) {
    leaderboardBySession(
      where: { userId: { _eq: $userId }, sessionId: { _eq: $sessionId } }
    ) {
      user {
        id
        ranking {
          currentRank {
            name
            icon
          }
        }
        profile {
          displayName
        }
      }
      position
    }
  }
`;

export const GET_LEADERBOARD = gql`
  query getLeaderboard($limit: Int) {
    leaderboard(order_by: { position: asc }, limit: $limit) {
      user {
        id
        ranking {
          currentRank {
            name
            icon
          }
        }
        profile {
          displayName
        }
      }
      position
    }
  }
`;

export const GET_LEADERBOARD_USER_POSITION = gql`
  query GetLeaderboardUserPosition($userId: uuid!) {
    leaderboard(where: { userId: { _eq: $userId } }) {
      user {
        id
        ranking {
          currentRank {
            name
            icon
          }
        }
        profile {
          displayName
        }
      }
      position
    }
  }
`;

export const GET_ME = gql`
  query MeQuery {
    me {
      displayName
      email
      roles
      id
      rank {
        id
        icon
        name
      }
      defaultWallet {
        address
        id
      }
      contributionCredit {
        credit
        numberOfReviewNeededBeforeNextCredit
      }
    }
  }
`;
