import React, { useMemo } from 'react';
import { useGetRatingsOrderedQuery } from 'generated/graphql';
import { SelectField, SelectFieldProps } from 'ui/form/fields';
import { DefaultOptionType } from 'antd/lib/select';
import classnames from 'classnames';
import { TokenRating, TokenRatingProps } from 'ui/rating';
import classes from './index.module.scss';

interface RatingSelectFieldProps<T>
  extends Omit<SelectFieldProps<T>, 'options'> {
  tokenRating: Pick<TokenRatingProps, 'type' | 'size'>;
}

export function RatingSelectField<T>({
  className,
  ...props
}: RatingSelectFieldProps<T>) {
  const { data } = useGetRatingsOrderedQuery();
  const options = useMemo<DefaultOptionType[]>(() => {
    if (!data) {
      return [];
    }

    return data.ratings.map((rating) => ({
      key: rating.id,
      value: rating.id,
      label: <TokenRating type="text" size="small" rating={rating} />,
    }));
  }, [data]);

  return (
    <SelectField<T>
      {...props}
      labelCol={{
        span: 24,
      }}
      options={options}
      className={classnames(classes.selectField, className)}
    />
  );
}
