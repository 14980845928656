import { Button } from 'antd';
import React from 'react';
import { useTranslation } from 'react-i18next';
import classes from './index.module.scss';

export interface SeeMoreLinkProps {
  onClick: () => void;
  className?: string;
}

const SeeMoreLink = ({ onClick, className }: SeeMoreLinkProps) => {
  const { t } = useTranslation(['token']);

  return (
    <Button
      type="link"
      htmlType="button"
      onClick={onClick}
      className={className}
    >
      {t('token:top-researches.see-more')} &#8594;
    </Button>
  );
};

export default SeeMoreLink;
