import { isNotNullOrUndefined } from 'technical/utils/is-not-null-or-undefined';
import colors from 'ui/theme/variables.module.scss';
import { NumericValueType } from 'ui/numeric';

enum ThemeColors {
  success = 'success',
  danger = 'danger',
}

export const getAssociatedColor = (value?: NumericValueType) => {
  const valueExist = isNotNullOrUndefined(value) && !Number.isNaN(value);
  let color: ThemeColors | undefined;
  let bgColor = colors.ratingStrongSlightlyTransparent;

  if (valueExist) {
    color = value < 0 ? ThemeColors.danger : ThemeColors.success;
    bgColor =
      value < 0
        ? colors.ratingJunkSlightlyTransparent
        : colors.ratingStrongSlightlyTransparent;
  }

  return {
    color,
    valueExist,
    bgColor,
  };
};
