import {
  Token,
  useGetResearchCategoriesAnswersRepartitionPreviewQuery,
} from 'generated/graphql';
import React from 'react';
import { BlurredFeature } from 'ui/blurred-feature';
import useCategoriesAnswersRepartitionModal from 'business/token/components/research-answers/categories-answer-repartition-modal/use-categories-answers-repartition-modal';
import { CategoriesAnswerRepartitionModal } from 'business/token/components/research-answers/categories-answer-repartition-modal';
import { CategoryPreview } from './category-preview';
import classes from './index.module.scss';

export interface ResearchAnswersProps {
  token?: Pick<Token, 'symbol' | 'name' | 'id' | 'reference'> | null;
}

export function ResearchAnswers({ token }: ResearchAnswersProps) {
  const {
    isVisible,
    setSelectedCategoryId,
    selectedCategoryId,
  } = useCategoriesAnswersRepartitionModal({
    defaultSelectedCategoryId: undefined,
  });
  const {
    data,
    loading,
  } = useGetResearchCategoriesAnswersRepartitionPreviewQuery({
    variables: { tokenReference: token?.reference! },
    skip: !token,
  });

  const categories =
    data?.ResearchCategoriesAnswersRepartitionPreview.categories ?? [];

  const authorized =
    !loading && !!data?.ResearchCategoriesAnswersRepartitionPreview.authorized;

  return (
    <BlurredFeature blurred={!authorized}>
      <div className={classes['grid-preview']}>
        {token
          ? categories.map((category) => (
              <CategoryPreview
                selectCategory={() => setSelectedCategoryId(category.id)}
                key={category.id}
                id={category.id}
                name={category.name}
                questions={category.questions ?? []}
                canSeeMore={authorized}
              />
            ))
          : null}
      </div>
      {token?.reference ? (
        <CategoriesAnswerRepartitionModal
          onCancel={() => setSelectedCategoryId(undefined)}
          visible={isVisible}
          categories={categories}
          tokenReference={token?.reference}
          defaultSelectedCategoryId={selectedCategoryId}
          setSelectedCategoryId={setSelectedCategoryId}
        />
      ) : null}
    </BlurredFeature>
  );
}
