import colors from 'ui/theme/variables.module.scss';
import React from 'react';

interface Props {
  className?: string;
  heigth?: number;
  width?: number;
  style?: React.CSSProperties;
}

const ErrorIllustration = ({
  className,
  heigth = 214,
  width = 214,
  style,
}: Props) => (
  <svg
    className={className}
    style={style}
    height={heigth}
    width={width}
    viewBox="0 0 1080.049 748.002"
  >
    <path
      fill={colors.primaryColor}
      d="M832.208 587.468c0 67.693-40.243 91.33-89.884 91.33s-89.884-23.637-89.884-91.33 89.884-153.809 89.884-153.809 89.884 86.116 89.884 153.81z"
      transform="translate(-59.976 -75.999)"
    />
    <path
      fill="#3f3d56"
      d="M679.994 535.79L718.305 465.702 680.138 526.903 680.552 501.43 706.956 450.722 680.662 494.688 681.406 448.873 709.68 408.504 681.523 441.669 681.988 357.66 679.191 464.109 650.56 420.284 678.845 473.092 676.166 524.259 676.087 522.901 642.948 476.597 675.986 527.699 675.651 534.098 675.591 534.195 675.619 534.72 668.823 603 677.902 603 678.992 597.484 711.949 546.507 679.074 592.443 679.994 535.79z"
    />
    <path
      fill="#f2f2f2"
      d="M1140.024 369.813c0 129.31-76.873 174.46-171.7 174.46s-171.701-45.15-171.701-174.46 171.7-293.814 171.7-293.814 171.701 164.503 171.701 293.814z"
      transform="translate(-59.976 -75.999)"
    />
    <path
      fill="#3f3d56"
      d="M902.093 448.494L903.851 340.273 977.035 206.388 904.127 323.296 904.918 274.635 955.355 177.771 905.127 261.758 905.127 261.759 906.549 174.24 960.558 97.124 906.772 160.478 907.661 0 902.078 212.444 902.537 203.68 847.625 119.628 901.656 220.503 896.54 318.246 896.387 315.652 833.084 227.2 896.196 324.817 895.556 337.041 895.441 337.225 895.494 338.228 882.513 586.21 899.856 586.21 901.937 458.123 964.894 360.745 902.093 448.494z"
    />
    <path
      fill="#3f3d56"
      d="M1132.976 640.557c0 38.596-250.37 183.444-543.052 183.444S59.976 749.036 59.976 710.441s231.442 7.28 524.124 7.28 548.876-115.76 548.876-77.164z"
      transform="translate(-59.976 -75.999)"
    />
    <path
      d="M1132.976 640.557c0 38.596-250.37 183.444-543.052 183.444S59.976 749.036 59.976 710.441s231.442 7.28 524.124 7.28 548.876-115.76 548.876-77.164z"
      opacity="0.1"
      transform="translate(-59.976 -75.999)"
    />
    <path
      fill="#3f3d56"
      d="M1132.976 640.557c0 38.596-250.37 139.767-543.052 139.767S59.976 749.036 59.976 710.441s231.442-36.398 524.124-36.398 548.876-72.081 548.876-33.486z"
      transform="translate(-59.976 -75.999)"
    />
    <ellipse cx="535.878" cy="641.774" opacity="0.1" rx="225.044" ry="17.407" />
    <path
      fill="#a0616a"
      d="M417.574 368.965s65.883 1.22 53.682 12.81-57.953 0-57.953 0z"
      transform="translate(-59.976 -75.999)"
    />
    <path
      fill={colors.primaryColor}
      d="M280.304 228.182s26.6-4.75 35.85 16.723 28.243 82.716 28.243 82.716 50.833 33.648 60.08 32.543 25.051 1.15 22.152 11.237-1.612 21.525-8.287 22.927-17.29-11.98-25.415-5.535-80.53-17.096-85.179-21.974-43.278-110.501-43.278-110.501 1.545-28.48 15.834-28.136z"
      transform="translate(-59.976 -75.999)"
    />
    <path
      fill="#a0616a"
      d="M277.379 605.607L280.429 631.228 254.808 637.939 240.167 631.228 240.167 608.657 277.379 605.607z"
    />
    <path
      fill="#a0616a"
      d="M208.445 622.078L211.495 647.699 185.874 654.41 171.233 647.699 171.233 625.128 208.445 622.078z"
    />
    <path
      fill="#2f2e41"
      d="M339.795 401.601l11.59 42.092 17.081 85.405s4.88 20.741-4.88 45.752-27.452 106.756-23.791 111.636-41.483 8.54-40.873 3.66 6.71-71.983 6.71-71.983 8.541-59.173 12.811-66.494-19.52-25.621-19.52-25.621-6.1 70.153-15.251 76.254-7.93 100.655-12.201 101.875-35.382 6.1-39.042 0 0-107.976 0-107.976 20.741-77.474 16.47-88.454-1.83-57.953-1.83-57.953-15.86-35.992-7.32-54.903z"
      transform="translate(-59.976 -75.999)"
    />
    <path
      fill="#a0616a"
      d="M337.965 167.349s-25.622 31.722-24.402 45.752-40.262-25.621-40.262-25.621 29.892-40.262 29.892-48.803 34.772 28.672 34.772 28.672z"
      transform="translate(-59.976 -75.999)"
    />
    <circle cx="277.684" cy="62.984" r="40.262" fill="#a0616a" />
    <path
      fill={colors.primaryColor}
      d="M236.193 378.78c-1.824 6.796-4.087 14.5-3.764 20.991.207 4.203 11.365 6.96 26.793 8.766 14.305 1.678 32.283 2.532 48.625 2.971 17.16.464 32.503.464 39.878.464 21.961 0 4.27-14.03-4.27-20.741s-6.71-101.876-6.1-114.076-5.49-47.583-5.49-54.293-14.355-24.224-14.355-24.224-3.337 9.583-16.757-3.227-31.112-16.471-31.112-16.471c-13.42 4.88-35.992 58.563-37.212 67.713-.494 3.715-.085 22.742.744 44.465 1.202 31.753 3.3 69.288 4.746 73.271 1.141 3.142-.122 8.413-1.726 14.391z"
      transform="translate(-59.976 -75.999)"
    />
    <path
      fill="#a0616a"
      d="M241.387 338.413L245.657 353.664 229.186 370.745 223.696 346.953 241.387 338.413z"
    />
    <path
      fill="#2f2e41"
      d="M306.853 700.517s12.81 14.64 22.571 4.88 9.15-15.86 10.98-15.86 29.282 24.4 29.282 24.4 46.973 10.981 21.351 18.912-98.215 2.44-98.215 0-1.83-34.772 4.88-34.772z"
      transform="translate(-59.976 -75.999)"
    />
    <path
      fill="#2f2e41"
      d="M237.92 716.988s12.81 14.64 22.57 4.88 9.15-15.86 10.981-15.86 29.282 24.4 29.282 24.4 46.972 10.981 21.35 18.911-98.214 2.44-98.214 0-1.83-34.771 4.88-34.771zM292.456 171.122c-1.3.84-2.994 1.692-4.296.854a194.661 194.661 0 01-10.803-37.403c-.822-4.496-1.393-9.493 1.104-13.32.985-1.51 2.403-2.744 3.125-4.395 1.092-2.501.373-5.397.736-8.102.76-5.667 6.118-9.62 11.505-11.537s11.238-2.512 16.373-5.027c4.876-2.39 8.782-6.35 13.303-9.357s10.313-5.061 15.348-3.03c4.454 1.797 7.229 6.338 11.44 8.65 2.856 1.568 6.172 2.005 9.361 2.678a59.922 59.922 0 0124.34 11.308 17.673 17.673 0 014.43 4.567c5.402 8.895-4.271 20.988-.003 30.48l-9.227-7.28a32.13 32.13 0 00-8.24-5.237c-3.057-1.157-6.676-1.297-9.452.427-3.896 2.42-4.953 7.476-6.314 11.856s-4.37 9.237-8.955 9.124c-6.236-.152-8.417-8.854-14.012-11.61-3.648-1.795-8.24-.613-11.35 2.007-3.11 2.619-4.976 6.42-6.344 10.248-.856 2.393-1.658 5.09-3.177 7.164-1.675 2.287-3.818 2.47-5.608 4.179-4.658 4.446-7.522 9.033-13.284 12.756z"
      transform="translate(-59.976 -75.999)"
    />
    <path
      d="M236.193 378.78c7.583 10.999 16.13 22.345 23.029 29.757 14.305 1.678 32.283 2.532 48.625 2.971a38.527 38.527 0 00-10.755-9.907c-7.93-4.88-26.841-62.833-26.841-62.833s23.181-59.783 29.282-82.354-17.691-35.382-17.691-35.382c-10.981-9.15-29.892 12.2-29.892 12.2s-9.65 29.111-18.777 57.886c1.202 31.753 3.3 69.288 4.746 73.271 1.141 3.142-.122 8.413-1.726 14.391z"
      opacity="0.1"
      transform="translate(-59.976 -75.999)"
    />
    <path
      fill={colors.primaryColor}
      d="M280.011 216.152s23.792 12.81 17.691 35.381-29.281 82.355-29.281 82.355 18.91 57.953 26.841 62.833 18.911 16.47 10.37 22.571-14.64 15.861-20.74 12.81-6.1-20.13-16.471-20.13-52.463-63.444-53.073-70.154 34.772-113.466 34.772-113.466 18.91-21.35 29.891-12.2z"
      transform="translate(-59.976 -75.999)"
    />
    <path
      fill="#ff6584"
      d="M774.721 382.89l-6.862-6.862a21.345 21.345 0 00-30.186 0L604.557 509.143 471.442 376.028a21.345 21.345 0 00-30.186 0l-6.863 6.863a21.345 21.345 0 000 30.186l133.115 133.115-133.115 133.115a21.345 21.345 0 000 30.187l6.863 6.862a21.345 21.345 0 0030.186 0l133.115-133.115 133.116 133.115a21.345 21.345 0 0030.186 0l6.862-6.862a21.345 21.345 0 000-30.187L641.606 546.192l133.115-133.115a21.345 21.345 0 000-30.186z"
      transform="translate(-59.976 -75.999)"
    />
    <path
      d="M429.643 686.545l129.162-129.163-1.243-1.243-123.169 123.168a21.26 21.26 0 00-4.75 7.238zM432.553 387.218a21.345 21.345 0 0130.186 0l133.115 133.115 133.115-133.115a21.345 21.345 0 0130.186 0l6.863 6.863a21.344 21.344 0 014.75 22.949l3.954-3.953a21.345 21.345 0 000-30.186l-6.863-6.863a21.345 21.345 0 00-30.186 0L604.557 509.143 471.442 376.028a21.345 21.345 0 00-30.186 0l-6.863 6.863a21.26 21.26 0 00-4.75 7.237zM642.85 547.436l-9.947 9.946 133.115 133.116a21.344 21.344 0 014.75 22.949l3.954-3.953a21.345 21.345 0 000-30.187z"
      opacity="0.1"
      transform="translate(-59.976 -75.999)"
    />
    <path
      fill={colors.primaryColor}
      d="M214.976 636.191c0 56.222-33.423 75.852-74.652 75.852s-74.652-19.63-74.652-75.852 74.652-127.744 74.652-127.744 74.652 71.523 74.652 127.744z"
      transform="translate(-59.976 -75.999)"
    />
    <path
      fill="#3f3d56"
      d="M78.393 580.392L110.212 522.181 78.513 573.011 78.857 551.854 100.786 509.739 78.948 546.255 79.566 508.204 103.048 474.675 79.663 502.221 80.049 432.448 77.726 520.858 53.947 484.46 77.438 528.318 75.214 570.815 75.148 569.687 47.625 531.23 75.064 573.672 74.786 578.987 74.736 579.067 74.759 579.503 69.115 636.212 76.656 636.212 77.561 631.631 104.933 589.293 77.629 627.444 78.393 580.392z"
    />
  </svg>
);

export default ErrorIllustration;
