import { ResearchForm } from 'business/research/components/research-form/research-form';
import { TokenMarketStatistics } from 'business/token/components/market-statistics';
import { useGetTokenInfosQuery } from 'generated/graphql';
import { TokenOverview } from 'business/token/components/token-overview';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { Card } from 'ui/cards';
import { PeerReviewReminder } from 'business/peer-review/components/reminder';
import classes from './index.module.scss';
import {
  BlockchainSummary,
  PoweredByITB,
} from 'business/token/components/blockchain-summary';
import config from 'config';
import classNames from 'classnames';

export function ResearchTokenPage() {
  const { t } = useTranslation(['token', 'review']);
  const { reference } = useParams<'reference'>();
  if (reference === undefined) {
    throw new Error('reference param is not defined');
  }
  const { data, loading } = useGetTokenInfosQuery({
    variables: {
      reference,
    },
    skip: !reference,
  });

  const { identity, about, market } = data?.getTokenInfos ?? {};

  return (
    <div
      className={classNames(
        classes.grid,
        config.features.showITBData && classes.itb,
      )}
    >
      <div className={classes.token}>
        <TokenOverview
          loading={loading}
          about={about}
          token={identity}
          image={identity?.coinGeckoImage}
        />
      </div>
      <div className={classes.form}>
        {identity ? (
          <ResearchForm
            key={identity.id}
            tokenId={identity.id}
            tokenReference={identity.reference}
          />
        ) : null}
      </div>
      <div className={classes.statistics}>
        <Card>
          <Card.Header level={3} title={t('token:market-statistics.title')} />
          <TokenMarketStatistics tokenId={identity?.id} {...market} />
        </Card>
      </div>
      {config.features.showITBData ? (
        <div className={classes['on-chain-metrics']}>
          <Card>
            <Card.Header
              level={2}
              title={t('token:itb-integration.title')}
              actions={<PoweredByITB />}
            />
            <BlockchainSummary token={identity?.itb_id} />
          </Card>
        </div>
      ) : null}
      {/* TODO: change this to a better reminder */}
      <div className={classes.reminder}>
        <Card>
          <Card.Header level={3} title={t('review:reminder.title')} />
          <PeerReviewReminder />
        </Card>
      </div>
    </div>
  );
}
