import React from 'react';
import { FilterDropdownProps } from 'antd/lib/table/interface';
import { TFunction } from 'i18next';
import { Spacer } from 'ui/spacer';
import Button from 'ui/button';
import { Typography, Checkbox } from 'antd';
import classes from './index.module.scss';

export function hasEnoughReviewsDropdown(t: TFunction) {
  return ({
    clearFilters,
    selectedKeys,
    setSelectedKeys,
    confirm,
  }: FilterDropdownProps) => {
    const indeterminate = selectedKeys.length === 0;

    const checked = selectedKeys[0] === 'true';
    return (
      <Spacer direction="vertical" space={1} className={classes.filter}>
        <Spacer space={1} justify="center">
          <Typography.Text>
            {t('token:research.table.enough-reviews-filter')}
          </Typography.Text>
          <Checkbox
            checked={checked}
            indeterminate={indeterminate}
            onChange={(e) => {
              setSelectedKeys([`${e.target.checked}`]);
              confirm({ closeDropdown: false });
            }}
          />
        </Spacer>
        {!indeterminate && (
          <Button
            onClick={() => {
              clearFilters?.();
              confirm({ closeDropdown: true });
            }}
          >
            {t('token:research.table.clear-filter')}
          </Button>
        )}
      </Spacer>
    );
  };
}
