import React, { PropsWithChildren } from 'react';
import { Spacer } from 'ui/spacer';
import { StepProps, Steps } from 'antd';
import { LaterButton } from '../common/later-button';
import { OnboardingStepEnumType } from './types';

export interface OnboardingStepProps extends Pick<StepProps, 'title'> {
  show: boolean;
  loading?: boolean;
  setSkipped?: () => void;
  laterButtonLabel?: string;
}

export const OnboardingStepDescription: React.FC<
  PropsWithChildren<OnboardingStepProps>
> = ({ show, loading, children, setSkipped, laterButtonLabel }) => {
  if (!show) {
    return null;
  }

  return (
    <Spacer direction="vertical" space={1}>
      {children}
      {setSkipped && (
        <LaterButton
          text={laterButtonLabel}
          loading={loading}
          onClick={setSkipped}
        />
      )}
    </Spacer>
  );
};
