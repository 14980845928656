import React from 'react';
import {
  NavLinkItem,
  NavLinkTypesEnumValues,
  useNavLinks,
} from 'business/navigation/service/use-nav-links';
import { ItemType } from 'antd/es/menu/hooks/useItems';
import { isInternalLink } from 'technical/utils/is-internal-link';
import { Link, matchPath } from 'react-router-dom';
import { Spacer } from 'ui/spacer';
import { Menu } from 'antd';
import classes from 'ui/new-header/navigation-menu/navigation-menu.module.scss';
import { useLocation } from 'react-router';

const MenuItem = ({ href, label, Icon }: NavLinkItem) => {
  if (isInternalLink(href)) {
    return (
      <Link to={href}>
        <Spacer
          direction="horizontal"
          className={classes.container}
          space={3}
          align="center"
        >
          <Icon className={classes.icon} />
          <p className={classes.label}>{label}</p>
        </Spacer>
      </Link>
    );
  }
  return (
    <a href={href} target="_blank" rel="noreferrer" aria-label={label}>
      <Spacer
        direction="horizontal"
        className={classes.container}
        space={3}
        align="center"
      >
        <Icon className={classes.icon} />
        <p className={classes.label}>{label}</p>
      </Spacer>
    </a>
  );
};

interface NavigationMenuProps {
  types: NavLinkTypesEnumValues[];
  onClick?: () => void;
}

export const NavigationMenu: React.VFC<NavigationMenuProps> = ({
  types,
  onClick,
}) => {
  const menuLinks = useNavLinks(types);
  const location = useLocation();

  const items: ItemType[] = menuLinks.map((menuLink) => ({
    title: menuLink.label,
    key: menuLink.href,
    onClick,
    label: <MenuItem {...menuLink} />,
    className: classes.item,
  }));

  const selectedKeys = menuLinks
    .filter((menuLink) =>
      matchPath(
        {
          path: menuLink.href,
          end: true,
          caseSensitive: false,
        },
        location.pathname,
      ),
    )
    .map((menuLink) => menuLink.href);

  return (
    <Menu
      selectedKeys={selectedKeys}
      unselectable={'on'}
      className={classes.menu}
      items={items}
    />
  );
};
