import React, { ReactNode } from 'react';
import { Col, Row } from 'antd';
import SelectableBlock from 'ui/block-select/selectable-block';

interface Block {
  id: string /* value */;
  isSelected: boolean;
  render: ReactNode;
}

interface BlockSelectProps {
  blocks: Block[];
  onValueChange: ({ id }: { id: string; isSelected: boolean }) => void;
  onValueClicked?: (id: string) => void;
  onChange?: (blocks: Block[]) => void;
  selectedValues?: { [key: string]: boolean };
}

const BlockSelect: React.FC<BlockSelectProps> = ({ blocks, onValueChange }) => {
  return (
    <Row gutter={[8, 8]}>
      {blocks.map(({ id, isSelected, render }) => (
        <Col xs={12} xl={8} key={id}>
          <SelectableBlock
            onClick={() => onValueChange({ id, isSelected: !isSelected })}
            isSelected={isSelected}
          >
            {render}
          </SelectableBlock>
        </Col>
      ))}
    </Row>
  );
};

export default BlockSelect;
