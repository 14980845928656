import React, { useState } from 'react';
import { Tabs, TabPane } from 'ui/tabs';
import { Card } from 'ui/cards';
import { useParams } from 'react-router-dom';
import { PublicProfileBanner } from 'business/user/components/profile-banner';
import { PublicPersonalPortfolios } from 'business/user/components/personal-portfolios';
import { useTranslation } from 'react-i18next';
import { CompactUserRanking } from 'business/user/components/ranking/compact';
import ResearchHistoryTable from 'business/user/components/research-history-table';
import { Spacer } from 'ui/spacer';
import { useUserRankingQuery } from 'generated/graphql';
import classes from './index.module.scss';
import ReportingNoMatch from 'technical/router/switch/reporting-no-match';

enum MainPanelTabs {
  portfolios = 'portfolios',
  researches = 'researches',
}

const defaultTab = MainPanelTabs.portfolios;

export function PublicAccountPage() {
  const { t } = useTranslation(['user']);
  const [currentTab, setCurrentTab] = useState<MainPanelTabs>(defaultTab);
  const { id } = useParams<'id'>();

  if (id === undefined) {
    throw new Error('id param is not defined');
  }

  const { data: rankData } = useUserRankingQuery({
    variables: {
      userId: id,
    },
  });

  const { ranks } = rankData ?? {};

  return (
    <div className={classes.grid}>
      <div className={classes.profileInfo}>
        <PublicProfileBanner
          userId={id}
          position={ranks?.leaderboard?.position ?? 0}
        />
      </div>
      <div className={classes.tabs}>
        <Tabs
          defaultActiveKey={defaultTab}
          onChange={(tab) => setCurrentTab(tab as MainPanelTabs)}
        >
          <TabPane
            tab={t(`user:public-account.tabs.portfolios`)}
            key={MainPanelTabs.portfolios}
          >
            <PublicPersonalPortfolios authorId={id} />
          </TabPane>
          <TabPane
            tab={t(`user:public-account.tabs.scorings`)}
            key={MainPanelTabs.researches}
          >
            <Spacer direction="vertical">
              <ResearchHistoryTable
                authorId={id}
                title={t('user:research-history.past-title')}
                includeStatus={['rejected', 'validated']}
              />
            </Spacer>
          </TabPane>
        </Tabs>
      </div>
      <div className={classes.rank}>
        <Card>
          <Card.Header title={t('user:public-profile.rank-title')} level={3} />
          <Card type="well" className={classes.card}>
            <Spacer align="center" direction="vertical">
              {ranks ? (
                <CompactUserRanking
                  icon={ranks.current.icon}
                  position={ranks.leaderboard?.position ?? 0}
                />
              ) : null}
            </Spacer>
          </Card>
        </Card>
      </div>
    </div>
  );
}
