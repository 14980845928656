import { useTranslation } from 'react-i18next';
import React from 'react';
import { useNavigate } from 'react-router-dom';
import Routes from 'business/router/routes';
import ActionCard from '../../components/action-card';
import PageCenteredContent from '../../../../ui/layouts/page-centered-content';

function EmailVerifiedPage() {
  const { t } = useTranslation(['common']);
  const navigate = useNavigate();

  return (
    <PageCenteredContent>
      <ActionCard
        dataTestId="success-email-verified"
        title={t('pages.emailVerified.title')}
        hint={t('pages.emailVerified.hint')}
        btnText={t('pages.emailVerified.loginButton')}
        handleSubmit={() => navigate(Routes.SignIn)}
      />
    </PageCenteredContent>
  );
}

export default EmailVerifiedPage;
