import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import enTrads from 'translations/en';

enum ILanguage {
  en = 'en',
}

const resources = { [ILanguage.en]: enTrads } as const;
const defaultNS = 'common';

i18n
  .use(LanguageDetector) // detect browser language
  .use(initReactI18next) // passes i18n down to react-i18next
  .init({
    resources,
    returnNull: false,
    fallbackLng: ILanguage.en,
    supportedLngs: Object.keys(resources),
    ns: [
      // must be updated in front/i18next-scanner.config.engine.js as well
      'common',
      'forms',
      'form-wizard',
      'home',
      'token',
      'portfolio',
      'onboarding',
      'user',
      'review',
      'research',
      'rewardPoint',
      'landing',
    ],
    defaultNS,
    nsSeparator: ':',
    interpolation: {
      escapeValue: false, // react already safes from xss
    },
  });

export { ILanguage, i18n, resources, defaultNS };
