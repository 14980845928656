import React, { ReactNode } from 'react';
import { Card } from 'ui/cards';
import { Tag, Typography } from 'antd';
import Button, { LinkAsButton } from 'ui/button';
import Routes from 'business/router/routes';
import { Spacer } from 'ui/spacer';
import { Me } from 'business/user/types/user';
import { Ranking } from 'ui/ranking';
import { AvatarPlaceHolder } from 'business/user/avatar';
import { useAppContext } from 'business/app-bootstrapper';
import { CloseOutlined } from '@ant-design/icons';
import { useTranslation } from 'react-i18next';
import classnames from 'classnames';
import { WalletMenu } from 'ui/header/wallet-menu';
import { ReactComponent as KryptviewWhiteIcon } from 'ui/app-logo/kryptview-white.svg';
import classes from './menu-header.module.scss';

interface CloseProps {
  onClick: () => void;
}
function Close({ onClick }: CloseProps) {
  const { t } = useTranslation(['common']);
  return (
    <Button
      type="primary"
      onClick={onClick}
      className={classnames(classes.button, classes.close)}
    >
      {t('common:close')} <CloseOutlined />
    </Button>
  );
}

interface MenuHeaderProps {
  user: Me;
  links: { label: string; route: Routes }[];
  action?: ReactNode;
  wallet?: ReactNode;
  onItemClick?: () => void;
}

export const ConnectedMenuHeader: React.VFC<MenuHeaderProps> = ({
  user,
  links,
  action,
  wallet,
  onItemClick,
}) => {
  const { t } = useTranslation(['common']);

  return (
    <Card type="primary" className={classes.container}>
      <Spacer direction="vertical" space={2}>
        <Spacer direction="horizontal" space={2}>
          <AvatarPlaceHolder />

          <Spacer direction="vertical" space={1}>
            <Typography.Title className={classes.profileName} level={5}>
              {user.displayName}
            </Typography.Title>
            <Typography className={classes.mail}>{user.email}</Typography>

            {user.rank ? (
              <Spacer direction="horizontal" justify="start" align="center">
                <div>
                  <Ranking rank={user.rank} compact />
                </div>

                {wallet}
              </Spacer>
            ) : (
              <Spacer
                direction="vertical"
                justify="center"
                align="start"
                space={1}
              >
                {wallet}
                <Typography.Text className={classes.rankError}>
                  {t('common:errors.rank-load-error')}
                </Typography.Text>
              </Spacer>
            )}
          </Spacer>
          {action}
        </Spacer>

        <Spacer direction="horizontal" space={1}>
          {links.map((link) => (
            <LinkAsButton
              type="primary"
              className={classes.button}
              key={link.route}
              to={link.route}
              onClick={onItemClick}
              block
            >
              {link.label}
            </LinkAsButton>
          ))}
        </Spacer>
      </Spacer>
    </Card>
  );
};

export const NotConnectedHeaderMenu = ({
  action,
}: Pick<MenuHeaderProps, 'action'>) => {
  const { t } = useTranslation(['common']);

  return (
    <Card type="primary" className={classes.container}>
      <Spacer justify="end">{action}</Spacer>
      <Spacer direction="vertical" space={2} justify="center" align="center">
        <KryptviewWhiteIcon />
        <Tag className={classes.tag}>
          <Typography.Text className={classes.label}>
            {t('common:not-connected')}
          </Typography.Text>
        </Tag>
      </Spacer>
    </Card>
  );
};

export const HeaderMenu = ({
  action,
  onItemClick,
}: Pick<MenuHeaderProps, 'action' | 'onItemClick'>) => {
  const { t } = useTranslation(['common']);
  const { user } = useAppContext();
  if (user) {
    return (
      <ConnectedMenuHeader
        user={user}
        links={[
          {
            route: Routes.Dashboard,
            label: t('header.account-menu.dashboard'),
          },
          {
            route: Routes.PersonalPortfolio,
            label: t('header.account-menu.portfolios'),
          },
          {
            route: Routes.ResearchHistory,
            label: t('header.account-menu.scoring'),
          },
        ]}
        action={action}
        wallet={<WalletMenu user={user} />}
        onItemClick={onItemClick}
      />
    );
  }

  return <NotConnectedHeaderMenu action={action} />;
};

HeaderMenu.Close = Close;
