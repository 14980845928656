import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Spacer } from 'ui/spacer';
import classes from './index.module.scss';

const MILLISECONDS = 1000;
const SECONDS = 1 * MILLISECONDS;
const MINUTES = 60 * SECONDS;
const HOURS = 60 * MINUTES;
const DAYS = 24 * HOURS;

export function CountDown({ endDate }: { endDate: Date }) {
  const { t } = useTranslation(['landing']);
  const [now, setNow] = useState(new Date());

  useEffect(function syncWithDateEvery10seconds() {
    const interval = setInterval(() => {
      setNow(new Date());
    }, 10 * SECONDS);
    return () => clearInterval(interval);
  }, []);

  const diff = endDate.getTime() - now.getTime();

  const days = Math.floor(diff / DAYS);
  const hours = Math.floor((diff % DAYS) / HOURS);
  const minutes = Math.floor((diff % HOURS) / MINUTES);

  return (
    <Spacer direction="horizontal" space={2}>
      {Object.entries({ days, hours, minutes }).map(([key, value]) => (
        <Spacer
          key={key}
          direction="vertical"
          space={3}
          align="center"
          className={classes.countdown}
        >
          <span className={classes.timer}>{Math.max(value, 0)}</span>
          <span className={classes.label}>
            {t('landing:countdown.label', { context: key, defaultValue: key })}
          </span>
        </Spacer>
      ))}
    </Spacer>
  );
}
