import React, { useMemo } from 'react';
import {
  ResearchFormAnswerChoice,
  ResearchFormAnswerRepartition,
  ResearchFormAnswerRepartitionQuestionType,
  ResearchFormQuestionAnswerRepartition,
} from 'generated/graphql';
import { scaleBand, scaleLinear, scaleOrdinal } from '@visx/scale';
import { LegendOrdinal, LegendItem, LegendLabel } from '@visx/legend';
import { Group } from '@visx/group';
import { BarStackHorizontal } from '@visx/shape';
import { BarStack, SeriesPoint } from '@visx/shape/lib/types';
import { useTranslation } from 'react-i18next';
import colors from 'ui/theme/variables.module.scss';
import { formatPercent } from 'technical/currency/formatters';
import { Tooltip, useTooltip, defaultStyles } from '@visx/tooltip';
import { Spacer } from 'ui/spacer';
import classes from './simple-choice-answer-repartition-visualisation.module.scss';

type TooltipData = {
  bar: SeriesPoint<{ id: string; [key: string]: number | string }>;
  key: string;
  index: number;
  height: number;
  width: number;
  x: number;
  y: number;
  color: string;
};

export type ChoiceAnswerRepartition = Pick<
  ResearchFormAnswerRepartition,
  'answerRankByPercentage' | 'percentage'
> & {
  choice: Pick<ResearchFormAnswerChoice, 'id' | 'value'>;
};

type SimpleChoiceAnswerRepartitionVisualisationProps = {
  question: Pick<ResearchFormQuestionAnswerRepartition, 'id'> & {
    type: Pick<ResearchFormAnswerRepartitionQuestionType, 'type'>;
    answersRepartition: Array<ChoiceAnswerRepartition>;
  };
};

const tooltipStyles = {
  ...defaultStyles,
  boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.07)',
  borderRadius: 20,
  padding: '5px 10px',
  backgroundColor: colors.body,
  color: colors.text,
  transform: 'translate(-50%, -50%)',
};

const otherKey = 'other';
// todo define colors properly
const otherColor = colors.answerRepartitionOther;
const barsColors = [
  '#4457FFFF', // 100
  // '#4457ffE6', // 90
  '#4457ffCC', // 80
  // '#4457ffB3', // 70
  '#4457ff99', // 60
  // '#4457ff80', // 50
  '#4457ff66', // 40
  // '#4457ff4D', // 30
  '#4457ff33', // 20
  // '#4457ff1A', // 10
];

const legendGlyphSize = 15;
const height = 15;

function getAnswerRepartitionTotalPercentage(
  filteredAnswersRepartition: ChoiceAnswerRepartition[],
) {
  return filteredAnswersRepartition.reduce(
    (previousValue, currentValue) => previousValue + currentValue.percentage,
    0,
  );
}

/**
 * Generate a dictionary from choices list
 * { [choiceId]: choice object }
 * @param filteredAnswersRepartition
 */
function getChoicesDictionary(
  filteredAnswersRepartition: ChoiceAnswerRepartition[],
) {
  return filteredAnswersRepartition.reduce(
    (previousValue, { choice, percentage }) => {
      // eslint-disable-next-line no-param-reassign
      previousValue[choice.id] = { value: choice.value, percentage };
      return previousValue;
    },
    {} as { [key: string]: { value: string; percentage: number } },
  );
}

/**
 * Group all answer repartition in the same object as we want all choices in the same stack and display only one stack with multiple bar
 * @param filteredAnswersRepartition
 * @param id
 */
function getComputedSingleChoiceData(
  filteredAnswersRepartition: ChoiceAnswerRepartition[],
  id: string,
) {
  return [
    filteredAnswersRepartition.reduce(
      (previousValue, { choice, percentage }) => {
        // eslint-disable-next-line no-param-reassign
        previousValue[choice.id] = percentage;
        return previousValue;
      },
      { id } as { id: string } & { [key: string]: number },
    ),
  ];
}

let tooltipTimeout: number;

/**
 * function to compute and format
 *    - keys (choice id, to differentiate each options)
 *    - choice dictionary, to get choice value using its id
 *    - grouped choice repartition in one stack
 * @param answersRepartition
 * @param id
 */
function getComputedDataForSingleChoice(
  answersRepartition: Array<ChoiceAnswerRepartition>,
  id: string,
  otherLabel = 'Other',
) {
  return () => {
    // we could filter small percentage here
    // make sure we have distinct colors for each bar
    const filteredAnswersRepartition = answersRepartition.slice(
      0,
      barsColors.length,
    );

    const total = getAnswerRepartitionTotalPercentage(
      filteredAnswersRepartition,
    );
    const isComplete = total === 1;

    const keys = filteredAnswersRepartition.map(
      (answerRepartition) => answerRepartition.choice.id,
    );
    if (!isComplete) {
      keys.push(otherKey);
    }

    const rangeColors = barsColors.slice(0, filteredAnswersRepartition.length);
    if (!isComplete) {
      rangeColors.push(otherColor);
    }

    const choiceDictionary = getChoicesDictionary(filteredAnswersRepartition);
    if (!isComplete) {
      choiceDictionary[otherKey] = { value: otherLabel, percentage: 1 - total };
    }

    const data = getComputedSingleChoiceData(filteredAnswersRepartition, id);

    if (!isComplete) {
      data[0][otherKey] = 1 - total;
    }

    return { keys, choiceDictionary, data, rangeColors };
  };
}

export const SimpleChoiceAnswerRepartitionVisualisation: React.VFC<
  Pick<
    SimpleChoiceAnswerRepartitionVisualisationProps['question'],
    'answersRepartition' | 'id'
  > & { width: number }
> = ({ answersRepartition, id, width }) => {
  const { t } = useTranslation(['token']);
  const {
    showTooltip,
    tooltipOpen,
    tooltipData,
    hideTooltip,
    tooltipTop = 0,
    tooltipLeft = 0,
  } = useTooltip<TooltipData>();
  // keys have the values for the x-axis ticks.
  const {
    keys,
    choiceDictionary,
    data,
    rangeColors,
  } = useMemo(
    getComputedDataForSingleChoice(
      answersRepartition,
      id,
      t('token:research-answers.answers-repartition-modal.visualisation.other'),
    ),
    [answersRepartition, id],
  );

  // x scale
  const percentageScale = scaleLinear<number>({
    domain: [0, 1],
    range: [0, width],
  });

  const yScale = scaleBand({
    domain: [id],
    range: [0, height],
  });

  const ordinalColorScale = scaleOrdinal({
    domain: keys,
    range: rangeColors,
  });
  return (
    <Spacer
      direction="vertical"
      space={1}
      className={classes.container}
      align="center"
    >
      <svg width={width} height={height}>
        <Group>
          <BarStackHorizontal
            data={data}
            keys={keys}
            xScale={percentageScale}
            color={ordinalColorScale}
            yScale={yScale}
            y={(yDomain) => yDomain.id}
          >
            {(barStacks) => {
              const stacks = barStacks.flatMap(({ bars }) => bars);
              const entries = stacks.length / barStacks.length;

              type Bars = BarStack<
                { [p: string]: string | number; id: string },
                string
              >['bars'];

              const rows = stacks.reduce(
                (groups, stack, index) => {
                  groups[index % entries].push(stack);
                  return groups;
                },
                Array.from({ length: entries }, () => [] as Bars),
              );
              return rows.map((bars) => {
                const firstBar = bars.at(0);
                const lastBar = bars.at(-1);
                if (!firstBar || !lastBar) {
                  // meaning we have no stack in our bar
                  return null;
                }

                return (
                  <>
                    <defs>
                      {/* TODO: do not use this index as key */}
                      <mask id={`image-mask-${firstBar?.bar.data.id}`}>
                        <rect
                          x={firstBar?.x}
                          y={firstBar?.y}
                          width={(lastBar?.x ?? 0) + (lastBar?.width ?? 0)}
                          height={firstBar?.height}
                          rx={(firstBar?.height ?? 0) / 2}
                          fill="white"
                        />
                      </mask>
                    </defs>
                    <Group mask={`url(#image-mask-${firstBar?.bar.data.id})`}>
                      {bars.map((bar) => (
                        <rect
                          key={`choice-percentage-use-barstack-horizontal-${firstBar?.index}-${bar.index}-${firstBar?.bar.data.id}`}
                          x={bar.x}
                          y={bar.y}
                          width={bar.width}
                          height={bar.height}
                          fill={bar.color}
                          onMouseLeave={() => {
                            tooltipTimeout = window.setTimeout(() => {
                              hideTooltip();
                            }, 300);
                          }}
                          onMouseEnter={() => {
                            if (tooltipTimeout) {
                              clearTimeout(tooltipTimeout);
                            }
                            const top = bar.y + bar.height / 2;
                            const left = bar.x + bar.width / 2;
                            showTooltip({
                              tooltipData: bar,
                              tooltipTop: top,
                              tooltipLeft: left,
                            });
                          }}
                        />
                      ))}
                    </Group>
                  </>
                );
              });
            }}
          </BarStackHorizontal>
        </Group>
      </svg>
      <LegendOrdinal
        labelFormat={(choiceId) =>
          `${choiceDictionary[choiceId].value} ${formatPercent(
            choiceDictionary[choiceId].percentage,
          )} `
        }
        scale={ordinalColorScale}
      >
        {(labels) => (
          <Spacer justify="center">
            {labels.map((label) => (
              <LegendItem
                key={`legend-${label.index}-${label.value}`}
                margin="0 5px"
              >
                <svg width={legendGlyphSize} height={legendGlyphSize}>
                  <rect
                    width={legendGlyphSize}
                    height={legendGlyphSize}
                    rx={legendGlyphSize}
                    fill={label.value}
                  />
                </svg>
                <LegendLabel align="left" margin="0 0 0 4px">
                  {label.text}
                </LegendLabel>
              </LegendItem>
            ))}
          </Spacer>
        )}
      </LegendOrdinal>
      {tooltipOpen && tooltipData && (
        <Tooltip
          offsetLeft={0}
          offsetTop={0}
          top={tooltipTop}
          left={tooltipLeft}
          style={tooltipStyles}
        >
          <div>{formatPercent(+tooltipData.bar.data[tooltipData.key])}</div>
        </Tooltip>
      )}
    </Spacer>
  );
};
