import { Popover, Select, Typography } from 'antd';
import { PeerReviewOpinion } from 'business/peer-review/types';
import config from 'config';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import Button from 'ui/button';
import { Spacer } from 'ui/spacer';
import classes from './index.module.scss';

const { Option } = Select;

export interface PeerReviewOpinionButtonsProps {
  answerId: string;
  reviewId: string;
  opinion: 'agree' | 'disagree' | undefined;
  reason: string | null | undefined;
  setApproval: (
    answerId: string,
    reviewId: string,
    opinion: PeerReviewOpinion,
    reason: string | null | undefined,
  ) => void;
  alreadySubmitted: boolean;
  reasons: { reason: string; comment: string }[];
}

const opinionActions: PeerReviewOpinion[] = ['agree', 'disagree'];
const opinionAccent: Record<PeerReviewOpinion, 'success' | 'danger'> = {
  agree: 'success',
  disagree: 'danger',
};

export function PeerReviewOpinionButtons({
  answerId,
  reviewId,
  opinion,
  reason: currentReason,
  setApproval,
  alreadySubmitted,
  reasons,
}: PeerReviewOpinionButtonsProps) {
  const { t } = useTranslation(['review']);
  const [currentOpinion, setCurrentOpinion] = useState(opinion);
  const [selectedReason, setSelectedReason] = useState<
    string | null | undefined
  >(currentReason);
  const [isReasonPopoverOpen, setIsReasonPopoverOpen] = useState(false);

  const getAccent = (action: PeerReviewOpinion) => {
    if (alreadySubmitted) {
      return currentOpinion === action ? opinionAccent[action] : undefined;
    }
    return opinionAccent[action];
  };

  const buttonLabel = (action: PeerReviewOpinion) => {
    if (
      action !== 'disagree' ||
      !currentReason ||
      !config.features.useSpecificDisagree
    ) {
      return t('review:approval', { context: action });
    }
    return `${t('review:approval', { context: action })} (${t('review:reason', {
      context: currentReason,
    })})`;
  };

  const hidePopOver = () => {
    setIsReasonPopoverOpen(false);
  };

  const handlePopOverOpenChange = (newOpen: boolean) => {
    setIsReasonPopoverOpen(newOpen);
  };

  const saveApproval = (
    action: PeerReviewOpinion,
    reason: string | null | undefined,
  ) => {
    setCurrentOpinion(action);
    setApproval(answerId, reviewId, action, reason);
  };

  const saveDisagree = (reason: string) => {
    setSelectedReason(reason);
    saveApproval('disagree', reason);
    hidePopOver();
  };

  const handleOpinion = (action: PeerReviewOpinion) => {
    if (currentOpinion !== action && !alreadySubmitted) {
      saveApproval(action, null);
      setSelectedReason(null);
    }
  };

  const disagreePopOverContent = (
    <Spacer direction="vertical" space={1}>
      <Typography.Title level={3}>{t('review:reason-title')}</Typography.Title>
      <Typography.Text>{t('review:reason-subtitle')}</Typography.Text>
      <Select
        className={classes.input}
        value={selectedReason}
        onChange={saveDisagree}
      >
        {reasons &&
          reasons.map((option) => (
            <Option key={option.reason} value={option.reason}>
              {t('review:reason', {
                context: option.reason,
              })}
            </Option>
          ))}
      </Select>
    </Spacer>
  );

  return (
    <>
      <Spacer direction="horizontal" space={1}>
        {opinionActions.map((action) => {
          if (config.features.useSpecificDisagree && action === 'disagree') {
            return (
              <Popover
                key={action}
                placement="bottomRight"
                trigger="click"
                open={isReasonPopoverOpen}
                onOpenChange={handlePopOverOpenChange}
                content={disagreePopOverContent}
              >
                <Button
                  className={classes.button}
                  type={currentOpinion === action ? 'primary' : 'default'}
                  accent={getAccent(action)}
                  size="large"
                  disabled={alreadySubmitted && currentOpinion !== action}
                >
                  {buttonLabel(action)}
                </Button>
              </Popover>
            );
          }
          return (
            <Button
              key={action}
              className={classes.button}
              type={currentOpinion === action ? 'primary' : 'default'}
              accent={getAccent(action)}
              size="large"
              disabled={alreadySubmitted && currentOpinion !== action}
              onClick={() => handleOpinion(action)}
            >
              {buttonLabel(action)}
            </Button>
          );
        })}
      </Spacer>
    </>
  );
}
