import React, { useState } from 'react';
import { Typography } from 'antd';
import { useTranslation } from 'react-i18next';
import Button, { LinkAsButton } from 'ui/button';
import { Card } from 'ui/cards';
import { Spacer } from 'ui/spacer';
import { TokenTable, TokenTableFilters } from 'business/token/components/table';
import classes from './index.module.scss';
import Routes from 'business/router/routes';
import config from 'config';
import Tag from 'ui/tag';
import variables from 'ui/theme/variables.module.scss';

const defaultTab: TokenTableFilters = TokenTableFilters.avgRatingDesc;

export function AllTokens() {
  const { t } = useTranslation(['home']);
  const [currentFilter, setCurrentFilter] = useState(defaultTab);

  const filtersKeys: TokenTableFilters[] = [
    TokenTableFilters.avgRatingDesc,
    TokenTableFilters.lastResearched,
    TokenTableFilters.marketCapDesc,
  ];

  return (
    <Card className={classes.container}>
      <Spacer space={2} direction="vertical">
        <Spacer
          space={2}
          align="baseline"
          justify="start"
          direction={'horizontal'}
          className={classes.header}
        >
          <Typography.Title level={2} className={classes.title}>
            {t('home:tokens.title')}
          </Typography.Title>
          <Spacer
            space={1}
            direction={'horizontal'}
            className={classes['buttons-over-md']}
          >
            {filtersKeys.map((key) => (
              <Button
                key={key}
                type={currentFilter === key ? 'primary' : 'ghost'}
                onClick={() => setCurrentFilter(key)}
              >
                {t(`home:tokens.tabs`, { context: key })}
              </Button>
            ))}
          </Spacer>
          {config.features.isPowerSearchActivated ? (
            <div className={classes['link-to-search']}>
              <LinkAsButton type="primary" to={Routes.PowerSearch}>
                Power Search
              </LinkAsButton>
              <Tag
                color={variables.ratingMedium}
                style={{
                  position: 'absolute',
                  top: 8,
                  right: 8,
                  transform: 'rotate(12deg)',
                }}
              >
                New
              </Tag>
            </div>
          ) : null}
        </Spacer>
        <Spacer
          space={1}
          direction={'horizontal'}
          className={classes['buttons-under-md']}
        >
          {filtersKeys.map((key) => (
            <Button
              key={key}
              type={currentFilter === key ? 'primary' : 'ghost'}
              onClick={() => setCurrentFilter(key)}
            >
              {t(`home:tokens.tabs`, { context: key })}
            </Button>
          ))}
        </Spacer>
        <TokenTable filter={currentFilter} setFilter={setCurrentFilter} />
      </Spacer>
    </Card>
  );
}
