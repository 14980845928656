import React from 'react';
import { Article } from 'business/article/components/article';
import { Spacer } from 'ui/spacer';
import { useArticlesQuery } from 'generated/graphql';
import { ArticleTypesEnum } from 'business/article/types';
import { skeletonArticles } from 'business/article/components/hot-topic-articles';
import EmbedVideoLink from 'business/home/components/embed-video-link';
import { isInternalLink } from 'technical/utils/is-internal-link';
import classes from './regular-articles.module.scss';

export const RegularArticles: React.VFC = () => {
  const { data, loading } = useArticlesQuery({
    variables: {
      types: [ArticleTypesEnum.regularVideo, ArticleTypesEnum.regular],
    },
  });
  const articles = loading ? skeletonArticles : data?.articles ?? [];

  return (
    <Spacer
      space={2}
      direction={{ xl: 'vertical', default: 'horizontal' }}
      className={classes.discoverItems}
      align={{ xl: 'center', default: 'start' }}
    >
      {articles.map((article) => {
        switch (article.type) {
          case ArticleTypesEnum.regularVideo:
            return <EmbedVideoLink title={article.title} link={article.link} />;
          default:
            return (
              <Article
                loading={loading}
                key={article.id}
                internal={isInternalLink(article.link)}
                {...article}
              />
            );
        }
      })}
    </Spacer>
  );
};
