import React, { useEffect, useState, useCallback } from 'react';
import logger from 'technical/logger';

interface WidgetFn<Args extends object> {
  new (
    args: Args & {
      autosize: boolean;
      height: string | number;
      width: string | number;
      container_id: string;
    },
  ): any;
}

type WidgetArgs = {
  symbol: string;
  interval: string;
  timezone: string;
  theme: 'light' | 'dark';
  style: '1';
  locale: string;
  toolbar_bg?: string;
  enable_publishing: false;
  withdateranges?: boolean;
  hide_side_toolbar?: boolean;
  allow_symbol_change: boolean;
};

type MediumWidgetArgs = {
  symbols: Array<Array<string>>;
  chartOnly?: boolean;
  locale: string;
  colorTheme: 'light' | 'dark';
  gridLineColor?: string;
  fontColor?: string;
  isTransparent: boolean;
  showVolume?: boolean;
  scalePosition?: 'no';
  scaleMode?: 'Normal';
  fontFamily?: 'Trebuchet MS, sans-serif';
  noTimeScale?: boolean;
  valuesTracking: '1';
  chartType: 'area';
  lineColor?: string;
  bottomColor?: string;
  topColor?: string;
};

declare const TradingView:
  | undefined
  | { widget: WidgetFn<WidgetArgs>; MediumWidget: WidgetFn<MediumWidgetArgs> };

type TradingViewHelpers = Exclude<typeof TradingView, undefined>;

type WidgetType = keyof TradingViewHelpers;

export function useTradingViewWidgets(
  src: string = 'https://s3.tradingview.com/tv.js',
) {
  const [status, setStatus] = useState<'idle' | 'script-loaded' | 'error'>(
    'idle',
  );

  useEffect(
    function loadTradingView() {
      // we load the script like that because when using <script src='...' />
      // script is prevented to load by the browser
      const script = document.createElement('script');
      script.async = true;
      script.src = src;
      script.type = 'text/javascript';
      script.id = 'tradingview-chart-script';
      script.onerror = () => {
        setStatus('error');
      };
      script.onload = () => {
        setStatus('script-loaded');
      };
      document.head.appendChild(script);
      return () => {
        document.head.removeChild(script);
      };
    },
    [src],
  );

  // this helps us to render the trading view widgets
  const renderWidget = useCallback(
    function render<Type extends WidgetType>(
      id: string,
      type: Type,
      args: {
        widget: WidgetArgs;
        MediumWidget: MediumWidgetArgs;
      }[Type],
    ) {
      // after loading the script the TradingView object should be accessible
      if (typeof TradingView === 'undefined') {
        logger.error(
          'tried to access TradingView global, but it was not found',
        );
        setStatus('error');
        return;
      }

      const element = document.getElementById(id);
      if (element) {
        element.innerHTML = '';
      }

      // eslint-disable-next-line no-new
      new TradingView[type]({
        ...args,
        autosize: true,
        height: '100%',
        width: '100%',
        container_id: id,
        // we cast as any here because typescript cannot infer correctly those types
      } as any);
    },
    [setStatus],
  );

  return {
    status,
    renderWidget: status === 'script-loaded' ? renderWidget : undefined,
  } as const;
}
