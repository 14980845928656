import Routes from 'business/router/routes';
import { TFunction } from 'i18next';
import { generatePath, LinkProps } from 'react-router-dom';

export type AccountLinks = Pick<LinkProps, 'to' | 'children'> & {
  key: string;
  hasDivider?: boolean;
};

export function getAccountLinks(t: TFunction): AccountLinks[] {
  return [
    {
      key: 'dashboard',
      to: Routes.Dashboard,
      children: t('header.account-menu.dashboard'),
    },
    {
      key: 'portfolio',
      to: Routes.PersonalPortfolio,
      children: t('header.account-menu.portfolios'),
    },
    {
      key: 'researches',
      to: Routes.ResearchHistory,
      children: t('header.account-menu.scoring'),
    },
  ];
}
