import React from 'react';
import { Dropdown, Menu } from 'antd';
import classes from 'ui/header/account-menu.module.scss';
import Button from 'ui/button';
import { LeaderboardSession } from 'generated/graphql';
import { Spacer } from 'ui/spacer';
import { formatIfPossible } from 'technical/date-fns';
import { useTranslation } from 'react-i18next';
import { ItemType } from 'antd/lib/menu/hooks/useItems';
import { isNotNullOrUndefined } from 'technical/utils/is-not-null-or-undefined';
import { LeftOutlined, RightOutlined } from '@ant-design/icons';

interface LeaderboardSessionSelectorProps {
  sessions?: LeaderboardSession[];
  currentSession?: LeaderboardSession | null;
  loading?: boolean;
  onChange: (newSession: LeaderboardSession) => void;
}

export const LeaderboardSessionSelector: React.VFC<LeaderboardSessionSelectorProps> = ({
  sessions = [],
  currentSession,
  loading,
  onChange,
}) => {
  const { t } = useTranslation(['common']);

  const items: ItemType[] = sessions.map((session) => ({
    key: session.id,
    onClick: () => onChange(session),
    label: t('common:leaderboard.session-dates', {
      startDate: formatIfPossible(session?.startDate, 'P'),
      endDate: formatIfPossible(session?.endDate, 'P'),
    }),
  }));

  const isOldestSession =
    isNotNullOrUndefined(currentSession) &&
    currentSession?.id === sessions[sessions.length - 1]?.id;
  const isMoreRecentSession =
    isNotNullOrUndefined(currentSession) &&
    currentSession?.id === sessions[0]?.id;

  return (
    <Spacer space={1}>
      <Button
        disabled={isMoreRecentSession || loading}
        icon={<LeftOutlined />}
        onClick={() => {
          onChange(
            sessions[
              sessions?.findIndex(
                (session) => session.id === currentSession?.id,
              ) - 1
            ],
          );
        }}
      />
      <Dropdown
        disabled={loading}
        overlay={<Menu items={items} className={classes.desktopMenu} />}
      >
        <Button loading={loading} className={classes.fakeButton}>
          {t('common:leaderboard.session-dates', {
            startDate: formatIfPossible(currentSession?.startDate, 'P'),
            endDate: formatIfPossible(currentSession?.endDate, 'P'),
          })}
        </Button>
      </Dropdown>
      <Button
        disabled={isOldestSession || loading}
        icon={<RightOutlined />}
        onClick={() => {
          onChange(
            sessions[
              sessions?.findIndex(
                (session) => session.id === currentSession?.id,
              ) + 1
            ],
          );
        }}
      />
    </Spacer>
  );
};
