import React from 'react';
import { useTranslation } from 'react-i18next';
import Button, { ButtonProps } from 'ui/button';
import { Spacer } from 'ui/spacer';
import classes from './later-button.module.scss';

interface LaterButtonProps extends Pick<ButtonProps, 'onClick' | 'loading'> {
  text?: string;
}

export function LaterButton({ text, ...props }: LaterButtonProps) {
  const { t } = useTranslation(['onboarding']);

  return (
    <Spacer>
      <Button type="link" {...props} className={classes.laterButton}>
        {text ?? t('onboarding:later')}
      </Button>
    </Spacer>
  );
}
