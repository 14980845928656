import React, { FC } from 'react';
import Icon from '@ant-design/icons';
import type { CustomIconComponentProps } from '@ant-design/icons/lib/components/Icon';

const ArrowDownSvg = () => (
  <svg
    focusable="false"
    viewBox="0 0 9 6"
    data-icon="caret-down"
    width="1em"
    height="1em"
    fill="currentColor"
    aria-hidden="true"
  >
    <path d="M4.81424 5.49841L8.33919 1.47C8.75519 0.994469 8.4171 0.250671 7.78576 0.250671L0.735853 0.250671C0.594558 0.25055 0.456222 0.291159 0.337412 0.367635C0.218603 0.444112 0.124356 0.553214 0.0659575 0.681877C0.00755925 0.810539 -0.0125146 0.953308 0.00814002 1.09309C0.0287937 1.23286 0.0893005 1.36372 0.182414 1.47L3.70737 5.49768C3.77635 5.57662 3.86143 5.63989 3.95688 5.68324C4.05234 5.72659 4.15597 5.74902 4.2608 5.74902C4.36564 5.74902 4.46927 5.72659 4.56472 5.68324C4.66018 5.63989 4.74526 5.57662 4.81424 5.49768L4.81424 5.49841Z" />
  </svg>
);

export const ArrowDownFilled: FC<Partial<CustomIconComponentProps>> = (
  props,
) => <Icon component={ArrowDownSvg} {...props} />;
