import {
  initAuthentication,
  goToLogin,
  goToSignUp,
  signUp,
  isAuthenticated,
  signIn,
  requestLoginCallback,
  getAuthResult,
  logout,
  forgotPassword,
  resetPassword,
  getAccessToken,
  renewToken,
  refreshEmailValidation,
  requestEmailVerificationCallback,
} from 'technical/auth/providers/custom';

const authService = {
  goToLogin,
  goToSignUp,
  signUp,
  isAuthenticated,
  signIn,
  requestLoginCallback,
  getAuthResult,
  getAccessToken,
  initAuthentication,
  logout,
  requestPasswordReset: forgotPassword,
  resetPassword,
  renewToken,
  refreshEmailValidation,
  requestEmailVerificationCallback,
};

export default authService;
