import React, { useState } from 'react';
import { Spacer } from 'ui/spacer';
import { Typography } from 'antd';
import { useReadOnlyResearchQuery } from 'generated/graphql';
import { useTranslation } from 'react-i18next';
import { isNotNullOrUndefined } from 'technical/utils/is-not-null-or-undefined';
import { PeerReviewAnswersQuestionsByCategoryName } from 'business/peer-review/types';
import { PeerReviewFormWizard } from 'business/peer-review/components/peer-review-form-wizard';
import { PeerReviewGeneralOpinion } from 'business/peer-review/components/global-opinion';
import { Card } from 'ui/cards';
import classes from './review-research.module.scss';
import { CategoryTab } from './category-tab';
import { CategoryTabs } from './category-tabs';
import { Modal } from 'ui/modal';

export interface ReadonlyResearchProps {
  researchId: string;
  visible: boolean;
  onClose: () => void;
}
export function ReadonlyResearch({
  researchId,
  visible,
  onClose,
}: ReadonlyResearchProps) {
  const { t } = useTranslation(['research', 'review']);
  const { data } = useReadOnlyResearchQuery({
    variables: {
      researchId,
    },
    skip: !visible,
  });

  const [currentCategoryIndex, setCurrentCategoryIndex] = useState(0);

  const research = data?.research;
  const categories = research?.categories;

  // create the record category => questions
  const answersQuestionsByCategoryName =
    research?.answers.reduce<PeerReviewAnswersQuestionsByCategoryName>(
      (record, answer) => {
        const categoryName = answer.question.category.name;

        if (isNotNullOrUndefined(record[categoryName])) {
          return {
            ...record,
            [categoryName]: {
              order: answer.question.category.order,
              categoryId: answer.question.category.id,
              questions: [...record[categoryName].questions, answer],
            },
          };
        }
        return {
          ...record,
          [categoryName]: {
            order: answer.question.category.order,
            categoryId: answer.question.category.id,
            questions: [answer],
          },
        };
      },
      {},
    ) ?? {};

  const categoryTabs = categories?.map((category) => {
    const key = answersQuestionsByCategoryName[category.infos.name]?.order - 1;

    return {
      label: (
        <Spacer
          className={classes.review}
          direction="horizontal"
          space={1}
          align="center"
        >
          <CategoryTab
            category={category.infos.id}
            name={category.infos.name}
            active={currentCategoryIndex === key}
          />
        </Spacer>
      ),
      key: key.toString(),
      children: (
        <PeerReviewFormWizard
          answers={
            answersQuestionsByCategoryName[category.infos.name]?.questions ?? []
          }
        />
      ),
    };
  });

  return (
    <Modal
      open={visible}
      onCancel={onClose}
      footer={null}
      focusTriggerAfterClose={false}
      destroyOnClose
      title={
        <Typography.Title level={2} style={{ margin: 0 }}>
          {t('research:readonly.title', {
            token: research?.token.name,
            symbol: research?.token.symbol.toUpperCase(),
            user: research?.author.profile?.displayName,
          })}
        </Typography.Title>
      }
      width={1024}
      className={classes.topResearchModal}
    >
      <Card className={classes.generalOpinion}>
        <Card.Header level={2} title={t('review:global-opinion.title')} />
        <PeerReviewGeneralOpinion
          comment={research?.comment ?? ''}
          prediction={research?.targetPrice ?? 0}
        />
      </Card>
      <Card>
        <CategoryTabs
          activeKey={`${currentCategoryIndex}`}
          onTabClick={(activeKey) => setCurrentCategoryIndex(Number(activeKey))}
          items={categoryTabs}
        />
      </Card>
    </Modal>
  );
}
