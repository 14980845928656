import React from 'react';
import { Image } from 'antd';
import Routes from 'business/router/routes';
import { useTranslation } from 'react-i18next';
import { generatePath, Link } from 'react-router-dom';
import { Spacer } from 'ui/spacer';
import { ReactComponent as UserIcon } from 'assets/illustrations/user-picto.svg';
import variables from 'ui/theme/variables.module.scss';
import classes from './index.module.scss';

export interface UserLinkProps {
  id: string;
  rank?: { icon: string };
  displayName?: string;
  iconSize?: number;
  hideAvatar?: boolean;
  className?: string;
}
export default function UserLink({
  id,
  displayName,
  iconSize = 16,
  hideAvatar,
  className,
}: UserLinkProps) {
  const { t } = useTranslation(['common']);
  return (
    <Spacer space={1} align="center" className={className}>
      {!hideAvatar ? (
        <UserIcon
          width={iconSize}
          height={iconSize}
          fill={variables.primaryColor2}
        />
      ) : null}
      <Link
        to={generatePath(Routes.PublicProfile, { id })}
        className={classes.link}
      >
        {displayName ?? t('user.no-display-name')}
      </Link>
    </Spacer>
  );
}
