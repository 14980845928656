import React, { useState } from 'react';
import { Card } from 'ui/cards';
import { useTranslation } from 'react-i18next';
import { Button, Image, Typography } from 'antd';
import { useAppContext } from 'business/app-bootstrapper';
import { Spacer } from 'ui/spacer';
import MenuSVG from 'assets/icons/menu.svg';
import { useGetUserRewardsQuery, UserRewardFilters } from 'generated/graphql';
import { Modal } from 'ui/modal';
import { useLocation, useNavigate } from 'react-router-dom';
import { LinkAsButton } from 'ui/button';
import { UserRewardList } from 'business/reward-point/components/reward-list';
import Routes from 'business/router/routes';
import { UserInfoTabs } from 'business/user/types/user';
import classes from './index.module.scss';

function TotalEarned() {
  const { t } = useTranslation(['dashboard', 'rewardPoint']);

  const location = useLocation();
  const navigate = useNavigate();
  const [isRewardDetailVisible, setIsRewardDetailVisible] = useState(false);
  const { data } = useGetUserRewardsQuery({
    variables: { filter: UserRewardFilters.All },
  });

  function setTab(next: UserInfoTabs) {
    const params = new URLSearchParams(location.search);
    params.set('tab', next);
    navigate({ search: params.toString() }, { replace: true });
  }

  const defaultTokenEmptyValue = '-';
  const token = data?.getUserRewards?.tokenAmount ?? defaultTokenEmptyValue;

  return (
    <>
      <Card type="well" className={classes['total-earned']}>
        <Spacer justify="center" direction="vertical" align="center">
          <Typography.Title level={4}>
            <Image
              src={MenuSVG}
              alt={t('dashboard:menu-description')}
              className={classes.icon}
              preview={false}
            />
            {t('dashboard:total_earned')}
          </Typography.Title>

          <Typography.Text
            className={classes.kvt}
          >{`${token} KVT`}</Typography.Text>
          <Button
            onClick={() => setIsRewardDetailVisible(true)}
            type="primary"
            className={classes['rewars-button']}
          >
            {t('dashboard:see_history')}
          </Button>
        </Spacer>
      </Card>

      <Modal
        onCancel={() => setIsRewardDetailVisible(false)}
        open={isRewardDetailVisible}
        destroyOnClose
        footer={null}
      >
        <UserRewardList
          noRewardActions={
            <Spacer direction="vertical" space={1}>
              <LinkAsButton to={Routes.CommunityResearches}>
                {t('rewardPoint:noRewards.goToReviews')}
              </LinkAsButton>
              <Button
                onClick={() => {
                  setIsRewardDetailVisible(false);
                  setTab(UserInfoTabs.portfolios);
                }}
              >
                {t('rewardPoint:noRewards.goToPortfolio')}
              </Button>
            </Spacer>
          }
        />
      </Modal>
    </>
  );
}

export default TotalEarned;
