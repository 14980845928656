import React, { SVGProps } from 'react';

interface FacebookLogoSVGComponentProps extends SVGProps<SVGSVGElement> {}

export function FacebookLogoSVGComponent(props: FacebookLogoSVGComponentProps) {
  return (
    <svg
      width="9"
      height="16"
      viewBox="0 0 9 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M7.86926 8.93204L8.3057 6.05825H5.57558V4.19417C5.57558 3.40777 5.95626 2.64078 7.17904 2.64078H8.42105V0.194175C8.42105 0.194175 7.2944 0 6.21774 0C3.96828 0 2.4994 1.3767 2.4994 3.86796V6.05825H0V8.93204H2.4994V15.8796C3.0012 15.9592 3.51454 16 4.03749 16C4.56044 16 5.07378 15.9592 5.57558 15.8796V8.93204H7.86926Z"
        fill="current"
      />
    </svg>
  );
}
