import Routes from 'business/router/routes';
import {
  ASK_FOR_EMAIL_VALIDATION,
  ASK_FOR_PASSWORD_RESET,
  LOGOUT_USER,
  RESET_PASSWORD,
  SIGN_IN_USER,
  SIGN_UP_USER,
  VALIDATE_EMAIL,
} from 'business/user/services/queries.gql';
import { fetchUser } from 'business/user/services/user';
import { Me } from 'business/user/types/user';
import client from 'technical/graphql/client';
import { i18n } from 'translations';
import { setUserId } from 'technical/analytics';
import {
  RefreshTokenDocument,
  RefreshTokenMutation,
  RefreshTokenMutationVariables,
} from 'generated/graphql';
import { router } from 'business/router';
import errorReporting from 'technical/error-reporting';

let authResult: Me | null = null;
let accessToken: string | null = null;

export const goToLogin = () => {
  router.navigate(Routes.SignIn);
};

export const goToSignUp = () => {
  router.navigate(Routes.SignUp);
};

export function isAuthenticated() {
  return !!authResult;
}

export function getAccessToken() {
  return accessToken;
}

export async function persistAuth(token: string) {
  accessToken = token;
  const user = await fetchUser(client);
  if (user) {
    authResult = user;
  }
}

export async function unpersistAuth(): Promise<void> {
  authResult = null;
  accessToken = null;
  setUserId(undefined);
  errorReporting.removeUser();
  await client.mutate({
    mutation: LOGOUT_USER,
  });
}

// Use a promise cache system to avoid multi call asking token refresh at the same time
let cachedRenewToken: Promise<void> | null = null;

async function renewTokenRequest() {
  // Do not launch the next request with an access token, it may lead
  // to an infinite loop if the current access token is outdated
  accessToken = null;

  try {
    const { data, errors } = await client.mutate<
      RefreshTokenMutation,
      RefreshTokenMutationVariables
    >({
      mutation: RefreshTokenDocument,
      fetchPolicy: 'no-cache',
    });

    if (errors) {
      throw new Error('Token renewal have failed');
    }

    if (data?.queryRefreshToken?.success && data.queryRefreshToken.idToken) {
      await persistAuth(data.queryRefreshToken.idToken);
    } else {
      throw new Error(
        data?.queryRefreshToken?.message || 'Token renewal have failed',
      );
    }
  } catch (err) {
    // avoid keep renewtoken into error state
    cachedRenewToken = null;
    throw err;
  }

  cachedRenewToken = null;
}

// The only public way to ask a renew of the access token
export async function renewToken() {
  if (cachedRenewToken) {
    return cachedRenewToken;
  }

  cachedRenewToken = renewTokenRequest();

  return cachedRenewToken;
}

export const initAuthentication = async () => {
  try {
    await renewToken();
  } catch (error) {
    if (
      error.message !== 'login-required' &&
      error.message !== 'email-not-verified'
    ) {
      errorReporting.error(error);
    }
  }
};

export const signUp = async (
  email: string,
  password: string,
  passwordConfirmation: string,
  registeredFromCampaign?: string,
  referredFromCode?: string,
  hasAgreedToCommercialOffers?: boolean,
  hasCertifiedAgeMajority?: boolean,
) => {
  const { data } = await client.mutate({
    mutation: SIGN_UP_USER,
    variables: {
      email,
      password,
      passwordConfirmation,
      registeredFromCampaign,
      referredFromCode: referredFromCode || undefined,
      hasAgreedToCommercialOffers,
      hasCertifiedAgeMajority,
    },
  });

  if (!data?.signUp?.success) {
    throw new Error(data.signUp.message);
  }
};

export const signIn = async (email: string, password: string) => {
  const { data } = await client.mutate({
    mutation: SIGN_IN_USER,
    variables: {
      email,
      password,
    },
  });

  if (data?.signIn?.success) {
    // 1. Store Token
    await persistAuth(data.signIn.idToken);
    // 2. Go To LoginCallBack so the app can "requestReboostrap" and handle the user as logged-in
    router.navigate(Routes.LoginCallback);
  } else {
    throw new Error(data.signIn.message);
  }
};

export const refreshEmailValidation = async (email: string) => {
  await client.mutate({
    mutation: ASK_FOR_EMAIL_VALIDATION,
    variables: {
      email,
    },
  });
};

export const requestLoginCallback = (): Promise<void> =>
  new Promise<void>((resolve, reject) => {
    if (authResult) {
      return resolve();
    }

    return reject(new Error('An error occured during authentication'));
  });

export const requestEmailVerificationCallback = async (token: string) => {
  // Add the verification of the token
  if (!token) {
    throw new Error(i18n.t('errors.signIn'));
  } else {
    try {
      const { data } = await client.mutate({
        mutation: VALIDATE_EMAIL,
        variables: {
          token,
        },
      });
      const response = data?.validateEmail;

      if (response?.success && response?.idToken) {
        await persistAuth(data.validateEmail.idToken);
        router.navigate(Routes.LoginCallback);
        return;
      }

      if (response?.message === 'email-already-verified') {
        router.navigate(Routes.EmailAlreadyVerified);
        return;
      }

      router.navigate(Routes.EmailVerified);
    } catch (error) {
      throw new Error(
        i18n.t('errors.signIn', {
          context: error.message,
        }),
      );
    }
  }
};

export const getAuthResult = () => authResult;

export const logout = async () => {
  await unpersistAuth();
  // Instead of using History, we use window.location so
  // The app is re-bootstrap and informations concerning our user
  // are up to date: ie he's not here anymore
  window.location.href = Routes.Home;
};

export const forgotPassword = async (email: string) => {
  const { data, errors } = await client.mutate({
    mutation: ASK_FOR_PASSWORD_RESET,
    variables: {
      email,
    },
  });
  if (errors || !data?.askForPasswordReset?.success) {
    throw new Error(i18n.t('errors.generic'));
  }
};

// Token can be null because if the user is logged in he does not require
// a token to reset his password
export const resetPassword = async (
  token: string | null,
  password: string,
  passwordConfirmation: string,
) => {
  const { data, errors } = await client.mutate({
    mutation: RESET_PASSWORD,
    variables: {
      resetPasswordToken: token,
      password,
      passwordConfirmation,
    },
  });

  if (errors) {
    throw new Error(i18n.t('errors.generic'));
  }
  if (!data.resetPassword.success) {
    throw new Error(
      i18n.t('errors.resetPassword', { context: data.resetPassword.message }),
    );
  }

  if (isAuthenticated()) {
    unpersistAuth();
    window.location.href = Routes.SignIn;
  }
};
