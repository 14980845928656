// if we need to display multiple modal at the same time
// we can add a counter who increment everytime disableBodyScroll is called
// and decrement everytime enableBodyScroll is called
// we add and remove only when counter equal zero

let scrollPosition = 0;

export function disableBodyScroll() {
  const body = document.querySelector('body');
  scrollPosition = window.pageYOffset;

  if (body !== null) {
    body.classList.add('scrollDisabled');
    body.style.top = `-${scrollPosition}px`;
  }

  return;
}

export function enableBodyScroll() {
  const body = document.querySelector('body');

  if (body !== null) {
    body.classList.remove('scrollDisabled');
    body.style.removeProperty('top');

    window.scrollTo(0, scrollPosition);
  }

  return;
}
