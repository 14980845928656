import { useFilters } from 'technical/filters/use-filters-hook';
import {
  orderByFilter,
  pageFilter,
  stringArrayFilter,
} from 'technical/filters/table-filters';
import { TablePaginationConfig } from 'antd';
import { FilterValue, SorterResult } from 'antd/lib/table/interface';
import { PartialPortfolio } from '../components/portfolio-table';
import { isNullOrUndefined } from 'technical/utils/is-null-or-undefined';

export const usePortfolioTableFilters = () =>
  useFilters({
    page: pageFilter,
    tokenRefs: stringArrayFilter,
    authorIds: stringArrayFilter,
    orderBy: orderByFilter({
      keys: ['d7Perf', 'lastUpdatePerf', 'lastPublicationDate'],
      multiple: false,
      defaultValue: { lastPublicationDate: 'desc' },
    }),
  });

export type PortfolioTableFilters = ReturnType<typeof usePortfolioTableFilters>;

function extractOrderBy(
  sorter: SorterResult<PartialPortfolio> | SorterResult<PartialPortfolio>[],
): PortfolioTableFilters['filtersValues']['orderBy'] {
  if (Array.isArray(sorter)) {
    return null;
  }
  if (isNullOrUndefined(sorter.field) || isNullOrUndefined(sorter.order)) {
    return null;
  }
  const key = sorter.field as
    | 'd7Perf'
    | 'lastUpdatePerf'
    | 'lastPublicationDate';
  const value = sorter.order.slice(0, -3) as 'asc' | 'desc';
  return {
    [key]: value,
  } as PortfolioTableFilters['filtersValues']['orderBy'];
}

export function portfolioTableOnChange(
  { current: page }: TablePaginationConfig,
  { tokenRefs, authorIds }: Record<string, FilterValue | null>,
  sorter: SorterResult<PartialPortfolio> | SorterResult<PartialPortfolio>[],
): PortfolioTableFilters['filtersValues'] {
  return {
    page: page ?? 0,
    tokenRefs: tokenRefs?.map(String) ?? null,
    authorIds: authorIds?.map(String) ?? null,
    orderBy: extractOrderBy(sorter),
  };
}
