import React from 'react';
import { Card } from 'ui/cards';
import { Spacer } from 'ui/spacer';
import { useTranslation } from 'react-i18next';
import { Typography } from 'antd';
import { PeerReviewComment } from 'business/peer-review/types';

interface PeerReviewCommentsProps {
  comments: PeerReviewComment[];
  type?: 'default' | 'well';
}

export function PeerReviewComments({
  comments,
  type = 'default',
}: PeerReviewCommentsProps) {
  return (
    <Spacer direction="vertical" space={1}>
      {comments.map((comment) => {
        return (
          <Spacer direction="vertical" space={1} key={comment.id}>
            <Typography.Text strong>
              {comment.commentConfig.label}
            </Typography.Text>
            <Card type={type}>
              <Typography.Text>{comment.content}</Typography.Text>
            </Card>
          </Spacer>
        );
      })}
    </Spacer>
  );
}
