import routes from 'business/router/routes';

enum Env {
  local = 'local',
  ci = 'ci',
  prod = 'production',
  test = 'test',
}

function isEnv(str: string): str is Env {
  return (Object.values(Env) as Array<string>).includes(str);
}

if (!process.env.REACT_APP_GRAPHQL_URI) {
  throw new Error('Set REACT_APP_GRAPHQL_URI');
}

if (!process.env.REACT_APP_GRAPHQL_WS_URI) {
  throw new Error('Set REACT_APP_GRAPHQL_WS_URI');
}

if (!process.env.REACT_APP_GOOGLE_TAG_MANAGER_CONTAINER_ID) {
  throw new Error('Set REACT_APP_GOOGLE_TAG_MANAGER_CONTAINER_ID');
}

if (!process.env.REACT_APP_ENVIRONMENT) {
  throw new Error('Set REACT_APP_ENVIRONMENT');
}

if (
  process.env.REACT_APP_ENVIRONMENT &&
  !isEnv(process.env.REACT_APP_ENVIRONMENT)
) {
  throw new Error('REACT_APP_ENVIRONMENT is not recognized');
}

if (!process.env.REACT_APP_ERC20_ADDRESS) {
  throw new Error('Set REACT_APP_ERC20_ADDRESS');
}
if (!process.env.REACT_APP_LOCKING_ADDRESS) {
  throw new Error('Set REACT_APP_LOCKING_ADDRESS');
}
if (!process.env.REACT_APP_CHAIN_ID) {
  throw new Error('Set REACT_APP_CHAIN_ID');
}
if (!process.env.REACT_APP_FEEDBACKFISH_PROJECT_ID) {
  throw new Error('Set REACT_APP_FEEDBACKFISH_PROJECT_ID');
}
if (!process.env.REACT_APP_ITB_API_KEY) {
  throw new Error('Set REACT_APP_ITB_API_KEY');
}

const env = process.env.REACT_APP_ENVIRONMENT;

const config = {
  env,
  graphqlUri: process.env.REACT_APP_GRAPHQL_URI,
  graphqlWsUri: process.env.REACT_APP_GRAPHQL_WS_URI,
  adsense: {
    active: process.env.REACT_APP_SHOW_ADS === 'true',
    client: process.env.REACT_APP_ADSENSE_CLIENT,
    slots: {
      homepage: process.env.REACT_APP_ADSENSE_SLOT_HOMEPAGE,
    },
  },
  analytics: {
    enabled: process.env.REACT_APP_ANALYTICS_ENABLED === 'true',
    gtm: process.env.REACT_APP_GOOGLE_TAG_MANAGER_CONTAINER_ID,
  },
  itb: {
    key: process.env.REACT_APP_ITB_API_KEY,
  },
  feedback: {
    projectId: process.env.REACT_APP_FEEDBACKFISH_PROJECT_ID,
  },
  contact: {
    email: '...',
    credits: `© ${new Date().getFullYear()} Kryptview. All right reserved`,
  },
  links: {
    about: 'https://corporate.kryptview.com/',
    learning: 'https://learn.kryptview.com/',
    careers: 'https://www.linkedin.com/company/kryptview/',

    home: routes.Home,
    dashboard: routes.Dashboard,
    portfolio: routes.CommunityPortfolios,
    scorings: routes.CommunityResearches,
    partnerships:
      'https://docs.google.com/forms/d/e/1FAIpQLSc-3r9DMs4ye6xvA0kIKXMLLTlXUHx-mT5GnU5ENJqWsDSKfg/viewform?usp=sf_link',

    whitepaper: 'https://corporate.kryptview.com/coming-soon/',
    privacyPolicy: 'https://corporate.kryptview.com/privacy-policy/',
    termsOfUse: 'https://corporate.kryptview.com/terms-of-use/',
    legal: 'https://corporate.kryptview.com/legal-terms/',
    ratingScale:
      'https://docs.kryptview.com/the-platform/kryptviews-scoring-formula',

    help: 'https://discord.gg/ch7sbbQzQc',
    documentation: 'https://docs.kryptview.com',
    scoreTokenDocumentation:
      'https://docs.kryptview.com/the-platform/score-a-token',
    faqs: '',
    contact: 'mailto:contact@kryptview.com',

    discord: 'https://discord.gg/ch7sbbQzQc',
    telegram: 'https://t.me/Kryptviewportal',
    twitter: 'https://twitter.com/theKryptview',
    crew3: 'https://kryptview.crew3.xyz/',
  },
  smartContracts: {
    chainId: +process.env.REACT_APP_CHAIN_ID,
    erc20Address: process.env.REACT_APP_ERC20_ADDRESS,
    lockingAddress: process.env.REACT_APP_LOCKING_ADDRESS,
  },
  features: {
    showITBData: process.env.REACT_APP_SHOW_ITB_DATA === 'true',
    useNewHeader: process.env.REACT_APP_USE_NEW_HEADER === 'true',
    replaceArticlesWithBounties:
      process.env.REACT_APP_REPLACE_ARTICLES_WITH_BOUNTIES === 'true',
    useSpecificDisagree: process.env.REACT_APP_USE_SPECIFIC_DISAGREE === 'true',
    isPowerSearchActivated:
      process.env.REACT_APP_POWER_SEARCH_ACTIVATED === 'true',
    areContributionCreditsActive:
      process.env.REACT_APP_CONTRIBUTION_CREDIT_ACTIVATED === 'true',
    isPortfolioPerformanceSortingActive:
      process.env.REACT_APP_PORTFOLIO_PERFORMANCE_SORTING_ACTIVATED === 'true',
    isInHibernation: process.env.REACT_APP_HIBERNATION === 'true',
  },
  signModalCloseDelayInHours: parseInt(
    process.env.SIGN_MODAL_CLOSE_DELAY_IN_HOURS ?? '12',
    10,
  ),
};

export default config;
