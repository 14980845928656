import colors from 'ui/theme/variables.module.scss';
import React from 'react';

interface Props {
  error?: string;
}

const InputError = ({ error }: Props) => {
  if (!error) {
    return null;
  }

  return (
    <div data-test-id="error-message" style={{ color: colors.errorColor }}>
      {error}
    </div>
  );
};

export default InputError;
