import React, { useState } from 'react';
import { Me } from 'business/user/types/user';
import { WalletConnectButton } from 'business/user/components/wallet-connect-button';

interface WalletMenuProps {
  user: Me | undefined;
}
export function WalletMenu({ user }: WalletMenuProps) {
  return (
    <WalletConnectButton
      user={user}
      kvtAddress="0xFCBE144427Ce3bD47BB2cDae33c30aC761b4AADC"
    />
  );
}
