import React, { PropsWithChildren, ReactNode, useEffect } from 'react';
import { Modal as AntdModal, ModalProps as AntdModalProps } from 'antd';
import classNames from 'classnames';
import classes from './index.module.scss';
import {
  disableBodyScroll,
  enableBodyScroll,
} from 'technical/utils/body-scroll';

export const Modal = ({
  className,
  children,
  ...rest
}: PropsWithChildren<AntdModalProps>) => {
  useEffect(() => {
    if (rest.open) {
      // disabled body scroll
      disableBodyScroll();
    } else {
      enableBodyScroll();
    }
  }, [rest.open]);

  return (
    <AntdModal
      {...rest}
      className={classNames(classes.antModal, className, classes.customModal)}
      wrapClassName={classes.antModalWrap}
    >
      {children}
    </AntdModal>
  );
};
