import { MailOutlined } from '@ant-design/icons';
import { Auth0ParseHashError } from 'auth0-js';
import { useAppContext } from 'business/app-bootstrapper';
import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import logger from 'technical/logger';
import { Spacer } from 'ui/spacer';
import Loader from 'ui/loader';
import { Result } from 'antd';
import { useParams } from 'react-router-dom';
import authService from 'business/user/services/authentication.gql';

interface ValidateEmailRouteProps {
  token?: string;
}

function ValidateEmailProviderCallbackScreen() {
  const params = useParams<'*'>();
  const [error, setError] = useState<Auth0ParseHashError | undefined>(
    undefined,
  );
  const { t } = useTranslation(['common']);
  const { requestRebootstrap } = useAppContext();

  const token = params['*'] || null;

  useEffect(() => {
    authService
      .requestEmailVerificationCallback(token!)
      .then(() => requestRebootstrap())
      .catch((err) => {
        logger.error(err);
        setError(err);
      });
  }, [requestRebootstrap]);

  if (error) {
    let errorKey = '';

    switch (error.errorDescription) {
      case 'invalid-email-domain':
      case 'email-not-verified':
        errorKey = error.errorDescription;
        break;
      default:
        break;
    }

    return (
      <Spacer
        style={{ flex: 1, padding: '20px' }}
        justify="center"
        align="center"
      >
        <Result
          icon={<MailOutlined />}
          title={t('notConnected.login.callback.error.title', {
            context: errorKey,
          })}
          subTitle={t('notConnected.login.callback.error.subTitle', {
            context: errorKey,
          })}
        />
      </Spacer>
    );
  }
  return (
    // Shortly displaying loading indicator, before either displaying error
    // or redirecting to home page
    <Loader />
  );
}

export default ValidateEmailProviderCallbackScreen;
