import React from 'react';
import { Tabs, TabPane } from 'ui/tabs';
import { useTranslation } from 'react-i18next';
import { PlaceHolder } from 'ui/place-holder';
import { TokenAboutTab, TokenAboutTabProps } from './token-about-tab';
import { TokenMarketTab, TokenMarketTabProps } from './token-market-tab';

interface InfosPanelProps {
  about?: TokenAboutTabProps;
  market: TokenMarketTabProps;
  transactions?: null;
  wallets?: null;
  aboutClassName?: string;
}

const tabsKeys: {
  key: keyof InfosPanelProps;
  Element: (props: InfosPanelProps) => JSX.Element | null;
}[] = [
  {
    key: 'about',
    Element: ({ about, aboutClassName }: InfosPanelProps) => (
      <TokenAboutTab className={aboutClassName} {...about} />
    ),
  },
  {
    key: 'market',
    Element: ({ market }) => <TokenMarketTab {...market} />,
  },
  {
    key: 'transactions',
    Element: () => <PlaceHolder style={{ height: 200 }} />,
  },
  // { key: 'wallets', Element: () => <PlaceHolder style={{ height: 200 }} /> },
];

export function InfosPanel(props: InfosPanelProps) {
  const { t } = useTranslation(['token']);

  return (
    <Tabs>
      {tabsKeys.map(({ key, Element }) => (
        <TabPane key={key} tab={t('token:infos.tab', { context: key })}>
          <Element {...props} />
        </TabPane>
      ))}
    </Tabs>
  );
}
