import React, { ReactNode } from 'react';
import { Tabs as TabsUi } from 'antd';
import { TabsProps } from 'antd/es';
import classes from './index.module.scss';

interface TabsPropsTypes extends TabsProps {
  children?: ReactNode;
}

export const Tabs = ({ children, ...props }: TabsPropsTypes) => (
  <TabsUi className={classes.tabs} {...props}>
    {children}
  </TabsUi>
);

export const { TabPane } = TabsUi;
