import React from 'react';
import ThemeSwitcher from 'ui/header/theme-switcher';
import { Spacer } from 'ui/spacer';

// we try to implement with the ant Segmented component but use theme and localStorage do not work
export default function ThemeSwitcherSegmented() {
  return (
    <Spacer justify="center">
      <ThemeSwitcher size="large" />
    </Spacer>
  );
}
