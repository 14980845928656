import React from 'react';
import classes from 'business/onboarding/components/onboarding/index.module.scss';
import { Trans, useTranslation } from 'react-i18next';
import { TwitterShareButton } from 'react-twitter-embed';
import { Spacer } from 'ui/spacer';
import Button from 'ui/button';
import { JoinDiscordButton } from 'business/onboarding/components/join-discord-button';
import {
  OnboardingStepEnum,
  OnboardingStepEnumType,
} from 'business/onboarding/components/onboarding/types';

interface OnboardingStepSocialsMediaProps {
  setSkipped: () => void;
}
const OnboardingStepSocialsMediaContent: React.VFC<OnboardingStepSocialsMediaProps> = () => {
  const { t } = useTranslation(['onboarding']);
  return (
    <>
      <JoinDiscordButton />
      <Trans
        t={t}
        i18nKey="onboarding:socials-media.share-on-twitter"
        components={{
          span: <span className={classes['share-on-twitter-text']} />,
          tweet: (
            <TwitterShareButton
              options={{
                text: t('onboarding:socials-media.tweet-content'),
                size: 'small',
              }}
              url={t('onboarding:socials-media.tweet-hashtags')}
            />
          ),
        }}
      />
    </>
  );
};

const OnboardingStepSocialsMediaTitle: React.VFC<{
  onboardingStepStatus: OnboardingStepEnumType;
}> = ({ onboardingStepStatus }) => {
  const { t } = useTranslation(['onboarding']);

  return (
    <>
      {t('onboarding:socials-media.title', { context: onboardingStepStatus })}
    </>
  );
};

export const OnboardingStepSocialsMedia = {
  Step: OnboardingStepSocialsMediaContent,
  Title: OnboardingStepSocialsMediaTitle,
};
