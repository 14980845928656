import React from 'react';
import { BetaWaitingScreen } from 'business/beta-waiting-screen/pages';
import EmailAlreadyVerifiedPage from 'business/user/pages/email-already-verified';
import EmailVerifiedPage from 'business/user/pages/email-verified';
import ForgotPasswordPage from 'business/user/pages/forgot-password';
import LoginProviderCallbackScreen from 'business/user/pages/login-provider-callback';
import ResetPasswordPage from 'business/user/pages/reset-password';
import SignUpPage from 'business/user/pages/sign-up';
import ValidateEmailProviderCallbackScreen from 'business/user/pages/validate-email-callback';
import { createBrowserRouter, Navigate, Outlet } from 'react-router-dom';
import Routes from './routes';
import Page from 'ui/layout/page';
import { KryptviewFooter } from 'ui/footer';
import HomePage, { BraveHomepage } from 'business/home/pages/home';
import { DataWarningBanner } from './data-warning-banner';
import { BitcoinInfosPage } from 'business/token/pages/bitcoin-infos';
import { TokenInfosPage } from 'business/token/pages/infos';
import { CommunityPortfoliosPage } from 'business/portfolio/pages/community-portfolios';
import PersonalPortfoliosPage from 'business/user/pages/personal-portfolios';
import { TokenResearchesPage } from 'business/token/pages/researches';
import { ResearchHistoryPage } from 'business/user/pages/research-history';
import { ResearchTokenPage } from 'business/research/pages/research-token';
import { PublicAccountPage } from 'business/user/pages/public-account';
import { CommunityResearchesPage } from 'business/research/pages/сommunity-researches';
import { LeaderBoardPage } from 'business/user/pages/leaderboard';
import { DashboardPage } from 'business/user/pages/dashboard';
import { PeerReviewPage } from 'business/peer-review/pages';
import ReportingNoMatch from 'technical/router/switch/reporting-no-match';
import { Protect } from './protect-route';
import SignInPage from 'business/user/pages/sign-in';
import { ConnectionCard } from 'business/user/components/connection-card';
import { PowerSearchPage } from 'business/token/pages/power-search';
import config from 'config';

export const router = createBrowserRouter([
  ...(config.features.isInHibernation
    ? []
    : [
        {
          // layout route
          // https://reactrouter.com/en/main/route/route#layout-routes
          element: (
            <Protect authentication="not-connected">
              <Outlet />
            </Protect>
          ),
          children: [
            {
              path: Routes.Soon,
              element: <BetaWaitingScreen />,
            },
            {
              path: Routes.LoginCallback,
              element: <LoginProviderCallbackScreen />,
            },
            {
              path: Routes.SignUp,
              element: <SignUpPage />,
            },
            {
              path: Routes.SignIn,
              element: <SignInPage />,
            },
            {
              path: Routes.ForgotPassword,
              element: <ForgotPasswordPage />,
            },
            {
              path: Routes.ResetPassword,
              element: <ResetPasswordPage />,
            },

            {
              path: Routes.ValidateEmail,
              element: <ValidateEmailProviderCallbackScreen />,
            },
            {
              path: Routes.EmailAlreadyVerified,
              element: <EmailAlreadyVerifiedPage />,
            },
            {
              path: Routes.EmailVerified,
              element: <EmailVerifiedPage />,
            },
          ],
        },
      ]),
  {
    element: (
      <Page
        footer={<KryptviewFooter />}
        subHeader={<DataWarningBanner />}
        topElement={
          config.features.isInHibernation ? undefined : <ConnectionCard />
        }
      >
        <Outlet />
      </Page>
    ),
    children: [
      { path: '*', element: <ReportingNoMatch /> },
      {
        path: Routes.Home,
        element: <HomePage />,
      },
      {
        path: Routes.BraveLanding,
        element: <BraveHomepage />,
      },
      {
        path: Routes.BitcoinToken,
        element: <BitcoinInfosPage />,
      },
      {
        path: Routes.token,
        element: <TokenInfosPage />,
      },
      {
        path: Routes.CommunityPortfolios,
        element: <CommunityPortfoliosPage />,
      },
      {
        path: Routes.PowerSearch,
        element: <PowerSearchPage />,
      },
      ...(config.features.isInHibernation
        ? [
            {
              path: Routes.PublicProfile,
              element: <PublicAccountPage />,
            },
            {
              path: Routes.Leaderboard,
              element: <LeaderBoardPage />,
            },
          ]
        : [
            {
              element: (
                <Protect authentication="connected">
                  <Outlet />
                </Protect>
              ),
              children: [
                {
                  path: Routes.PersonalPortfolio,
                  element: <PersonalPortfoliosPage />,
                },
                {
                  path: Routes.ResearchHistory,
                  element: <ResearchHistoryPage />,
                },
                {
                  path: Routes.ConnectedResetPassword,
                  element: <ResetPasswordPage />,
                },
                // TODO: This feature has been disabled to be better handled
                // {
                //   path: Routes.ChangeEmail,
                //   component: ConnectedHoc(ChangeEmailPage),
                // },
                {
                  path: Routes.PublicProfile,
                  element: <PublicAccountPage />,
                },
                {
                  path: Routes.CommunityResearches,
                  element: <CommunityResearchesPage />,
                },
                {
                  path: Routes.ResearchToken,
                  element: <ResearchTokenPage />,
                },
                {
                  path: Routes.BitcoinResearchHistory,
                  element: <Navigate to={Routes.BitcoinToken} replace />,
                },
                {
                  path: Routes.BitcoinTokenResearches,
                  element: <Navigate to={Routes.BitcoinToken} replace />,
                },
                {
                  path: Routes.tokenResearches,
                  element: <TokenResearchesPage />,
                },
                {
                  path: Routes.Leaderboard,
                  element: <LeaderBoardPage />,
                },
                {
                  path: Routes.Dashboard,
                  element: <DashboardPage />,
                },
                {
                  path: Routes.PeerReviewPage,
                  element: <PeerReviewPage />,
                },
              ],
            },
          ]),
    ],
  },
]);
