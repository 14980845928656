import { Typography } from 'antd';
import { PeerReviewAnswersQuestion } from 'business/peer-review/types';
import { Trans, useTranslation } from 'react-i18next';
import { Spacer } from 'ui/spacer';
import { PeerReviewFormWizard } from '../peer-review-form-wizard';

import classes from './index.module.scss';

export interface PeerReviewCategoryTabProps {
  categoryName: string;
  answers: PeerReviewAnswersQuestion[];
  actionButtons?: (answerId: string, answerHasSources: boolean) => JSX.Element;
}

export function PeerReviewCategoryTab({
  categoryName,
  answers,
  actionButtons,
}: PeerReviewCategoryTabProps) {
  const { t } = useTranslation(['review']);

  return (
    <Spacer direction="vertical" space={1}>
      <div className={classes.description}>
        <Trans
          t={t}
          i18nKey="review:description"
          values={{
            categoryName,
          }}
          components={{
            highlight: <Typography.Text strong className={classes.highlight} />,
          }}
        />
      </div>

      <Spacer direction="vertical" space={2}>
        <PeerReviewFormWizard answers={answers} actionButtons={actionButtons} />
      </Spacer>
    </Spacer>
  );
}
