import React, { useEffect } from 'react';
import {
  FormikConfig,
  FormikProps,
  FormikValues,
  useFormikContext,
  Formik,
} from 'formik';

import { Form as AntdForm, FormProps as AntdFormProps } from 'antd';
import { useDebouncedCallback } from 'use-debounce';

export type FormProps<FormValues> = Omit<
  FormikConfig<FormValues>,
  'children' | 'render'
> &
  Pick<AntdFormProps, 'size' | 'layout'> & {
    children: (props: FormikProps<FormValues>) => React.ReactNode;
    className?: string;
  };

type AutosaveProps = {
  debounceMs?: number;
};

function isAnyFieldTouched(obj: object): boolean {
  if (typeof obj !== 'object') {
    return false;
  }

  return Object.values(obj).some((elem) =>
    typeof elem === 'boolean' ? elem : isAnyFieldTouched(elem),
  );
}

function Autosave({ debounceMs = 400 }: AutosaveProps) {
  const formik = useFormikContext();

  const debouncedSubmitCaller = useDebouncedCallback((ctx: typeof formik) => {
    ctx.submitForm();
  }, debounceMs);

  useEffect(() => {
    // TODO: we should find a way to reduce the number of call here
    const isConsideredDirty = formik.dirty || isAnyFieldTouched(formik.touched);
    if (isConsideredDirty) {
      debouncedSubmitCaller(formik);
    }
  }, [debouncedSubmitCaller, formik.values]);

  return null;
}

export function Form<FormValues extends FormikValues>({
  children,
  size,
  layout,
  className,
  ...formikProps
}: FormProps<FormValues>) {
  return (
    <Formik<FormValues> {...formikProps}>
      {(props) => (
        <AntdForm
          className={className}
          size={size}
          layout={layout}
          scrollToFirstError
          onFinish={() => props.handleSubmit()}
        >
          {children(props)}
        </AntdForm>
      )}
    </Formik>
  );
}

Form.Autosave = Autosave;
