import React from 'react';
import { useField } from 'formik';
import { ColProps, ConfigProvider, Form, Select, SelectProps } from 'antd';
import { SelectValue } from 'antd/lib/select';
import { Empty } from 'ui/empty-state';
import { RecursiveFormNames } from '../types';
import { useFieldTranslation } from '../translations';
import styles from './index.module.scss';

export type SelectFieldProps<FormValues> = Required<
  Pick<SelectProps<SelectValue>, 'options'>
> &
  Omit<SelectProps<SelectValue>, 'children' | 'options'> & {
    label: React.ReactNode;
    name: RecursiveFormNames<FormValues>;
    id: string;
    labelCol?: ColProps;
    required?: boolean;
  };

export function SelectField<FormValues>({
  label,
  name,
  id,
  labelCol,
  required = false,
  ...rest
}: SelectFieldProps<FormValues>) {
  const { t } = useFieldTranslation();
  const [{ value }, meta, helpers] = useField<SelectValue>({
    name,
    multiple: !!rest.mode,
  });

  const status = meta.touched && meta.error ? 'error' : 'success';
  const message = status === 'error' ? t(meta.error as any) : '\u00A0';

  return (
    <ConfigProvider renderEmpty={() => <Empty description={t('no-data')} />}>
      <Form.Item
        htmlFor={id}
        label={label}
        validateStatus={status}
        help={message}
        className={styles.form}
        labelCol={labelCol}
        required={required}
      >
        <Select
          {...rest}
          id={id}
          value={value}
          onChange={(newValue, ...args) => {
            rest.onChange?.(newValue, ...args);
            helpers.setValue(newValue);
          }}
          onBlur={(...args) => {
            rest.onBlur?.(...args);
            helpers.setTouched(true);
          }}
        />
      </Form.Item>
    </ConfigProvider>
  );
}
