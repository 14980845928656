import React, { useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  TokenMarketStatisticsInterval,
  useTokenMarketStatisticsQuery,
} from 'generated/graphql';
import { Spacer } from 'ui/spacer';
import { Typography } from 'antd';
import { NumericValue, NumberFormats } from 'ui/numeric';
import { PercentageValue } from 'ui/percentage';
import { isNotNullOrUndefined } from 'technical/utils/is-not-null-or-undefined';
import { Duration, sub } from 'date-fns';
import classNames from 'classnames';
import Select from 'ui/select';
import classes from './index.module.scss';

interface MarketStatisticsFixedValues {
  marketRank?: number | null;
  circulatingSupply?: number | null;
  totalSupply?: number | null;
  researchCount?: number | null;
}

interface MarketStatisticsVariableValues {
  marketCap?: number | null;
  fullyDiluted?: number | null;
  volume?: number | null;
  volumeChangePercentage?: number | null;
}

interface MarketStatisticsExternals {
  fullyDiluted?: number | null;
  maxSupply?: number | null;
  totalSupply?: number | null;
  marketRank?: number | null;
  circulatingSupply?: number | null;
}

function computeFullyDiluted(
  price: number | undefined | null,
  fullyDiluted: MarketStatisticsExternals['fullyDiluted'],
  maxSupply: MarketStatisticsExternals['maxSupply'],
  totalSupply: MarketStatisticsExternals['totalSupply'],
) {
  if (!isNotNullOrUndefined(price)) {
    return fullyDiluted ?? null;
  }

  if (isNotNullOrUndefined(maxSupply)) {
    return price * maxSupply;
  }

  if (isNotNullOrUndefined(totalSupply)) {
    return price * totalSupply;
  }

  return fullyDiluted ?? null;
}

const intervalToDuration: Record<TokenMarketStatisticsInterval, Duration> = {
  day: { days: 1 },
  week: { weeks: 1 },
  week2: { weeks: 2 },
  month: { months: 1 },
  day200: { days: 200 },
  year: { years: 1 },
};

function intervalToResearchDate(interval: TokenMarketStatisticsInterval) {
  const duration = intervalToDuration[interval];
  return sub(Date.now(), duration);
}

function useMarketStatistics(
  tokenId: string | undefined | null,
  externals: MarketStatisticsExternals,
): {
  fixed: MarketStatisticsFixedValues;
  variable: MarketStatisticsVariableValues;
} & {
  interval: TokenMarketStatisticsInterval;
  setInterval: (_: TokenMarketStatisticsInterval) => void;
} {
  const [interval, setInterval] = useState<TokenMarketStatisticsInterval>(
    TokenMarketStatisticsInterval.Day,
  );

  const { data, previousData } = useTokenMarketStatisticsQuery({
    variables: {
      tokenId: tokenId ?? '',
      interval,
    },
    skip: !isNotNullOrUndefined(tokenId),
  });

  const ms =
    data?.token?.market_statistics ?? previousData?.token?.market_statistics;
  const researchCount =
    data?.token?.validatedResearchCount ??
    previousData?.token?.validatedResearchCount;

  const fullyDiluted = useMemo<number | null>(
    () =>
      computeFullyDiluted(
        ms?.price,
        externals.fullyDiluted,
        externals.maxSupply,
        externals.totalSupply,
      ),
    [
      ms?.price,
      externals.fullyDiluted,
      externals.maxSupply,
      externals.totalSupply,
    ],
  );

  return {
    fixed: {
      marketRank: externals.marketRank,
      circulatingSupply: externals.circulatingSupply,
      totalSupply: externals.totalSupply,
      researchCount,
    },
    variable: {
      marketCap: ms?.marketCap,
      fullyDiluted,
      volume: ms?.volume,
      volumeChangePercentage: ms?.volumeChangePercentage,
    },
    interval,
    setInterval,
  };
}

const intervalOptions: TokenMarketStatisticsInterval[] = [
  'day',
  'week',
  'week2',
  'month',
  'day200',
  'year',
];

const valueComponentRecord: Record<
  string,
  (
    values: MarketStatisticsFixedValues & MarketStatisticsVariableValues,
  ) => JSX.Element
> = {
  marketCap: ({ marketCap }) => (
    <NumericValue
      strong
      format={NumberFormats.currency}
      value={marketCap}
      options={{ maximumFractionDigits: 0, minimumFractionDigits: 0 }}
    />
  ),
  volume: ({ volume, volumeChangePercentage }) => (
    <Spacer align="end" direction="vertical" space="none">
      <NumericValue
        strong
        format={NumberFormats.currency}
        value={volume}
        options={{ maximumFractionDigits: 0, minimumFractionDigits: 0 }}
      />
      <PercentageValue value={volumeChangePercentage} />
    </Spacer>
  ),
  fullyDiluted: ({ fullyDiluted }) => (
    <NumericValue
      strong
      format={NumberFormats.currency}
      value={fullyDiluted}
      options={{ maximumFractionDigits: 0, minimumFractionDigits: 0 }}
    />
  ),
  marketRank: ({ marketRank }) => (
    <Typography.Text strong>#{marketRank}</Typography.Text>
  ),
  researchCount: ({ researchCount }) => (
    <Typography.Text strong>{researchCount}</Typography.Text>
  ),
  circulatingSupply: ({ circulatingSupply }) => (
    <Typography.Text strong>{circulatingSupply}</Typography.Text>
  ),
  totalSupply: ({ totalSupply }) => (
    <NumericValue strong format={NumberFormats.currency} value={totalSupply} />
  ),
};

interface TokenMarketStatisticsProps extends MarketStatisticsExternals {
  tokenId: string | undefined | null;
}

export function TokenMarketStatistics({
  tokenId,
  ...externals
}: TokenMarketStatisticsProps) {
  const { t } = useTranslation(['token']);
  const { interval, setInterval, fixed, variable } = useMarketStatistics(
    tokenId,
    externals,
  );

  return (
    <Spacer direction="vertical" space={1}>
      {Object.entries(fixed)
        .filter(([_, value]) => isNotNullOrUndefined(value))
        .map(([key, _], index) => {
          const Element = valueComponentRecord[key];

          if (!Element) {
            return null;
          }

          return (
            <Spacer
              key={key}
              space={1}
              justify="between"
              direction="horizontal"
              className={classNames(
                classes.marketStatistic,
                index % 2 === 0 ? classes.wellBackground : undefined,
              )}
            >
              <Typography style={{ flex: 'none' }}>
                {t('token:market-statistics.line', { context: key })}
              </Typography>
              <Element {...fixed} />
            </Spacer>
          );
        })}

      <Select<TokenMarketStatisticsInterval>
        value={interval}
        handleChange={setInterval}
        options={intervalOptions.map((option) => ({
          key: option,
          value: option,
          label: t('token:market-statistics.interval', { context: option }),
        }))}
        className={classes.select}
      />

      {Object.entries(variable)
        .filter(([_, value]) => isNotNullOrUndefined(value))
        .map(([key, _], index) => {
          const Element = valueComponentRecord[key];

          if (!Element) {
            return null;
          }

          return (
            <Spacer
              key={key}
              space={1}
              justify="between"
              direction="horizontal"
              className={classNames(
                classes.marketStatistic,
                index % 2 === 0 ? classes.wellBackground : undefined,
              )}
            >
              <Typography style={{ flex: 'none' }}>
                {t('token:market-statistics.line', { context: key })}
              </Typography>
              <Element {...variable} />
            </Spacer>
          );
        })}
    </Spacer>
  );
}
