import React, { FC } from 'react';
import Icon from '@ant-design/icons';
import type { CustomIconComponentProps } from '@ant-design/icons/lib/components/Icon';

const ArrowUpSvg = () => (
  <svg
    focusable="false"
    viewBox="0 0 9 6"
    data-icon="caret-up"
    width="1em"
    height="1em"
    fill="currentColor"
    aria-hidden="true"
  >
    <path d="M3.70822 0.501589L0.183267 4.53C-0.23273 5.00553 0.10536 5.74933 0.736705 5.74933H7.78661C7.9279 5.74945 8.06624 5.70884 8.18505 5.63236C8.30386 5.55589 8.39811 5.44678 8.4565 5.31812C8.5149 5.18946 8.53498 5.04669 8.51432 4.90691C8.49367 4.76714 8.43316 4.63627 8.34005 4.53L4.8151 0.502324C4.74611 0.42338 4.66103 0.360109 4.56558 0.316757C4.47012 0.273405 4.3665 0.250977 4.26166 0.250977C4.15682 0.250977 4.05319 0.273405 3.95774 0.316757C3.86228 0.360109 3.77721 0.42338 3.70822 0.502324V0.501589Z" />
  </svg>
);

export const ArrowUpFilled: FC<Partial<CustomIconComponentProps>> = (props) => (
  <Icon component={ArrowUpSvg} {...props} />
);
