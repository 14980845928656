import { PeerReviewQuestion } from 'business/peer-review/types';
import { DraftQuestion } from 'generated/graphql';
import { useTranslation } from 'react-i18next';
import { isNotNullOrUndefined } from 'technical/utils/is-not-null-or-undefined';
import { Spacer } from 'ui/spacer';

export interface QuestionToolTipProps {
  question: DraftQuestion | PeerReviewQuestion;
}

export function QuestionHelperToolTip({ question }: QuestionToolTipProps) {
  const { t } = useTranslation('form-wizard');

  const someChoiceHasComment =
    'choices' in question &&
    question.choices.some(({ comment }) => isNotNullOrUndefined(comment));

  return (
    <Spacer direction="vertical" space={1} style={{ padding: '4px 8px' }}>
      {question.description ? (
        <p>
          {t('form-wizard:questions.tooltip.description')}
          <br />
          {question.description}
        </p>
      ) : null}
      {question.tip ? (
        <p>
          {t('form-wizard:questions.tooltip.tip')}
          <br />
          {question.tip}
        </p>
      ) : null}
      {someChoiceHasComment ? (
        <p>
          {t('form-wizard:questions.tooltip.possible-answers')}
          <br />
          {'choices' in question
            ? question.choices.map((choice) => (
                <li key={choice.id}>
                  &quot;{choice.value}&quot;: {choice.comment ?? '-'}
                </li>
              ))
            : null}
        </p>
      ) : null}
    </Spacer>
  );
}
