import { Typography } from 'antd';
import ExternalLink from 'antd/lib/typography/Link';
import { useAppContext } from 'business/app-bootstrapper';
import { isRestricted } from 'business/user/services/is-restricted';
import { UserRole } from 'business/user/types/user';
import classnames from 'classnames';
import config from 'config';
import { FunctionComponent } from 'react';
import { useTranslation } from 'react-i18next';
import { isInternalLink } from 'technical/utils/is-internal-link';
import InternalLink from 'ui/link';
import { Spacer } from 'ui/spacer';
import Tag from 'ui/tag';
import { FooterKryptviewSVGComponent } from './footer-kryptview-s-v-g-component';
import classes from './index.module.scss';
import { SocialsCard } from './socials';

const contents = {
  ...config.links,
};

type ContentEntry = keyof typeof contents;

type FooterContentEntry<Content = ContentEntry> = {
  content: Content;
  accessControl?: UserRole[] | string[]; // UserRole allow auto complete while string[] match useRoles type
  hide?: boolean;
  isComingSoon?: boolean;
  newTab?: boolean;
};

const services: FooterContentEntry[] = [
  { content: 'home' },
  { content: 'dashboard', accessControl: ['user'], hide: true }, // hide only when restricted
  { content: 'portfolio' },
  { content: 'scorings', accessControl: ['user', 'anonymous'], hide: true },
  {
    content: 'partnerships',
    newTab: true,
    accessControl: ['user', 'anonymous'],
    hide: true,
  },
];
const resources: FooterContentEntry[] = [
  { content: 'whitepaper', isComingSoon: true, newTab: true },
  { content: 'privacyPolicy', newTab: true },
  { content: 'termsOfUse', newTab: true },
  { content: 'legal', newTab: true },
];
const support: FooterContentEntry[] = [
  {
    content: 'help',
    newTab: true,
    accessControl: ['user', 'anonymous'],
    hide: true,
  },
  { content: 'documentation', newTab: true },
  { content: 'faqs', accessControl: [] },
  { content: 'contact' },
];
const generals: FooterContentEntry[] = [
  { content: 'about', newTab: true },
  { content: 'learning', newTab: true },
  { content: 'careers', newTab: true },
];

const SoonMark: FunctionComponent = () => {
  const { t } = useTranslation(['common']);
  return <Tag className={classes.soonMark}>{t('footer.soon')}</Tag>;
};

interface ContentColumnProps {
  i18nKey:
    | 'footer.services'
    | 'footer.resources'
    | 'footer.support'
    | 'footer.generals';
  footerContents: FooterContentEntry[];
}

const ContentColumn: FunctionComponent<ContentColumnProps> = ({
  i18nKey,
  footerContents,
}) => {
  const { t } = useTranslation(['common']);
  const { user } = useAppContext();
  const roles = user?.roles ?? [];
  return (
    <Spacer direction="vertical" space={2} className={classes.columnContainer}>
      <Typography className={classes.title}>
        {t(i18nKey, { context: 'title', defaultValue: 'title' })}
      </Typography>

      <Spacer direction="vertical" space={1}>
        {footerContents.map((footerContent) => {
          const userIsRestricted = isRestricted(
            roles,
            footerContent.accessControl,
          );
          const link = contents[footerContent.content];
          if (userIsRestricted && footerContent.hide) {
            return null;
          }

          return (
            <Spacer
              key={footerContent.content}
              direction="horizontal"
              space={1}
              align="center"
            >
              {isInternalLink(link) ? (
                <InternalLink
                  to={link}
                  className={classnames(classes.link, {
                    [classes.disabled]: userIsRestricted,
                  })}
                  underline={false}
                >
                  {t(i18nKey, {
                    context: footerContent.content,
                    defaultValue: footerContent.content,
                  })}
                </InternalLink>
              ) : (
                <ExternalLink
                  href={link}
                  disabled={userIsRestricted}
                  target={footerContent.newTab ? '_blank' : '_self'}
                  rel="noopener noreferrer"
                  className={classnames(classes.link, {
                    [classes.disabled]: userIsRestricted,
                  })}
                >
                  {t(i18nKey, {
                    context: footerContent.content,
                    defaultValue: footerContent.content,
                  })}
                </ExternalLink>
              )}

              {footerContent.isComingSoon && <SoonMark />}
            </Spacer>
          );
        })}
      </Spacer>
    </Spacer>
  );
};

export function KryptviewFooter() {
  return (
    <Spacer className={classes.wrapper} direction="vertical" space={4}>
      <div className={classes.grid}>
        <div className={classes.logo}>
          <FooterKryptviewSVGComponent className={classes.logo} />
        </div>

        <div
          hidden={config.features.isInHibernation}
          className={classes.socials}
        >
          <SocialsCard />
        </div>

        <div className={classes.services}>
          <ContentColumn i18nKey="footer.services" footerContents={services} />
        </div>

        <div className={classes.resources}>
          <ContentColumn
            i18nKey="footer.resources"
            footerContents={resources}
          />
        </div>

        <div className={classes.support}>
          <ContentColumn i18nKey="footer.support" footerContents={support} />
        </div>

        <div className={classes.general}>
          <ContentColumn i18nKey="footer.generals" footerContents={generals} />
        </div>
      </div>

      <Spacer justify={{ default: 'center', md: 'start' }}>
        <Typography className={classes.allRightsReserved}>
          {config.contact.credits}
        </Typography>
      </Spacer>
    </Spacer>
  );
}
