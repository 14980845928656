import React from 'react';
import {
  GetUserLastResearchOnTokenQuery,
  useGetUserLastResearchOnTokenQuery,
  ResearchResearchStatusEnumEnum,
} from 'generated/graphql';
import { add, isFuture } from 'date-fns';
import { generatePath } from 'react-router-dom';
import { useAppContext } from 'business/app-bootstrapper';
import { useTranslation } from 'react-i18next';
import Button, { LinkAsButton } from 'ui/button';
import Routes from 'business/router/routes';
import { Spacer } from 'ui/spacer';
import { Tooltip } from 'antd';
import classes from './index.module.scss';
import config from 'config';

export type CreateResearchStatus = 'create' | 'resume' | 'disabled';

export function getNextAvailableResearchTime(
  query: GetUserLastResearchOnTokenQuery | undefined,
) {
  const lastResearch = query?.research[0];

  if (!lastResearch?.publishedAt) {
    return undefined;
  }

  return add(new Date(lastResearch.publishedAt), {
    hours: lastResearch.token.hoursBetweenResearches,
  });
}

export function getCreateResearchStatusFromLastResearch(
  query: GetUserLastResearchOnTokenQuery | undefined,
): CreateResearchStatus {
  const lastResearch = query?.research[0];

  if (!lastResearch) {
    return 'create';
  }

  switch (lastResearch.status) {
    case ResearchResearchStatusEnumEnum.Draft:
      return 'resume';

    case ResearchResearchStatusEnumEnum.Published:
    case ResearchResearchStatusEnumEnum.Rejected:
    case ResearchResearchStatusEnumEnum.Validated:
      if (
        lastResearch.publishedAt &&
        isFuture(
          add(new Date(lastResearch.publishedAt), {
            hours: lastResearch.token.hoursBetweenResearches,
          }),
        )
      ) {
        return 'disabled';
      }

      return 'create';

    default:
      return 'disabled';
  }
}

export interface TokenHeaderActionsProps {
  tokenId?: string;
  tokenReference?: string;
}

export function TokenHeaderActions({
  tokenId,
  tokenReference,
}: TokenHeaderActionsProps) {
  const { t } = useTranslation(['token']);
  const { user } = useAppContext();
  const { data: lastResearchData } = useGetUserLastResearchOnTokenQuery({
    skip: !user || !tokenId,
    // we skip the execution until we have the data
    variables: { userId: user?.id!, tokenId: tokenId! },
  });
  if (!tokenId || !tokenReference) {
    return null;
  }

  const createResearchStatus = getCreateResearchStatusFromLastResearch(
    lastResearchData,
  );

  const createResearchLabel = t('token:actions.research', {
    context: createResearchStatus,
  });
  const goToResearchLabel = t('token:actions.go-to-researches');
  const notLoggedUser = t('token:actions.must-be-logged');
  // TODO: update naming for buttons
  const researchActionsToken = user ? (
    <>
      {!config.features.areContributionCreditsActive ||
      user.contributionCredit.credit > 0 ? (
        <LinkAsButton
          block
          type="primary"
          to={generatePath(Routes.ResearchToken, {
            reference: tokenReference,
          })}
        >
          {createResearchLabel}
        </LinkAsButton>
      ) : (
        <Tooltip
          placement="top"
          title={`You do not have enough credit to score a token. You need to review ${user.contributionCredit.numberOfReviewNeededBeforeNextCredit} scorings to win your next credit.`}
        >
          <span // span are needed or tooltip is not visible on disabled button ant-design/issues/1816
            className={classes.disabledButtonWrapper}
          >
            <Button type="primary" block disabled>
              {createResearchLabel}
            </Button>
          </span>
        </Tooltip>
      )}
      <LinkAsButton
        type="primary"
        to={generatePath(Routes.tokenResearches, {
          reference: tokenReference,
        })}
        block
      >
        {goToResearchLabel}
      </LinkAsButton>
    </>
  ) : (
    <>
      <Tooltip placement="top" title={notLoggedUser}>
        <span // span are needed or tooltip is not visible on disabled button ant-design/issues/1816
          className={classes.disabledButtonWrapper}
        >
          <Button type="primary" block disabled>
            {createResearchLabel}
          </Button>
        </span>
      </Tooltip>
      <Tooltip placement="top" title={notLoggedUser}>
        <span className={classes.disabledButtonWrapper}>
          <Button type="primary" block disabled>
            {goToResearchLabel}
          </Button>
        </span>
      </Tooltip>
    </>
  );

  return (
    <Spacer space={1} justify="between" direction="horizontal">
      {researchActionsToken}
    </Spacer>
  );
}
