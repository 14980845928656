import React from 'react';
import { useTranslation } from 'react-i18next';
import { NewProfileBanner } from '../new-profile-banner/new-profile-banner';

interface PublicProfileBannerProps {
  userId: string;
  position: number | string;
}

export function PublicProfileBanner({
  userId,
  position,
}: PublicProfileBannerProps) {
  const { t } = useTranslation(['user']);

  return (
    <NewProfileBanner
      userId={userId}
      noUsernameText={t('user:public-profile.no-username')}
      noDescriptionText={t('user:public-profile.no-description')}
      noSocialNetworksText={t('user:public-profile.no-social-networks')}
      position={position}
    />
  );
}
