import React, { Fragment } from 'react';
import { Wordcloud } from '@visx/wordcloud';
import { Text } from '@visx/text';
import { scaleLog } from '@visx/scale';
import { Group } from '@visx/group';
import variables from 'ui/theme/variables.module.scss';
import classes from './index.module.scss';

export interface WordData {
  text: string;
  value: number;
}

interface WordCloudProps {
  words: WordData[];
  width?: number;
  height?: number;
}

const WordCloud = ({ words, width = 200, height = 200 }: WordCloudProps) => {
  const fontScale = scaleLog({
    domain: [
      Math.min(...words.map((w) => w.value)),
      Math.max(...words.map((w) => w.value)),
    ],
    range: [10, 30],
  });
  const fontSizeSetter = (datum: WordData) => fontScale(datum.value);
  const fixedValueGenerator = () => 0.5;

  return (
    <Wordcloud
      words={words}
      width={width}
      height={height}
      fontSize={fontSizeSetter}
      padding={15}
      spiral="archimedean"
      rotate={0}
      random={fixedValueGenerator}
      font="Source Sans Pro"
    >
      {(cloudWords) => {
        return cloudWords.map((w) => (
          <Group key={w.text}>
            <rect
              x={(w.x ?? 0) * 1.2 - (w as any).width / 2 - 4}
              y={(w.y ?? 0) * 1.2 - ((w as any).height * 0.8) / 2}
              width={(w as any).width + 8}
              height={(w as any).height * 0.8}
              fill={variables.primaryColorSlightlyTransparent}
              rx={8}
            />
            <Text
              key={w.text}
              fill={variables.primaryColor}
              x={(w.x ?? 0) * 1.2}
              y={(w.y ?? 0) * 1.2}
              textAnchor="middle"
              verticalAnchor="middle"
              fontSize={w.size}
              fontFamily={w.font}
              fontWeight={700}
            >
              {w.text}
            </Text>
          </Group>
        ));
      }}
    </Wordcloud>
  );
};

export default WordCloud;
