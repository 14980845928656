import authService from 'business/user/services/authentication.gql';
import Routes from 'business/router/routes';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import ActionCard from 'business/user/components/action-card';
import ResetPasswordCard, {
  Values,
} from 'business/user/components/reset-password-card';
import Link from 'ui/link';
import PageCenteredContent from '../../../../ui/layouts/page-centered-content';

function ForgotPasswordPage() {
  const [errorForgotPassword, setErrorForgotPassword] = useState<string>('');
  const [showSuccess, setShowSuccess] = useState(false);

  const { t } = useTranslation(['common']);

  const handleSubmitClick = async (values: Values) => {
    try {
      setErrorForgotPassword('');
      await authService.requestPasswordReset(values.login);
      setShowSuccess(true);
    } catch (error) {
      setErrorForgotPassword(error.message);
    }
  };

  if (showSuccess) {
    return (
      <PageCenteredContent>
        <ActionCard
          dataTestId="success-forgot-password"
          title={t('pages.forgotPassword.success.title')}
          hint={t('pages.forgotPassword.success.description')}
          btnText={t('pages.common.goToHome')}
        />
      </PageCenteredContent>
    );
  }

  return (
    <PageCenteredContent>
      <ResetPasswordCard
        submitButton={{
          label: t('pages.forgotPassword.submit'),
          onClick: handleSubmitClick,
        }}
        errorResetPassword={errorForgotPassword}
        title={t('pages.forgotPassword.title_question')}
        description={t('pages.forgotPassword.description')}
        inputType="email"
        bottomHint={
          <Link to={Routes.Home}>{t('pages.forgotPassword.backToSignIn')}</Link>
        }
      />
    </PageCenteredContent>
  );
}

export default ForgotPasswordPage;
