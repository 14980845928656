import React, { FC } from 'react';
import { PercentageValue, PercentageValueProps } from 'ui/percentage';
import Tag from 'ui/tag';
import { getAssociatedColor } from 'ui/percentage/services/utils';

interface TagPercentageProps extends PercentageValueProps {
  className?: string;
}

const TagPercentage: FC<TagPercentageProps> = ({
  value,
  className,
  ...rest
}) => {
  const { bgColor } = getAssociatedColor(value);

  return (
    <Tag className={className} color={bgColor}>
      <PercentageValue value={value} {...rest} />
    </Tag>
  );
};

export default TagPercentage;
