/* eslint-disable @typescript-eslint/no-use-before-define */
import { DeleteOutlined, PlusOutlined } from '@ant-design/icons';
import { FieldArray, useFormikContext } from 'formik';
import {
  DraftQuestion,
  MultiChoiceQuestion,
  PartialAnswer,
  SingleChoiceQuestion,
  TagQuestion,
  TokenQuestion,
} from 'generated/graphql';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { match } from 'technical/utils/match';
import Button from 'ui/button';
import { Card } from 'ui/cards';
import { Form } from 'ui/form';
import { SelectField, TextAreaField } from 'ui/form/fields';
import { SingleChoice } from 'ui/form/fields/single-choice';
import TextFieldSelect from 'ui/form/fields/text-field-select';
import { TokenSelectField } from 'ui/form/fields/token-select';
import { Spacer } from 'ui/spacer';
import Tag from 'ui/tag';
import variables from 'ui/theme/variables.module.scss';
import { useDebouncedCallback } from 'use-debounce';
import { areChoiceIdsMatching, hasAnswer } from './helpers';
import classes from './index.module.scss';
import { QuestionHelperToolTip } from './question-helper-tool-tip';
import { buildSchemaFromQuestion } from './question-schema';
import { QuestionFormValue } from './types';

type QuestionCardProps = {
  question: DraftQuestion;
  onSubmit: (values: PartialAnswer) => void;
};

export function QuestionCard({ question, onSubmit }: QuestionCardProps) {
  const { t } = useTranslation(['form-wizard', 'common']);
  // TODO: this component is not in this final state
  const schema = useMemo(() => buildSchemaFromQuestion(question), [question]);

  const isMandatory = question.mandatory;
  const wasAlreadyAnswered = hasAnswer(question);
  const isInvalid = !question.isValid;

  const showError = isInvalid;

  return (
    <Card
      id={`_${question.id}`}
      type="well"
      className={classes.card}
      data-invalid={showError}
    >
      <Spacer space={1} direction="vertical">
        <Spacer space={1} align="center">
          {isMandatory && !wasAlreadyAnswered ? (
            <Tag color="error">{t('form-wizard:missing-answer')}</Tag>
          ) : null}
          {isInvalid && wasAlreadyAnswered ? (
            <Tag color="error">{t('form-wizard:invalid-answer')}</Tag>
          ) : null}
          {isMandatory ? (
            <Tag color={variables.primaryColor}>
              {t('form-wizard:mandatory')}
            </Tag>
          ) : (
            <Tag color={variables.grey300}>{t('form-wizard:optional')}</Tag>
          )}
        </Spacer>
        <Card.Header
          level={3}
          title={question.title}
          tooltip={<QuestionHelperToolTip question={question} />}
        />
      </Spacer>
      <Form<QuestionFormValue>
        initialValues={{
          choiceId: (question as SingleChoiceQuestion).choiceId,
          choiceIds: (question as MultiChoiceQuestion).choiceIds?.map(
            (x) => x.choiceId,
          ),
          tags: (question as TagQuestion).tags?.map((x) => x.value),
          tokenIds: (question as TokenQuestion).tokenIds?.map((x) => x.tokenId),
          sources: question.sources.map((source) =>
            source.content.length === 0
              ? {
                  ...source,
                  content: [{ id: -1, type: source.accepted[0], value: '' }],
                }
              : source,
          ),
          comments: question.comments,
        }}
        // enableReinitialize
        validationSchema={schema}
        onSubmit={(values) => {
          // TODO: add ids here to prevent inserting and deleting a lot when updating answer
          onSubmit({
            questionId: question.id,
            choiceId: values.choiceId,
            choiceIds: values.choiceIds?.map((choiceId) => ({ choiceId })),
            tags: values.tags?.map((value) => ({ value })),
            tokens: values.tokenIds?.map((tokenId) => ({ tokenId })),
            sources: values.sources
              ?.filter(areChoiceIdsMatching(values))
              .flatMap((config) =>
                config.content.map(({ type, value }) => ({
                  configId: config.configId,
                  type,
                  content: value,
                })),
              ),
            comments: values.comments
              ?.filter(areChoiceIdsMatching(values))
              .map((config) => ({
                configId: config.configId,
                content: config.content?.value ?? '',
              })),
          });
        }}
      >
        {({ isValid, submitForm }) => {
          const debouncedSubmitForm = useDebouncedCallback(submitForm, 150);

          return (
            <Spacer space={2} direction="vertical" data-form-invalid={!isValid}>
              {match(
                question.type,
                {
                  multi_choice: ({
                    id,
                    question: q,
                    choices,
                  }: MultiChoiceQuestion) => (
                    <SelectField<QuestionFormValue>
                      id={id}
                      name="choiceIds"
                      required={isMandatory}
                      label={q}
                      showSearch
                      optionFilterProp="label"
                      onChange={() => debouncedSubmitForm()}
                      mode="multiple"
                      options={choices.map((choice) => ({
                        key: choice.id,
                        label: choice.value,
                        value: choice.id,
                      }))}
                      labelCol={{ span: 24 }}
                      showArrow
                    />
                  ),
                  single_choice: ({
                    id,
                    question: q,
                    choices,
                  }: SingleChoiceQuestion) => (
                    <SingleChoice<QuestionFormValue>
                      choices={choices}
                      id={id}
                      name="choiceId"
                      required={isMandatory}
                      label={q}
                      onChange={debouncedSubmitForm}
                      labelCol={{ span: 24 }}
                      size="large"
                    />
                  ),
                  tag: ({ id, question: q }: TagQuestion) => (
                    <SelectField<QuestionFormValue>
                      id={id}
                      name="tags"
                      required={isMandatory}
                      label={q}
                      showSearch
                      onChange={() => debouncedSubmitForm()}
                      mode="tags"
                      options={[]}
                      labelCol={{ span: 24 }}
                    />
                  ),
                  token: ({ id, question: q }: TokenQuestion) => (
                    <TokenSelectField<QuestionFormValue>
                      id={id}
                      name="tokenIds"
                      onChange={() => debouncedSubmitForm()}
                      required={isMandatory}
                      label={q}
                      mode="multiple"
                      placeholder={t('form-wizard:questions.token.placeholder')}
                    />
                  ),
                },
                question,
              )}
              <Sources />
              <Comments />
            </Spacer>
          );
        }}
      </Form>
    </Card>
  );
}

function Sources() {
  const { t } = useTranslation(['form-wizard']);
  const { values, submitForm } = useFormikContext<QuestionFormValue>();
  const { sources } = values;

  const debouncedSubmitForm = useDebouncedCallback(submitForm, 500);

  if (!sources || sources.length === 0) {
    return null;
  }
  // TODO: this component is not in this final state
  return (
    <>
      {sources.map((source, index) => (
        <FieldArray key={source.configId} name={`sources.${index}.content`}>
          {({ push, remove }) => {
            return (
              <Spacer
                hidden={!areChoiceIdsMatching(values)(source)}
                direction="vertical"
                space="none"
              >
                <div className="ant-col ant-col-24 ant-form-item-label">
                  <label
                    htmlFor="nothing"
                    className={
                      source.required ? 'ant-form-item-required' : undefined
                    }
                  >
                    {source.label}
                  </label>
                </div>
                {source.content.map((content, i) => {
                  return (
                    <TextFieldSelect<QuestionFormValue, QuestionFormValue>
                      // eslint-disable-next-line react/no-array-index-key
                      key={`${i}-${content.id}`}
                      inputName={`sources.${index}.content.${i}.value`}
                      label=""
                      id={`source-${content.id}`}
                      selectName={`sources.${index}.content.${i}.type`}
                      onChange={() => debouncedSubmitForm()}
                      size="large"
                      options={source.accepted.map((type) => ({
                        label: type,
                        value: type,
                      }))}
                    >
                      <Button
                        type="primary"
                        htmlType="button"
                        icon={<DeleteOutlined className={classes.deleteIcon} />}
                        aria-label={t('form-wizard:questions.remove')}
                        onClick={() => remove(i)}
                      />
                    </TextFieldSelect>
                  );
                })}

                <Button
                  type="default"
                  size="large"
                  className={classes.addSource}
                  icon={<PlusOutlined className={classes.addIcon} />}
                  onClick={() => push({ value: '', type: source.accepted[0] })}
                >
                  {t('form-wizard:questions.sources.add')}
                </Button>
              </Spacer>
            );
          }}
        </FieldArray>
      ))}
    </>
  );
}

function Comments() {
  const { values, submitForm } = useFormikContext<QuestionFormValue>();
  const { comments } = values;

  const debouncedSubmitForm = useDebouncedCallback(submitForm, 500);

  if (!comments || comments.length === 0) {
    return null;
  }
  // TODO: this component is not in this final state
  return (
    <Spacer>
      {comments.map((comment, index) => (
        <div
          hidden={!areChoiceIdsMatching(values)(comment)}
          key={comment.configId}
          className={classes.comment}
        >
          <TextAreaField<QuestionFormValue>
            id={`comment-${comment.configId}`}
            name={`comments.${index}.content.value`}
            label={comment.label}
            labelCol={{ span: 24 }}
            onChange={() => debouncedSubmitForm()}
            className={classes.input}
            required={comment.required}
          />
        </div>
      ))}
    </Spacer>
  );
}
