import { useLocation, useNavigate } from 'react-router-dom';
import { Values } from 'technical/types';

export function useCurrentTab<Tabs extends { [key: string]: string }>({
  defaultTab,
  tabs,
  defaultParamName = 'tab',
}: {
  defaultTab: Values<Tabs>;
  tabs: Tabs;
  defaultParamName?: string;
}) {
  const location = useLocation();
  const navigate = useNavigate();

  const tab = new URLSearchParams(location.search).get(defaultParamName);
  const currentTab =
    tab && Object.values(tabs).includes(tab) ? tab : defaultTab;

  function setTab(next: Values<Tabs>) {
    const params = new URLSearchParams(location.search);
    params.set(defaultParamName, next);
    navigate({ search: params.toString() }, { replace: true });
  }

  return [currentTab, setTab] as const;
}
