import React, { useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { Result } from 'antd';
import { useTranslation } from 'react-i18next';
import Button from 'ui/button';
import { Spacer } from 'ui/spacer';
import { Card } from 'ui/cards';
import { TokenOverview } from 'business/token/components/token-overview';
import { TokenMarketStatistics } from 'business/token/components/market-statistics';
import { CategoryTab } from 'business/research/components/category-tab';
import { CategoryTabs } from 'business/research/components/category-tabs';
import classes from './index.module.scss';

import { PeerReviewReminder } from '../components/reminder';
import { usePeerReviewResearch } from '../services';
import { PeerReviewCategoryTab } from '../components/category-tab';
import { PeerReviewGeneralOpinion } from '../components/global-opinion';
import { PeerReviewOpinionButtons } from '../components/opinion';
import config from 'config';
import {
  PoweredByITB,
  BlockchainSummary,
} from 'business/token/components/blockchain-summary';
import classNames from 'classnames';

export function PeerReviewPage() {
  const { researchId } = useParams<'researchId'>();
  const { t } = useTranslation(['token', 'review']);
  const navigate = useNavigate();

  const [currentCategoryIndex, setCurrentCategoryIndex] = useState(0);

  if (researchId === undefined) {
    throw new Error('researchId param is not defined');
  }

  const {
    loading,
    token,
    answersQuestionsByCategoryName,
    peerReview,
    opinionAndReasonByAnswerId,
    setApproval,
    categories,
    alreadySubmitted,
    canPublish,
    publish,
    comment,
    prediction,
    reasons,
  } = usePeerReviewResearch(researchId);

  const isNotFirstCategory = currentCategoryIndex - 1 >= 0;
  const isNotLastCategory = currentCategoryIndex + 1 <= categories.length - 1;

  const scrollToTop = () => setTimeout(() => window.scrollTo({ top: 0 }), 100);

  if (loading || !peerReview) {
    return <div />;
  }

  const categoryTabs = categories.map((category, index) => {
    return {
      label: (
        <CategoryTab
          category={category.id}
          name={category.name}
          active={currentCategoryIndex === index}
        />
      ),
      key: index.toString(),
      children: (
        <PeerReviewCategoryTab
          categoryName={category.name}
          answers={answersQuestionsByCategoryName[category.name].questions}
          actionButtons={(answerId: string, answerHasSources: boolean) => (
            <PeerReviewOpinionButtons
              answerId={answerId}
              reviewId={peerReview.id}
              opinion={opinionAndReasonByAnswerId[answerId]?.opinion}
              reason={opinionAndReasonByAnswerId[answerId]?.reason}
              setApproval={setApproval}
              alreadySubmitted={alreadySubmitted}
              reasons={
                answerHasSources
                  ? reasons
                  : reasons.filter(
                      (option) => option.reason !== 'invalid-source',
                    )
              }
            />
          )}
        />
      ),
    };
  });

  if (!researchId) {
    return (
      <Result
        status="404"
        title="404"
        subTitle={t('errors.notAuthorized')}
        extra={
          <Button type="primary" onClick={() => navigate(-1)}>
            {t('common.back')}
          </Button>
        }
      />
    );
  }

  return (
    <div
      className={classNames(
        classes.grid,
        config.features.showITBData && classes.itb,
      )}
    >
      <div className={classes.title}>
        <TokenOverview
          token={token}
          loading={loading}
          about={token?.infos}
          image={token?.coinGeckoImage}
        />
      </div>
      <Spacer className={classes.review} direction="vertical" space={4}>
        <Card>
          <Card.Header level={2} title={t('review:global-opinion.title')} />
          <PeerReviewGeneralOpinion comment={comment} prediction={prediction} />
        </Card>

        <Card>
          <Spacer className={classes.review} direction="vertical" space={2}>
            <CategoryTabs
              activeKey={`${currentCategoryIndex}`}
              onTabClick={(activeKey) => {
                setCurrentCategoryIndex(Number(activeKey));
                scrollToTop();
              }}
              items={categoryTabs}
              destroyInactiveTabPane
            />
            <Spacer direction="horizontal" justify="end" space={1}>
              {isNotFirstCategory && (
                <Button
                  htmlType="button"
                  onClick={() => {
                    setCurrentCategoryIndex(currentCategoryIndex - 1);
                    scrollToTop();
                  }}
                >
                  {t('review:actions.previous')}
                </Button>
              )}
              {isNotLastCategory ? (
                <Button
                  type="primary"
                  htmlType="button"
                  onClick={() => {
                    setCurrentCategoryIndex(currentCategoryIndex + 1);
                    scrollToTop();
                  }}
                >
                  {t('review:actions.next')}
                </Button>
              ) : (
                !alreadySubmitted && (
                  <Button
                    type="primary"
                    htmlType="button"
                    disabled={!canPublish}
                    onClick={publish}
                  >
                    {t('review:actions.publish')}
                  </Button>
                )
              )}
            </Spacer>
          </Spacer>
        </Card>
      </Spacer>
      <Card className={classes.marketAnalytics}>
        <Card.Header level={2} title={t('token:market-statistics.title')} />
        <TokenMarketStatistics tokenId={token?.id} {...token?.infos} />
      </Card>
      {config.features.showITBData ? (
        <div className={classes['on-chain-metrics']}>
          <Card>
            <Card.Header
              level={2}
              title={t('token:itb-integration.title')}
              actions={<PoweredByITB />}
            />
            <BlockchainSummary token={token?.itb_id} />
          </Card>
        </div>
      ) : null}
      <Card className={classes.reminder}>
        <Card.Header level={2} title={t('review:reminder.title')} />
        <PeerReviewReminder />
      </Card>
    </div>
  );
}
