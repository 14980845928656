import React from 'react';
import { Card } from 'ui/cards';
import { Typography } from 'antd';
import { Spacer } from 'ui/spacer';
import { useTranslation } from 'react-i18next';
import Rewards from './rewards';
import TotalEarned from './total-earned';

export function DashboardRewards() {
  const { t } = useTranslation(['common', 'dashboard']);

  return (
    <Card>
      <Typography.Title level={5}>{t('dashboard:rewards')}</Typography.Title>

      <div>
        <Typography.Text>{t('common:comingSoon')}</Typography.Text>
        <a
          href="https://learn.kryptview.com/kryptview-beta-research-to-earn/"
          target="_blank"
          rel="noreferrer"
        >
          {t('dashboard:more-info')}
        </a>
      </div>
      <Spacer justify="between" className="blurry">
        <Rewards />
        <TotalEarned />
      </Spacer>
    </Card>
  );
}
