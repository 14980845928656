import React from 'react';
import { WarningOutlined } from '@ant-design/icons';
import { Typography } from 'antd';
import { useTranslation } from 'react-i18next';
import { Spacer } from 'ui/spacer';
import { useIsAllDataAvailableQuery } from 'generated/graphql';
import classes from './data-warning-banner.module.scss';

export function DataWarningBanner() {
  const { t } = useTranslation(['common']);
  const { loading, data } = useIsAllDataAvailableQuery({
    pollInterval: 600000,
  });

  if (
    loading ||
    !data ||
    !data.isAllDataAvailable.success ||
    data.isAllDataAvailable.value
  ) {
    return null;
  }

  return (
    <Spacer
      space={2}
      align="center"
      justify="center"
      direction="horizontal"
      className={classes.spacer}
    >
      <WarningOutlined />
      <Typography.Text strong italic className={classes.text}>
        {t('common:data-provider-warning')}
      </Typography.Text>
      <WarningOutlined />
    </Spacer>
  );
}
