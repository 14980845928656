import React from 'react';
import Table from 'ui/table';
import { useTranslation } from 'react-i18next';
import {
  PublicPortfolioResponse,
  Rank,
  User,
  UserProfile,
} from 'generated/graphql';
import { ColumnsType } from 'antd/es/table';
import Button from 'ui/button';
import { TableProps, Typography } from 'antd';
import { format } from 'technical/date-fns';
import UserLink from 'business/user/components/user-link';
import { PercentageValue } from 'ui/percentage';
import { Ranking } from 'ui/ranking';
import classes from './index.module.scss';
import config from 'config';

export interface PartialPortfolio
  extends Pick<PublicPortfolioResponse, 'id' | 'name' | 'lastPublicationDate'> {
  user: Pick<User, 'id'> & {
    profile?: Pick<UserProfile, 'displayName'> | null;
    ranking?: null | {
      currentRank: Pick<Rank, 'name' | 'icon'>;
    };
  };
}

interface PortfolioTableProps
  extends Pick<
    TableProps<PartialPortfolio>,
    'loading' | 'pagination' | 'onChange'
  > {
  portfolios: PartialPortfolio[];
  isAuthorized?: boolean;
  onDetailsClick?: (portfolioId: string) => void;
  orderBy:
    | Record<'lastPublicationDate', 'desc' | 'asc'>
    | Record<'d7Perf', 'desc' | 'asc'>
    | Record<'lastUpdatePerf', 'desc' | 'asc'>
    | null;
}

export const PortfolioTable: React.VFC<PortfolioTableProps> = ({
  portfolios,

  isAuthorized = true,
  onDetailsClick,
  orderBy,
  ...otherProps
}) => {
  const { t } = useTranslation(['portfolio']);
  const columns: ColumnsType<PartialPortfolio> = [
    {
      title: t('portfolio:table.name.title'),
      align: 'left',
      dataIndex: 'name',
      render: (_, { name }) => (
        <Typography.Text strong>{name || '-'}</Typography.Text>
      ),
    },
    {
      title: t('portfolio:table.author'),
      align: 'left',
      dataIndex: 'user',
      render: (_, { user: { id, profile, ranking } }) => (
        <UserLink
          className={classes.userLink}
          iconSize={24}
          id={id}
          rank={ranking?.currentRank}
          displayName={profile?.displayName ?? undefined}
        />
      ),
    },
    {
      title: t('portfolio:table.rank'),
      align: 'left',
      dataIndex: 'user',
      render: (_, { user: { ranking } }) => (
        <Ranking rank={ranking?.currentRank} />
      ),
    },
    {
      title: t('portfolio:table.name.d7-perf'),
      align: 'left',
      dataIndex: 'd7Perf',
      sorter: config.features.isPortfolioPerformanceSortingActive,
      sortOrder:
        orderBy !== null && 'd7Perf' in orderBy
          ? { desc: 'descend' as const, asc: 'ascend' as const }[orderBy.d7Perf]
          : undefined,
      sortDirections: ['descend', 'ascend'],
      render: (value: number | null | undefined) => (
        <PercentageValue value={value} />
      ),
    },
    {
      title: t('portfolio:table.name.last-update-perf'),
      align: 'left',
      dataIndex: 'lastUpdatePerf',
      sorter: config.features.isPortfolioPerformanceSortingActive,
      sortOrder:
        orderBy !== null && 'lastUpdatePerf' in orderBy
          ? { desc: 'descend' as const, asc: 'ascend' as const }[
              orderBy.lastUpdatePerf
            ]
          : undefined,
      sortDirections: ['descend', 'ascend'],
      render: (value: number | null | undefined) => (
        <PercentageValue value={value} />
      ),
    },
    {
      title: t('portfolio:table.lastUpdate.title'),
      align: 'left',
      dataIndex: 'lastPublicationDate',
      sorter: config.features.isPortfolioPerformanceSortingActive,
      sortOrder:
        orderBy !== null && 'lastPublicationDate' in orderBy
          ? { desc: 'descend' as const, asc: 'ascend' as const }[
              orderBy.lastPublicationDate
            ]
          : undefined,
      sortDirections: ['descend', 'ascend'],
      render: (_, { lastPublicationDate }) => (
        <Typography.Text strong>
          {lastPublicationDate
            ? format(new Date(lastPublicationDate), 'PP')
            : '-'}
        </Typography.Text>
      ),
    },
    {
      dataIndex: 'id',
      align: 'right',
      render: (_, { id }) => (
        <Button
          type="default"
          onClick={() => onDetailsClick && onDetailsClick(id)}
        >
          {t('portfolio:table.viewPortfolio')}
        </Button>
      ),
    },
  ];

  return (
    <Table<PartialPortfolio>
      columns={columns}
      dataSource={portfolios}
      rowKey={(portfolio) => portfolio.id}
      onRow={() => ({
        className: !isAuthorized ? 'blurry' : '',
      })}
      {...otherProps}
    />
  );
};
