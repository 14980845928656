import React, { ReactElement, useState } from 'react';
import Button from 'ui/button';
import { Trans, useTranslation } from 'react-i18next';
import { Spacer } from 'ui/spacer';
import {
  useSelectUserQualificationQuery,
  useSetOnboardingInterestMutation,
  useUpsertUserQualificationMutation,
} from 'generated/graphql';
import { useAppContext } from 'business/app-bootstrapper';
import classes from './qualification.module.scss';
import { LaterButton } from '../common/later-button';
import { OnboardingStepEnum, OnboardingStepEnumType } from './types';

interface OnboardingQualificationStepContentProps {
  onCompleted: () => void;
  showLaterButton: boolean;
}

function OnboardingQualificationStepContent({
  onCompleted,
  showLaterButton,
}: OnboardingQualificationStepContentProps) {
  const { t } = useTranslation(['onboarding']);
  const { user } = useAppContext();
  const [
    upsertUserQualification,
    { loading },
  ] = useUpsertUserQualificationMutation({ onCompleted });
  const [qualification, setQualification] = useState({
    createTokenResearch: false,
    reviewTokenResearch: false,
    viewCommunityResearch: false,
  });
  const [
    setOnboardingInterest,
    { loading: setOnboardingInterestLoading },
  ] = useSetOnboardingInterestMutation({
    onCompleted,
  });

  const toggleOption = (option: keyof typeof qualification) => {
    setQualification({
      ...qualification,
      [option]: !qualification[option],
    });
  };

  const qualificationButtons: {
    key: string;
    text: string;
    checked: boolean;
    toggle: () => void;
  }[] = [
    {
      key: 'qualification-create-token-research',
      text: t('onboarding:interest.create-token-research'),
      checked: qualification.createTokenResearch,
      toggle: () => toggleOption('createTokenResearch'),
    },
    {
      key: 'qualification-review-token-research',
      text: t('onboarding:interest.review-token-research'),
      checked: qualification.reviewTokenResearch,
      toggle: () => toggleOption('reviewTokenResearch'),
    },
    {
      key: 'qualification-view-community-research',
      text: t('onboarding:interest.view-community-research'),
      checked: qualification.viewCommunityResearch,
      toggle: () => toggleOption('viewCommunityResearch'),
    },
  ];

  const shouldSaveInterest =
    qualification.createTokenResearch ||
    qualification.reviewTokenResearch ||
    qualification.viewCommunityResearch;

  return (
    <div role="group" aria-labelledby="qualification-label">
      <span id="qualification-label" className={classes.qualificationLabel}>
        {t('onboarding:interest.sr-qualification-label')}
      </span>
      <Spacer direction="vertical" space={1} className={classes.qualification}>
        {qualificationButtons.map(({ key, text, checked, toggle }) => (
          <Button
            key={key}
            role="checkbox"
            onClick={toggle}
            aria-checked={checked}
            checked={checked}
            type="default"
          >
            {text}
          </Button>
        ))}
        <Spacer>
          {showLaterButton ? (
            <LaterButton
              loading={loading}
              text={
                shouldSaveInterest
                  ? t('onboarding:validate')
                  : t('onboarding:later')
              }
              onClick={() => {
                if (shouldSaveInterest) {
                  upsertUserQualification({ variables: { ...qualification } });
                  setOnboardingInterest({
                    variables: {
                      // @ts-expect-error
                      userId: user?.id,
                      value: OnboardingStepEnum.done,
                    },
                  });
                } else {
                  setOnboardingInterest({
                    variables: {
                      // @ts-expect-error
                      userId: user?.id,
                      value: OnboardingStepEnum.skipped,
                    },
                  });
                }
              }}
            />
          ) : (
            <LaterButton
              loading={loading}
              text={t('onboarding:validate')}
              onClick={() =>
                upsertUserQualification({ variables: { ...qualification } })
              }
            />
          )}
        </Spacer>
      </Spacer>
    </div>
  );
}

function OnboardingQualificationStepTitle({
  onboardingStepStatus,
}: {
  onboardingStepStatus: OnboardingStepEnumType;
}): JSX.Element {
  const { t } = useTranslation(['onboarding']);
  const { isConnected, user } = useAppContext();
  const { data } = useSelectUserQualificationQuery({
    skip: !isConnected,
    // @ts-expect-error
    variables: { userId: user?.id },
  });

  if (
    !isConnected ||
    !data?.userQualification ||
    onboardingStepStatus === OnboardingStepEnum.unbegun
  ) {
    return <>{t(`onboarding:interest.title`)}</>;
  }

  const interests: ReactElement[] = [];

  if (data?.userQualification?.createTokenResearch) {
    interests.push(
      <>{t(`onboarding:interest.create-token-research`).toLowerCase()}</>,
    );
  }

  if (data?.userQualification?.reviewTokenResearch) {
    interests.push(
      <>{t(`onboarding:interest.review-token-research`).toLowerCase()}</>,
    );
  }

  if (data?.userQualification?.viewCommunityResearch) {
    interests.push(
      <>{t(`onboarding:interest.view-community-research`).toLowerCase()}</>,
    );
  }

  return (
    <Trans
      t={t}
      i18nKey={
        `onboarding:interest.title_completed.${interests.length}` as const
      }
      components={interests}
    />
  );
}

export const OnboardingQualificationStep = {
  Step: OnboardingQualificationStepContent,
  Title: OnboardingQualificationStepTitle,
};
