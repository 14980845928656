import { useAppContext } from 'business/app-bootstrapper';
import Routes from 'business/router/routes';
import { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { useLocaleStorage } from 'technical/storage/use-storage';
import * as yup from 'yup';
import { addHours, isAfter } from 'date-fns';
import config from 'config';

export function useUTMCampaign() {
  const location = useLocation();
  const [isFromBrave, setIsFromBrave] = useState(false);

  useEffect(() => {
    setIsFromBrave(window.localStorage.getItem('coming-from-brave') === 'true');
  });

  if (location.pathname === Routes.BraveLanding || isFromBrave) {
    return 'brave';
  }

  const params = new URLSearchParams(location.search);
  const utmCampaign = params.get('utm_campaign') ?? undefined;

  return utmCampaign;
}

export function useDefaultReferralCode() {
  const location = useLocation();
  const params = new URLSearchParams(location.search);
  return params.get('referral-code') ?? undefined;
}

export function useLoginModal() {
  const { isConnected } = useAppContext();
  const [signupModalClosedAt, setSignupModalClosedAt] = useLocaleStorage(
    'signup-modal-closed-at',
    undefined,
    yup.date(),
  );

  const loginModalIsOpen =
    !isConnected &&
    (signupModalClosedAt === undefined ||
      isAfter(
        new Date(),
        addHours(
          new Date(signupModalClosedAt),
          config.signModalCloseDelayInHours,
        ),
      ));

  const setLoginModalIsOpen = (isOpen: boolean) => {
    if (isOpen) {
      setSignupModalClosedAt(undefined);
    } else {
      setSignupModalClosedAt(new Date());
    }
  };
  return { loginModalIsOpen, setLoginModalIsOpen };
}
