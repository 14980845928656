import React from 'react';
import { ModalProps, Typography } from 'antd';
import { Modal } from 'ui/modal';
import { Spacer } from 'ui/spacer';
import { useTranslation } from 'react-i18next';
import Button from 'ui/button';

interface WalletAccountModalProps extends ModalProps {
  defaultWalletAddress?: string;
  currentWalletAddress?: string;
  walletData?: {
    formatted: string;
    symbol: string;
  };
  logout: () => void;
}

const WalletAccountModal = ({
  defaultWalletAddress,
  currentWalletAddress,
  walletData,
  logout,
  ...props
}: WalletAccountModalProps) => {
  const { t } = useTranslation(['common']);

  return (
    <Modal
      footer={
        <Spacer justify="center">
          <Button type="primary" onClick={logout}>
            {t('pages.common.logout')}
          </Button>
        </Spacer>
      }
      {...props}
    >
      <Spacer direction="vertical" space={1}>
        <Typography.Text>
          {t('wallet.address')}
          <Typography.Text strong>{defaultWalletAddress}</Typography.Text>
        </Typography.Text>
        {walletData ? (
          <Typography.Text>
            {t('wallet.balance')}
            <Typography.Text strong>
              {walletData.formatted} {walletData.symbol.toUpperCase()}
            </Typography.Text>
          </Typography.Text>
        ) : null}
        {currentWalletAddress !== defaultWalletAddress ? (
          <Typography.Text type="warning">
            {t('wallet.non-matching')}
          </Typography.Text>
        ) : null}
      </Spacer>
    </Modal>
  );
};

export default WalletAccountModal;
