/* eslint-disable no-param-reassign */
/* eslint-disable func-names */
/* eslint-disable no-multi-assign */
/* eslint-disable block-scoped-var */
/* eslint-disable no-return-assign */
/* eslint-disable no-var */
/* eslint-disable vars-on-top */
/* eslint-disable no-bitwise */
/* eslint-disable no-plusplus */

// for some explanations about those algorithms you can go to
// https://stackoverflow.com/questions/521295/seeding-the-random-number-generator-in-javascript

function xmur3(str: string) {
  for (var i = 0, h = 1779033703 ^ str.length; i < str.length; i++) {
    h = Math.imul(h ^ str.charCodeAt(i), 3432918353);
    h = (h << 13) | (h >>> 19);
  }
  return function () {
    h = Math.imul(h ^ (h >>> 16), 2246822507);
    h = Math.imul(h ^ (h >>> 13), 3266489909);
    return (h ^= h >>> 16) >>> 0;
  };
}

function mulberry32(a: number) {
  return function () {
    let t = (a += 0x6d2b79f5);
    t = Math.imul(t ^ (t >>> 15), t | 1);
    t ^= t + Math.imul(t ^ (t >>> 7), t | 61);
    return ((t ^ (t >>> 14)) >>> 0) / 4294967296;
  };
}

export function createSeed(str: string) {
  return xmur3(str);
}

export function createRand(seed: number) {
  const random = mulberry32(seed);
  return (config: { min: number; max: number } = { min: 0, max: 1 }) =>
    random() * (config.max - config.min) + config.min;
}
