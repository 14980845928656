import { ApolloProvider } from '@apollo/client';
import { ConfigProvider } from 'antd';
import React from 'react';
import 'technical/yup-config';
import ErrorBoundary from 'technical/error-boundary';
import apolloClient from 'technical/graphql/client';
import CookieConsentBanner from 'ui/cookie/consent-banner';
import { ThemeLoader } from 'ui/theme';
import './app.scss';
import { WagmiProvider } from 'technical/wagmi/config';
import { AppProvider } from './app-bootstrapper';
import { RouterProvider } from 'react-router';
import { router } from './router';

const App = () => (
  <ErrorBoundary>
    <ConfigProvider>
      <ApolloProvider client={apolloClient}>
        <ThemeLoader>
          <WagmiProvider>
            <AppProvider>
              <>
                <CookieConsentBanner />
                <RouterProvider router={router} />
              </>
            </AppProvider>
          </WagmiProvider>
        </ThemeLoader>
      </ApolloProvider>
    </ConfigProvider>
  </ErrorBoundary>
);

export default App;
