import React, { SVGProps } from 'react';

interface FooterKryptviewSVGComponentProps extends SVGProps<SVGSVGElement> {}

const KryptviewSVGComponent = (props: FooterKryptviewSVGComponentProps) => {
  return (
    <svg
      width="39"
      height="40"
      viewBox="0 0 39 40"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M19.5 36.1151C28.4001 36.1151 35.6151 28.9001 35.6151 20C35.6151 11.0999 28.4001 3.88494 19.5 3.88494C10.5999 3.88494 3.38494 11.0999 3.38494 20C3.38494 28.9001 10.5999 36.1151 19.5 36.1151ZM19.5 39.5C30.2696 39.5 39 30.7696 39 20C39 9.23045 30.2696 0.5 19.5 0.5C8.73045 0.5 0 9.23045 0 20C0 30.7696 8.73045 39.5 19.5 39.5Z"
        fill="#4457FF"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M25.5773 1.77858C26.1653 2.31584 26.2065 3.22806 25.6692 3.81608L9.51984 21.4912C8.98258 22.0792 8.07036 22.1204 7.48234 21.5831C6.89432 21.0458 6.85317 20.1336 7.39043 19.5456L23.5398 1.87048C24.0771 1.28246 24.9893 1.24132 25.5773 1.77858Z"
        fill="#4457FF"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M31.0148 5.95529C31.6057 6.48938 31.6518 7.40137 31.1177 7.99227L20.434 19.8124C19.8999 20.4033 18.9879 20.4493 18.397 19.9152C17.8061 19.3811 17.7601 18.4692 18.2942 17.8783L28.9778 6.05816C29.5119 5.46726 30.4239 5.4212 31.0148 5.95529Z"
        fill="#4457FF"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M32.1972 32.8569C31.6132 33.3986 30.7007 33.3643 30.159 32.7804L18.2873 19.9825C17.7456 19.3986 17.7799 18.486 18.3638 17.9444C18.9478 17.4027 19.8603 17.4369 20.402 18.0209L32.2737 30.8187C32.8154 31.4027 32.7811 32.3152 32.1972 32.8569Z"
        fill="#4457FF"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M14.3056 26.3959L19.4459 31.9419C19.9874 32.526 20.8999 32.5607 21.484 32.0192C22.0682 31.4778 22.1028 30.5653 21.5614 29.9811L15.4795 23.4192C14.8395 22.7287 13.746 22.7325 13.1109 23.4276L6.88542 30.2397C6.3481 30.8276 6.38914 31.7398 6.9771 32.2772C7.56506 32.8145 8.47729 32.7734 9.01461 32.1855L14.3056 26.3959Z"
        fill="#4457FF"
      />
    </svg>
  );
};

export default KryptviewSVGComponent;
