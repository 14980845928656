import React from 'react';
import { Card } from 'ui/cards';
import { Typography, Image } from 'antd';
import { Maybe, Rating, TokenIdentity, TokenMarkets } from 'generated/graphql';
import { generatePath, useNavigate } from 'react-router-dom';
import Routes from 'business/router/routes';
import Link from 'ui/link';
import TagPercentage from 'ui/tag-percentage';
import { NumericValue, NumberFormats } from 'ui/numeric';
import RatingTag from 'ui/rating-tag';
import defaultTokenImage from './img/default_token.png';
import classes from './index.module.scss';

type RatedTokenIdentity = {
  currentTokenRating?: Maybe<{
    rating?: Maybe<Pick<Rating, 'type' | 'name'>>;
  }>;
};

export interface TokenCardProps
  extends RatedTokenIdentity,
    Pick<TokenIdentity, 'name' | 'reference' | 'symbol'>,
    RatedTokenIdentity,
    Pick<TokenMarkets, 'price' | 'h24' | 'image'> {
  onClick?: (reference: string) => void;
}

export const TokenCard: React.VFC<TokenCardProps> = ({
  name,
  symbol,
  image,
  reference,
  price,
  h24,
  currentTokenRating,
}) => {
  const navigate = useNavigate();
  const path = generatePath(Routes.token, { reference });

  return (
    <Card
      onClick={() => {
        navigate(path);
      }}
      type="well"
      className={classes.tokenCardWrapper}
      stretchHeight
    >
      <div className={classes.titleGrid}>
        <Image
          className={classes.image}
          src={image || defaultTokenImage}
          alt={`${name} logo`}
          preview={false}
        />
        <Link to={path}>
          <Typography.Text
            className={classes.name}
            ellipsis={{ tooltip: name }}
            strong
          >
            {name}
          </Typography.Text>
        </Link>
        <Typography.Text className={classes.symbol}>{symbol}</Typography.Text>
      </div>
      <RatingTag
        rating={currentTokenRating?.rating?.name}
        type={currentTokenRating?.rating?.type}
        className={classes.rating}
      />
      <div className={classes.numbers}>
        {price && (
          <NumericValue
            format={NumberFormats.currency}
            value={price}
            emptyValue={'\u00A0'}
            strong
          />
        )}
        {h24 && <TagPercentage value={h24} emptyValue={'\u00A0'} />}
      </div>
    </Card>
  );
};
