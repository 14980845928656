import { researchPageOptions } from 'business/token/common-values';
import * as yup from 'yup';
import { FilterInURL } from './use-filters-hook';

type OrderByFilter<
  Keys extends string,
  Multiple extends boolean
> = true extends Multiple
  ? Partial<Record<Keys, 'asc' | 'desc'>>
  : { [K in Keys]: Record<K, 'asc' | 'desc'> }[Keys];

export function orderByFilter<T extends string, Multiple extends boolean>({
  defaultValue,
}: {
  keys: Array<T>;
  multiple: Multiple;
  defaultValue: OrderByFilter<T, Multiple>;
}): FilterInURL<OrderByFilter<T, Multiple> | null> {
  return {
    defaultValue,
    fromSearchParam: (param) => {
      return param ? JSON.parse(param) : null;
    },
    toSearchParam: (value) =>
      value === null ? undefined : JSON.stringify(value),
  };
}

export const stringArrayFilter: FilterInURL<string[] | null> = {
  defaultValue: [],
  fromSearchParam: (param) => {
    const e = param ? decodeURI(param).trim() : '';
    return e.length > 0 ? e.split(',') : null;
  },
  toSearchParam: (value) => value?.join(','),
};

const numberFilter = (defaultValue: number): FilterInURL<number> => ({
  defaultValue,
  fromSearchParam: (param) =>
    yup
      .number()
      .required()
      .validateSync(param ?? defaultValue),
  toSearchParam: (value) =>
    value === defaultValue ? undefined : value.toString(),
});

export const pageFilter = numberFilter(researchPageOptions.defaultPage);
export const pageSizeFilter = numberFilter(researchPageOptions.defaultPageSize);

export const booleanFilter: FilterInURL<boolean | null> = {
  defaultValue: null,
  fromSearchParam: (param) => {
    switch (param) {
      case 'true':
        return true;
      case 'false':
        return false;
      default:
        return null;
    }
  },
  toSearchParam: (value) => (value === null ? undefined : `${value}`),
};
