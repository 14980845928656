import React from 'react';
import classnames from 'classnames';
import { getCategoryColor } from 'business/research/utils/get-category-color';
import { getCategoryIcons } from 'business/research/utils/get-category-icons';
import { Spacer } from 'ui/spacer';
import colors from 'ui/theme/variables.module.scss';
import { CategoriesEnumValues } from 'business/research/constants/category';
import classes from './category-icon.module.scss';

type CategorySizes = 'small' | 'medium' | 'large';

interface CategoryIconProps {
  size?: CategorySizes;
  category: string | CategoriesEnumValues;
  disabled?: boolean;
}

/**
 * Display the category icon for a specific category
 * As categories are fixed ids we could use directly their ids or use categoryEnum
 * @param size the size of the category
 * @param category category enum or id
 * @param disabled if the icon bg must be grey
 * @constructor
 */
export const CategoryIcon: React.VFC<CategoryIconProps> = ({
  size = 'medium',
  category,
  disabled,
}) => {
  const Icon = getCategoryIcons(category);
  const backgroundColor = disabled
    ? colors.categoryDisabledBg
    : getCategoryColor(category);
  return (
    <Spacer
      style={{
        backgroundColor,
      }}
      className={classnames(classes[size])}
      justify="center"
      align="center"
    >
      <Icon height="100%" width="100%" />
    </Spacer>
  );
};
