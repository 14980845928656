import React from 'react';

export function ResearchFormError() {
  // this is temporary
  return (
    <>
      You are not authorized to score this token for now, you already scored it
      not so long ago.
    </>
  );
}
