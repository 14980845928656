import React from 'react';
import config from 'config';
import { DiscordLogoSVGComponent } from 'ui/social-networks/icons/discord-logo';
import { useTranslation } from 'react-i18next';
import classes from './join-discord-button.module.scss';

export const JoinDiscordButton: React.VFC = () => {
  const { t } = useTranslation(['common']);

  return (
    <a
      className={classes.discord}
      rel="noopener noreferrer"
      target="_blank"
      href={config.links.discord}
    >
      <DiscordLogoSVGComponent fill="white" height={20} width={20} />
      {t('common:join-us-on-discord')}
    </a>
  );
};
