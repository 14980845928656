import React from 'react';
import classnames from 'classnames';
import config from 'config';
import Link from 'antd/lib/typography/Link';
import { useTranslation } from 'react-i18next';
import { Card } from 'ui/cards';
import { SocialIcon } from 'ui/social-networks/social-icon';
import colors from 'ui/theme/variables.module.scss';
import { Typography } from 'antd';
import { Spacer } from 'ui/spacer';
import classes from './index.module.scss';

const contents = {
  ...config.links,
};

type ContentEntry = keyof typeof contents;

const socials: Extract<
  ContentEntry,
  'twitter' | 'discord' | 'telegram' | 'crew3'
>[] = ['twitter', 'discord', 'telegram', 'crew3'];

export function SocialsCard() {
  const { t } = useTranslation(['common']);

  return (
    <Card type="primary">
      <Spacer space={2} direction="vertical" align="center">
        <Typography.Text className={classes.socialTitle}>
          {t('footer.join_community')}
        </Typography.Text>
        <Spacer
          space={1}
          direction="horizontal"
          justify="center"
          className={classes.socialsIcons}
        >
          {socials.map((key) => (
            <div className={classes.socialLink} key={key}>
              <Link
                href={contents[key]}
                target="_blank"
                rel="noopener noreferrer"
                className={classnames(classes.content, classes.link)}
              >
                <SocialIcon
                  key={key}
                  color={colors.textWhite}
                  social={key}
                  size="small"
                />
              </Link>
            </div>
          ))}
        </Spacer>
      </Spacer>
    </Card>
  );
}
