import React from 'react';
import {
  ResearchTable,
  ResearchTableColumnKey,
} from 'business/research/components/table';
import {
  ResearchResearchStatusEnumEnum,
  useUserDraftSoftDeleteMutation,
} from 'generated/graphql';
import { Card } from 'ui/cards';
import useResearchHistoryTable from './use-research-history-table';

export interface ResearchHistoryTableProps {
  includeStatus: ResearchResearchStatusEnumEnum[];
  title: string;
  authorId?: string;
  columnSelection?: ResearchTableColumnKey[];
}

function ResearchHistoryTable({
  includeStatus,
  title,
  authorId,
  columnSelection,
}: ResearchHistoryTableProps) {
  const {
    loading,
    count,
    limit,
    currentPage,
    researches,
    onTableChange,
  } = useResearchHistoryTable({
    authorId,
    includeStatus,
  });
  const [deleteDraft] = useUserDraftSoftDeleteMutation({
    refetchQueries: ['SelectUserResearches'],
    awaitRefetchQueries: true,
  });

  return (
    <>
      <Card>
        <Card.Header title={title} level={2} />
        <ResearchTable
          loading={loading}
          tableLayout="auto"
          showHeader
          pagination={{
            pageSize: limit,
            total: count,
            current: currentPage,
          }}
          onClickDeleteDraft={async ({ id }) =>
            deleteDraft({ variables: { id } })
          }
          onChange={(pagination, filters) => {
            onTableChange(pagination, filters);
          }}
          columnSelection={columnSelection}
          researches={researches}
        />
      </Card>
    </>
  );
}

export default ResearchHistoryTable;
