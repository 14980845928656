import React, { useState } from 'react';
import { Spacer } from 'ui/spacer';
import { AutoComplete, Input, Skeleton, Typography } from 'antd';
import Button from 'ui/button';
import TokenCard from 'business/portfolio/components/token-card';
import InputError from 'ui/form/input-error';
import { FieldArray, FieldArrayRenderProps, useFormikContext } from 'formik';
import { useTranslation } from 'react-i18next';
import {
  PortfolioFormAllocatedToken,
  PortfolioFormToken,
  PortfolioFormValues,
} from 'business/portfolio/types';
import { CoinGeckoTokenImage } from 'ui/coin-gecko-token-image';
import { useDebounce } from 'technical/hooks/use-debounce';
import { Token, useSearchTokenQuery } from 'generated/graphql';
import classes from 'business/portfolio/components/personal-portfolio/portfolio-token-repartition/index.module.scss';
import el from 'date-fns/esm/locale/el/index';

interface PortfolioTokenRepartitionProps {
  disabled: boolean;
}

const setSplitHandler = (
  formValues: PortfolioFormValues,
  setFieldValue: (field: string, value: any, shouldValidate?: boolean) => void,
) => {
  const tokenAmount = formValues.allocatedTokens.length;
  const hundredPercents = 100;
  const amount = hundredPercents / tokenAmount;
  formValues.allocatedTokens.forEach((_item: any, index: number) => {
    setFieldValue(`allocatedTokens.${index}.percentage`, amount);
  });
};

function TokenOption({
  token,
}: {
  token: Pick<Token, 'name' | 'symbol' | 'coinGeckoImage'>;
}) {
  const { name, symbol, coinGeckoImage } = token;

  return (
    <Spacer direction="horizontal" space={1} align="center">
      <Spacer direction="horizontal" space={1} align="center">
        <CoinGeckoTokenImage
          size="thumb"
          width={20}
          coinGeckoImage={coinGeckoImage}
        />
        <Typography.Text>{name}</Typography.Text>
      </Spacer>
      <Typography.Text>{symbol.toUpperCase()}</Typography.Text>
    </Spacer>
  );
}

const PublicPortfolioTokenRepartition: React.VFC<PortfolioTokenRepartitionProps> = ({
  disabled,
}) => {
  const { t } = useTranslation(['portfolio']);
  const {
    values,
    setFieldValue,
    errors,
  } = useFormikContext<PortfolioFormValues>();

  // search
  const [searchText, setSearchText] = useState('');
  const debouncedValue = useDebounce(searchText);
  const [excludedTokens, setExcludedTokens] = useState<PortfolioFormToken[]>(
    [],
  );
  const { data } = useSearchTokenQuery({
    variables: {
      searchText: `%${debouncedValue}%`,
      filter: { id: { _nin: excludedTokens.map((token) => token.id) } },
    },
  });

  const options = data?.token.map((token) => ({
    value: token.id,
    label: <TokenOption token={token} />,
    key: token.id,
  }));

  const handleSelectToken = (
    id: string,
    arrayHelpers: FieldArrayRenderProps,
  ) => {
    const selectedToken = data?.token.find((token) => token.id === id);
    if (selectedToken) {
      arrayHelpers.insert(0, {
        percentage: 0,
        token: selectedToken,
      });
      setExcludedTokens([...excludedTokens, selectedToken]);
    }
    setSearchText('');
  };

  const handleDeleteToken = (
    arrayHelpers: FieldArrayRenderProps,
    index: number,
    tokenRepartition: PortfolioFormAllocatedToken,
  ) => {
    arrayHelpers.remove(index);
    setExcludedTokens(
      excludedTokens.filter(
        (excludedTokenId) => excludedTokenId.id !== tokenRepartition.token.id,
      ),
    );
  };

  return (
    <FieldArray
      name="allocatedTokens"
      render={(arrayHelpers) => (
        <Spacer direction="vertical" space={1} className={classes.form}>
          <AutoComplete
            onSelect={(id: string) => handleSelectToken(id, arrayHelpers)}
            disabled={disabled}
            value={searchText}
            popupClassName="certain-category-search-dropdown"
            dropdownMatchSelectWidth={500}
            options={options}
          >
            <Input
              size="large"
              value={searchText}
              onChange={(e) => setSearchText(e.target.value)}
              placeholder={t('portfolio:form.addToken')}
              className={classes.inputs}
            />
          </AutoComplete>
          <Button
            disabled={disabled}
            onClick={() => setSplitHandler(values, setFieldValue)}
          >
            {t('portfolio:form.split')}
          </Button>
          <Spacer
            className={classes.editableTokenContainer}
            direction="vertical"
            space={1}
          >
            {values.allocatedTokens.map((tokenRepartition, index) => (
              <TokenCard.Editable
                key={tokenRepartition.token.id}
                tokenRepartition={tokenRepartition}
                index={index}
                isEditable={!disabled}
                onDeleteToken={() => {
                  handleDeleteToken(arrayHelpers, index, tokenRepartition);
                }}
              />
            ))}
          </Spacer>
          <Spacer className={classes.errorContainer}>
            {typeof errors.allocatedTokens === 'string' && (
              <InputError error={errors.allocatedTokens} />
            )}
          </Spacer>
        </Spacer>
      )}
    />
  );
};

const PortfolioTokenRepartitionSkeleton = () => {
  return (
    <Spacer space={1} align="center" direction="vertical">
      <Skeleton.Input active size="large" block />
      <Skeleton.Button active block />
      <Spacer
        className={classes.editableTokenContainer}
        direction="vertical"
        space={1}
      >
        {[0, 1, 2, 3].map((key) => (
          <TokenCard.Skeleton key={key} />
        ))}
      </Spacer>
    </Spacer>
  );
};

export const PortfolioTokenRepartition = {
  Editable: PublicPortfolioTokenRepartition,
  Skeleton: PortfolioTokenRepartitionSkeleton,
};
