import React from 'react';
import classnames from 'classnames';

import { Button as AntdButton } from 'antd';
import { ButtonProps as AntdButtonProps } from 'antd/lib/button/button';
import { LinkProps, Link } from 'react-router-dom';

import { logEvent } from 'technical/analytics';

import classes from './index.module.scss';

interface AnalyticsEvent {
  eventName?: string;
  logEventData?: Object;
}

export interface LinkAsButtonProps extends LinkProps, AnalyticsEvent {
  type?: AntdButtonProps['type'];
  size?: AntdButtonProps['size'];
  accent?: 'danger' | 'success';
  block?: boolean;
}

export function LinkAsButton({
  type = 'default',
  size = 'middle',
  className,
  children,
  onClick: originalOnClick,
  eventName = 'unknown_event',
  logEventData,
  accent,
  block = false,
  ...rest
}: Omit<LinkAsButtonProps, 'danger'>) {
  return (
    <Link
      {...rest}
      onClick={(...args) => {
        logEvent('link_button_clicked', {
          button_event: eventName,
          ...logEventData,
        });
        originalOnClick?.(...args);
      }}
      className={classnames(className, 'ant-btn', {
        'ant-btn-primary': type === 'primary',
        'ant-btn-text': type === 'text',
        'ant-btn-link': type === 'link',
        'ant-btn-lg': size === 'large',
        'ant-btn-sm': size === 'small',
        'ant-btn-block': block,
        [classes['btn-success-primary']]:
          accent === 'success' && type === 'primary',
        [classes['btn-success-default']]:
          accent === 'success' && type === 'default',
      })}
    >
      <span>{children}</span>
    </Link>
  );
}

export interface ButtonProps extends AntdButtonProps, AnalyticsEvent {
  accent?: 'danger' | 'success';
  checked?: boolean;
}

const Button = ({
  onClick: originalOnClick,
  eventName = 'unknown_event',
  type = 'default',
  size = 'middle',
  logEventData,
  className,
  accent,
  checked,
  ...props
}: Omit<ButtonProps, 'danger'>) => {
  return (
    <AntdButton
      className={classnames(className, classes.button, {
        [classes['btn-success-primary']]:
          accent === 'success' && type === 'primary',
        [classes['btn-success-default']]:
          accent === 'success' && type === 'default',
        [classes['btn-text-success']]:
          (accent === 'success' && type === 'link') ||
          (accent === 'success' && type === 'text'),
        [classes['btn-checked']]: checked,
      })}
      type={type}
      size={size}
      danger={accent === 'danger'}
      {...props}
      onClick={(...args) => {
        logEvent('button_clicked', {
          button_event: eventName,
          ...logEventData,
        });
        originalOnClick?.(...args);
      }}
    />
  );
};

export default Button;
