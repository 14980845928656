import React, { ReactNode } from 'react';
import { useField } from 'formik';
import { Form, Input, InputProps } from 'antd';
import { TextAreaProps } from 'antd/lib/input';
import { EyeInvisibleOutlined, EyeTwoTone } from '@ant-design/icons';
import { ColProps } from 'antd/lib/grid/col';
import { RecursiveFormNames } from '../types';
import { useFieldTranslation } from '../translations';

export type TextFieldProps<FormValues> = InputProps & {
  label?: string | ReactNode;
  name: RecursiveFormNames<FormValues>;
  id: string;
  containerStyle?: React.CSSProperties;
  labelCol?: ColProps;
  className?: string;
  containerClassName?: string;
};

export function TextField<FormValues>({
  label,
  name,
  id,
  required,
  containerStyle,
  className,
  containerClassName,
  labelCol,
  ...rest
}: TextFieldProps<FormValues>) {
  const { t } = useFieldTranslation();
  const [{ value }, meta, helpers] = useField({ name });

  const status = meta.touched && meta.error ? 'error' : 'success';
  const message = status === 'error' ? t(meta.error as any) : '\u00A0';

  return (
    <Form.Item
      htmlFor={id}
      label={label}
      labelCol={labelCol}
      validateStatus={status}
      help={message}
      required={required}
      className={containerClassName}
      style={containerStyle}
    >
      <Input
        {...rest}
        id={id}
        value={value}
        required={required}
        onChange={(event) => {
          helpers.setValue(event.target.value);
        }}
        onBlur={() => helpers.setTouched(true)}
        className={className}
      />
    </Form.Item>
  );
}

export type PasswordFieldProps<FormValues> = InputProps & {
  label: string;
  name: RecursiveFormNames<FormValues>;
  id: string;
  containerStyle?: React.CSSProperties;
};

export function PasswordField<FormValues>({
  label,
  name,
  id,
  required,
  containerStyle,
  ...rest
}: PasswordFieldProps<FormValues>) {
  const { t } = useFieldTranslation();
  const [{ value }, meta, helpers] = useField({ name });

  const status = meta.touched && meta.error ? 'error' : 'success';
  const message = status === 'error' ? t(meta.error as any) : '\u00A0';

  return (
    <Form.Item
      htmlFor={id}
      label={label}
      validateStatus={status}
      help={message}
      required={required}
      style={containerStyle}
    >
      <Input.Password
        {...rest}
        id={id}
        value={value}
        required={required}
        onChange={(event) => {
          helpers.setValue(event.target.value);
        }}
        onBlur={() => helpers.setTouched(true)}
        iconRender={(visible) =>
          visible ? <EyeTwoTone /> : <EyeInvisibleOutlined />
        }
      />
    </Form.Item>
  );
}

export type TextAreaFieldProps<FormValues> = TextAreaProps & {
  label: string | ReactNode;
  name: RecursiveFormNames<FormValues>;
  id: string;
  labelCol?: ColProps;
  containerClassName?: string;
};

export function TextAreaField<FormValues>({
  label,
  name,
  id,
  labelCol,
  containerClassName,
  required = false,
  ...rest
}: TextAreaFieldProps<FormValues>) {
  const { t } = useFieldTranslation();
  const [{ value }, meta, helpers] = useField({ name });

  const status = meta.touched && meta.error ? 'error' : 'success';
  const message = status === 'error' ? t(meta.error as any) : '\u00A0';

  return (
    <Form.Item
      htmlFor={id}
      label={label}
      validateStatus={status}
      help={message}
      labelCol={labelCol}
      className={containerClassName}
      required={required}
    >
      <Input.TextArea
        {...rest}
        id={id}
        value={value}
        onChange={(event) => {
          rest.onChange?.(event);
          helpers.setValue(event.target.value);
        }}
        onBlur={(...args) => {
          rest.onBlur?.(...args);
          helpers.setTouched(true);
        }}
      />
    </Form.Item>
  );
}
