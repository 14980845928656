import Analytics from 'analytics';
import gtm from '@analytics/google-tag-manager';
import config from 'config';

// Analytics is the interface used by GTM to define possible action
// as page or track
// A cookie is set by the cookieConsentBanner (see business/cookie/consentBanner.tsx)
// This cookie is used by GTM to define if it send the information to
// other marketing tools as defined by the client (could be GA, Hotjar, etc...)
// In case the user allow cookies, then in a second time deny them
// Cookies sets by the marketing tools (GA, Hotjar, etc...) are still in place
// But the events are not triggered anymore by GTM
// TL;DR : It works, but the cookie are not cleared.

const analytics = Analytics({
  app: 'kryptview',
  plugins: [
    gtm({
      containerId: config.analytics.gtm,
    }),
  ],
});

export const logEvent = (eventName: string, metadata?: object) => {
  if (config.analytics.enabled) {
    analytics.track(eventName, metadata);
  }
};

export const setUserId = (userId: string | undefined) => {
  if (config.analytics.enabled) {
    if (userId) {
      analytics.identify(userId);
    } else {
      analytics.reset();
    }
  }
};

export const logPageViewed = (path: string) => {
  if (config.analytics.enabled) {
    analytics.page({ path });
  }
};

export default analytics;
