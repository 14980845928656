import { Typography } from 'antd';
import React, { CSSProperties, useState } from 'react';
import Button from 'ui/button';
import { Card } from 'ui/cards';
import { Spacer } from 'ui/spacer';
import { ReactComponent as OuterSpace } from 'assets/illustrations/undraw_outer_space_re_u9vd.svg';
import { useThemes } from 'ui/theme';
import { useTranslation } from 'react-i18next';
import classNames from 'classnames';
import LoginModal, {
  LoginModalFormTabs,
} from 'business/user/components/login-modal';
import classes from './index.module.scss';

export interface BlurredFeatureProps {
  blurred: boolean;
  children: React.ReactNode;
  isCompact?: boolean;
  className?: string;
}
export function BlurredFeature({
  blurred,
  children,
  className,
  isCompact = false,
}: BlurredFeatureProps) {
  const { t } = useTranslation(['common']);
  const [theme] = useThemes();
  const [isLoginModalOpen, setIsProfileModalOpen] = useState(false);

  return (
    <div className={classes.container}>
      <div
        className={classNames(
          { [classes['blurred-feature']]: blurred },
          className,
        )}
      >
        {children}
      </div>
      {blurred ? (
        <div className={classes.infos}>
          <Card
            type="default"
            className={classes.card}
            style={
              {
                '--blurred-feature-background-card':
                  theme.current === 'light' ? '#ffffff80' : '#00000050',
              } as CSSProperties
            }
          >
            <Spacer direction="vertical" space={2}>
              <Spacer space={2} align="center" style={{ flexWrap: 'wrap' }}>
                {!isCompact && (
                  <OuterSpace
                    width={92}
                    height={80}
                    className={classes['outer-space']}
                  />
                )}
                <Typography.Text>
                  {t('blurred-feature.description')}
                </Typography.Text>
              </Spacer>
              {!isCompact && (
                <Button
                  type="primary"
                  onClick={() => setIsProfileModalOpen(true)}
                >
                  {t('blurred-feature.cta')}
                </Button>
              )}
            </Spacer>
          </Card>
        </div>
      ) : null}
      <LoginModal
        isOpen={isLoginModalOpen}
        setIsOpen={setIsProfileModalOpen}
        startTab={LoginModalFormTabs.signup}
      />
    </div>
  );
}
