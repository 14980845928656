import React, { CSSProperties, PropsWithChildren, ReactNode } from 'react';
import { ReactComponent as DesignObjectiveSVG } from 'assets/illustrations/design-objectives.svg';
import { Card, CardProps } from 'ui/cards';
import { Typography } from 'antd';
import { useTranslation } from 'react-i18next';
import { Spacer } from 'ui/spacer';
import classnames from 'classnames';
import classes from './index.module.scss';

interface PlaceHolderProps {
  message?: string;
  alignVertically?: boolean;
  maxSizeIllustration?: number | string;
  className?: string;
  style?: CSSProperties;
}

export const PlaceHolder: React.FC<PlaceHolderProps> = ({
  message,
  className,
  maxSizeIllustration = 150,
  style,
}) => {
  const { t } = useTranslation(['common']);

  return (
    <Spacer
      space={1}
      direction="vertical"
      align="center"
      className={classnames([classes.container, className])}
      style={style}
    >
      <DesignObjectiveSVG
        style={{
          maxWidth: maxSizeIllustration,
          maxHeight: maxSizeIllustration,
        }}
      />
      <Typography.Text className={classes.text}>
        {message ?? t('comingSoon')}
      </Typography.Text>
    </Spacer>
  );
};

export const PlaceHolderCard: React.FC<
  PropsWithChildren<Omit<CardProps, 'children'> & PlaceHolderProps>
> = ({ message, className, children, ...props }) => {
  return (
    <Card className={classnames([className])} {...props}>
      {children}
      <PlaceHolder message={message} />
    </Card>
  );
};
