import React from 'react';
import classnames from 'classnames';
import { Typography } from 'antd';
import { getAssociatedColor } from 'ui/percentage/services/utils';
import { ArrowUpFilled, ArrowDownFilled } from 'ui/icons';
import { defaultTokenEmptyValue } from 'business/token/common-values';
import { TypographyProps } from 'antd/lib/typography/Typography';
import { NumericValueType } from 'ui/numeric';
import { i18n } from 'translations';
import styles from './index.module.scss';

const percentageOptions: Intl.NumberFormatOptions = {
  style: 'percent',
  signDisplay: 'never',
  maximumFractionDigits: 2,
};

export interface PercentageValueProps extends TypographyProps<any> {
  value?: NumericValueType;
  emptyValue?: string;
  strong?: boolean;
  colored?: boolean;
  options?: Intl.NumberFormatOptions;
}

export const PercentageValue: React.VFC<PercentageValueProps> = ({
  value,
  emptyValue = defaultTokenEmptyValue,
  colored = true,
  options = {},
  ...rest
}) => {
  const { color, valueExist } = getAssociatedColor(value);
  const allOptions = {
    ...percentageOptions,
    ...options,
  };

  let formattedValue = emptyValue;
  if (valueExist) {
    formattedValue = (value!.valueOf() / 100).toLocaleString(
      i18n.language,
      allOptions,
    );
  }

  return (
    <Typography.Text type={color} className={styles.percentWrapper} {...rest}>
      {valueExist && (value! < 0 ? <ArrowDownFilled /> : <ArrowUpFilled />)}
      <span
        className={classnames(styles.value, {
          [styles.valuePresence]: valueExist,
        })}
      >
        {formattedValue}
      </span>
    </Typography.Text>
  );
};
