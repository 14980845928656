import { Typography } from 'antd';
import React from 'react';
import { Spacer } from 'ui/spacer';
import classes from './index.module.scss';

interface EmbedVideoLinkProps {
  title: string;
  link: string;
}

const EmbedVideoLink = ({ title, link }: EmbedVideoLinkProps) => {
  return (
    <Spacer space={1} direction="vertical">
      <div className={classes.video}>
        <iframe
          src={link}
          title={title}
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
          allowFullScreen
          style={{ width: '100%', height: '100%' }}
        />
      </div>
      <Typography.Text className={classes.link}>{title}</Typography.Text>
    </Spacer>
  );
};

export default EmbedVideoLink;
