import React from 'react';
import { ModalProps, notification, Typography } from 'antd';
import { useAppContext } from 'business/app-bootstrapper';
import {
  useSelectUserProfileQuery,
  useUpsertUserProfileMutation,
} from 'generated/graphql';
import { useTranslation } from 'react-i18next';
import Button from 'ui/button';
import { Form } from 'ui/form';
import { TextAreaField, TextField } from 'ui/form/fields';
import { Modal } from 'ui/modal';
import { Spacer } from 'ui/spacer';
import { SelectUserProfile } from 'business/user/services/queries.gql';
import { Card } from 'ui/cards';
import classNames from 'classnames';
import { getValidationSchema } from './get-validation-schema';
import { ProfileUpdateFormValues } from './types';
import classes from './index.module.scss';

function formatFormValues(
  formValues: ProfileUpdateFormValues,
): ProfileUpdateFormValues {
  return {
    ...formValues,
    displayName: formValues.displayName,
    discord: formValues.discord !== '' ? formValues.discord : null,
    reddit: formValues.reddit !== '' ? formValues.reddit : null,
    telegram: formValues.telegram !== '' ? formValues.telegram : null,
    twitter: formValues.twitter !== '' ? formValues.twitter : null,
    youtube: formValues.youtube !== '' ? formValues.youtube : null,
  };
}

const socialNetworksFieldKeys: (keyof ProfileUpdateFormValues)[] = [
  'discord',
  'reddit',
  'telegram',
  'twitter',
  'youtube',
];

interface ProfileUpdateModalProps extends Pick<ModalProps, 'open'> {
  setIsVisible: (isVisible: boolean) => void;
  onCompleted: () => void;
}

export function ProfileUpdateModal({
  open,
  setIsVisible,
  onCompleted,
}: ProfileUpdateModalProps) {
  const { t } = useTranslation(['user']);
  const { user, refetchCurrentUser } = useAppContext();
  const { data } = useSelectUserProfileQuery({
    // @ts-expect-error
    variables: { userId: user?.id },
  });
  const [upsertUserProfile, { loading }] = useUpsertUserProfileMutation({
    refetchQueries: [SelectUserProfile],
    onCompleted: () => {
      notification.success({ message: t('user:profile-update.success') });
      setIsVisible(false);
      onCompleted();
      refetchCurrentUser().catch(() => {
        notification.error({ message: t('user:profile-update.error-refetch') });
      });
    },
    onError: () => {
      notification.error({ message: t('user:profile-update.error') });
    },
  });

  const validationSchema = getValidationSchema(t);

  const initialValues: ProfileUpdateFormValues = {
    displayName: data?.userProfile?.displayName ?? '',
    description: data?.userProfile?.description ?? null,
    discord: data?.userProfile?.discord ?? null,
    reddit: data?.userProfile?.reddit ?? null,
    telegram: data?.userProfile?.telegram ?? null,
    twitter: data?.userProfile?.twitter ?? null,
    youtube: data?.userProfile?.youtube ?? null,
  };

  return (
    <Modal
      centered
      open={open}
      width={1024}
      closable
      destroyOnClose
      title={
        <Typography.Title level={3} className={classes.modalTitle}>
          {t('user:profile-update.title')}
        </Typography.Title>
      }
      footer={null}
      onCancel={() => setIsVisible(false)}
    >
      <Form<ProfileUpdateFormValues>
        onSubmit={(formValues: ProfileUpdateFormValues) => {
          upsertUserProfile({
            variables: {
              // @ts-expect-error
              userId: user?.id,
              ...formatFormValues(formValues),
            },
          });
        }}
        layout="horizontal"
        initialValues={initialValues}
        validationSchema={validationSchema}
      >
        {() => (
          <Spacer direction="vertical">
            <TextField<ProfileUpdateFormValues>
              id="displayName" // test
              name="displayName"
              placeholder={t('user:profile-update.placeholders.display-name')}
              className={classNames(classes.nameInput, classes.input)}
              containerClassName={classes.inputContainer}
              label={`${t('user:profile-update.labels.display-name')}::`}
              labelCol={{ span: 24 }}
            />

            <TextAreaField<ProfileUpdateFormValues>
              id="description"
              name="description"
              rows={3}
              className={classes.input}
              placeholder={t('user:profile-update.placeholders.description')}
              containerClassName={classes.inputContainer}
              label={`${t('user:profile-update.labels.description')}::`}
              labelCol={{ span: 24 }}
            />
            <Card
              type="well"
              className={classes.socialNetworksTextFieldsSpacer}
            >
              <Typography className={classes.socialNetworksTitle}>
                {t('user:profile-update.social-networks-title')}
              </Typography>
              {socialNetworksFieldKeys.map((key) => (
                <TextField<ProfileUpdateFormValues>
                  key={key}
                  label=""
                  id={key}
                  name={key}
                  className={classes.socialNetworksTextField}
                  addonBefore={t(`user:profile-update.labels.${key}`)}
                  placeholder={t(`user:profile-update.placeholders.${key}`)}
                />
              ))}
            </Card>
            <Spacer justify="end">
              <Button type="primary" loading={loading} htmlType="submit">
                {t('user:profile-update.validate')}
              </Button>
            </Spacer>
          </Spacer>
        )}
      </Form>
    </Modal>
  );
}
