import React from 'react';
import { TokenAbout } from 'generated/graphql';
import { Spacer } from 'ui/spacer';
import { SimpleSocialButtons } from 'business/social-network/components/simple-social-buttons';
import { SocialNetwork, SocialNetworkKey } from 'business/social-network/types';

const socialNetworkKeys: SocialNetworkKey[] = [
  'discord',
  'facebook',
  'reddit',
  'telegram',
  'twitter',
  'youtube',
];

interface TokenSocialNetworkProps
  extends Pick<
    TokenAbout,
    | 'contract'
    | 'discord'
    | 'facebook'
    | 'reddit'
    | 'telegram'
    | 'twitter'
    | 'website'
    | 'whitepaper'
    | 'youtube'
  > {
  size?: 'small' | 'medium';
}

export function TokenSocialNetwork({
  size = 'medium',
  ...values
}: TokenSocialNetworkProps) {
  const socialNetworks: SocialNetwork[] = socialNetworkKeys
    .map((key) => ({ key, value: values[key] }))
    .filter((sn): sn is SocialNetwork => !!sn.value);

  return (
    <Spacer direction="horizontal" align="center" space={2}>
      <SimpleSocialButtons socialNetworks={socialNetworks} size={size} />
    </Spacer>
  );
}
