import React from 'react';
import { Group } from '@visx/group';
import { scaleLinear } from '@visx/scale';
import { Circle, LineRadial } from '@visx/shape';
import { Text } from '@visx/text';
import { RadialGradient } from '@visx/gradient';
import variables from 'ui/theme/variables.module.scss';
import { getCategoryColor } from 'business/research/utils/get-category-color';
import { createRand, createSeed } from './random';

const defaultMargin = { top: 40, left: 80, right: 80, bottom: 80 };
const degrees = 360;

/**
 * TODO:
 * - refactor components
 *    - add everything in the same componet
 * - add hover styles + tooltip ?
 *    - https://airbnb.io/visx/docs/tooltip
 * - better color (gradient ?)
 *    - example here https://airbnb.io/visx/areas
 * - add comments about the maths behing this
 * - find better maths for the dispersion algorithm
 *    to create a better circle around the data
 * - find better maths for the point placements
 *    points should not be able to go over the last radar line
 *    but is it ok visually to shift points slitely like this ?
 * - section labels rn uses some dy dx shenanigans to be placed correctly,
 *    we should find a better way of doing this
 */

interface DispersionChartProps {
  data: Array<{
    mean: number;
    name: string;
    points: Array<number>;
    categoryId: string;
  }>;
  width: number;
  height: number;
  scale: Array<string>;
  margin?: { top: number; right: number; bottom: number; left: number };
}
export default function DispersionChart({
  data,
  scale,
  height,
  width,
  margin = defaultMargin,
}: DispersionChartProps) {
  const lgDisplayBreakpoint = 576;
  const smDisplayOffset = 230;

  const isLarge = width > lgDisplayBreakpoint;

  const xMax = isLarge ? width - margin.left - margin.right : width;
  const yMax = isLarge ? height - margin.top - margin.bottom : height;
  const radius = Math.min(xMax, yMax) / 2;

  const mobileHeight = height + smDisplayOffset;

  const stepAngle = (Math.PI * 2) / data.length;

  const radialStart = Math.PI;
  const radialOffset = stepAngle / 2;

  const radialScale = scaleLinear<number>({
    range: [
      radialStart - radialOffset,
      radialStart - radialOffset + Math.PI * 2,
    ],
    domain: [0, degrees],
  });

  const yScale = scaleLinear<number>({
    range: [0, radius],
    domain: [0, 1],
  });

  const webs = Array.from({ length: data.length + 1 }, (_, i) => ({
    angle: i * (degrees / data.length),
  }));

  const seed = createSeed('random-word');
  const rand = createRand(seed());

  const points = data.map((datum, i) => {
    const angle = i * stepAngle - radialOffset;
    const x = yScale(datum.mean) * Math.sin(angle);
    const y = yScale(datum.mean) * Math.cos(angle);
    const color = getCategoryColor(datum.categoryId);
    return { x, y, color };
  });

  const dispersion = data.flatMap((datum, i) => {
    return datum.points.map((value) => {
      const distance = Math.abs(value - datum.mean);
      const range = (stepAngle * (1.15 - value) * (1 - distance)) / 2;
      const randomOffset = rand({ min: -range, max: range });
      const angle = i * stepAngle - radialOffset + randomOffset;
      return {
        x: yScale(value) * Math.sin(angle),
        y: yScale(value) * Math.cos(angle),
      };
    });
  });

  const polygon = points.map(({ x, y }) => `${x},${y}`).join(' ');

  return (
    <svg height={isLarge ? height : mobileHeight} width={width}>
      <RadialGradient
        id="area-background-gradient"
        from="#706df6"
        fromOpacity={0}
        to="#706df6"
        toOpacity={0.6}
      />
      <Group top={height / 2} left={width / 2}>
        {scale.map((_label, i) => {
          const currentRadius = ((i + 1) * radius) / scale.length;
          return (
            <LineRadial
              // eslint-disable-next-line react/no-array-index-key
              key={`web-${i}`}
              data={webs}
              angle={(d) => radialScale(d.angle) ?? 0}
              radius={currentRadius}
              fill="none"
              stroke="#8C9695"
              strokeWidth={1}
              strokeOpacity={0.8}
              strokeLinecap="round"
            />
          );
        })}
        {dispersion.map((point, i) => (
          <circle
            // eslint-disable-next-line react/no-array-index-key
            key={`radar-point-${i}`}
            cx={point.x}
            cy={point.y}
            r={2}
            fill="#757576"
          />
        ))}
        <polygon
          points={polygon}
          fill="url(#area-background-gradient)"
          fillOpacity={0.2}
          stroke="#706df6a0"
          strokeWidth={2}
        />
        {points.map((point, i) => (
          <circle
            // eslint-disable-next-line react/no-array-index-key
            key={`radar-point-${i}`}
            cx={point.x}
            cy={point.y}
            r={5}
            fill={point.color}
          />
        ))}
        <Group style={{ display: isLarge ? 'block' : 'none' }}>
          {data.map((datum, i) => {
            const angle = i * stepAngle - radialOffset;
            // we place the labels 12% after the end of the radar
            const position = 1.12;

            const x = yScale(position) * Math.sin(angle);
            const y = yScale(position) * Math.cos(angle);

            const dy = Math.sign(y) * (Math.abs(y) >= 0.75 * radius ? 0.5 : 0);
            const dx = Math.sign(x) * (Math.abs(x) >= 1 * radius ? 2 : 0);

            return (
              <Text
                // eslint-disable-next-line react/no-array-index-key
                key={`name-${i}`}
                fill={variables.textColor}
                width={80}
                x={x}
                y={y}
                dy={`${dy}em`}
                dx={`${dx}em`}
                fontSize={14}
                textAnchor="middle"
                verticalAnchor="middle"
                pointerEvents="none"
              >
                {datum.name}
              </Text>
            );
          })}
        </Group>
        {scale.map((label, i) => {
          const currentRadius = ((i + 1) * radius) / scale.length;
          const textPosition =
            Math.sin((Math.PI - stepAngle) / 2) * currentRadius;
          return (
            <Text
              // eslint-disable-next-line react/no-array-index-key
              key={`web-${i}`}
              fill={variables.textColor}
              x={0}
              y={textPosition}
              dy="-0.4em"
              fontSize={12}
              textAnchor="middle"
              pointerEvents="none"
            >
              {label}
            </Text>
          );
        })}
      </Group>
      <Group
        top={height}
        left={20}
        style={{ display: isLarge ? 'none' : 'block' }}
      >
        {data.map((datum, i) => {
          const y = 40 * i;

          return (
            // eslint-disable-next-line react/no-array-index-key
            <Group key={`name-${i}`}>
              <Circle
                cx="0"
                cy={y + 6}
                r="5"
                fill={getCategoryColor(datum.categoryId)}
              />
              <Text
                fill={variables.textColor}
                width={width}
                x={20}
                y={y}
                fontSize={16}
                fontWeight={600}
                textAnchor="start"
                verticalAnchor="start"
                pointerEvents="none"
              >
                {datum.name}
              </Text>
            </Group>
          );
        })}
      </Group>
    </svg>
  );
}
