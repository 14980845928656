import React from 'react';
import { Spacer } from 'ui/spacer';
import { TextField } from 'ui/form/fields';
import Button from 'ui/button';
import {
  PortfolioFormAllocatedToken,
  PortfolioFormValues,
} from 'business/portfolio/types';
import { Card } from 'ui/cards';
import { TokenThumbnail } from 'business/token/components/token-thumbnail';
import { DeleteOutlined } from '@ant-design/icons';
import { Skeleton, Typography } from 'antd';
import { formatPercent } from 'technical/currency/formatters';
import styles from 'business/portfolio/components/token-card/index.module.scss';

interface TokenCardProps {
  tokenRepartition: PortfolioFormAllocatedToken;
}

interface EditableTokenCardProps extends TokenCardProps {
  index: number;
  isEditable?: boolean;
  onDeleteToken?: () => void;
}

function EditableTokenCard({
  tokenRepartition,
  isEditable,
  onDeleteToken,
  index,
}: EditableTokenCardProps) {
  const { token, price, h24PriceChange } = tokenRepartition;

  return (
    <Card key={token.id} className={styles.tokenCard} type="well">
      <Spacer direction="horizontal" align="center" justify="between" space={2}>
        <TokenThumbnail
          token={token}
          price={price}
          h24PriceChange={h24PriceChange}
        />
        <Spacer
          className={styles.inputsSpacer}
          direction="horizontal"
          space={1}
          align="center"
          justify="end"
        >
          <TextField<PortfolioFormValues>
            id="name"
            name={`allocatedTokens.${index}.percentage`}
            label=""
            type="number"
            min={0}
            max={100}
            size="small"
            suffix="%"
            bordered
            readOnly={!isEditable}
            className={isEditable ? styles.editableInput : styles.input}
          />

          {isEditable ? (
            <Button onClick={onDeleteToken} type="text">
              <DeleteOutlined />
            </Button>
          ) : null}
        </Spacer>
      </Spacer>
    </Card>
  );
}

function TokenCard({ tokenRepartition }: TokenCardProps) {
  const { token, price, h24PriceChange } = tokenRepartition;

  return (
    <Card key={token.id} className={styles.tokenCard} type="well">
      <Spacer direction="horizontal" align="center" justify="between" space={2}>
        <TokenThumbnail
          token={token}
          price={price}
          h24PriceChange={h24PriceChange}
        />

        <Spacer direction="horizontal" align="center" justify="end">
          <Typography.Text className={styles.ReadOnlyInput}>
            {formatPercent(tokenRepartition.percentage / 100)}
          </Typography.Text>
        </Spacer>
      </Spacer>
    </Card>
  );
}

const TokenCardSkeleton = () => {
  return (
    <Card className={styles.tokenCard} type="well">
      <Spacer space={1} align="center">
        <Skeleton.Avatar active />
        <Skeleton.Input active block />
      </Spacer>
    </Card>
  );
};

export default {
  Editable: EditableTokenCard,
  Readonly: TokenCard,
  Skeleton: TokenCardSkeleton,
};
