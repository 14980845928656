import React from 'react';
import {
  LeaderBoardProps,
  LeaderBoardTable,
  UserLeaderBoard,
} from 'business/user/components/leaderboard/index';
import { useGetLeaderboardQuery } from 'generated/graphql';
import { isDisplayableLeaderboardRow } from 'business/user/types/user';

export const GlobalLeaderboard: React.VFC<
  Pick<LeaderBoardProps, 'limit' | 'compact'>
> = ({ limit = 5, compact = false }) => {
  const { data, loading } = useGetLeaderboardQuery({ variables: { limit } });
  const users: UserLeaderBoard[] =
    data?.leaderboard.filter(isDisplayableLeaderboardRow).map((row) => ({
      ...row.user,
      position: row.position,
    })) ?? [];

  return (
    <LeaderBoardTable
      users={users}
      loading={loading}
      limit={limit}
      compact={compact}
    />
  );
};
