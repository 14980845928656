import React, { ReactNode } from 'react';
import { Typography } from 'antd';
import classnames from 'classnames';
import { formatDistanceToNow } from 'date-fns';
import { useTranslation } from 'react-i18next';
import { Spacer } from 'ui/spacer';
import { getDateFnsLocale } from 'technical/date-fns/locale';
import styles from './index.module.scss';

export interface ReviewBlockProps {
  questionBlock: string;
  dateLastReview?: string | null;
  dateHighlight?: boolean;
  icon?: ReactNode;
}

const ReviewBlock: React.FC<ReviewBlockProps> = ({
  questionBlock,
  dateLastReview,
  dateHighlight,
  icon,
}) => {
  const { t } = useTranslation(['form-wizard']);

  return (
    <Spacer direction="vertical" space={2} align="center">
      {icon}
      <Spacer direction="vertical" space="none">
        <Typography.Title level={4} className={styles.questionBlock}>
          {questionBlock}
        </Typography.Title>
        <Typography.Paragraph
          strong={dateHighlight}
          className={classnames([
            dateHighlight ? styles.highLight : undefined,
            styles.date,
          ])}
        >
          {dateLastReview
            ? formatDistanceToNow(new Date(dateLastReview), {
                addSuffix: true,
                locale: getDateFnsLocale(),
              })
            : t('form-wizard:categorySelection.never-researched')}
        </Typography.Paragraph>
      </Spacer>
    </Spacer>
  );
};

export default ReviewBlock;
