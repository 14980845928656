import classnames from 'classnames';
import colors from 'ui/theme/variables.module.scss';
import React from 'react';
import { Link as ReactRouterLink } from 'react-router-dom';
import styles from './index.module.scss';

interface Props {
  to: string;
  children: React.ReactNode;
  prefix?: string;
  color?: keyof typeof colors;
  align?: 'center' | 'left' | 'right';
  className?: string;
  onClick?: () => void;
  underline?: boolean;
}

export default function Link({
  to,
  children,
  prefix,
  className,
  align,
  color,
  onClick,
  underline = true,
}: Props) {
  const LinkComponent = (
    <ReactRouterLink
      className={classnames(underline && styles.link, className)}
      style={{ color: color ? colors[color] : undefined, textAlign: align }}
      onClick={onClick}
      to={to}
    >
      {children}
    </ReactRouterLink>
  );

  if (prefix) {
    return (
      <p
        className={classnames(className, styles.prefixedLink)}
        style={{ textAlign: align }}
      >
        {prefix}
        {/* Space between the prefix and the link */}
        {` `}
        {LinkComponent}
      </p>
    );
  }

  return LinkComponent;
}
