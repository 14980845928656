import React, { ReactNode, useState } from 'react';
import { ConfigProvider, List, Tag, Typography } from 'antd';
import KvtBalloonSvg from 'assets/illustrations/kvt-balloon.svg';
import {
  RewardResponse,
  useGetUserRewardsDetailsQuery,
} from 'generated/graphql';
import { useTranslation } from 'react-i18next';
import { tokenExt } from 'business/reward-point/components/earned-rewards-card';
import { Empty } from 'ui/empty-state';

interface RewardListProps {
  rewards: Pick<
    RewardResponse,
    'id' | 'tokenAmount' | 'createdAt' | 'status'
  >[];
  isLoading?: boolean;
  page: number;
  setPage: (newPage: number) => void;
  total?: number;
  footer?: ReactNode;
}

const statusColor: {
  [key: string]: 'processing' | 'success';
} = {
  unclaimed: 'processing',
  claimed: 'success',
};

export const RewardList: React.VFC<RewardListProps> = ({
  rewards,
  isLoading,
  page,
  setPage,
  total,
  footer,
}) => {
  const { t } = useTranslation(['rewardPoint']);

  return (
    <ConfigProvider
      renderEmpty={() => (
        <Empty
          image={KvtBalloonSvg}
          imageStyle={{ minHeight: 180 }}
          description={t('rewardPoint:noRewards.message')}
        />
      )}
    >
      <List<Pick<RewardResponse, 'id' | 'tokenAmount' | 'createdAt' | 'status'>>
        header={t('rewardPoint:yourRewards')}
        loading={isLoading}
        footer={footer}
        pagination={{
          total,
          current: page,
          pageSize: 10,
          hideOnSinglePage: true,
          onChange: (newPage) => setPage(newPage),
        }}
        dataSource={rewards}
        renderItem={(item) => (
          <List.Item style={{ justifyContent: 'space-between' }} key={item.id}>
            <Typography.Text
              strong
            >{`${item.tokenAmount} ${tokenExt}`}</Typography.Text>
            <Tag color={statusColor[item.status]}>{item.status}</Tag>
          </List.Item>
        )}
      />
    </ConfigProvider>
  );
};

interface UserRewardListProps {
  noRewardActions?: ReactNode;
}

export const UserRewardList: React.VFC<UserRewardListProps> = ({
  noRewardActions,
}) => {
  const [page, setPage] = useState(1);
  const { data, loading } = useGetUserRewardsDetailsQuery({
    variables: { page },
  });

  return (
    <RewardList
      total={data?.getUserRewardsDetails?.total}
      rewards={data?.getUserRewardsDetails?.rewards ?? []}
      isLoading={loading}
      page={page}
      setPage={setPage}
      footer={data?.getUserRewardsDetails?.total ? undefined : noRewardActions}
    />
  );
};
