import React from 'react';
import { FilterDropdownProps } from 'antd/lib/table/interface';
import TokenSelect from 'business/token/components/token-select';
import { TFunction } from 'i18next';
import { Spacer } from 'ui/spacer';
import Button from 'ui/button';
import { selectValueToList } from 'technical/form/select-value-to-list';
import classes from './index.module.scss';

export function tokenFilterDropdown(t: TFunction) {
  return ({
    clearFilters,
    selectedKeys,
    setSelectedKeys,
    confirm,
  }: FilterDropdownProps) => {
    return (
      <Spacer direction="vertical" space={1} className={classes.filter}>
        <TokenSelect
          placeholder={t('token:research.table.token-filter-placeholder')}
          allowClear={false}
          value={selectedKeys.filter(
            (key): key is string => typeof key === 'string',
          )}
          onChange={(value) => {
            setSelectedKeys(selectValueToList(value));
            confirm({ closeDropdown: false });
          }}
          field="reference"
          style={{ width: '100%' }}
          mode="multiple"
          onClear={clearFilters}
        />
        {selectedKeys.length > 0 && (
          <Button
            onClick={() => {
              clearFilters?.();
              confirm({ closeDropdown: true });
            }}
          >
            {t('token:research.table.clear-filter')}
          </Button>
        )}
      </Spacer>
    );
  };
}
