export function priceToVariation(price: number, originalPrice: number) {
  // dividing current price by original price we get a percentage between 0 and +infinity
  // we want our percentage scale to go from -1 (-100%) to +infinity
  // for this we remove 1 (100%) from the result
  return price / originalPrice - 1;
}

export function variationToPrice(variation: number, originalPrice: number) {
  // our percentage scale goes from -1 (-100%) to +infinity
  // to compute a price given a percentage we need to change our scale to 0 (0%) and +infinity
  // for this we add 1 (100%) to the percentage
  return (variation + 1) * originalPrice;
}
