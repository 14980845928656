import React from 'react';
import { PortfolioContainer } from 'business/portfolio/components/portfolio-container';
import { PublicPortfolioResponse } from 'generated/graphql';
import { PortfolioTitle } from 'business/portfolio/components/portfolio-title';
import { Typography } from 'antd';
import { useTranslation } from 'react-i18next';
import { Spacer } from 'ui/spacer';
import TokenCard from 'business/portfolio/components/token-card';
import { PortfolioPublicationInfo } from 'business/portfolio/components/portfolio-publication-info';
import { RemoveTypenames } from 'technical/graphql/remove-typenames';
import classes from './index.module.scss';

interface PublicPortfolioProps {
  portfolio: Pick<
    RemoveTypenames<PublicPortfolioResponse>,
    | 'name'
    | 'lastPublicationDate'
    | 'visibility'
    | 'lastPublishedUpdate'
    | 'updateFrequencyInDays'
    | 'd7Perf'
    | 'lastUpdatePerf'
  >;
}

export const PublicPortfolio: React.VFC<PublicPortfolioProps> = ({
  portfolio,
}) => {
  const { t } = useTranslation(['portfolio']);

  const allocatedTokens = portfolio.lastPublishedUpdate?.allocatedTokens ?? [];
  return (
    <PortfolioContainer>
      <Spacer direction="vertical" space={1}>
        <PortfolioTitle.ReadOnly
          name={portfolio.name}
          status={portfolio.visibility}
        />
        <Typography.Text strong>
          {t('portfolio:form.allocations')}
        </Typography.Text>
        <Spacer
          className={classes.tokenContainer}
          direction="vertical"
          space={1}
        >
          {allocatedTokens.map((tokenRepartition) => (
            <TokenCard.Readonly
              key={tokenRepartition.token.id}
              tokenRepartition={tokenRepartition}
            />
          ))}
        </Spacer>
        <PortfolioPublicationInfo
          updateFrequencyInDays={portfolio.updateFrequencyInDays}
          lastPublicationDate={
            portfolio.lastPublicationDate
              ? new Date(portfolio.lastPublicationDate)
              : undefined
          }
          d7Perf={portfolio.d7Perf}
          lastUpdatePerf={portfolio.lastUpdatePerf}
        />
      </Spacer>
    </PortfolioContainer>
  );
};
