import React, { FC } from 'react';
import Tag from 'ui/tag';
import { ratingTagColors } from 'ui/rating-tag/services/utils';
import classNames from 'classnames';
import { useTranslation } from 'react-i18next';
import { isOfRatingType, RatingType } from 'business/rating/types';
import classes from './index.module.scss';

export interface RatingTagProps {
  rating?: string;
  type?: RatingType | string;
  className?: string;
}

const RatingTag: FC<RatingTagProps> = ({
  rating,
  type: rowType = 'unknown',
  className,
}) => {
  const { t } = useTranslation(['common']);
  const type = rowType && isOfRatingType(rowType) ? rowType : 'unknown';
  const isUnknownType = type === 'unknown';
  return (
    <Tag
      color={ratingTagColors[type || 'unknown'].basic}
      className={classNames(className, classes.ratingTag, {
        [classes.unknown]: isUnknownType,
      })}
    >
      {rating ?? t('no-rating')}
    </Tag>
  );
};

export default RatingTag;
