import React, { useMemo, useState } from 'react';
import { Typography, TableColumnProps, Image, Tooltip } from 'antd';
import { useTranslation } from 'react-i18next';
import { Spacer } from 'ui/spacer';
import Table from 'ui/table';
import {
  Token,
  TokenMarkets,
  TokenTicker,
  useGetTokenTickersQuery,
} from 'generated/graphql';
import Link from 'antd/lib/typography/Link';
import Button from 'ui/button';
import classnames from 'classnames';
import { FieldTimeOutlined, QuestionOutlined } from '@ant-design/icons';
import { NumericValue, NumberFormats } from 'ui/numeric';
import classes from './index.module.scss';
import { TFunction } from 'i18next';

const maxPairLength = 5;

export interface TableTokenTickerRow extends Omit<TokenTicker, '__typename'> {}

const getColumns = (
  t: TFunction<'token'>,
  totalVolume: number | undefined,
): TableColumnProps<TableTokenTickerRow>[] => [
  {
    key: 'ticker-table-column-logo',
    title: undefined,
    dataIndex: 'image',
    render: (_, { name, image, link }) => (
      <Link className="clickable" href={link ?? undefined}>
        <Tooltip title={name}>
          <Image
            className={classnames(classes.columnImage, 'clickable')}
            src={image ?? undefined}
            preview={false}
          />
        </Tooltip>
      </Link>
    ),
  },
  {
    key: 'ticker-table-column-pairs',
    title: t('token:infos.market.columns.pairs'),
    render: (_, { base, target, link }) => {
      const baseText =
        base && base.length > maxPairLength
          ? `${base.substring(0, maxPairLength)}...`
          : base;
      const targetText =
        target && target.length > maxPairLength
          ? `${target.substring(0, maxPairLength)}...`
          : target;

      return (
        <Link className="clickable" href={link ?? undefined}>
          <Spacer direction="horizontal" space={1}>
            <Typography.Text
              className={classes.columnPairs}
            >{`${baseText} / ${targetText}`}</Typography.Text>
          </Spacer>
        </Link>
      );
    },
  },
  {
    key: 'ticker-table-column-price',
    title: t('token:infos.market.columns.price'),
    dataIndex: 'price',
    render: (_, { price, isStale, isAnomaly }) => (
      <Spacer direction="horizontal" space={1} align="center">
        <NumericValue format={NumberFormats.currency} value={price} />
        {isAnomaly && (
          <Tooltip overlay={t('token:infos.market.columns.tooltips.anomaly')}>
            <QuestionOutlined className={classes.columnPriceAnomalyIcon} />
          </Tooltip>
        )}
        {isStale && (
          <Tooltip overlay={t('token:infos.market.columns.tooltips.stale')}>
            <FieldTimeOutlined className={classes.columnPriceStaleIcon} />
          </Tooltip>
        )}
      </Spacer>
    ),
  },
  {
    key: 'ticker-table-column-volume',
    title: t('token:infos.market.columns.volume'),
    dataIndex: 'volume',
    render: (value: Number) => value && `$ ${value.toLocaleString('en-US')}`,
  },
  {
    key: 'ticker-table-column-volume-percentage',
    title: t('token:infos.market.columns.volume-percentage'),
    render: (_, { volume }) =>
      volume && totalVolume && totalVolume >= volume
        ? `${(volume / totalVolume).toPrecision(2)}%`
        : '-',
  },
];

export interface TokenMarketTabProps
  extends Pick<Token, 'reference'>,
    Pick<TokenMarkets, 'volume'> {}

export function TokenMarketTab({ reference, volume }: TokenMarketTabProps) {
  const { t } = useTranslation(['token']);
  const [page, setPage] = useState(1);
  const { loading, data, previousData } = useGetTokenTickersQuery({
    variables: {
      reference,
      page: 1 + Math.floor((page - 1) / 10),
    },
  });

  const tickersData =
    data?.getTokenTickers.tickers ??
    previousData?.getTokenTickers.tickers ??
    [];
  const count = tickersData.length;

  const tickers = useMemo(() => {
    if (!tickersData || tickersData.length === 0) {
      return [];
    }

    const index = ((page - 1) * 10) % 100;
    return tickersData.slice(index, index + 10);
  }, [tickersData, page]);

  return (
    <Spacer direction="vertical" space={2}>
      <Typography.Text strong>{t('token:infos.market.title')}</Typography.Text>
      <Table<TableTokenTickerRow>
        loading={loading}
        dataSource={tickers}
        columns={getColumns(t, volume ?? undefined)}
        pagination={false}
        scroll={{ x: true }}
      />
      <Spacer
        direction="horizontal"
        space={1}
        style={{ justifyContent: 'flex-end' }}
      >
        <Button
          disabled={loading || page <= 1}
          onClick={() => {
            setPage(page - 1);
          }}
        >
          {t('token:infos.market.previous')}
        </Button>
        <Button
          disabled={loading || count !== 100}
          onClick={() => {
            setPage(page + 1);
          }}
        >
          {t('token:infos.market.next')}
        </Button>
      </Spacer>
    </Spacer>
  );
}
