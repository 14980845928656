import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useThemes } from 'ui/theme';
import { Result } from 'antd';
import { useTradingViewWidgets } from './tradingview-widgets';
import classes from './tradingview-charts.module.scss';

export interface TradingViewChartsProps {
  symbol: string;
  exchange?: string;
  type?: 'simple' | 'ticker';
}
export function TradingViewCharts({
  symbol,
  exchange = 'BINANCE',
  type = 'simple',
}: TradingViewChartsProps) {
  const [stableId] = useState(Math.random().toString(36).substring(2, 9));

  const { i18n, t } = useTranslation(['token']);
  const [{ current }] = useThemes();

  const timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;

  const tradingview = useTradingViewWidgets();

  useEffect(() => {
    tradingview.renderWidget?.(
      `tradingview-widget-simple-${stableId}`,
      'MediumWidget',
      {
        symbols: [[`${exchange}:${symbol.toUpperCase()}USD|1D`]],
        chartOnly: true,
        locale: i18n.language,
        colorTheme: current,
        gridLineColor: 'rgba(240, 243, 250, 0)',
        fontColor: '#787B86',
        isTransparent: true,
        showVolume: false,
        scalePosition: 'no',
        scaleMode: 'Normal',
        fontFamily: 'Trebuchet MS, sans-serif',
        noTimeScale: false,
        valuesTracking: '1',
        chartType: 'area',
        lineColor: '#706df6',
        topColor: '#706df670',
        bottomColor: '#706df600',
      },
    );

    tradingview.renderWidget?.(
      `tradingview-widget-ticker-${stableId}`,
      'widget',
      {
        symbol: `${exchange}:${symbol.toUpperCase()}USD`,
        interval: 'D',
        timezone,
        theme: current,
        style: '1',
        locale: i18n.language,
        toolbar_bg: '#f1f3f6',
        enable_publishing: false,
        withdateranges: false,
        hide_side_toolbar: false,
        allow_symbol_change: false,
      },
    );
  }, [
    tradingview.renderWidget,
    stableId,
    symbol,
    exchange,
    current,
    i18n.language,
    timezone,
  ]);

  return (
    <div className={classes.tradingview}>
      <div className={classes.charts}>
        <div
          id={`tradingview-widget-simple-${stableId}`}
          aria-hidden={type !== 'simple'}
        />
        <div
          id={`tradingview-widget-ticker-${stableId}`}
          aria-hidden={type !== 'ticker'}
        />
        {tradingview.status === 'error' ? (
          <div className={classes.error}>
            <Result
              status="error"
              title={t('token:trading-view.error.title')}
              subTitle={t('token:trading-view.error.sub-title')}
            />
          </div>
        ) : null}
      </div>
      <div className="tradingview-widget-copyright">
        {tradingview.status === 'script-loaded' ? (
          <>
            <div hidden={type !== 'simple'}>
              <a
                href={`https://www.tradingview.com/symbols/${symbol.toUpperCase()}USD/?exchange=${exchange}`}
                rel="noopener noreferrer"
                target="_blank"
                className={classes['copyright-link']}
              >
                <span>{symbol}USD Rates</span>
              </a>{' '}
              by TradingView
            </div>
            <div hidden={type !== 'ticker'}>
              <a
                href={`https://www.tradingview.com/symbols/${symbol.toUpperCase()}USD/?exchange=${exchange}`}
                rel="noopener noreferrer"
                target="_blank"
                className={classes['copyright-link']}
              >
                <span>{symbol}USD Chart</span>
              </a>{' '}
              by TradingView
            </div>
          </>
        ) : null}
      </div>
    </div>
  );
}
