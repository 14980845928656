import Routes from 'business/router/routes';
import { Maybe, Rank, User, UserProfile } from 'generated/graphql';
import React from 'react';
import { generatePath } from 'react-router-dom';
import { Spacer } from 'ui/spacer';
import { useTranslation } from 'react-i18next';
import Table from 'ui/table';
import { ColumnsType } from 'antd/es/table';
import { LinkAsButton } from 'ui/button';
import { Ranking } from 'ui/ranking';
import UserLink from '../user-link';
import classes from './index.module.scss';

export type UserLeaderBoard = Pick<User, 'id'> & {
  position: number | string;
  profile?: Maybe<Pick<UserProfile, 'displayName'>>;
  ranking?: Maybe<{ currentRank: Pick<Rank, 'icon' | 'name'> }>;
};

function generateRows(length: number): UserLeaderBoard[] {
  return Array.from({ length }, (_, i) => ({
    id: i.toString(),
    position: i + 1,
    profile: {
      displayName: `User ${i}`,
    },
    ranking: {
      currentRank: {
        name: `Unranked`,
        icon: `/Unranked.svg`,
      },
    },
  }));
}

export interface LeaderBoardProps {
  limit?: number;
  compact?: boolean;
  loading?: boolean;
  users?: UserLeaderBoard[];
}

export function LeaderBoardTable({
  limit = 5,
  compact = false,
  loading = false,
  users,
}: LeaderBoardProps) {
  const { t } = useTranslation(['common']);

  // const preview = data?.leaderboard ?? [];

  const preview = users;
  const columns: ColumnsType<UserLeaderBoard> = [
    {
      title: () => t('common:leaderboard.ranking'),
      dataIndex: 'position',
      render: (_, user) => (
        <Spacer space={1} align="center">
          <span className={classes.position}>{`#${user?.position}`}</span>
        </Spacer>
      ),
    },
    {
      title: t('common:leaderboard.author'),
      align: 'left',
      dataIndex: 'profile',
      render: (_, { id, profile }) => (
        <Spacer
          space={1}
          align="center"
          justify="start"
          className={classes['name-link-wrapper']}
        >
          {id && (
            <UserLink
              displayName={profile?.displayName || ''}
              id={id}
              hideAvatar={compact}
              className={classes.userLink}
            />
          )}
        </Spacer>
      ),
    },
    {
      title: t('common:leaderboard.rank'),
      dataIndex: 'rankIcon',
      render: (_, user) => (
        <Ranking compact={compact} rank={user?.ranking?.currentRank} />
      ),
    },
    {
      dataIndex: 'action',
      render: (_, user) =>
        !compact &&
        user && (
          <LinkAsButton
            type="default"
            to={generatePath(Routes.PublicProfile, { id: user.id })}
          >
            {t('common:leaderboard.view-profile')}
          </LinkAsButton>
        ),
    },
  ];

  return (
    <div className={classes['leaderboard-table']}>
      <Table<UserLeaderBoard>
        columns={compact ? columns.map(({ title, ...rest }) => rest) : columns}
        dataSource={loading ? generateRows(limit) : preview}
        loading={loading}
        pagination={{ pageSize: limit + 1, hideOnSinglePage: true }}
        rowKey={(user) => user.id}
      />
    </div>
  );
}
