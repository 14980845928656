import {
  CategoriesEnum,
  CategoriesEnumValues,
} from 'business/research/constants/category';

export const isCategoryEnum = (
  id: string | CategoriesEnumValues,
): id is CategoriesEnumValues => {
  return Object.values(CategoriesEnum).includes(id as CategoriesEnumValues);
};
