import { useHeadBandsQuery } from 'generated/graphql';
import React from 'react';
import { HeadBandComponent } from 'ui/head-band-component';
import { useLocaleStorage } from 'technical/storage/use-storage';
import * as yup from 'yup';
import { useHeadBand } from './services/head-band';

export function HeadBand() {
  const { filteredItems, setSkipedIds, loading, error } = useHeadBand();
  if (loading || error) {
    return null;
  }

  return (
    <HeadBandComponent
      isVisible={filteredItems.length > 0}
      onClose={setSkipedIds}
      items={filteredItems ?? []}
    />
  );
}
