import React, { useState } from 'react';
import { notification } from 'antd';
import { useTranslation } from 'react-i18next';
import { ResearchTable } from 'business/research/components/table';
import { Card } from 'ui/cards';
import {
  useCreateReviewMutation,
  useGetPendingDayRankedResearchesQuery,
} from 'generated/graphql';
import { researchPageOptions } from 'business/token/common-values';
import {
  useResearchTableFilters,
  researchTableOnChange,
} from 'business/research/services';
import { generatePath, useNavigate } from 'react-router-dom';
import Routes from 'business/router/routes';
import { GetPendingDayRankedResearches } from 'business/research/services/new-queries.gql';

interface PendingCommunityResearchesTabProps {
  tokenRefs?: string | undefined;
  title?: string;
}

export function PendingCommunityResearchesTab({
  tokenRefs,
  title,
}: PendingCommunityResearchesTabProps) {
  const { t } = useTranslation(['token']);
  const { filtersValues, setFiltersValues } = useResearchTableFilters();
  const navigate = useNavigate();

  const { page, pageSize, tokens, hasEnoughReviews } = filtersValues;

  const { data, previousData, loading } = useGetPendingDayRankedResearchesQuery(
    {
      variables: {
        tokens: tokenRefs ?? tokens,
        page,
        pageSize,
        hasEnoughReviews,
      },
    },
  );

  const [createReview] = useCreateReviewMutation({
    refetchQueries: [GetPendingDayRankedResearches],
    awaitRefetchQueries: true,
    onCompleted({ insert_research_peerReview_one }) {
      if (insert_research_peerReview_one?.id) {
        navigate(
          generatePath(Routes.PeerReviewPage, {
            researchId: insert_research_peerReview_one?.researchId,
          }),
        );
      }
    },
    onError() {
      notification.error({
        message: t('token:research.toast.temporary-unavailable'),
      });
    },
  });

  const { count, researches } =
    data?.getPendingDayRankedResearches ??
    previousData?.getPendingDayRankedResearches ??
    {};

  return (
    <>
      <Card.Header
        level={2}
        title={title ?? t('token:pending-community-researches.title')}
        tooltip={t('token:pending-community-researches.tooltip')}
      />
      <ResearchTable
        loading={loading}
        researches={researches ?? []}
        defaultFilters={{
          tokenRefs: filtersValues.tokens ?? undefined,
          hasEnoughReviews: filtersValues.hasEnoughReviews ?? undefined,
        }}
        onClickReviewPending={(research) => {
          createReview({
            variables: { researchId: research.id },
          });
        }}
        pagination={{
          total: count,
          current: filtersValues.page,
          pageSize: filtersValues.pageSize,
          pageSizeOptions: researchPageOptions.pageSizeOptions,
        }}
        onChange={(pagination, filters) => {
          setFiltersValues({
            ...filtersValues,
            ...researchTableOnChange(pagination, filters),
          });
        }}
      />
    </>
  );
}
