import React from 'react';
import { UserPosition } from 'business/user/components/user-position/index';
import { useAppContext } from 'business/app-bootstrapper';
import { useUserLeaderboardSessionPositionQuery } from 'generated/graphql';
import { isNotNullOrUndefined } from 'technical/utils/is-not-null-or-undefined';
import { Spacer } from 'ui/spacer';
import { useTranslation } from 'react-i18next';
import { LinkAsButton } from 'ui/button';
import { generatePath } from 'react-router-dom';
import Routes from 'business/router/routes';
import { StaffPosition } from '../staff-position';
import config from 'config';

interface SessionUserPositionProps {
  sessionId?: number;
}

export const SessionUserPosition: React.VFC<SessionUserPositionProps> = ({
  sessionId,
}) => {
  const { user } = useAppContext();
  const { t } = useTranslation(['common']);
  const { data, loading, called } = useUserLeaderboardSessionPositionQuery({
    skip: !isNotNullOrUndefined(user?.id) || !isNotNullOrUndefined(sessionId),
    variables: {
      // @ts-expect-error
      userId: user?.id,
      sessionId: sessionId!, // should be defined due to skip condition
    },
  });

  const userPosition = data?.leaderboardBySession[0];

  if (config.features.isInHibernation) {
    return (
      <Spacer justify="center" space={1} direction="vertical" align="center">
        You need to be connected to see your ranking!
      </Spacer>
    );
  }

  if (user?.rank?.name === 'Staff') {
    return <StaffPosition user={user} />;
  }

  if (!loading && !userPosition && called) {
    return (
      <Spacer justify="center" space={1} direction="vertical" align="center">
        {t('common:leaderboard.not-ranked-for-session')}
        <LinkAsButton
          type="primary"
          to={generatePath(Routes.CommunityResearches)}
          block
        >
          {t('common:leaderboard.not-ranked-actions.review-a-score')}
        </LinkAsButton>
        <LinkAsButton block type="primary" to={generatePath(Routes.Home)}>
          {t('common:leaderboard.not-ranked-actions.publish-token-scoring')}
        </LinkAsButton>
        <LinkAsButton
          type="primary"
          block
          to={generatePath(Routes.PersonalPortfolio)}
        >
          {t('common:leaderboard.not-ranked-actions.publish-portfolio')}
        </LinkAsButton>
      </Spacer>
    );
  }

  return (
    <UserPosition
      userId={user?.id}
      position={userPosition?.position}
      rank={userPosition?.user?.ranking?.currentRank}
      displayName={userPosition?.user?.profile?.displayName}
    />
  );
};
