import { red } from '@ant-design/colors';
import { ApolloClient } from '@apollo/client';
import { GET_ME } from 'business/user/services/queries.gql';
import { FormikProps } from 'formik';
import { MeQueryQuery, MeQueryQueryVariables } from 'generated/graphql';
import { isNullOrUndefined } from 'technical/utils/is-null-or-undefined';
import { Me } from '../types/user';

export async function fetchUser(
  client: ApolloClient<object>,
): Promise<Me | undefined> {
  // try to get my user
  const { data } = await client.query<MeQueryQuery, MeQueryQueryVariables>({
    query: GET_ME,
  });

  const me = data?.me;

  if (
    !me ||
    isNullOrUndefined(me.id) ||
    isNullOrUndefined(me.email) ||
    isNullOrUndefined(me.contributionCredit)
  ) {
    return undefined;
  }
  return {
    id: me.id,
    email: me.email,
    displayName: me.displayName ?? '-',
    roles: me.roles,
    defaultWallet: me.defaultWallet ?? null,
    rank: me.rank ?? null,
    contributionCredit: me.contributionCredit,
  };
}

export const generateStyleOnErrors = (
  formik: FormikProps<any>,
  fieldName: string,
) =>
  formik.errors[fieldName] && formik.touched[fieldName]
    ? { borderColor: red.primary }
    : {};

export const formikErrorMessage = (
  formik: FormikProps<any>,
  fieldName: string,
) => (formik.touched[fieldName] ? formik.errors[fieldName]?.toString() : '');
