import React from 'react';
import { useAppContext } from 'business/app-bootstrapper';
import { NavLink } from 'react-router-dom';
import { isInternalLink } from 'technical/utils/is-internal-link';
import classes from './menu-link.module.scss';

export interface MenuLinkProps {
  href: string;
  label: string;
  /**
   * @deprecated do not use loggedOnly, filter list before using menu links
   */
  loggedOnly: boolean;
  noStyle?: boolean;
  target?: '_self' | '_blank' | '_parent' | '_top';
}

export function MenuLink({
  href,
  label,
  loggedOnly,
  noStyle = false,
  target = '_self',
}: MenuLinkProps) {
  const { isConnected } = useAppContext();

  if (loggedOnly && !isConnected) {
    return null;
  }

  if (isInternalLink(href)) {
    return (
      <NavLink
        to={href}
        className={noStyle ? undefined : classes.menulink}
        target={target}
        end
      >
        {label}
      </NavLink>
    );
  }

  return (
    <a
      href={href}
      className={noStyle ? undefined : classes.menulink}
      target={target}
    >
      {label}
    </a>
  );
}
