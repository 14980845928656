import { useTranslation } from 'react-i18next';
import React from 'react';
import ErrorIllustration from 'ui/illustrations/error';
import ActionCard from '../../components/action-card';
import PageCenteredContent from '../../../../ui/layouts/page-centered-content';

function EmailAlreadyVerifiedPage() {
  const { t } = useTranslation(['common']);

  return (
    <PageCenteredContent>
      <ActionCard
        dataTestId="email-already-verified"
        title={t('pages.emailAlreadyVerified.title')}
        hint={t('pages.emailAlreadyVerified.hint')}
        btnText={t('pages.emailAlreadyVerified.backButton')}
      >
        <ErrorIllustration />
      </ActionCard>
    </PageCenteredContent>
  );
}

export default EmailAlreadyVerifiedPage;
