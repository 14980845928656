import React from 'react';
import * as yup from 'yup';
import { useHeadBandsQuery } from 'generated/graphql';
import { useSessionStorage } from 'technical/storage/use-storage';

export function useHeadBand() {
  const { data, loading, error } = useHeadBandsQuery();
  const headBandItems = data?.headBand ?? [];

  const [headBandSkipedIds, setHeadBandSkipedIds] = useSessionStorage(
    'headBandSkipedIds',
    [],
    yup.array().of(yup.number().required()),
  );

  const setSkipedIds = () => {
    const skipedIds = headBandItems.map(({ id }) => id);

    setHeadBandSkipedIds(skipedIds);
  };

  const filteredItems =
    headBandItems.filter(
      (headBandItem) => !headBandSkipedIds.includes(headBandItem.id),
    ) ?? [];

  return { filteredItems, setSkipedIds, loading, error };
}
