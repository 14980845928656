import { WarningTwoTone } from '@ant-design/icons';
import { ConnectButton } from '@rainbow-me/rainbowkit';
import { Tag } from 'antd';
import { useAppContext } from 'business/app-bootstrapper';
import { OnboardingStepEnum } from 'business/onboarding/components/onboarding/types';
import { Me } from 'business/user/types/user';
import {
  useLinkWalletMutation,
  useRemoveUserDefaultWalletMutation,
  useSetOnboardingWalletMutation,
} from 'generated/graphql';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { formatBalance, truncate } from 'technical/utils/format-string';
import Button from 'ui/button';
import { Spacer } from 'ui/spacer';
import { useAccount, useBalance, useDisconnect } from 'wagmi';
import WalletAccountModal from '../wallet-account-modal';

interface WalletConnectButtonProps {
  user?: Pick<Me, 'id' | 'defaultWallet'>;
  kvtAddress: string;
}
export function WalletConnectButton({
  user,
  kvtAddress,
}: WalletConnectButtonProps) {
  const { t } = useTranslation(['common']);
  // TODO: this can be done in one mutation or in the back
  const variables = { userId: user?.id, value: OnboardingStepEnum.done };
  const [
    setOnboardingWallet,
    { loading: setOnboardingWalletLoading },
  ] = useSetOnboardingWalletMutation({
    // @ts-expect-error
    variables,
    refetchQueries: ['SelectUserOnboarding'],
  });

  const { requestRebootstrap } = useAppContext();
  const [linkWallet] = useLinkWalletMutation({
    onCompleted() {
      requestRebootstrap();
      setOnboardingWallet();
    },
  });
  const [removeDefaultWallet] = useRemoveUserDefaultWalletMutation({
    // @ts-expect-error
    variables: { id: user?.id },
  });
  const { disconnect } = useDisconnect();

  const [isModalVisible, setIsModalVisible] = useState(false);
  const { address: currentWalletAddress } = useAccount({
    onConnect({ address }) {
      if (address) {
        linkWallet({
          variables: {
            address,
          },
        });
      }
    },
  });
  const defaultWalletAddress = user?.defaultWallet?.address;
  const { data } = useBalance({
    addressOrName: defaultWalletAddress,
    token: kvtAddress,
  });

  const handleCancel = () => {
    setIsModalVisible(false);
  };

  const logout = async () => {
    await removeDefaultWallet();
    disconnect();
    handleCancel();
    requestRebootstrap();
  };

  return (
    <>
      <ConnectButton.Custom>
        {({ openConnectModal }) => {
          return (
            <div>
              {(() => {
                if (!user?.defaultWallet) {
                  if (!currentWalletAddress) {
                    return (
                      <Button onClick={openConnectModal}>
                        {t('wallet.no-wallet-connected')}
                      </Button>
                    );
                  }
                  // when the user is already logged to a wallet on a navigator, but there is no default wallet, link directly this wallet
                  return (
                    <Button
                      onClick={() =>
                        linkWallet({
                          variables: { address: currentWalletAddress },
                        })
                      }
                    >
                      {t('wallet.no-wallet-connected')}
                    </Button>
                  );
                }

                return (
                  <div>
                    <Button onClick={() => setIsModalVisible(true)}>
                      <Spacer space={1} align="center">
                        {currentWalletAddress !== defaultWalletAddress ? (
                          <WarningTwoTone twoToneColor="orange" />
                        ) : null}
                        <span>
                          {truncate(defaultWalletAddress || '', {
                            start: 5,
                            end: 3,
                          })}
                        </span>
                        <Tag color="blue">
                          {data ? formatBalance(data.formatted, 5) : '...'}{' '}
                          {data?.symbol.toUpperCase()}
                        </Tag>
                      </Spacer>
                    </Button>
                  </div>
                );
              })()}
            </div>
          );
        }}
      </ConnectButton.Custom>
      <WalletAccountModal
        defaultWalletAddress={defaultWalletAddress}
        currentWalletAddress={currentWalletAddress}
        walletData={data}
        open={isModalVisible}
        logout={logout}
        onCancel={handleCancel}
        centered
      />
    </>
  );
}
