import React, { PropsWithChildren } from 'react';
import { Card } from 'ui/cards';
import { Typography } from 'antd';
import classNames from 'classnames';
import { Spacer } from 'ui/spacer';
import QuestionTooltip from 'ui/question-tooltip';
import classes from './index.module.scss';

export const tokenExt = 'KVT';

interface RewardsTotalProps {
  token: string | number;
  label: string;
  tooltip?: string;
}

export const EarnedRewards: React.FC<PropsWithChildren<RewardsTotalProps>> = ({
  token,
  label,
  tooltip,
  children,
}) => {
  return (
    <Card className="elevation2">
      <Spacer direction="vertical" space={1} align="center">
        <Typography.Text
          className={classNames([classes.tokenAmount, 'highlightText'])}
        >
          {`${token} ${tokenExt}`}
        </Typography.Text>

        <Typography.Text>
          <Typography.Text>{label}</Typography.Text>{' '}
          {!!tooltip && <QuestionTooltip tooltip={tooltip} />}
        </Typography.Text>

        {children}
      </Spacer>
    </Card>
  );
};
