import React from 'react';
import * as yup from 'yup';
import { useLocaleStorage } from 'technical/storage/use-storage';
import logger from 'technical/logger';
import THEMES from './themes';

type Themes = typeof THEMES;
type ThemeKeys = keyof Themes;

function isTheme(t: string | null): t is ThemeKeys {
  return t != null && t in THEMES;
}

const defaultTheme = ((): ThemeKeys => {
  let stored = JSON.parse(window?.localStorage.getItem('theme') ?? 'null');
  if (!isTheme(stored)) {
    window.localStorage.removeItem('theme');
    stored = null;
  }
  const preference = window?.matchMedia?.('(prefers-color-scheme: dark)')
    .matches
    ? 'dark'
    : 'light';

  const theme: ThemeKeys = stored ?? preference;

  THEMES[theme].preload().catch((err) => {
    logger.warn({ message: 'could not preload theme', err });
  });

  return theme;
})();

interface ThemeContextProps {
  current: ThemeKeys;
  changeTheme: (t: ThemeKeys) => void;
  themes: Array<{ preload: () => Promise<any>; key: ThemeKeys }>;
}
const ThemeContext = React.createContext<ThemeContextProps>({
  current: defaultTheme,
  changeTheme: () => {},
  themes: Object.keys(THEMES).map((key) => ({
    key: key as ThemeKeys,
    preload: THEMES[key as ThemeKeys].preload,
  })),
});

export interface ThemeLoaderProps {
  children: React.ReactNode;
}
export function ThemeLoader({ children }: ThemeLoaderProps) {
  const [theme, setTheme] = useLocaleStorage(
    'theme',
    defaultTheme,
    yup
      .mixed<ThemeKeys>()
      .oneOf(Object.keys(THEMES) as Array<ThemeKeys>)
      .required(),
  );

  const context: ThemeContextProps = React.useMemo(
    () => ({
      current: theme,
      themes: Object.keys(THEMES).map((key) => ({
        key: key as ThemeKeys,
        preload: THEMES[key as ThemeKeys].preload,
      })),
      changeTheme: setTheme,
    }),
    [theme, setTheme],
  );

  const StylesheetLoader = THEMES[theme].component;

  return (
    <ThemeContext.Provider value={context}>
      <React.Suspense fallback={null}>
        {children}
        <StylesheetLoader />
      </React.Suspense>
    </ThemeContext.Provider>
  );
}

export function useThemes() {
  const { current, themes, changeTheme } = React.useContext(ThemeContext);
  return [{ current, themes }, changeTheme] as const;
}
