import React from 'react';
import { Card } from 'ui/cards';
import { Image, Typography } from 'antd';
import { useTranslation } from 'react-i18next';
import { Spacer } from 'ui/spacer';
import classes from './index.module.scss';
import { format } from 'technical/date-fns';

export enum StatusType {
  locked = 'locked',
  ongoing = 'ongoing',
  achieved = 'achieved',
}
export interface AchievementType {
  id: string;
  img?: string | null | undefined;
  status: string;
  title: string;
  attributionDate: string;
}

const AchievementStatus = ({
  achievement,
}: {
  achievement: AchievementType;
}) => {
  const { t } = useTranslation(['dashboard']);

  return (
    <Typography.Text className={classes[`status-${achievement.status}`]}>
      {achievement.attributionDate
        ? format(new Date(achievement.attributionDate), 'PPP')
        : t(`dashboard:${achievement.status}`)}
    </Typography.Text>
  );
};

export function AchievementCard({
  achievement,
}: {
  achievement: AchievementType;
}) {
  const { t } = useTranslation();

  return (
    <Card className={classes['dashboard-card']}>
      <Spacer direction="vertical" justify="center" align="center">
        <Image
          preview={false}
          src={`/achievement/${achievement.img}`}
          className={classes['status-img']}
        />
        <Typography.Text strong className={classes.title}>
          {t('dashboard:achievements', { context: achievement.title })}
        </Typography.Text>
        <AchievementStatus achievement={achievement} />
      </Spacer>
    </Card>
  );
}
