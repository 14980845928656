/**
 * Binds the DatePicker to date-fns in order to use simple Date object
 * and reduce bundle size.
 *
 * More infos: https://ant.design/docs/react/replace-moment
 */
import dateFnsGenerateConfig from 'rc-picker/lib/generate/dateFns';
// we have to import this helper from the common js antd folder
// because jest is unabled to parse esm modules
// https://jestjs.io/docs/ecmascript-modules
import generatePicker from 'antd/lib/date-picker/generatePicker';

const DatePicker = generatePicker<Date>(dateFnsGenerateConfig);

export default DatePicker;
