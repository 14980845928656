import React from 'react';
import { Card } from 'ui/cards';
import { useTranslation } from 'react-i18next';
import { Button, Typography } from 'antd';
import { Spacer } from 'ui/spacer';
import authService from 'business/user/services/authentication.gql';
import classes from './index.module.scss';

export function DashboardOtherActions() {
  const { t } = useTranslation(['dashboard']);
  const onClickLogout = () => authService.logout();

  return (
    <Card>
      <Typography.Title level={5}>
        {t('dashboard:other_actions')}
      </Typography.Title>

      <Spacer direction="vertical">
        <Button
          type="default"
          onClick={onClickLogout}
          className={classes['logout-button']}
        >
          {t('dashboard:logout')}
        </Button>
      </Spacer>
    </Card>
  );
}
