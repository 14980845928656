import { ParentSize } from '@visx/responsive';
import { TableColumnProps, Typography } from 'antd';
import { RatingType } from 'business/rating/types';
import { TokenIconAndName } from 'business/research/components/token-icon-and-name';
import { useTranslation } from 'react-i18next';
import { NumberFormats, NumericValue } from 'ui/numeric';
import { PercentageValue } from 'ui/percentage';
import RatingTag from 'ui/rating-tag';
import Table from 'ui/table';
import { SparklineChart } from '../sparkline-chart';
import classes from './index.module.scss';

type MatchingTokenData = {
  id: string;
  reference: string;
  name: string;
  symbol: string;
  coinGeckoImage?: string | null;
  validatedResearchCount: number;
  rating?: { name: string; type: string } | null;
  market?: {
    price?: number | null;
    marketCap?: number | null;
    volume?: number | null;
    circulatingSupply?: number | null;
    h24?: number | null;
    d7?: number | null;
    sparkline?: Array<number> | null;
  } | null;
};

export type MatchingTokenTableProps = {
  loading?: boolean;
  list: Array<MatchingTokenData>;
  count: number;
  page: number;
  pageSize: number;
  sorting: {
    communityScore?: 'desc' | 'asc';
    validatedResearchCount?: 'desc' | 'asc';
  };
  onChange: (
    pagination: { page: number; size: number },
    sorting: {
      communityScore?: 'desc' | 'asc';
      validatedResearchCount?: 'desc' | 'asc';
    },
  ) => void;
};

export function MatchingTokenTable({
  page,
  pageSize,
  list,
  count,
  sorting,
  loading = false,
  onChange,
}: MatchingTokenTableProps) {
  const { t } = useTranslation(['token']);

  const columns: TableColumnProps<MatchingTokenData>[] = [
    {
      key: 'token-table-column-order',
      title: '#',
      align: 'center',
      fixed: 'left',
      render: (_, __, index) => (
        <Typography.Text strong>
          {(page - 1) * pageSize + index + 1}
        </Typography.Text>
      ),
    },
    {
      key: 'token-table-column-name',
      title: t('token:table.name'),
      dataIndex: 'name',
      fixed: 'left',
      render: (_, record) => <TokenIconAndName token={record} />,
    },
    {
      key: 'token-table-column-price',
      title: t('token:table.price'),
      dataIndex: 'price',
      render: (_, { market }) => (
        <NumericValue
          format={NumberFormats.currency}
          value={market?.price}
          strong
        />
      ),
    },
    {
      key: 'token-table-column-title',
      title: t('token:table.h24'),
      dataIndex: 'h24',
      render: (_, { market }) => <PercentageValue value={market?.h24} strong />,
    },
    {
      title: t('token:table.d7'),
      dataIndex: 'd7',
      render: (_, { market }) => <PercentageValue value={market?.d7} strong />,
    },
    {
      key: 'token-table-column-market-cap',
      title: t('token:table.marketCap'),
      dataIndex: 'marketCap',
      render: (_, { market }) => (
        <NumericValue
          format={NumberFormats.currency}
          value={market?.marketCap}
          strong
        />
      ),
    },
    {
      key: 'token-table-column-volume',
      title: t('token:table.volume'),
      dataIndex: 'volume',
      render: (_, { market }) => <NumericValue value={market?.volume} strong />,
    },
    {
      key: 'token-table-column-avg-rating',
      title: t('token:table.avgRating'),
      dataIndex: 'avgRating',
      className: classes.columnAvgRating,
      width: '120px',
      align: 'center',
      sorter: true,
      sortOrder: sorting?.communityScore
        ? { asc: 'ascend' as const, desc: 'descend' as const }[
            sorting.communityScore
          ]
        : undefined,
      sortDirections: ['descend', 'ascend'],
      render: (_, { rating }) => (
        <RatingTag rating={rating?.name} type={rating?.type as RatingType} />
      ),
    },
    {
      key: 'token-table-column-rating-count',
      title: t('token:table.ratingCount'),
      dataIndex: 'validatedResearchCount',
      align: 'center',
      sorter: true,
      sortOrder: sorting?.validatedResearchCount
        ? { asc: 'ascend' as const, desc: 'descend' as const }[
            sorting.validatedResearchCount
          ]
        : undefined,
      sortDirections: ['descend', 'ascend'],
      render: (_, { rating, validatedResearchCount }) =>
        rating?.type === 'reference'
          ? t('common:common.na')
          : validatedResearchCount,
    },
    {
      key: 'token-table-column-last-7d',
      title: t('token:table.last7Days'),
      className: classes.columnLast7Days,
      render: (_, { market }) => (
        <ParentSize>
          {({ width }) => (
            <SparklineChart
              data={market?.sparkline ?? []}
              height={48}
              width={width}
            />
          )}
        </ParentSize>
      ),
    },
    {
      key: 'token-table-column-circulating-supply',
      title: t('token:table.circulatingSupply'),
      dataIndex: 'circulatingSupply',
      align: 'right',
      render: (_, { market }) => (
        <NumericValue
          value={market?.circulatingSupply}
          options={{ maximumFractionDigits: 0, minimumFractionDigits: 0 }}
          strong
        />
      ),
    },
  ];

  return (
    <Table<MatchingTokenData>
      loading={loading}
      dataSource={list}
      rowKey={({ reference }) => reference}
      columns={columns}
      scroll={{ x: true }}
      onChange={(
        { current: newPage, pageSize: newSize },
        _filters,
        sorters,
      ) => {
        if (Array.isArray(sorters)) {
          return;
        }
        const direction =
          { ascend: 'asc' as const, descend: 'desc' as const }[
            sorters.order ?? 'descend'
          ] ?? 'desc';
        const key =
          {
            'token-table-column-avg-rating': 'communityScore' as const,
            'token-table-column-rating-count': 'validatedResearchCount' as const,
          }[sorters.columnKey ?? 'token-table-column-avg-rating'] ??
          'communityScore';

        onChange(
          { page: newPage ?? 1, size: newSize ?? 25 },
          { [key]: direction },
        );
      }}
      pagination={{
        total: count,
        current: page,
        pageSize,
      }}
    />
  );
}
