import React from 'react';
import { Card } from 'ui/cards';
import { useTranslation } from 'react-i18next';
import { Button, Image, Typography } from 'antd';
import { Spacer } from 'ui/spacer';
import StarSVG from 'assets/icons/star.svg';
import { useGetUserRewardsQuery, UserRewardFilters } from 'generated/graphql';
import classes from './index.module.scss';

function Rewards() {
  const { t } = useTranslation(['dashboard']);
  const { data } = useGetUserRewardsQuery({
    variables: { filter: UserRewardFilters.Unclaimed },
  });

  const defaultTokenEmptyValue = '-';
  const token = data?.getUserRewards?.tokenAmount ?? defaultTokenEmptyValue;

  return (
    <Card type="well" className={classes.reward}>
      <Spacer justify="center" direction="vertical" align="center">
        <Typography.Title level={4}>
          <Image
            src={StarSVG}
            alt={t('dashboard:star-description')}
            className={classes.icon}
            preview={false}
          />
          {t('dashboard:rewards')}
        </Typography.Title>
        <Typography.Text
          className={classes.kvt}
        >{`${token} KVT`}</Typography.Text>

        <Button
          block
          disabled
          type="primary"
          className={classes['rewars-button']}
        >
          {t('dashboard:claim')}
        </Button>
      </Spacer>
    </Card>
  );
}

export default Rewards;
