import React from 'react';
import classnames from 'classnames';
import { Typography } from 'antd';
import QuestionTooltip from 'ui/question-tooltip';
import { Spacer } from 'ui/spacer';
import { info } from 'console';
import classes from './index.module.scss';

export interface CardProps extends React.HTMLAttributes<HTMLDivElement> {
  type?: 'default' | 'well' | 'info' | 'primary';
  children: React.ReactNode;
  className?: string;
  dataTestId?: string;
  stretchHeight?: boolean;
}

export function Card({
  children,
  type = 'default',
  className,
  dataTestId,
  stretchHeight,
  ...other
}: CardProps) {
  return (
    <div
      className={classnames(className, classes.card, {
        [classes.well]: type === 'well',
        [classes.stretchHeight]: stretchHeight,
        [classes.info]: type === 'info',
        [classes.primary]: type === 'primary',
        clickable: !!other.onClick,
      })}
      data-test-id={dataTestId}
      {...other}
    >
      {children}
    </div>
  );
}

export interface CardHeaderProps {
  title: React.ReactNode;
  level: 1 | 2 | 3 | 4 | 5;
  tooltip?: string | React.ReactNode;
  actions?: React.ReactNode;
  icon?: React.ReactNode;
  className?: string;
}

function CardHeader({
  level,
  title,
  tooltip,
  actions,
  icon,
  className,
}: CardHeaderProps) {
  return (
    <Spacer
      justify="between"
      align="center"
      space={1}
      className={classnames(className, classes.header)}
    >
      <div className={classes.title}>
        {icon}
        <Typography.Title level={level}>{title}</Typography.Title>
        <QuestionTooltip tooltip={tooltip} />
      </div>
      <div className={classes.actions}>{actions}</div>
    </Spacer>
  );
}

Card.Header = CardHeader;
