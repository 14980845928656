import React, { useState } from 'react';
import { Typography, Image } from 'antd';
import { Card } from 'ui/cards';
import { Spacer } from 'ui/spacer';
import { useTranslation } from 'react-i18next';
import { ReadonlyResearch } from 'business/research/components/readonly-research';
import variables from 'ui/theme/variables.module.scss';
import UserLink from 'business/user/components/user-link';
import SeeMoreLink from 'ui/see-more-link';
import Tag from 'ui/tag';
import { useAppContext } from 'business/app-bootstrapper';
import classes from './top-research-card.module.scss';
import { format } from 'technical/date-fns';

export interface TopResearchCardProps {
  id: string;
  targetPrice: number;
  comment: string;
  publishedAt?: string | null;
  author: {
    rank: { icon: string };
    id: string;
    displayName?: string;
  };
}
export function TopResearchCard({
  id,
  targetPrice,
  comment,
  author,
  publishedAt,
}: TopResearchCardProps) {
  const { user } = useAppContext();
  const { i18n, t } = useTranslation(['token']);
  const [isResearchModalVisible, setIsResearchModalVisible] = useState(false);

  return (
    <Card type="well">
      <Spacer justify="between" align="center">
        <Card.Header
          level={3}
          title={<UserLink {...author} />}
          className={classes.header}
        />
        <Image
          preview={false}
          width={60}
          height={60}
          className={classes.rank}
          alt="rank icon"
          src={`/ranks/${author.rank?.icon}`}
        />
      </Spacer>

      <Spacer space={1} direction="vertical">
        <Spacer space="none" direction="horizontal">
          <Typography.Text strong>
            {t('token:top-researches.target-price')}
          </Typography.Text>
          <Tag
            color={variables.primaryColor}
            className={classes.targetPriceTag}
          >
            ${targetPrice.toLocaleString(i18n.language)}
          </Tag>
        </Spacer>
        {publishedAt ? (
          <Spacer space={1} direction="horizontal">
            <Typography.Text strong>Published on: </Typography.Text>
            <Typography.Text>
              {format(new Date(publishedAt), 'PPP')}
            </Typography.Text>
          </Spacer>
        ) : null}
        <Typography.Paragraph
          className={classes.comment}
          ellipsis={{ rows: 3 }}
        >
          {comment}
        </Typography.Paragraph>
        {user ? (
          <SeeMoreLink
            onClick={() => setIsResearchModalVisible(true)}
            className={classes['see-more']}
          />
        ) : null}
      </Spacer>
      <ReadonlyResearch
        researchId={id}
        visible={isResearchModalVisible}
        onClose={() => setIsResearchModalVisible(false)}
      />
    </Card>
  );
}
