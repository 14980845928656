import React, { useMemo, useState } from 'react';
import { max, min } from 'd3-array';
import { curveMonotoneX } from '@visx/curve';
import { Group } from '@visx/group';
import { LinePath } from '@visx/shape';
import { scaleLinear } from '@visx/scale';
import variables from 'ui/theme/variables.module.scss';
import { useThemes } from 'ui/theme';

export type SparklineChartProps = {
  width: number;
  height: number;
  data: Array<number>;
  dull?: boolean;
};

const colors = {
  bullish: variables.successColor,
  bearish: variables.errorColor,
  dull: 'var(--dull-color)',
};

export function SparklineChart({
  width,
  height,
  data,
  dull = false,
}: SparklineChartProps) {
  const [theme] = useThemes();
  const xScale = scaleLinear<number>({
    domain: [0, data.length],
    range: [0, width - 8],
  });
  const yScale = scaleLinear<number>({
    domain: [min(data) ?? 0, max(data) ?? 0],
    range: [height - 8, 0],
  });

  const diff = data[data.length - 1] - data[0];

  const trend: keyof typeof colors = diff > 0 ? 'bullish' : 'bearish';

  const datapoints = useMemo(() => {
    return data.map((value, index) => ({ index, value }));
  }, [data]);

  const colorName = dull ? 'dull' : trend;
  const color = colors[colorName];

  return (
    <svg
      height={height}
      width="100%"
      viewBox={`0 0 ${width} ${height}`}
      style={
        {
          '--dull-color':
            theme.current === 'light'
              ? variables.primaryColor
              : variables.textColor,
        } as any
      }
    >
      <Group left={4} top={4}>
        <LinePath<{ index: number; value: number }>
          curve={curveMonotoneX}
          data={datapoints}
          x={(d) => xScale(d.index) ?? 0}
          y={(d) => yScale(d.value) ?? 0}
          stroke={color}
          strokeWidth={1.5}
        />
      </Group>
    </svg>
  );
}
