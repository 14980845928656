import React from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { Skeleton, Typography } from 'antd';
import { LinkAsButton } from 'ui/button';
import Routes from 'business/router/routes';
import { Spacer } from 'ui/spacer';
import { BlurredFeature } from 'ui/blurred-feature';
import classes from './index.module.scss';

export interface CountInPortfolioProps {
  count?: number;
  tokenRef: string;
  loading?: boolean;
  blurred?: boolean;
}

export function CountInPortfolio({
  count,
  tokenRef,
  loading = false,
  blurred = false,
}: CountInPortfolioProps) {
  const { t } = useTranslation(['token']);

  return (
    <BlurredFeature isCompact blurred={!loading && blurred}>
      <Spacer space={2} direction="vertical" align="start">
        <div>
          <Trans
            t={t}
            i18nKey="token:count-in-portfolio"
            components={[
              loading ? (
                <Skeleton.Button active size="small" />
              ) : (
                <Typography.Text className={classes.highlight} />
              ),
              <Typography.Text key="text" className={classes.text} />,
            ]}
            count={count ?? 0}
          />
        </div>

        <LinkAsButton
          type="primary"
          to={{
            pathname: Routes.CommunityPortfolios,
            search: `tokenRefs=${tokenRef}`,
          }}
        >
          {t('token:actions.view-portfolios')}
        </LinkAsButton>
      </Spacer>
    </BlurredFeature>
  );
}
