import React from 'react';
import {
  Maybe,
  ResearchFormAnswerChoice,
  ResearchFormAnswerRepartition,
  ResearchFormAnswerRepartitionQuestionType,
  ResearchFormAnswerToken,
  ResearchFormQuestionTypeEnumEnum,
  ResearchFormRating,
  ResearchFormTokenInfos,
} from 'generated/graphql';
import { useTranslation } from 'react-i18next';
import { Typography } from 'antd';
import { formatPercent } from 'technical/currency/formatters';
import { Spacer } from 'ui/spacer';
import { TokenRating } from 'ui/rating';
import { NumberFormats, NumericValue } from 'ui/numeric';
import TagPercentage from 'ui/tag-percentage';
import classNames from 'classnames';
import Link from 'ui/link';
import { generatePath } from 'react-router-dom';
import Routes from 'business/router/routes';
import { isNotNullOrUndefined } from 'technical/utils/is-not-null-or-undefined';
import ParentSize from '@visx/responsive/lib/components/ParentSizeModern';
import {
  ChoiceAnswerRepartition,
  SimpleChoiceAnswerRepartitionVisualisation,
} from 'business/token/components/research-answers/categories-answer-repartition-modal/simple-choice-answer-repartition-visualisation';
import { MultiChoiceAnswerRepartitionVisualisation } from 'business/token/components/research-answers/categories-answer-repartition-modal/multi-choice-answer-repartition-visualisation';
import WordCloud, { WordData } from 'ui/word-cloud';
import classes from './index.module.scss';

type AnswerRepartitionVisualisationProps = {
  question: {
    id: string;
    type: { __typename?: 'research_form_AnswerRepartitionQuestionType' } & Pick<
      ResearchFormAnswerRepartitionQuestionType,
      'type'
    >;
    answersRepartition?: Maybe<
      Array<
        { __typename?: 'research_form_AnswerRepartition' } & Pick<
          ResearchFormAnswerRepartition,
          'answerRankByPercentage' | 'percentage' | 'tag'
        > & {
            choice?: Maybe<
              { __typename?: 'research_form_AnswerChoice' } & Pick<
                ResearchFormAnswerChoice,
                'id' | 'value'
              >
            >;
            answeredToken?: Maybe<
              { __typename?: 'research_form_AnswerToken' } & Pick<
                ResearchFormAnswerToken,
                'name' | 'reference' | 'validatedResearchCount'
              > & {
                  currentTokenRating?: Maybe<
                    { __typename?: 'research_form_CurrentTokenRating' } & {
                      rating?: Maybe<
                        { __typename?: 'research_form_Rating' } & Pick<
                          ResearchFormRating,
                          'name' | 'type'
                        >
                      >;
                    }
                  >;
                  infos: { __typename?: 'research_form_TokenInfos' } & Pick<
                    ResearchFormTokenInfos,
                    'h24' | 'd7' | 'marketCap' | 'volume' | 'price'
                  >;
                }
            >;
          }
      >
    >;
  };
  onCloseModal?: () => void;
};

export const DefaultAnswerRepartitionVisualisation: React.VFC<
  Pick<AnswerRepartitionVisualisationProps['question'], 'answersRepartition'>
> = ({ answersRepartition }) => {
  const { t } = useTranslation(['token']);

  return (
    <>
      {answersRepartition?.map((answerRepartition) => {
        // TODO real representation component according to question type
        return (
          <Typography.Text key={answerRepartition.choice?.id} strong>
            {t('token:research-answers.category-modal.answer', {
              answer:
                answerRepartition.choice?.value ??
                answerRepartition.tag ??
                answerRepartition.answeredToken?.name,
              percentage: formatPercent(answerRepartition.percentage, {
                precision: 2,
              }),
            })}
          </Typography.Text>
        );
      })}
    </>
  );
};

export const TokenAnswerRepartitionVisualisation: React.VFC<
  Pick<
    AnswerRepartitionVisualisationProps['question'],
    'answersRepartition'
  > & { onCloseModal?: () => void }
> = ({ answersRepartition, onCloseModal }) => {
  const { t } = useTranslation(['token']);

  return (
    <Spacer space={1} direction="vertical">
      {answersRepartition?.map((answerRepartition) => {
        return (
          <Spacer
            key={answerRepartition.choice?.id}
            direction={{
              default: 'vertical',
              lg: 'horizontal',
            }}
            className={classes.answer_card}
          >
            <Spacer // title
              direction="vertical"
              space="none"
              align="start"
              justify="between"
              className={classNames(
                classes.answer_card_column,
                classes.token_title_section,
              )}
            >
              <Link
                underline={false}
                to={generatePath(Routes.token, {
                  reference: answerRepartition.answeredToken?.reference ?? '',
                })}
                onClick={onCloseModal}
              >
                <Typography.Title
                  level={5}
                  className={classes.answer_card_title}
                >
                  {answerRepartition.answeredToken?.name}
                </Typography.Title>
              </Link>
              <TokenRating
                rating={
                  answerRepartition.answeredToken?.currentTokenRating?.rating
                }
                size="small"
              />
            </Spacer>

            <Spacer
              direction={{
                default: 'vertical',
                xs: 'vertical',
                sm: 'horizontal',
              }}
              space={2}
              className={classes.token_details_section}
            >
              <Spacer // current price & data point
                direction="vertical"
                align="start"
                space="none"
                className={classes.answer_card_column}
              >
                <NumericValue
                  format={NumberFormats.currency}
                  value={answerRepartition.answeredToken?.infos.price}
                  className={classes.token_price}
                />

                <Typography.Text>
                  {t(
                    'token:research-answers.category-modal.token.researchCount',
                    {
                      count:
                        answerRepartition.answeredToken?.validatedResearchCount,
                    },
                  )}
                </Typography.Text>
              </Spacer>

              <Spacer // volume and market cap
                direction="vertical"
                align="start"
                space="none"
                className={classes.answer_card_column}
              >
                <Spacer space={1} align="center">
                  <span className={classes.info_icon}> M </span>
                  <NumericValue
                    format={NumberFormats.currency}
                    value={answerRepartition.answeredToken?.infos.marketCap}
                  />
                </Spacer>
                <Spacer space={1} align="center">
                  <span className={classes.info_icon}> V </span>
                  <NumericValue
                    format={NumberFormats.currency}
                    value={answerRepartition.answeredToken?.infos.volume}
                  />
                </Spacer>
              </Spacer>

              <Spacer // d24 & d7
                direction="vertical"
                space="none"
                className={classNames(
                  classes.answer_card_column,
                  classes.token_evolution_column,
                )}
              >
                <Spacer
                  className={classes.token_evolution_value}
                  space={1}
                  align="center"
                >
                  <Typography.Text strong className={classes.label}>
                    {t('token:infos.h24')}
                  </Typography.Text>
                  {/* Replace by percentage value tag when will be developped */}
                  <TagPercentage
                    value={answerRepartition.answeredToken?.infos.h24}
                  />
                </Spacer>
                <Spacer
                  className={classes.token_evolution_value}
                  space={1}
                  align="center"
                >
                  <Typography.Text strong className={classes.label}>
                    {t('token:infos.d7')}
                  </Typography.Text>
                  {/* Replace by percentage value tag when will be developped */}
                  <TagPercentage
                    value={answerRepartition.answeredToken?.infos.d7}
                  />
                </Spacer>
              </Spacer>
            </Spacer>
          </Spacer>
        );
      })}
    </Spacer>
  );
};

export const TagAnswerRepartitionVisualisation: React.VFC<
  Pick<AnswerRepartitionVisualisationProps['question'], 'answersRepartition'>
> = ({ answersRepartition }) => {
  const tags = answersRepartition
    ?.map((answerRepartition) => ({
      text: answerRepartition.tag,
      value: answerRepartition.percentage,
    }))
    .filter((tag): tag is WordData => isNotNullOrUndefined(tag));

  return (
    <ParentSize>
      {({ width }) => (
        <WordCloud words={tags ?? []} width={width} height={200} />
      )}
    </ParentSize>
  );
};

export const AnswerRepartitionVisualisation: React.VFC<AnswerRepartitionVisualisationProps> = ({
  question,
  onCloseModal,
}) => {
  const { type } = question.type;
  if (type === ResearchFormQuestionTypeEnumEnum.SingleChoice) {
    const ar =
      question.answersRepartition?.filter(
        (answerRepartition): answerRepartition is ChoiceAnswerRepartition =>
          !!answerRepartition.choice,
      ) ?? [];
    return (
      <ParentSize>
        {({ width }) => (
          <SimpleChoiceAnswerRepartitionVisualisation
            id={question.id}
            answersRepartition={ar}
            width={width}
          />
        )}
      </ParentSize>
    );
  }
  if (type === ResearchFormQuestionTypeEnumEnum.MultiChoice) {
    const ar =
      question.answersRepartition?.filter(
        (answerRepartition): answerRepartition is ChoiceAnswerRepartition =>
          !!answerRepartition.choice,
      ) ?? [];
    return (
      <ParentSize>
        {({ width }) => (
          <MultiChoiceAnswerRepartitionVisualisation
            answersRepartition={ar}
            width={width}
          />
        )}
      </ParentSize>
    );
  }
  if (type === ResearchFormQuestionTypeEnumEnum.Tag) {
    return (
      <TagAnswerRepartitionVisualisation
        answersRepartition={question.answersRepartition}
      />
    );
  }
  if (type === ResearchFormQuestionTypeEnumEnum.Token) {
    return (
      <TokenAnswerRepartitionVisualisation
        answersRepartition={question.answersRepartition}
        onCloseModal={onCloseModal}
      />
    );
  }
  return (
    <DefaultAnswerRepartitionVisualisation
      answersRepartition={question.answersRepartition}
    />
  );
};
