import { useAppContext } from 'business/app-bootstrapper';
import React, { PropsWithChildren } from 'react';
import { Navigate } from 'react-router-dom';
import Routes from './routes';

export function Protect({
  children,
  redirectTo = Routes.Home,
  authentication = 'any',
}: PropsWithChildren<{
  redirectTo?: Routes;
  authentication?: 'any' | 'connected' | 'not-connected';
}>) {
  const { isConnected } = useAppContext();

  const shouldRedirect =
    (isConnected && authentication === 'not-connected') ||
    (!isConnected && authentication === 'connected');

  if (shouldRedirect) {
    return <Navigate to={redirectTo} replace />;
  }

  return <>{children}</>;
}
