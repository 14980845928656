import React, { CSSProperties } from 'react';
import { Typography } from 'antd';
import classNames from 'classnames';
import { Rating } from 'generated/graphql';
import { useTranslation } from 'react-i18next';
import { ratingColors } from 'business/rating/services/utils';
import classes from './index.module.scss';

export interface TokenRatingProps {
  rating?: Pick<Rating, 'name' | 'type'> | null;
  size?: 'small' | 'large';
  type?: 'box' | 'text';
}

export function TokenRating({
  rating,
  size = 'large',
  type = 'box',
}: TokenRatingProps) {
  const { t } = useTranslation(['common']);

  const color =
    ratingColors[rating?.type || 'unknown']?.basic ??
    ratingColors.unknown.basic;
  if (type === 'text') {
    return (
      <Typography.Text
        className={classNames(classes.rating, classes[size], classes.text)}
        style={{ color }}
        strong={!!rating}
        italic={!rating}
      >
        {rating ? rating.name : t('no-rating')}
      </Typography.Text>
    );
  }

  return (
    <div
      className={classNames(classes.rating, classes[size])}
      style={{
        backgroundColor: color,
      }}
    >
      <Typography.Text
        strong={!!rating}
        italic={!rating}
        className={classNames(classes.label)}
      >
        {rating ? rating.name : t('no-rating')}
      </Typography.Text>
    </div>
  );
}
