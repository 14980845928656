import React, { useEffect, useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import Routes from 'business/router/routes';
import AuthForm, { Values } from 'business/user/components/auth-form';
import ActionCard from 'business/user/components/action-card';
import Link from 'ui/link';
import Button from 'ui/button';
import { Modal } from 'ui/modal';
import authService from 'business/user/services/authentication.gql';
import { Image, Typography } from 'antd';
import { Spacer } from 'ui/spacer';
import classNames from 'classnames';
import classes from './index.module.scss';

export enum LoginModalFormTabs {
  signin = 'signin',
  signup = 'signup',
}

interface LoginModalProps {
  isOpen: boolean;
  setIsOpen: (isVisible: boolean) => void;
  startTab?: LoginModalFormTabs;
}

export default function LoginModal({
  isOpen,
  setIsOpen,
  startTab = LoginModalFormTabs.signin,
}: LoginModalProps) {
  const [signInError, setSignInError] = useState<string>('');
  const [signUpError, setSignUpError] = useState<string>('');
  const [showSuccess, setShowSuccess] = useState(false);
  const [currentTab, setCurrentTab] = useState<LoginModalFormTabs>(startTab);
  const { t } = useTranslation(['common']);

  // Temporary solution, waiting for centralized modale management
  useEffect(() => {
    setCurrentTab(startTab);
  }, [startTab]);

  const onSubmitClickSighUp = async (values: Values) => {
    try {
      await authService.signUp(
        values.login,
        values.password,
        values.password,
        values.registeredFromCampaign,
        values.referredFromCode,
        values.hasAgreedToCommercialOffers,
        values.hasCertifiedAgeMajority,
      );
      setShowSuccess(true);
      window.localStorage.removeItem('coming-from-brave');
    } catch (error) {
      setSignUpError(error.message);
    }
  };

  const onSubmitClickSignIn = async (values: Values) => {
    setSignInError('');
    try {
      await authService.signIn(values.login, values.password);
      setIsOpen(false);
      window.localStorage.removeItem('coming-from-brave');
    } catch (error) {
      setSignInError(error.message);
    }
  };

  const title =
    currentTab === LoginModalFormTabs.signin
      ? t('pages.signIn.form.title')
      : t('pages.signUp.form.title');

  const tabsContents = {
    [LoginModalFormTabs.signin]: (
      <AuthForm
        submitButton={{
          label: t('notConnected.login.submit'),
          onClick: onSubmitClickSignIn,
        }}
        authError={{ key: 'errors.signIn', context: signInError }}
        passwordHint={
          <Link
            color="tundora"
            to={Routes.ForgotPassword}
            onClick={() => setIsOpen(false)}
          >
            {t('pages.forgotPassword.title', {
              context: 'question',
            })}
          </Link>
        }
        bottomHint={
          <Button
            onClick={() => setCurrentTab(LoginModalFormTabs.signup)}
            prefix={t('common:notConnected.login.signUp.prefix')}
            block
          >
            {t('common:notConnected.login.signUp.cta')}
          </Button>
        }
      />
    ),
    [LoginModalFormTabs.signup]: (
      <Spacer direction="vertical" space={1}>
        {new Date() <= new Date('2023-01-12T13:00:00Z') ? (
          <Spacer direction="vertical" space={1} className={classes.infos}>
            <Typography.Text className={classes['text-18']}>
              {t('pages.signUp.infos.discover')}
            </Typography.Text>
            <Spacer
              direction={{ default: 'vertical', xs: 'horizontal' }}
              space={2}
              align="center"
            >
              <Spacer direction="vertical" space={1}>
                <Typography.Text>
                  <Trans
                    t={t}
                    i18nKey="common:pages.signUp.infos.rewards.price-pool"
                    components={{
                      b: <b className={classes['text-16']} />,
                    }}
                  />
                </Typography.Text>
                <Typography.Text>
                  <Trans
                    t={t}
                    i18nKey="common:pages.signUp.infos.rewards.list"
                    components={{
                      ul: <ul />,
                      li: <li />,
                      b: <b />,
                    }}
                  />
                </Typography.Text>
              </Spacer>
              <div className={classes['img-container']}>
                <Image
                  src="/signup-rewards.jpg"
                  alt="beta reward preview image"
                  preview={false}
                />
              </div>
            </Spacer>
            <Typography.Text
              className={classNames(classes['text-18'], classes['text-center'])}
            >
              {t('pages.signUp.infos.register-for-free')}
            </Typography.Text>
          </Spacer>
        ) : null}
        <AuthForm
          submitButton={{
            label: t('pages.signUp.form.submit'),
            onClick: onSubmitClickSighUp,
          }}
          showSignUpInput
          authError={{ key: 'errors.signUp', context: signUpError }}
          bottomHint={
            <Button
              onClick={() => {
                setCurrentTab(LoginModalFormTabs.signin);
              }}
              prefix={t('common:pages.signUp.signIn.prefix')}
              block
            >
              {t('common:pages.signUp.signIn.cta')}
            </Button>
          }
        />
      </Spacer>
    ),
  };
  const content = tabsContents[currentTab];

  return (
    <Modal
      centered
      open={isOpen}
      getContainer={false}
      title={
        <Typography.Title level={2} className={classes.modalTitle}>
          {title.toUpperCase()}
        </Typography.Title>
      }
      width={640}
      closable
      destroyOnClose
      onCancel={() => {
        setIsOpen(false);
        setShowSuccess(false);
      }}
      footer={null}
    >
      {showSuccess && currentTab === LoginModalFormTabs.signup ? (
        <ActionCard
          className={classes.actionCard}
          dataTestId="success-signup"
          title={t('pages.signUp.success.title')}
          hint={t('pages.signUp.success.description')}
          btnText={t('pages.signUp.goToLogin')}
          handleSubmit={() => {
            setCurrentTab(LoginModalFormTabs.signin);
            setShowSuccess(false);
          }}
        />
      ) : (
        content
      )}
    </Modal>
  );
}
