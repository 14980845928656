import { Select as SelectUi } from 'antd';
import React from 'react';
import classnames from 'classnames';
import styles from './index.module.scss';

const { Option } = SelectUi;

const ArrowSelectIcon = () => {
  return (
    <svg
      width="10"
      height="7"
      viewBox="0 0 10 7"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M1 1.5L4.54246 5.04246C4.79515 5.29515 5.20485 5.29515 5.45754 5.04246L9 1.5"
        stroke="#768098"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

interface IOption {
  label: string;
  value: string;
}

interface ISelectProps<T> {
  options: IOption[];
  handleChange: (option: T) => void;
  value: T;
  id?: string;
  className?: string;
}

const Select = <T extends unknown>({
  id,
  options,
  handleChange,
  value,
  className,
}: ISelectProps<T>) => (
  <>
    <SelectUi
      id={id}
      suffixIcon={<ArrowSelectIcon />}
      className={classnames(className, styles.customSelect)}
      value={value}
      onChange={handleChange}
    >
      {options.map((option) => (
        <Option key={option.value} value={option.value}>
          {option.label}
        </Option>
      ))}
    </SelectUi>
  </>
);

export default Select;
