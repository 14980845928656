import * as yup from 'yup';
import { DraftQuestion, DraftSource, DraftComment } from 'generated/graphql';
import { match } from 'technical/utils/match';
import { QuestionFormValue } from './types';
import { isNullOrUndefined } from 'technical/utils/is-null-or-undefined';

function addRequired<Schema extends yup.AnySchema>(
  schema: Schema,
  yes: boolean,
) {
  return yes ? schema.required() : schema.nullable(true).optional();
}

function addMinMax<T extends yup.ArraySchema<any, any, any>>(
  schema: T,
  metadata: Record<string, string>,
) {
  const min = +metadata.min ?? 1;
  const max = +metadata.max ?? 5;
  return schema.min(min).max(max);
}

function sourceSchema() {
  return yup.string().when('type', (type, schema) => {
    switch (type) {
      case 'url':
        return schema.url();
      case 'email':
        return schema.email();
      default:
        return schema;
    }
  });
}

function isCurrentChoice(
  id: string | null | undefined,
  ids: string[] | null | undefined,
  when: string | null | undefined,
) {
  return isNullOrUndefined(when) || when === id || ids?.includes(when);
}

export function buildSchemaFromQuestion(question: DraftQuestion) {
  return yup.lazy((formValues: QuestionFormValue) => {
    const common = <Rest extends Record<string, yup.AnySchema>>(rest: Rest) => {
      return yup.object({
        ...rest,
        sources: yup
          .array(
            // rn yup does not have a tuple schema, so we need to use lazy to compute
            // a valid schema on the fly
            yup.lazy((value: DraftSource) => {
              if (
                isCurrentChoice(
                  formValues.choiceId,
                  formValues.choiceIds,
                  value.when,
                )
              ) {
                // strong check
                const configId = yup
                  .number()
                  .oneOf([value.configId])
                  .required();
                const content = yup
                  .array(
                    yup
                      .object({
                        type: yup.string().oneOf(value.accepted).required(),
                        value: sourceSchema(),
                      })
                      .required(),
                  )
                  .required();
                return yup.object({
                  configId,
                  content,
                });
              }
              // no check
              return yup.object({});
            }),
          )
          .required(),
        comments: yup
          .array(
            // rn yup does not have a tuple schema, so we need to use lazy to compute
            // a valid schema on the fly
            yup.lazy((value: DraftComment) => {
              if (
                isCurrentChoice(
                  formValues.choiceId,
                  formValues.choiceIds,
                  value.when,
                )
              ) {
                const configId = yup
                  .number()
                  .oneOf([value.configId])
                  .required();
                const content = yup
                  .object({
                    value: addRequired(yup.string().min(5), value.required),
                  })
                  .nullable(true);
                return yup.object({
                  configId,
                  content,
                });
              }
              return yup.object({});
            }),
          )
          .required(),
      });
    };

    const res = match(question.type, {
      single_choice: () =>
        common({ choiceId: yup.string().uuid().required() }).required(),
      multi_choice: () =>
        common({
          choiceIds: addMinMax(
            yup.array().of(yup.string().uuid().required()),
            question.metadata,
          ).required(),
        }).required(),
      token: () =>
        common({
          tokenIds: addMinMax(
            yup.array().of(yup.string().uuid().required()),
            question.metadata,
          ).required(),
        }).required(),
      tag: () =>
        common({
          tags: addMinMax(
            yup.array().of(yup.string().required()),
            question.metadata,
          ).required(),
        }).required(),
    });
    return res;
  });
}
