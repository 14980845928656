import { i18n } from '../../translations';

const DEFAULT_LOCALE = i18n.language;

type Currency = 'EUR';

interface FormatOptions {
  locale?: string;
  precision?: number;
}

/**
 * Format a number as a monetary amount using the site's locale.
 *
 * The currency can only be 'EUR' for now, but we still require it to
 * be explicit.
 *
 * The local is hard-coded as the site is only in french for now.
 */
export function formatMonetaryAmount(
  value: number,
  currency: Currency,
  { locale = DEFAULT_LOCALE, precision = 2 }: FormatOptions = {},
) {
  return value.toLocaleString(locale, {
    currency,
    style: 'currency',
    minimumFractionDigits: precision,
  });
}

/**
 * Format a percentage according to the selected locale.
 *
 * @param value A number between 0 and 1
 */
export function formatPercent(
  value: number,
  { locale = DEFAULT_LOCALE, precision = 0 }: FormatOptions = {},
) {
  return value.toLocaleString(locale, {
    style: 'percent',
    minimumFractionDigits: precision,
  });
}
