import React from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { Typography } from 'antd';
import { Tabs } from 'ui/tabs';
import { ReactComponent as ReminderBanner } from 'assets/illustrations/reminder-banner.svg';

import { Spacer } from 'ui/spacer';

export function PeerReviewReminder() {
  const { t } = useTranslation(['review']);

  return (
    <>
      <Spacer direction="horizontal" justify="center" align="center">
        <ReminderBanner />
      </Spacer>
      <Tabs
        defaultActiveKey="1"
        items={[
          {
            label: `${t('review:reminder.contribution-quality')}`,
            key: '1',
            children: (
              <Typography.Text>
                {t('review:reminder.content.contribution-quality')}
              </Typography.Text>
            ),
          },
          {
            label: `${t('review:reminder.penalty')}`,
            key: '2',
            children: (
              <Typography.Text>
                <Trans
                  t={t}
                  i18nKey="review:reminder.content.penalty"
                  components={{ br: <br /> }}
                />
              </Typography.Text>
            ),
          },
        ]}
      />
    </>
  );
}
