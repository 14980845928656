import React from 'react';
import { UserPosition } from 'business/user/components/user-position/index';
import { useAppContext } from 'business/app-bootstrapper';
import { useGetLeaderboardUserPositionQuery } from 'generated/graphql';
import { Spacer } from 'ui/spacer';
import { useTranslation } from 'react-i18next';
import { isNullOrUndefined } from 'technical/utils/is-null-or-undefined';
import { StaffPosition } from '../staff-position';
import { RANK_STAFF_ID } from 'business/user/constant';
import config from 'config';

export const GlobalUserPosition = () => {
  const { user } = useAppContext();
  const { t } = useTranslation(['common']);
  const { data } = useGetLeaderboardUserPositionQuery({
    // @ts-expect-error
    variables: { userId: user?.id },
    skip: !user,
  });
  const leaderboardUserPosition = data?.leaderboard[0];

  if (user?.rank?.id === RANK_STAFF_ID) {
    return <StaffPosition user={user} />;
  }

  if (isNullOrUndefined(leaderboardUserPosition?.position)) {
    return (
      <Spacer justify="center" space={1} direction="vertical" align="center">
        {config.features.isInHibernation
          ? 'You need to be connected to see your ranking!'
          : t('common:leaderboard.no-rank')}
      </Spacer>
    );
  }

  return (
    <UserPosition
      userId={user?.id}
      position={leaderboardUserPosition?.position}
      rank={leaderboardUserPosition?.user?.ranking?.currentRank}
      displayName={leaderboardUserPosition?.user?.profile?.displayName}
    />
  );
};
