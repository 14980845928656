import React from 'react';
import { Skeleton, Typography } from 'antd';
import { Spacer } from 'ui/spacer';
import { formatIfPossible } from 'technical/date-fns';
import { useTranslation } from 'react-i18next';
import { isNotNullOrUndefined } from 'technical/utils/is-not-null-or-undefined';
import { PercentageValue } from 'ui/percentage';

interface PortfolioPublicationInfoProps {
  updateFrequencyInDays: number;
  lastPublicationDate?: Date;
  isInUpdateTime?: boolean;
  d7Perf?: number | null;
  lastUpdatePerf?: number | null;
}

export const PortfolioPublicationInfo: React.VFC<PortfolioPublicationInfoProps> = ({
  updateFrequencyInDays,
  lastPublicationDate,
  isInUpdateTime,
  d7Perf,
  lastUpdatePerf,
}) => {
  const { t } = useTranslation(['portfolio']);

  return (
    <Spacer>
      <Spacer space={1} direction="vertical">
        <Typography.Text strong>
          {t('portfolio:form.frequencyLabel')}
          <Typography.Text type="secondary">
            {t('portfolio:frequency', {
              context: updateFrequencyInDays,
            })}
          </Typography.Text>
        </Typography.Text>

        <Typography.Text strong>
          {t('portfolio:form.lastUpdate')}
          <Typography.Text type={isInUpdateTime ? 'danger' : 'secondary'}>
            {formatIfPossible(lastPublicationDate, 'PP')}
          </Typography.Text>
        </Typography.Text>
      </Spacer>
      {isNotNullOrUndefined(lastPublicationDate) && (
        <Spacer space={1} direction="vertical">
          {isNotNullOrUndefined(d7Perf) && (
            <Spacer space={1}>
              <Typography.Text strong>
                {t('portfolio:form.d7-perf')}
              </Typography.Text>
              <PercentageValue value={d7Perf} />
            </Spacer>
          )}

          {isNotNullOrUndefined(lastUpdatePerf) && (
            <Spacer space={1}>
              <Typography.Text strong>
                {t('portfolio:form.last-update-perf')}
              </Typography.Text>
              <PercentageValue value={lastUpdatePerf} />
            </Spacer>
          )}
        </Spacer>
      )}
    </Spacer>
  );
};

const PortfolioPublicationInfoSkeleton = () => {
  return (
    <Spacer>
      <Spacer space={1} direction="vertical">
        <Skeleton.Input active />
        <Skeleton.Input active />
      </Spacer>
      <Spacer space={1} direction="vertical">
        <Skeleton.Input active />
        <Skeleton.Input active />
      </Spacer>
    </Spacer>
  );
};

export const PublicationInfo = {
  ReadOnly: PortfolioPublicationInfo,
  Skeleton: PortfolioPublicationInfoSkeleton,
};
