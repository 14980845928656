import { TFunction } from 'i18next';
import { requiredErrorAlert } from 'technical/validation/ant-form-rules';
import * as yup from 'yup';
import { ProfileUpdateFormValues } from './types';

const alphanumericRegExp = /^[a-zA-Z0-9-_]+$/;

// This function create the validation schema usefull to the form in profileUpdateModal
// It takes a translation function as argument for errors translation
export function getValidationSchema(
  t: TFunction<'user'>,
): yup.SchemaOf<ProfileUpdateFormValues> {
  return yup
    .object()
    .shape({
      displayName: yup
        .string()
        .max(64, t('user:profile-update.validation-errors.display-name-length'))
        .required(),
      description: yup.string().nullable(),
      discord: yup
        .string()
        .nullable()
        .matches(
          /^(?!(here|everyone))^(?!.*(discord|```))[^@#:]{2,32}#\d{4}$/,
          {
            message: t('user:profile-update.validation-errors.must-match', {
              placeholder: t('user:profile-update.placeholders.discord'),
            }),
          },
        ),
      reddit: yup
        .string()
        .nullable()
        .matches(/^https:\/\/www.reddit.com\/user\/.*/, {
          message: t('user:profile-update.validation-errors.must-match', {
            placeholder: t('user:profile-update.placeholders.reddit'),
          }),
        }),
      telegram: yup
        .string()
        .nullable()
        .matches(/^https:\/\/t.me\/.*/, {
          message: t('user:profile-update.validation-errors.must-match', {
            placeholder: t('user:profile-update.placeholders.telegram'),
          }),
        }),
      twitter: yup
        .string()
        .nullable()
        .matches(/^https:\/\/twitter.com\/.*/, {
          message: t('user:profile-update.validation-errors.must-match', {
            placeholder: t('user:profile-update.placeholders.twitter'),
          }),
        }),
      youtube: yup
        .string()
        .nullable()
        .matches(/^https:\/\/www.youtube.com\/(c|channel)\/.*/, {
          message: t('user:profile-update.validation-errors.must-match', {
            placeholder: t('user:profile-update.placeholders.youtube'),
          }),
        }),
    })
    .required();
}
