import React, { useState } from 'react';
import {
  PortfolioFormValues,
  PortfolioVisibilityEnum,
} from 'business/portfolio/types';
import { Spacer } from 'ui/spacer';
import { Form } from 'ui/form';
import { compareAllocatedTokens } from 'business/portfolio/utils/allocated-tokens';
import { GetUserPortfolios } from 'business/portfolio/services/queries.gql';
import { useAddPortfolioUpdateMutation } from 'generated/graphql';
import { Divider, notification, Skeleton, Typography } from 'antd';
import { useTranslation } from 'react-i18next';
import { addDays, isAfter } from 'date-fns';
import * as yup from 'yup';
import Button from 'ui/button';
import { PortfolioContainer } from 'business/portfolio/components/portfolio-container';
import { PortfolioTokenRepartition } from 'business/portfolio/components/personal-portfolio/portfolio-token-repartition';
import { PublicationInfo } from 'business/portfolio/components/portfolio-publication-info';
import { Modal } from 'ui/modal';
import { PortfolioTitle } from 'business/portfolio/components/portfolio-title';
import classes from './index.module.scss';

export interface PersonalPortfolioProps {
  portfolio: PortfolioFormValues;
  isStatusVisible?: boolean;
}

const validationSchema = yup.object().shape({
  name: yup.string().required(),
  allocatedTokens: yup
    .array()
    .of(
      yup.object().shape({
        percentage: yup.number().required().positive().min(0.01).max(100),
        // todo 2 decimal max using validator or update
      }),
    )
    .required()
    .min(1)
    .test(
      'totalPercentage',
      'Your portfolio % must be equal to 100%',
      (allocatedTokens: any) => {
        // multiply percentage to avoid float issues
        const count = allocatedTokens.reduce(
          (sum: number, at: { percentage: number }) =>
            sum + at.percentage * 100,
          0,
        );
        return count === 10000;
      },
    ),
});

export const PersonalPortfolio: React.VFC<PersonalPortfolioProps> = ({
  portfolio,
}) => {
  const { t } = useTranslation(['portfolio']);
  const [isUnpublishModalOpen, setIsUnpublishModalOpen] = useState(false);

  // submit mutation
  const [addPortfolioUpdate] = useAddPortfolioUpdateMutation({
    onCompleted: ({ addPortfolioUpdate: updateResponse }) => {
      if (updateResponse?.success) {
        notification.success({
          message: t('portfolio:form.success.title'),
        });
      } else {
        notification.error({
          message: t('portfolio:form.error.title'),
          description: t('portfolio:form.error.message'),
        });
      }
    },
  });

  const isPublic = portfolio.visibility === PortfolioVisibilityEnum.public;
  const isInUpdateTime = portfolio.lastPublicationDate
    ? isAfter(
        new Date(),
        addDays(portfolio.lastPublicationDate, portfolio.updateFrequencyInDays),
      )
    : false;

  const isEditable = !isPublic || isInUpdateTime;

  const handleUpdate = async (values: PortfolioFormValues) => {
    const formattedAllocatedTokens = values.allocatedTokens.map((at) => ({
      percentage:
        typeof at.percentage === 'string' // InputField is changing number to string
          ? parseFloat(at.percentage)
          : at.percentage,
      tokenId: at.token.id,
    }));
    // if there is no change between initial data and form do not create a new portfolio update
    const isAllocatedTokensAreIdentical = compareAllocatedTokens(
      formattedAllocatedTokens,
      portfolio.allocatedTokens.map((at) => ({ ...at, tokenId: at.token.id })),
    );
    const allocatedTokens = !isAllocatedTokensAreIdentical
      ? formattedAllocatedTokens
      : undefined;
    await addPortfolioUpdate({
      variables: {
        ...values,
        id: portfolio.id,
        allocatedTokens,
      },
      refetchQueries: [GetUserPortfolios],
    });
  };

  return (
    <>
      <Form<PortfolioFormValues>
        validationSchema={validationSchema}
        onSubmit={handleUpdate}
        initialValues={portfolio}
      >
        {({ setFieldValue, handleSubmit }) => {
          return (
            <PortfolioContainer>
              <Spacer direction="vertical" justify="evenly" space={1}>
                <PortfolioTitle.Editable
                  disabled={!isEditable}
                  status={portfolio.visibility}
                />

                <Typography.Text className={classes.editableAllocation} strong>
                  {t('portfolio:form.allocations')}
                </Typography.Text>

                <PortfolioTokenRepartition.Editable disabled={!isEditable} />
              </Spacer>

              <PublicationInfo.ReadOnly
                updateFrequencyInDays={portfolio.updateFrequencyInDays}
                lastPublicationDate={portfolio.lastPublicationDate}
                isInUpdateTime={isInUpdateTime}
                d7Perf={portfolio.d7Perf}
                lastUpdatePerf={portfolio.lastUpdatePerf}
              />

              <Divider className={classes.divider} />
              {isEditable && !isPublic && (
                <Spacer
                  direction="horizontal"
                  justify="between"
                  align="end"
                  space={2}
                >
                  <Button
                    block
                    onClick={() => {
                      setFieldValue(
                        'visibility',
                        PortfolioVisibilityEnum.private,
                        false,
                      );
                      handleSubmit();
                    }}
                  >
                    {t('portfolio:form.save.draft.cta')}
                  </Button>
                  <Button
                    block
                    type="primary"
                    onClick={() => {
                      setFieldValue(
                        'visibility',
                        PortfolioVisibilityEnum.public,
                        false,
                      );

                      handleSubmit();
                    }}
                  >
                    {t('portfolio:form.save.publish.cta')}
                  </Button>
                </Spacer>
              )}
              {isPublic && (
                <Spacer direction="horizontal" justify="between" space={2}>
                  <Button
                    disabled={!isInUpdateTime}
                    block
                    onClick={() => handleSubmit()}
                  >
                    {t('portfolio:form.update.cta')}
                  </Button>
                  <Button
                    type="primary"
                    accent="danger"
                    block
                    onClick={() => {
                      setIsUnpublishModalOpen(true);
                    }}
                  >
                    {t('portfolio:form.unpublish.cta')}
                  </Button>
                </Spacer>
              )}
            </PortfolioContainer>
          );
        }}
      </Form>

      <Modal
        footer={
          <>
            <Button onClick={() => setIsUnpublishModalOpen(false)}>
              {t('portfolio:form.unpublish.confirm.cancel')}
            </Button>
            <Button
              type="primary"
              accent="danger"
              onClick={() => {
                handleUpdate({
                  ...portfolio,
                  visibility: PortfolioVisibilityEnum.private,
                });
                setIsUnpublishModalOpen(false);
              }}
            >
              {t('portfolio:form.unpublish.cta')}
            </Button>
          </>
        }
        closable
        onCancel={() => setIsUnpublishModalOpen(false)}
        open={isUnpublishModalOpen}
        title={
          <Typography.Text>
            {t('portfolio:form.unpublish.confirm.question')}
          </Typography.Text>
        }
      ></Modal>
    </>
  );
};

export function PersonalPortfolioSkeleton() {
  return (
    <PortfolioContainer>
      <Spacer direction="vertical" space={4}>
        <PortfolioTitle.Skeleton />
        <Spacer direction="vertical" space={1}>
          <Skeleton.Input active size="small" />
          <PortfolioTokenRepartition.Skeleton />
        </Spacer>
      </Spacer>
      <Spacer direction="vertical" space={3}>
        <PublicationInfo.Skeleton />
        <Spacer direction="horizontal" space={1}>
          <Skeleton.Button active block />
          <Skeleton.Button active block />
        </Spacer>
      </Spacer>
    </PortfolioContainer>
  );
}
