import { ModalProps } from 'antd';
import { PublicPortfolio } from 'business/portfolio/components/public-portfolio';
import Routes from 'business/router/routes';
import { PublicPortfolioResponse } from 'generated/graphql';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { generatePath } from 'react-router-dom';
import { isNullOrUndefined } from 'technical/utils/is-null-or-undefined';
import { LinkAsButton } from 'ui/button';
import { Modal } from 'ui/modal';
import classes from './index.module.scss';

export interface PortfolioDetailsModalProps
  extends Pick<ModalProps, 'open' | 'onCancel'> {
  portfolio: PublicPortfolioResponse | undefined;
}

export const PortfolioDetailsModal: React.VFC<PortfolioDetailsModalProps> = ({
  portfolio,
  ...modalProps
}) => {
  const { t } = useTranslation(['portfolio']);

  if (isNullOrUndefined(portfolio)) {
    return <></>;
  }

  const displayName = portfolio?.user.profile?.displayName;

  return (
    <Modal
      title={t('portfolio:details.title', {
        user: displayName ?? t('user.no-display-name'),
      })}
      closable
      footer={null}
      {...modalProps}
    >
      <PublicPortfolio portfolio={portfolio} />
      <LinkAsButton
        type="link"
        className={classes.profileLink}
        to={generatePath(Routes.PublicProfile, { id: portfolio?.user.id })}
      >
        {t('portfolio:details.toAuthorProfile', {
          user: displayName ?? t('user.no-display-name'),
        })}
      </LinkAsButton>
    </Modal>
  );
};
