import React from 'react';
import { Spacer } from 'ui/spacer';
import { HeaderMenu } from 'business/user/components/menu-header';
import { NavigationMenu } from 'ui/new-header/navigation-menu';
import { ReactComponent as SignOutSVG } from 'assets/icons/menu/sign-out.svg';
import authService from 'business/user/services/authentication.gql';
import Button from 'ui/button';
import classes from './index.module.scss';
import { useTranslation } from 'react-i18next';

interface ProfileSectionMenuProps {
  onItemClick?: () => void;
}

export const ProfileSectionMenu = ({
  onItemClick,
}: ProfileSectionMenuProps) => {
  const { t } = useTranslation(['common']);

  return (
    <Spacer space={2} className={classes.container} direction="vertical">
      <HeaderMenu onItemClick={onItemClick} />
      <NavigationMenu types={['profileSection']} onClick={onItemClick} />
      <Button
        onClick={() => {
          authService.logout();
        }}
        size="large"
        className={classes.button}
      >
        <Spacer
          direction="horizontal"
          space={1}
          align="center"
          justify="center"
        >
          {t('header.account-menu.sign-out')}
          <SignOutSVG className={classes.icon} />
        </Spacer>
      </Button>
    </Spacer>
  );
};
