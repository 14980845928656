import { Button } from 'antd';
import React from 'react';
import { DiscordLogoSVGComponent } from 'ui/social-networks/icons/discord-logo';
import { TelegramLogoSVGComponent } from 'ui/social-networks/icons/telegram-logo';
import { Spacer } from 'ui/spacer';
import config from 'config';
import colors from 'ui/theme/variables.module.scss';
import { useTranslation } from 'react-i18next';
import classes from './index.module.scss';

export default function SupportContactButtons() {
  const { t } = useTranslation(['common']);

  return (
    <Spacer direction="horizontal" space={1}>
      <Button
        type="primary"
        size="large"
        href={config.links.discord}
        target="_blank"
        rel="noreferrer"
      >
        <Spacer direction="horizontal" space={1} align="center">
          {t('common:footer.socials_discord')}
          <DiscordLogoSVGComponent fill="white" height={16} width={16} />
        </Spacer>
      </Button>
      <Button
        size="large"
        href={config.links.telegram}
        target="_blank"
        rel="noreferrer"
        className={classes.button}
      >
        <Spacer direction="horizontal" space={1} align="center">
          {t('common:footer.socials_telegram')}
          <TelegramLogoSVGComponent
            fill={colors.primaryColor2}
            height={16}
            width={16}
          />
        </Spacer>
      </Button>
    </Spacer>
  );
}
