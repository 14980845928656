import React from 'react';
import { useTranslation } from 'react-i18next';
import { Spacer } from 'ui/spacer';
import { TextField } from 'ui/form/fields';
import {
  isPortfolioVisibilityEnum,
  PortfolioFormValues,
  PortfolioVisibilityEnumValues,
} from 'business/portfolio/types';
import { PortfolioStatusTag } from 'business/portfolio/components/status-tag';
import { Skeleton, Typography } from 'antd';
import classes from './index.module.scss';

interface PrivatePortfolioTitleProps {
  disabled?: boolean;
  status: PortfolioVisibilityEnumValues | string;
}

export function PrivatePortfolioTitle({
  disabled,
  status,
}: PrivatePortfolioTitleProps) {
  const { t } = useTranslation(['portfolio']);
  return (
    <Spacer direction="horizontal" align="baseline" space={1}>
      <TextField<PortfolioFormValues>
        containerStyle={{
          flex: 1,
        }}
        disabled={disabled}
        className={classes.inputs}
        id="name"
        name="name"
        label={t('portfolio:form.name')}
      />
      {isPortfolioVisibilityEnum(status) && (
        <PortfolioStatusTag status={status} />
      )}
    </Spacer>
  );
}

interface PublicPortfolioTitleProps {
  name: string;
  status: PortfolioVisibilityEnumValues | string;
}

const PublicPortfolioTitle: React.VFC<PublicPortfolioTitleProps> = ({
  name,
  status,
}) => {
  const { t } = useTranslation(['portfolio']);

  return (
    <Spacer justify="between">
      <Spacer space={1}>
        <Typography.Text strong>{t('portfolio:form.name')}</Typography.Text>
        <Typography.Text>{name}</Typography.Text>
      </Spacer>
      {isPortfolioVisibilityEnum(status) && (
        <PortfolioStatusTag status={status} />
      )}
    </Spacer>
  );
};

const PortfolioTitleSkeleton = () => {
  return (
    <Spacer space={1} align="center">
      <Skeleton.Input active block />
      <Skeleton.Button active size="small" className={classes.skeletonTag} />
    </Spacer>
  );
};

export const PortfolioTitle = {
  Editable: PrivatePortfolioTitle,
  ReadOnly: PublicPortfolioTitle,
  Skeleton: PortfolioTitleSkeleton,
};
