/* eslint-disable no-restricted-imports */
import {
  format as datefnsFormat,
  formatDistance as dateFnsFormatDistance,
} from 'date-fns';
import { getDateFnsLocale } from 'technical/date-fns/locale';

export function format(
  date: Date | number,
  formatTemplate: string,
  options?: {
    locale?: Locale;
    weekStartsOn?: 0 | 1 | 2 | 3 | 4 | 5 | 6;
    firstWeekContainsDate?: number;
    useAdditionalWeekYearTokens?: boolean;
    useAdditionalDayOfYearTokens?: boolean;
  },
): string {
  const locale = getDateFnsLocale();
  return datefnsFormat(date, formatTemplate, { locale, ...options });
}

export function formatIfPossible(
  rawDate: Date | number | null | string | undefined,
  formatTemplate: string,
  options?: {
    locale?: Locale;
    weekStartsOn?: 0 | 1 | 2 | 3 | 4 | 5 | 6;
    firstWeekContainsDate?: number;
    useAdditionalWeekYearTokens?: boolean;
    useAdditionalDayOfYearTokens?: boolean;
  },
  empty = '-',
): string {
  if (rawDate) {
    const date = new Date(rawDate);
    return format(date, formatTemplate, options);
  }

  return empty;
}

export function formatDistance(
  date: Date | number,
  baseDate: Date | number,
  options?: {
    includeSeconds?: boolean;
    addSuffix?: boolean;
    locale?: Locale;
  },
): string {
  const locale = getDateFnsLocale();

  return dateFnsFormatDistance(date, baseDate, { locale, ...options });
}
