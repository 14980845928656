import { useCallback, useEffect, useState } from 'react';
import logger from 'technical/logger';
import * as yup from 'yup';
import { isNullOrUndefined } from 'technical/utils/is-null-or-undefined';
import {
  parseWithSchema,
  safeJsonParse,
} from 'technical/utils/parse-with-schema';

function readFromStorage(key: string, storage: Storage): unknown {
  const value = storage.getItem(key);
  return safeJsonParse(value);
}

function writeToStorage(key: string, value: any, storage: Storage) {
  try {
    storage.setItem(key, JSON.stringify(value));
  } catch (error) {
    logger.error({ message: `could not write to storage`, key, value });
  }
}

function useStorage<Schema extends yup.AnySchema>(
  key: string,
  defaultValue: yup.InferType<Schema>,
  schema: Schema,
  storage: Storage,
): readonly [
  Exclude<yup.Asserts<Schema>, null | undefined>,
  (value: yup.Asserts<Schema>) => void,
] {
  const [subscribed, update] = useState<yup.InferType<Schema>>(() => {
    const value = readFromStorage(key, storage);
    const parsed = parseWithSchema(value, schema);
    return isNullOrUndefined(parsed) ? defaultValue : parsed;
  });

  const store = useCallback(
    (value: yup.InferType<Schema>) => {
      writeToStorage(key, value, storage);
      update(value);
    },
    [key],
  );

  useEffect(
    function setupStorageListener() {
      function listen(event: StorageEvent) {
        if (event.key === key) {
          const oldValue = safeJsonParse(event.oldValue);
          const newValue = safeJsonParse(event.newValue);
          const value =
            parseWithSchema(newValue ?? oldValue, schema) ?? defaultValue;

          if (oldValue !== newValue) {
            writeToStorage(key, value, storage);
          }
          update(value);
        }
      }

      window.addEventListener('storage', listen);
      return () => window.removeEventListener('storage', listen);
    },
    [key, storage],
  );

  return [subscribed, store] as const;
}

export function useLocaleStorage<Schema extends yup.AnySchema>(
  key: string,
  defaultValue: yup.InferType<Schema>,
  schema: Schema,
) {
  return useStorage(key, defaultValue, schema, window.localStorage);
}

export function useSessionStorage<Schema extends yup.AnySchema>(
  key: string,
  defaultValue: yup.InferType<Schema>,
  schema: Schema,
) {
  return useStorage(key, defaultValue, schema, window.sessionStorage);
}
