import React from 'react';
import { useParams } from 'react-router-dom';
import { Card } from 'ui/cards';
import { useGetTokenInfosQuery } from 'generated/graphql';
import { CountInPortfolio } from 'business/token/components/count-in-portfolio';
import { TokenOverview } from 'business/token/components/token-overview';
import { PriceEvolutionCharts } from 'business/token/components/price-evolution-charts';
import { useTranslation } from 'react-i18next';
import { PriceChangePercentages } from 'business/token/components/price-change-percentages';
import { TokenMarketStatistics } from 'business/token/components/market-statistics';
import { defaultTokenEmptyValue } from 'business/token/common-values';
import { InfosPanel } from 'business/token/components/infos-panel';
import { TopResearches } from 'business/token/components/top-researches';
import { FundamentalRating } from 'business/token/components/fundamental-rating';
import { RatingDispersion } from 'business/token/components/rating-dispersion';
import { ResearchAnswers } from 'business/token/components/research-answers';
import { ReactComponent as GraphIcon } from 'assets/illustrations/graph-icon.svg';
import { ReactComponent as CommunityScore } from 'assets/illustrations/community-score-icon.svg';
import { Spacer } from 'ui/spacer';
import { TokenHeaderInfos } from 'business/token/components/token-header-infos';
import variables from 'ui/theme/variables.module.scss';
import { HotTopicArticles } from 'business/article/components/hot-topic-articles';
import classes from './index.module.scss';
import {
  BlockchainSummary,
  PoweredByITB,
} from 'business/token/components/blockchain-summary';
import config from 'config';
import classNames from 'classnames';
import { LinkAsButton } from 'ui/button';
import Routes from 'business/router/routes';
import Tag from 'ui/tag';

export function TokenInfosPage() {
  const { t } = useTranslation(['token']);
  const { reference } = useParams<'reference'>();
  if (reference === undefined) {
    throw new Error('reference param is not defined');
  }
  const { data, loading: queryLoading, previousData } = useGetTokenInfosQuery({
    variables: {
      reference,
    },
    skip: !reference,
  });

  const loading = !previousData && queryLoading;
  const { identity, market, portfolioCount, about } =
    data?.getTokenInfos ?? previousData?.getTokenInfos ?? {};

  return (
    <div
      className={classNames(
        classes.grid,
        config.features.showITBData && classes.itb,
      )}
    >
      <div className={classes.tokenTitle}>
        <TokenOverview
          loading={loading}
          about={data?.getTokenInfos.about}
          token={identity}
          {...market}
        />
      </div>
      <div className={classes.tokenInfos}>
        <TokenHeaderInfos
          tokenReference={reference}
          tokenId={identity?.id}
          {...market}
        />
      </div>
      <Spacer
        className={classes.articles}
        justify={{ xl: 'end', default: 'center' }}
        space="none"
      >
        <HotTopicArticles className={classes.articlesWrapper} />
      </Spacer>

      <div className={classes.charts}>
        <PriceEvolutionCharts
          token={{
            reference,
            symbol: identity?.symbol.toUpperCase() ?? defaultTokenEmptyValue,
          }}
        />
      </div>

      <div className={classes.dispersion}>
        <Card className={classes.dispersionCard}>
          <Card.Header level={2} title={t('token:dispersion.title')} />
          <RatingDispersion tokenReference={reference} />
        </Card>
      </div>

      <div className={classes.infos}>
        <InfosPanel
          about={about ?? undefined}
          market={{ reference, volume: market?.volume }}
        />
      </div>

      <div className={classes.notation}>
        <Card>
          <Card.Header
            level={2}
            title={t('token:notation.title')}
            icon={
              <CommunityScore
                width={20}
                height={14}
                fill={variables.primaryColor2}
              />
            }
          />
          <FundamentalRating tokenReference={reference} />
        </Card>
      </div>

      <div className={classes.evolution}>
        <Card>
          <PriceChangePercentages
            name={identity?.name ?? defaultTokenEmptyValue}
            priceChangePercentages={market?.priceChangePercentages}
          />
        </Card>
      </div>

      <div className={classes.portfolio}>
        <Card className={classes.portfolioCard}>
          <Card.Header
            icon={
              <GraphIcon
                width="13"
                height="13"
                fill={variables.primaryColor2}
              />
            }
            level={2}
            title={t('token:portfolios.title')}
          />
          <CountInPortfolio
            count={portfolioCount ?? 0}
            tokenRef={reference}
            loading={loading}
            // we blur this based on the fact that fake portfolio count should be between -99 and -10
            blurred={(portfolioCount ?? 0) < 0}
          />
        </Card>
      </div>

      <div className={classes.averages}>
        <Card.Header
          level={2}
          title={t('token:averages.title')}
          actions={
            config.features.isPowerSearchActivated ? (
              <>
                <LinkAsButton type="primary" to={Routes.PowerSearch}>
                  Power Search
                </LinkAsButton>
                <Tag
                  color={variables.ratingMedium}
                  style={{
                    position: 'absolute',
                    top: -8,
                    right: -8,
                    transform: 'rotate(12deg)',
                  }}
                >
                  New
                </Tag>
              </>
            ) : null
          }
        />
        <ResearchAnswers token={identity} />
      </div>

      <div className={classes['market-statistics']}>
        <Card>
          <Card.Header level={2} title={t('token:market-statistics.title')} />
          <TokenMarketStatistics tokenId={identity?.id} {...market} />
        </Card>
      </div>
      {config.features.showITBData ? (
        <div className={classes['on-chain-metrics']}>
          <Card>
            <Card.Header
              level={2}
              title={t('token:itb-integration.title')}
              actions={<PoweredByITB />}
            />
            <BlockchainSummary token={identity?.itb_id} />
          </Card>
        </div>
      ) : null}

      <div className={classes['top-analysts']}>
        <Card>
          <Card.Header level={2} title={t('token:top-analysts.title')} />
          <TopResearches tokenReference={reference} />
        </Card>
      </div>
    </div>
  );
}
