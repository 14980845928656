import React from 'react';

export function useIntersectionObserver<Element extends HTMLElement>(
  ref: React.RefObject<Element>,
  options?: IntersectionObserverInit | undefined,
) {
  const [isIntersecting, setIsIntersecting] = React.useState(false);

  React.useEffect(() => {
    const observer = new IntersectionObserver(([entry]) => {
      setIsIntersecting(entry.isIntersecting);
    }, options);

    const node = ref.current;
    if (node) {
      observer.observe(node);

      return () => {
        observer.unobserve(node);
      };
    }

    return () => {};
  }, [options]);

  return isIntersecting;
}
