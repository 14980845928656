import React from 'react';
import Avatar from 'antd/lib/avatar/avatar';
import { UserOutlined } from '@ant-design/icons';
import classes from './avatar.module.scss';

export const AvatarPlaceHolder: React.VFC = () => (
  <div className={classes.avatarContainer}>
    <Avatar size={82} icon={<UserOutlined />} />
  </div>
);
