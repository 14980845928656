import {
  AllocatedTokensResponse,
  Maybe,
  PortfolioResponse,
  PortfolioUpdateResponse,
  TokenResponse,
} from 'generated/graphql';
import { PortfolioFormValues } from 'business/portfolio/types';

// type copied from gql can be simplified
type PortfolioQuery = Pick<
  PortfolioResponse,
  | 'id'
  | 'createdAt'
  | 'name'
  | 'visibility'
  | 'updateFrequencyInDays'
  | 'lastPublicationDate'
  | 'd7Perf'
  | 'lastUpdatePerf'
> & {
  lastPublishedUpdate?: Maybe<
    Pick<PortfolioUpdateResponse, 'id' | 'createdAt' | 'updatedAt'> & {
      allocatedTokens?: Maybe<
        Array<
          Pick<
            AllocatedTokensResponse,
            'id' | 'percentage' | 'createdAt' | 'price' | 'h24PriceChange'
          > & {
            token: Pick<
              TokenResponse,
              'id' | 'symbol' | 'name' | 'reference' | 'coinGeckoImage'
            >;
          }
        >
      >;
    }
  >;
  lastUpdate?: Maybe<
    Pick<PortfolioUpdateResponse, 'id' | 'createdAt' | 'updatedAt'> & {
      allocatedTokens?: Maybe<
        Array<
          Pick<
            AllocatedTokensResponse,
            'id' | 'percentage' | 'createdAt' | 'price' | 'h24PriceChange'
          > & {
            token: Pick<
              TokenResponse,
              'id' | 'symbol' | 'name' | 'reference' | 'coinGeckoImage'
            >;
          }
        >
      >;
    }
  >;
};

export function transformQueryPortfolioToFormValues({
  lastPublicationDate,
  lastUpdate,
  ...portfolio
}: PortfolioQuery): PortfolioFormValues {
  return {
    lastPublicationDate: lastPublicationDate
      ? new Date(lastPublicationDate)
      : undefined,
    lastUpdateDate: lastUpdate?.createdAt
      ? new Date(lastUpdate.createdAt)
      : undefined,
    allocatedTokens: lastUpdate?.allocatedTokens ?? [],
    ...portfolio,
  };
}
