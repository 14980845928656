import { ParentSize } from '@visx/responsive';
import { useRatingDispersionQuery } from 'generated/graphql';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { clamp } from 'technical/utils/clamp';
import { BlurredFeature } from 'ui/blurred-feature';
import { Empty } from 'ui/empty-state';
import DispersionChart from './chart';
import classes from './index.module.scss';

const scale = ['Junk', 'Weak', 'Medium', 'Strong'];

export interface RatingDispersionProps {
  tokenReference: string;
}
export function RatingDispersion({ tokenReference }: RatingDispersionProps) {
  const { t } = useTranslation(['token']);
  const { data, loading } = useRatingDispersionQuery({
    variables: { tokenReference },
  });

  const entries = data?.rating_dispersion.entries ?? [];

  const isEmpty = entries.every((entry) => entry.points.length === 0);

  return (
    <BlurredFeature
      className={classes.centered}
      blurred={!loading && !data?.rating_dispersion.authorized}
    >
      {!loading && isEmpty ? (
        <Empty
          className={classes.empty}
          description={t('token:rating.no-data')}
        />
      ) : null}
      {!loading && !isEmpty ? (
        <div className={classes.wrapper}>
          <ParentSize>
            {({ width }) => {
              const height = clamp(width * 0.85, { min: 200, max: 400 });

              return (
                <DispersionChart
                  data={entries}
                  scale={scale}
                  width={width}
                  height={height}
                />
              );
            }}
          </ParentSize>
        </div>
      ) : null}
    </BlurredFeature>
  );
}
