import React from 'react';
import { Typography, TableProps } from 'antd';
import { useTranslation } from 'react-i18next';
import { format } from 'technical/date-fns';
import {
  PublicRating,
  ResearchFormCategory,
  Rank,
  ResearchResearch,
  Token,
  User,
  UserProfile,
} from 'generated/graphql';
import Table from 'ui/table';
import UserLink from 'business/user/components/user-link';
import { TokenIconAndName } from 'business/research/components/token-icon-and-name';
import { TokenCategoriesIcons } from 'business/research/components/token-categories-icons';
import RatingTag from 'ui/rating-tag';
import { tokenFilterDropdown } from '../table/filters/token-filter';
import classes from './index.module.scss';

type SelectedCategory = {
  questionCategory: Pick<ResearchFormCategory, 'name' | 'id'>;
};

type Author = Pick<User, 'id'> & {
  profile?: Pick<UserProfile, 'displayName'> | null;
  ranking: { currentRank: Pick<Rank, 'icon' | 'name'> };
};

export type ValidatedResearchRow = Pick<
  ResearchResearch,
  'id' | 'publishedAt' | 'status' | 'targetPrice' | 'comment'
> & {
  token: Pick<Token, 'name' | 'symbol' | 'reference' | 'coinGeckoImage'>;
  author: Author;
  selectedCategories: SelectedCategory[];
  rating?: Pick<PublicRating, 'name' | 'type'> | null;
};

export interface ResearchTableProps extends TableProps<ValidatedResearchRow> {
  researches: ValidatedResearchRow[];
  defaultFilters?: {
    tokenRefs?: string[];
  };
}

export function ValidatedResearchTable({
  researches,
  pagination,
  defaultFilters,
  ...tableProps
}: ResearchTableProps) {
  const { t } = useTranslation(['research']);

  return (
    <Table<ValidatedResearchRow>
      {...tableProps}
      scroll={{ x: true }}
      pagination={pagination ?? { pageSize: 10 }}
      dataSource={researches}
      rowKey={({ id }) => id}
      columns={[
        {
          title: t('research:validatedTable.token'),
          dataIndex: 'token',
          sorter: true,
          sortDirections: ['descend', 'ascend'],
          key: 'token',
          render: (_, { token }) => <TokenIconAndName token={token} />,
          filteredValue: defaultFilters?.tokenRefs,
          filterDropdown: tokenFilterDropdown(t),
        },
        {
          title: t('research:validatedTable.completedSections'),
          align: 'left',
          dataIndex: 'selectedCategories',
          key: 'selectedCategories',
          render: (_, { selectedCategories }) => {
            const selectedCategoriesIconsIDs = selectedCategories.map(
              ({ questionCategory }) => questionCategory.id,
            );

            return (
              <TokenCategoriesIcons
                categoriesIconsIds={selectedCategoriesIconsIDs}
              />
            );
          },
        },
        {
          title: t('research:validatedTable.publicationDate'),
          align: 'left',
          dataIndex: 'publishedAt',
          sorter: true,
          sortDirections: ['descend', 'ascend'],
          key: 'publishedAt', // update_at or creation date seems to be not perfectly good, we should maybe add a publication date & validation date
          render: (date: ResearchResearch['publishedAt']) => (
            <Typography.Text strong>
              {date ? format(new Date(date), 'PPP') : '-'}
            </Typography.Text>
          ),
        },
        {
          title: t('research:validatedTable.rating'),
          align: 'left',
          dataIndex: 'rating',
          key: 'rating',
          className: classes.columnRating,
          render: (rating) => {
            const { name, type } = rating;
            return <RatingTag rating={name} type={type} />;
          },
        },
        {
          title: t('research:validatedTable.author'),
          align: 'left',
          key: 'author',
          sorter: true,
          sortDirections: ['descend', 'ascend'],
          render: (
            _,
            {
              author: {
                id,
                profile,
                ranking: { currentRank },
              },
            },
          ) => (
            <UserLink
              id={id}
              displayName={profile?.displayName ?? undefined}
              rank={currentRank}
            />
          ),
        },
      ]}
    />
  );
}
