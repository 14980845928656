import React from 'react';
import { LeftOutlined } from '@ant-design/icons';
import { PartialAnswer } from 'generated/graphql';
import { useTranslation } from 'react-i18next';
import Button from 'ui/button';
import { Spacer } from 'ui/spacer';
import { Tabs } from 'ui/tabs';
import { QuestionCard } from './questions';
import { ResearchFormHelperProps } from './types';
import classes from './step-2.module.scss';
import { CategoryTab } from '../category-tab';
import { CategoryTabs } from '../category-tabs';

export function Step2({ state, send }: ResearchFormHelperProps) {
  const { t } = useTranslation(['form-wizard']);

  const tabs = state.context.draft.categories.map((category, index, array) => {
    type MaybeCategory = typeof array[number] | undefined;
    const previousCategory: MaybeCategory = array[index - 1];
    const nextCategory: MaybeCategory = array[index + 1];
    return {
      key: category.id,
      label: (
        <CategoryTab
          category={category.id}
          name={category.name}
          active={state.context.currentCategory === category.id}
        />
      ),
      children: (
        <Spacer direction="vertical" space={2} id={`category-${category.id}`}>
          {category.questions.map((question) => {
            return (
              <QuestionCard
                key={question.id}
                question={question}
                onSubmit={(answered: PartialAnswer) =>
                  send({ type: 'auto-save answer', answered })
                }
              />
            );
          })}
          {!category.isValid ? (
            <span className={classes['category-invalid']}>
              {t('form-wizard:invalid-category')}
            </span>
          ) : null}
          <Spacer
            space={1}
            direction={{ default: 'verticalReverse', sm: 'horizontal' }}
          >
            <Button
              type="ghost"
              size="large"
              onClick={() =>
                send({
                  type: 'finish later', // FINISH LATER
                })
              }
            >
              {t('form-wizard:actions.save-and-finish-later')}
            </Button>
            <Spacer
              direction="horizontal"
              space={1}
              align="stretch"
              justify={{ default: 'center', sm: 'end' }}
            >
              {previousCategory !== undefined ? (
                <Button
                  type="default"
                  size="large"
                  onClick={() =>
                    send({
                      type: 'switch category',
                      categoryId: previousCategory.id,
                    })
                  }
                  className={classes.button}
                >
                  {t('form-wizard:actions.previous-block')}
                </Button>
              ) : (
                <Button
                  type="default"
                  size="large"
                  onClick={() => send({ type: 'go to step 1' })}
                  className={classes.button} // PREVIOUS
                >
                  {t('form-wizard:actions.previous', {
                    context: 'answeringQuestions',
                  })}
                </Button>
              )}

              {nextCategory !== undefined ? (
                <Button
                  type="primary"
                  size="large"
                  className={classes.button}
                  onClick={() =>
                    send({
                      type: 'switch category',
                      categoryId: nextCategory.id,
                    })
                  }
                >
                  {t('form-wizard:actions.next-block')}
                </Button> // NEXT
              ) : (
                <Button
                  type="primary"
                  size="large"
                  className={classes.button}
                  disabled={!state.can('go to step 3')}
                  onClick={() => send({ type: 'go to step 3' })}
                >
                  {t('form-wizard:actions.next', {
                    context: 'answeringQuestions',
                  })}
                </Button>
              )}
            </Spacer>
          </Spacer>
        </Spacer>
      ),
    };
  });

  return (
    <div>
      <CategoryTabs
        activeKey={state.context.currentCategory ?? undefined}
        destroyInactiveTabPane
        items={tabs}
        onChange={(key) => send({ type: 'switch category', categoryId: key })}
      />
    </div>
  );
}
