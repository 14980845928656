import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Dropdown, Layout, Menu } from 'antd';
import { Spacer } from 'ui/spacer';
import KryptviewSVGComponent from 'ui/app-logo/kryptview';
import Routes from 'business/router/routes';
import { useAppContext } from 'business/app-bootstrapper';
import TokenSearchBar from 'business/token/components/token-search-bar';
import Button from 'ui/button';
import LoginModal from 'business/user/components/login-modal';
import { useMediaQuery } from 'technical/utils/use-mediaquery';
import { Me } from 'business/user/types/user';
import classNames from 'classnames';
import classes from './top.module.scss';
import ThemeSwitcher from './theme-switcher';
import { MenuLink } from './menu-link';
import { WalletMenu } from './wallet-menu';
import { AccountMenu } from './account-menu';
import { menuLinks } from './menu-links';

import { OverlayAccountMenu } from './overlay-account-menu';
import {
  AccountMobileBtnIcon,
  BurgerMenuIcon,
  CloseIcon,
  SearchIcon,
} from './icons';
import { TFunction } from 'i18next';

interface HeaderProps {
  user: Me | undefined;
  t: TFunction<'common'>;
  setLoginModalIsOpen: (value: boolean) => void;
  isSearchBarOpen: boolean;
  toggleSearch: () => void;
}

// TODO: all the responsiveness handle could be done much easier using css, and having child component handling their own responsiveness

const HeaderDesktop = ({ user, t, setLoginModalIsOpen }: HeaderProps) => {
  return (
    <Spacer align="center" space={1} className={classes.mainSpacer}>
      <Spacer align="center" space={2}>
        <Link to={Routes.Home} className={classes.logo}>
          <KryptviewSVGComponent />
        </Link>
        {menuLinks.map((menu) => (
          <MenuLink key={menu.href} {...menu} />
        ))}
      </Spacer>

      <Spacer align="center" space={2} className={classes.justifyContentEnd}>
        <div className={classes.searchBarContainer}>
          <TokenSearchBar />
        </div>
        <div className={classes.walletThemeContainer}>
          {user && <WalletMenu user={user} />}
          <ThemeSwitcher />
        </div>
        {user ? (
          <AccountMenu user={user} />
        ) : (
          <Button type="primary" onClick={() => setLoginModalIsOpen(true)}>
            {t('header.sign-in-or-register')}
          </Button>
        )}
      </Spacer>
    </Spacer>
  );
};

const HeaderMobile = ({
  user,
  t,
  setLoginModalIsOpen,
  isSearchBarOpen,
  toggleSearch,
}: HeaderProps) => {
  const [isBurgerMenuVisible, setIsBurgerMenuVisible] = useState(false);
  const [isAccountMenuVisible, setIsAccountMenuVisible] = useState(false);

  const toggleMenu = () => {
    setIsAccountMenuVisible(!isAccountMenuVisible);
  };

  return (
    <Spacer
      align="center"
      direction="vertical"
      space={1}
      className={classes.mainSpacer}
    >
      <Spacer align="center" justify="between" space={2}>
        <Dropdown
          className={classes.dropdown}
          overlayClassName={classes.overlayBurgerMenu}
          open={isBurgerMenuVisible}
          overlay={
            <Menu>
              <Button
                className={classes.closeBurgerMenuBtn}
                type="text"
                onClick={() => setIsBurgerMenuVisible(false)}
                icon={<CloseIcon />}
              />
              {menuLinks.map(
                (props) =>
                  (!props.loggedOnly || (props.loggedOnly && user)) && (
                    <Menu.Item key={`item-${props.href}`}>
                      <MenuLink {...props} noStyle />
                    </Menu.Item>
                  ),
              )}
            </Menu>
          }
        >
          <Button
            type="text"
            icon={<BurgerMenuIcon />}
            onClick={() => setIsBurgerMenuVisible(true)}
          />
        </Dropdown>
        <Link to={Routes.Home} className={classes.logo}>
          <KryptviewSVGComponent />
        </Link>
        <div className={classes.controlButtons}>
          <Button
            type="text"
            icon={<SearchIcon />}
            onClick={toggleSearch}
            className={classes.searchBtn}
          />
          {user ? (
            <Dropdown
              className={classes.dropdown}
              overlayClassName={classes.overlayAccountMenu}
              open={isAccountMenuVisible}
              overlay={
                <Menu>
                  <OverlayAccountMenu
                    user={user}
                    t={t}
                    closeHandler={toggleMenu}
                  />
                </Menu>
              }
            >
              <Button
                className={classes.accountBtn}
                onClick={toggleMenu}
                type="text"
                icon={<AccountMobileBtnIcon />}
              />
            </Dropdown>
          ) : (
            <Button
              type="primary"
              onClick={() => setLoginModalIsOpen(true)}
              className={classes.withPadding}
            >
              {t('header.sign-in-or-register')}
            </Button>
          )}
        </div>
      </Spacer>
      {isSearchBarOpen && <TokenSearchBar />}
    </Spacer>
  );
};

const HeaderTablet = ({
  user,
  t,
  setLoginModalIsOpen,
  isSearchBarOpen,
  toggleSearch,
}: HeaderProps) => {
  const isOver1280 = useMediaQuery('(min-width: 1280px)');
  const [isAccountMenuVisible, setIsAccountMenuVisible] = useState(false);

  const toggleMenu = () => {
    setIsAccountMenuVisible(!isAccountMenuVisible);
  };

  return (
    <Spacer
      align="center"
      direction="vertical"
      space={1}
      className={classes.mainSpacer}
    >
      <Spacer align="center" justify="between" space={2}>
        <Link to={Routes.Home} className={classes.logo}>
          <KryptviewSVGComponent />
        </Link>
        {menuLinks.map((menu) => (
          <MenuLink key={menu.href} {...menu} />
        ))}
        {isOver1280 && (
          <div className={classes.searchBarContainer}>
            <TokenSearchBar />
          </div>
        )}
        <div className={classes.controlButtons}>
          {!isOver1280 && (
            <Button
              type="text"
              icon={<SearchIcon />}
              onClick={toggleSearch}
              className={classes.searchBtn}
            />
          )}
          {user ? (
            <Dropdown
              className={classes.dropdown}
              overlayClassName={classes.overlayAccountMenu}
              open={isAccountMenuVisible}
              overlay={
                <Menu>
                  <OverlayAccountMenu
                    t={t}
                    user={user}
                    closeHandler={toggleMenu}
                  />
                </Menu>
              }
            >
              <Button
                className={classes.accountBtn}
                onClick={toggleMenu}
                type="text"
                icon={<AccountMobileBtnIcon />}
              />
            </Dropdown>
          ) : (
            <Button
              type="primary"
              onClick={() => setLoginModalIsOpen(true)}
              className={classes.signInSignUp}
            >
              {t('header.sign-in-or-register')}
            </Button>
          )}
        </div>
      </Spacer>
      {isSearchBarOpen && !isOver1280 && <TokenSearchBar />}
    </Spacer>
  );
};

export const TopHeader = () => {
  const { t } = useTranslation(['common']);
  const [loginModalIsOpen, setLoginModalIsOpen] = useState(false);
  const [isSearchBarOpen, setIsSearchBarOpen] = useState(false);
  const { user } = useAppContext();
  const isOver1440 = useMediaQuery('(min-width: 1440px)');
  const isBetween992and1440 = useMediaQuery(
    '(min-width: 992px) and (max-width: 1439px)',
  );
  const isUnder992 = useMediaQuery('(min-width: 320px) and (max-width: 991px)');

  const toggleSearch = () => {
    setIsSearchBarOpen(!isSearchBarOpen);
  };

  const headerProps = {
    user,
    t,
    setLoginModalIsOpen,
    isSearchBarOpen,
    toggleSearch,
  };

  return (
    <div className={classes.headerContainer}>
      <Layout.Header
        className={classNames(
          classes.header,
          isSearchBarOpen && classes.mobileHeader,
        )}
      >
        <div className={classes.skiplink}>
          <a
            id="skip-to-content"
            href="#main-content"
            className={classes.menulink}
          >
            {t('navigation.skip-to-content')}
          </a>
        </div>
        {isOver1440 && <HeaderDesktop {...headerProps} />}
        {isBetween992and1440 && <HeaderTablet {...headerProps} />}
        {isUnder992 && <HeaderMobile {...headerProps} />}
      </Layout.Header>
      <LoginModal isOpen={loginModalIsOpen} setIsOpen={setLoginModalIsOpen} />
    </div>
  );
};
