import {
  categoriesColors,
  CategoriesEnumValues,
  categoriesIds,
} from 'business/research/constants/category';
import { isCategoryId } from 'business/research/utils/is-category-id';
import { isCategoryEnum } from 'business/research/utils/is-category-enum';

export const getCategoryColor = (category: string | CategoriesEnumValues) => {
  if (isCategoryEnum(category)) {
    return categoriesColors[category];
  }

  // we use the known category ids
  if (isCategoryId(category)) {
    return categoriesColors[categoriesIds[category]];
  }

  return categoriesColors.unknown;
};
