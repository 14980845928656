import {
  categoriesIcons,
  CategoriesEnumValues,
  categoriesIds,
} from 'business/research/constants/category';
import { isCategoryId } from 'business/research/utils/is-category-id';
import { isCategoryEnum } from 'business/research/utils/is-category-enum';

export const getCategoryIcons = (category: string | CategoriesEnumValues) => {
  if (isCategoryEnum(category)) {
    return categoriesIcons[category];
  }

  // we use known category ids if possible
  if (isCategoryId(category)) {
    return categoriesIcons[categoriesIds[category]];
  }

  return categoriesIcons.unknown;
};
