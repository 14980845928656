import React from 'react';
import { Typography } from 'antd';
import { useTranslation } from 'react-i18next';
import { Spacer } from 'ui/spacer';
import { Card } from 'ui/cards';
import BlockSelect from 'ui/block-select';
import Button from 'ui/button';
import ReviewBlock from 'business/peer-review/components/review-block';
import { CategoryIcon } from '../category-icon';
import { ResearchFormHelperProps } from './types';

export function Step1({ state, send }: ResearchFormHelperProps) {
  const { t } = useTranslation(['form-wizard']);

  return (
    <Spacer direction="vertical">
      <Card.Header
        level={3}
        title={'Choose one or several blocks to rate' /* t('step-1.title') */}
        // className={classes.header}
      />
      <Typography.Paragraph>
        {t('form-wizard:categorySelection.limitBlock', {
          context: !state.context.token.isCovered ? 'coverage' : 'default',
        })}
      </Typography.Paragraph>
      <Spacer direction="vertical" space={2}>
        <BlockSelect
          blocks={state.context.categories.map(
            ({ id, name, lastResearchedTime }) => ({
              id,
              isSelected: state.context.selectedCategories.has(id),
              render: (
                <ReviewBlock
                  questionBlock={name}
                  dateLastReview={lastResearchedTime}
                  icon={<CategoryIcon size="medium" category={id} />}
                />
              ),
            }),
          )}
          onValueChange={({ id: categoryId }) => {
            send({ type: 'toggle category', categoryId });
          }}
        />
        <Spacer justify="end">
          <Button
            type="primary"
            disabled={!state.can('go to step 2')}
            onClick={() => send({ type: 'go to step 2' })}
          >
            Next
          </Button>
        </Spacer>
      </Spacer>
    </Spacer>
  );
}
