export function truncate(
  str: string,
  config: { start: number; end: number } = { start: 8, end: 4 },
) {
  return `${str.slice(0, config.start)}...${str.slice(-config.end)}`;
}

export function formatBalance(
  bigNumberAsString: string /* represents a formatted BigNumber */,
  precision: number = 4,
) {
  const nb = +bigNumberAsString; // convert to number, looses precision
  const rounded = Math.floor(nb * 10 ** precision) / 10 ** precision;
  return rounded.toString();
}
