import authService from 'business/user/services/authentication.gql';
import classnames from 'classnames';
import Routes from 'business/router/routes';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import Link from 'ui/link';
import AuthCard from '../auth-card';
import ActionCard from '../action-card';
import styles from './index.module.scss';
import { Values } from '../auth-form';

interface Props {
  className?: string;
}

export default function SignupCard({ className }: Props) {
  const [signUpError, setSignUpError] = useState<string>('');
  const [showSuccess, setShowSuccess] = useState(false);

  const { t } = useTranslation(['common']);

  const onSubmitClick = async (values: Values) => {
    try {
      await authService.signUp(
        values.login,
        values.password,
        values.password,
        values.registeredFromCampaign,
        values.referredFromCode, // whay wasn't it already included ?
        values.hasAgreedToCommercialOffers,
        values.hasCertifiedAgeMajority,
      );
      setShowSuccess(true);
      window.localStorage.removeItem('coming-from-brave');
    } catch (error) {
      setSignUpError(error.message);
    }
  };

  if (showSuccess) {
    return (
      <ActionCard
        dataTestId="success-signup"
        title={t('pages.signUp.success.title')}
        hint={t('pages.signUp.success.description')}
        btnText={t('pages.common.goToHome')}
      />
    );
  }

  return (
    <AuthCard
      className={classnames(className, styles.card)}
      submitButton={{
        label: t('pages.signUp.form.submit'),
        onClick: onSubmitClick,
      }}
      authError={{ key: 'errors.signUp', context: signUpError }}
      bottomHint={
        <Link
          to={Routes.SignIn}
          prefix={t('pages.signUp.signIn.prefix')}
          align="center"
        >
          {t('pages.signUp.signIn.cta')}
        </Link>
      }
      showSignUpInput
    />
  );
}
