import React from 'react';
import { Spacer } from 'ui/spacer';
import classNames from 'classnames';
import { Skeleton, Typography } from 'antd';
import { Link } from 'react-router-dom';
import classes from './index.module.scss';

export interface ArticleProps {
  title?: string;
  link: string;
  thumbnail: string;
  loading?: boolean;
  className?: string;
  internal?: boolean;
}

export function Article({
  title,
  link,
  thumbnail,
  loading = false,
  className,
  internal = false,
}: ArticleProps) {
  if (loading) {
    return (
      <Spacer direction="vertical" space={2} className={classes.article}>
        <div className={classNames(classes.thumbnail, classes.gray)} />
        <div>
          <Skeleton
            className={classes.skeleton}
            round
            paragraph={{ rows: 2, width: [112, 168] }}
            title={false}
            active
          />
        </div>
      </Spacer>
    );
  }

  if (internal) {
    return (
      <Link to={link}>
        <Spacer direction="vertical" space={1} className={classes.article}>
          <div className={classes.thumbnail}>
            <img
              src={thumbnail}
              alt={`"${title ?? 'Article'}" thumbnail`}
              className={classNames(className)}
            />
          </div>

          {title ? (
            <div>
              <Typography.Text className={classes.link}>
                {title}
              </Typography.Text>
            </div>
          ) : null}
        </Spacer>
      </Link>
    );
  }
  return (
    <a href={link} target="_blank" rel="noreferrer" aria-label={title}>
      <Spacer direction="vertical" space={1} className={classes.article}>
        <div className={classes.thumbnail}>
          <img
            src={thumbnail}
            alt={`"${title ?? 'Article'}" thumbnail`}
            className={classNames(className)}
          />
        </div>

        {title ? (
          <div>
            <Typography.Text className={classes.link}>{title}</Typography.Text>
          </div>
        ) : null}
      </Spacer>
    </a>
  );
}
