import React, { useState } from 'react';
import { StepProps, Steps, Typography } from 'antd';
import { useAppContext } from 'business/app-bootstrapper';
import * as yup from 'yup';
import {
  useSelectUserOnboardingQuery,
  useSetOnboardingHasSeenDocMutation,
  useSetOnboardingJoinSocialsMediaMutation,
  useSetOnboardingProfileMutation,
  useSetOnboardingWalletMutation,
} from 'generated/graphql';
import Button from 'ui/button';
import { ProfileUpdateModal } from 'business/user/components/profile-update-modal';
import LoginModal, {
  LoginModalFormTabs,
} from 'business/user/components/login-modal';
import { DownOutlined, RightOutlined } from '@ant-design/icons';
import classnames from 'classnames';
import { WalletConnectButton } from 'business/user/components/wallet-connect-button';
import { useTranslation } from 'react-i18next';
import { OnboardingStepSocialsMedia } from 'business/onboarding/components/onboarding/onboarding-step-socials-media';
import config from 'config';
import { useLoginModal } from 'business/user/services/hooks';
import classes from './index.module.scss';
import {
  OnboardingState,
  OnboardingStepEnum,
  OnboardingStepEnumType,
} from './types';
import { OnboardingStepDescription } from './onboarding-step-description';
import { OnboardingQualificationStep } from './onboarding-step-qualification';

const { Step } = Steps;

type ToggleState = {
  value: boolean;
  toggle: () => void;
  setFalse: () => void;
};

function useToggle(): ToggleState {
  const [value, setValue] = useState(false);
  return {
    value,
    toggle: () => setValue((old) => !old),
    setFalse: () => setValue(false),
  };
}

const SkippedIcon = ({ toggleState }: { toggleState?: ToggleState }) => {
  const { value, toggle } = toggleState ?? { value: false, toggle: undefined };
  const Icon = value ? DownOutlined : RightOutlined;

  return <Icon className={classnames(classes.skippedIcon)} onClick={toggle} />;
};

function getOnboardingStepEnum(value: string) {
  const record: Record<string, OnboardingStepEnumType> = OnboardingStepEnum;
  const step = record[value];

  if (!step) {
    return OnboardingStepEnum.unbegun;
  }

  return step;
}

export function useOnboardingState(): OnboardingState {
  const { isConnected, user } = useAppContext();
  const {
    data,
    previousData,
    error,
    refetch,
    loading,
  } = useSelectUserOnboardingQuery({
    skip: !isConnected,
    // @ts-expect-error
    variables: { userId: user?.id },
    fetchPolicy: 'cache-and-network',
  });

  const onboarding = data?.onboarding ?? previousData?.onboarding;

  const refreshOnboarding = () => {
    refetch();
  };

  const defaultStep: OnboardingStepEnumType = OnboardingStepEnum.unbegun;

  if (!onboarding || !isConnected) {
    return {
      currentStep: 0,
      isAllComplete: false,
      loading,
      error,
      refreshOnboarding,

      register: defaultStep,
      joinSocialsMedia: defaultStep,
      interest: defaultStep,
      wallet: defaultStep,
      profile: defaultStep,
      access: defaultStep,
      hasSeenDoc: defaultStep,
    };
  }

  const {
    interest,
    wallet,
    profile,
    joinSocialsMedia,
    hasSeenDoc,
  } = onboarding;

  const steps = [
    !isConnected,
    joinSocialsMedia === OnboardingStepEnum.unbegun,
    interest === OnboardingStepEnum.unbegun,
    wallet === OnboardingStepEnum.unbegun,
    profile === OnboardingStepEnum.unbegun,
    hasSeenDoc === OnboardingStepEnum.unbegun,
    true, // the last true meaning we have skipped or done all steps
  ];
  const currentStep = steps.findIndex((v) => v);

  return {
    currentStep,
    loading,
    error,
    isAllComplete: currentStep === steps.length - 1,
    refreshOnboarding,

    register: OnboardingStepEnum.done,
    joinSocialsMedia: getOnboardingStepEnum(joinSocialsMedia),
    interest: getOnboardingStepEnum(interest),
    wallet: getOnboardingStepEnum(wallet),
    profile: getOnboardingStepEnum(profile),
    access: defaultStep,
    hasSeenDoc: getOnboardingStepEnum(hasSeenDoc),
  };
}

export function Onboarding() {
  const { t } = useTranslation(['onboarding']);
  const { user, isConnected } = useAppContext();
  const { loginModalIsOpen, setLoginModalIsOpen } = useLoginModal();

  const [isProfileModalVisible, setIsProfileModalVisible] = useState(false);
  const joinSocialsMediaToggle = useToggle();
  const interestToggle = useToggle();
  const walletToggle = useToggle();
  const profileToggle = useToggle();
  const {
    currentStep,
    loading,
    error,
    refreshOnboarding,
    register,
    joinSocialsMedia,
    interest,
    wallet,
    profile,
    hasSeenDoc,
  } = useOnboardingState();
  const variables = { userId: user?.id, value: OnboardingStepEnum.skipped };
  const [
    setOnboardingWallet,
    { loading: setOnboardingWalletLoading },
  ] = useSetOnboardingWalletMutation({
    onCompleted: refreshOnboarding,
    // @ts-expect-error
    variables,
  });
  const [
    setOnboardingProfile,
    { loading: setOnboardingProfileLoading },
  ] = useSetOnboardingProfileMutation({
    onCompleted: refreshOnboarding,
    // @ts-expect-error
    variables,
  });
  const [
    setOnboardingJoinSocialsMedia,
  ] = useSetOnboardingJoinSocialsMediaMutation({
    onCompleted: refreshOnboarding,
  });
  const [setOnboardingHasSeenDoc] = useSetOnboardingHasSeenDocMutation({
    onCompleted: refreshOnboarding,
  });

  const ConnectWallet = () => (
    <WalletConnectButton
      user={user}
      kvtAddress="0xFCBE144427Ce3bD47BB2cDae33c30aC761b4AADC"
    />
  );

  const CompleteProfile = () => (
    <Button
      type="primary"
      loading={isProfileModalVisible}
      onClick={() => setIsProfileModalVisible(true)}
    >
      {t('onboarding:profile.description')}
    </Button>
  );

  const joinSocialsMediaSkipped = joinSocialsMedia === 'skipped';
  const interestSkipped = interest === 'skipped';
  const walletSkipped = wallet === 'skipped';
  const profileSkipped = profile === 'skipped';
  const hasSeenDocSkipped = hasSeenDoc === 'skipped';

  const steps: (StepProps & { key: string })[] = [
    {
      key: 'onboarding-step-setup',
      title: t('onboarding:setup.title', {
        context: register === OnboardingStepEnum.done ? 'completed' : undefined,
      }),
      description: (
        <OnboardingStepDescription show={currentStep === 0}>
          <Button
            type="primary"
            loading={loginModalIsOpen}
            onClick={() => setLoginModalIsOpen(true)}
          >
            {t('onboarding:setup.description')}
          </Button>
        </OnboardingStepDescription>
      ),
    },
    {
      key: 'onboarding-step-join-socials-media',
      icon: joinSocialsMediaSkipped && (
        <SkippedIcon toggleState={joinSocialsMediaToggle} />
      ),
      title: (
        <OnboardingStepSocialsMedia.Title
          onboardingStepStatus={joinSocialsMedia}
        />
      ),
      description: (
        <OnboardingStepDescription
          show={currentStep === 1 || joinSocialsMediaToggle.value}
          setSkipped={
            joinSocialsMediaSkipped
              ? undefined
              : // @ts-expect-error
                () => setOnboardingJoinSocialsMedia({ variables })
          }
        >
          <OnboardingStepSocialsMedia.Step
            setSkipped={() => {
              refreshOnboarding();
              joinSocialsMediaToggle.setFalse();
            }}
          />
        </OnboardingStepDescription>
      ),
    },
    {
      key: 'onboarding-step-interest',
      className: classes.skipped,
      icon: interestSkipped && <SkippedIcon toggleState={interestToggle} />,
      title: (
        <OnboardingQualificationStep.Title onboardingStepStatus={interest} />
      ),
      description: (
        <OnboardingStepDescription
          show={currentStep === 2 || interestToggle.value}
        >
          <OnboardingQualificationStep.Step
            showLaterButton={!interestSkipped}
            onCompleted={() => {
              refreshOnboarding();
              interestToggle.setFalse();
            }}
          />
        </OnboardingStepDescription>
      ),
    },
    {
      key: 'onboarding-step-wallet',
      icon: walletSkipped && <SkippedIcon />,
      title: walletSkipped ? (
        <ConnectWallet />
      ) : (
        t('onboarding:wallet.title', { context: wallet })
      ),
      description: (
        <OnboardingStepDescription
          show={currentStep === 3 || walletToggle.value}
          loading={setOnboardingWalletLoading}
          setSkipped={
            // @ts-expect-error
            walletSkipped ? undefined : () => setOnboardingWallet({ variables })
          }
        >
          <ConnectWallet />
        </OnboardingStepDescription>
      ),
    },
    {
      key: 'onboarding-step-profile',
      icon: profileSkipped && <SkippedIcon />,
      title: profileSkipped ? (
        <CompleteProfile />
      ) : (
        t(`onboarding:profile.title`, { context: profile })
      ),
      description: (
        <OnboardingStepDescription
          show={currentStep === 4}
          loading={setOnboardingProfileLoading}
          setSkipped={
            profileSkipped
              ? undefined
              : // @ts-expect-error
                () => setOnboardingProfile({ variables })
          }
        >
          <CompleteProfile />
        </OnboardingStepDescription>
      ),
    },
    {
      key: 'onboarding-step-has-seen-doc',
      title: t('onboarding:has-seen-doc.title'),
      description: (
        <OnboardingStepDescription
          show={currentStep === 5}
          setSkipped={
            hasSeenDocSkipped
              ? undefined
              : // @ts-expect-error
                () => setOnboardingHasSeenDoc({ variables })
          }
          laterButtonLabel={t('onboarding:has-seen-doc.complete-onboarding')}
        >
          <Typography.Text>
            {t('onboarding:has-seen-doc.description')}
          </Typography.Text>
          <Button
            href={config.links.scoreTokenDocumentation}
            referrerPolicy="no-referrer"
            target="_blank"
            rel="noopener noreferrer"
          >
            {t('onboarding:has-seen-doc.see-doc')}
          </Button>
        </OnboardingStepDescription>
      ),
    },
  ];

  return (
    <>
      <LoginModal
        isOpen={loginModalIsOpen} // type is boolean or undefined but we should have default value
        setIsOpen={setLoginModalIsOpen}
        startTab={LoginModalFormTabs.signup}
      />
      <ProfileUpdateModal
        open={isProfileModalVisible}
        setIsVisible={setIsProfileModalVisible}
        onCompleted={() => {
          refreshOnboarding();
          profileToggle.setFalse();
        }}
      />
      <Steps
        direction="vertical"
        className={classes.onboarding}
        current={currentStep}
        size="small"
        status={loading ? 'wait' : error && 'error'}
      >
        {steps.map(({ key, ...props }) => (
          <Step key={key} {...props} />
        ))}
      </Steps>
    </>
  );
}
