import React from 'react';
import { Tabs } from 'ui/tabs';
import { useAppContext } from 'business/app-bootstrapper';
import { Values } from 'technical/types';
import { useCurrentTab } from 'technical/router/url-param/use-current-tab';
import { useTranslation } from 'react-i18next';
import ResearchHistoryTable from 'business/user/components/research-history-table';
import { Typography } from 'antd';

const HistoryResearchTabsEnum = {
  pendingResearch: 'pending-research',
  draftResearch: 'draft-research',
  pastResearch: 'past-research',
} as const;

type ValuesOfCommunityResearchTabsEnum = Values<typeof HistoryResearchTabsEnum>;

export function ResearchHistoryPage() {
  const { user } = useAppContext();
  const { t } = useTranslation(['token', 'research']);
  const [currentTab, setTab] = useCurrentTab<typeof HistoryResearchTabsEnum>({
    defaultTab: HistoryResearchTabsEnum.draftResearch,
    tabs: HistoryResearchTabsEnum,
  });

  const tabsItems = [
    {
      label: t('user:research-history.draft-title'),
      key: HistoryResearchTabsEnum.draftResearch,
      children: (
        <ResearchHistoryTable
          columnSelection={['token', 'selectedCategories', 'rating', 'status']}
          includeStatus={['draft']}
          title={t('user:research-history.draft-title')}
          authorId={user?.id}
        />
      ),
    },
    {
      label: t('user:research-history.pending-title'),
      key: HistoryResearchTabsEnum.pendingResearch,
      children: (
        <ResearchHistoryTable
          title={t('user:research-history.pending-title')}
          includeStatus={['published']}
          authorId={user?.id}
        />
      ),
    },
    {
      label: t('user:research-history.past-title'),
      key: HistoryResearchTabsEnum.pastResearch,
      children: (
        <ResearchHistoryTable
          title={t('user:research-history.past-title')}
          includeStatus={['rejected', 'validated']}
          authorId={user?.id}
        />
      ),
    },
  ];

  return (
    <>
      <Typography.Title>{t('user:research-history.title')}</Typography.Title>
      <Tabs
        defaultActiveKey="draftResearchHistory"
        activeKey={currentTab}
        onChange={(tab) => setTab(tab as ValuesOfCommunityResearchTabsEnum)}
        items={tabsItems}
      />
    </>
  );
}
