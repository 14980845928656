import logger from 'technical/logger';
import * as yup from 'yup';

export function safeJsonParse(value: string | null): unknown {
  if (value === null) {
    return null;
  }
  try {
    return JSON.parse(value);
  } catch (error) {
    logger.error({ message: `could not JSON.parse value`, value });
    return null;
  }
}

export function parseWithSchema<Schema extends yup.AnySchema>(
  value: unknown,
  schema: Schema,
): yup.InferType<Schema> | null {
  try {
    return schema.cast(value, { assert: true, stripUnknown: true });
  } catch (error) {
    return null;
  }
}
