import React from 'react';
import { usePromotedTokensQuery } from 'generated/graphql';
import { Card } from 'ui/cards';
import { useTranslation } from 'react-i18next';
import { generatePath, useNavigate } from 'react-router-dom';
import Routes from 'business/router/routes';
import { Skeleton, Typography } from 'antd';
import Link from 'ui/link';
import defaultTokenImage from 'business/token/components/token-card/img/default_token.png';
import { CoinGeckoTokenImage } from 'ui/coin-gecko-token-image';
import { Spacer } from 'ui/spacer';
import Tag from 'ui/tag';
import variables from 'ui/theme/variables.module.scss';
import { formatDistance } from 'technical/date-fns';
import { capitalize } from 'technical/utils/capitalize';
import classnames from 'classnames';
import classes from './index.module.scss';

type PromotedTokenProps = {
  name: string;
  reference: string;
  symbol: string;
  image?: string;
  promotedUntil?: Date;
  bonus?: number;
};

function computeXPBonus(bonus?: number | null) {
  // we do this to know how much XP a user will gain by scoring this token
  // ex: bonus = 2, user will receive 2 times the default XP -> +100% XP
  return bonus ? bonus * 100 - 100 : '?';
}

function PromotedToken({
  reference,
  image,
  name,
  symbol,
  promotedUntil,
  bonus,
}: PromotedTokenProps) {
  const { t } = useTranslation(['home']);
  const navigate = useNavigate();
  const path = generatePath(Routes.token, { reference });

  return (
    <Card
      onClick={() => {
        navigate(path);
      }}
      type="well"
      className={classes.tokenCardWrapper}
      stretchHeight
    >
      <Spacer direction="vertical" space={2}>
        <div className={classes.titleGrid}>
          <CoinGeckoTokenImage
            className={classes.image}
            coinGeckoImage={image}
            fallback={defaultTokenImage}
            alt={`${name} logo`}
          />
          <Link to={path}>
            <Typography.Text
              className={classes.name}
              ellipsis={{ tooltip: name }}
              strong
            >
              {name}
            </Typography.Text>
          </Link>
          <Typography.Text className={classes.symbol}>{symbol}</Typography.Text>
        </div>
        <Spacer direction="vertical">
          {promotedUntil
            ? capitalize(
                t('home:token-bounties.remaining-time', {
                  time: formatDistance(promotedUntil, new Date()),
                }),
              )
            : null}
        </Spacer>
        <div className={classes['xp-tag']}>
          <Tag color={variables.primaryColor}>
            {t('home:token-bounties.bonus', { bonus: computeXPBonus(bonus) })}
          </Tag>
        </div>
      </Spacer>
    </Card>
  );
}

function PromotedTokenSkeleton() {
  return (
    <Card type="well">
      <Spacer direction="vertical" space={4} className={classes.skeletonCard}>
        <Skeleton
          className={classes.skeleton}
          avatar
          round
          paragraph={{ rows: 2, width: [70, 60] }}
          title={false}
          active
        />
        <Skeleton
          className={classnames(classes.skeleton, classes.skeletonFooter)}
          round
          paragraph={{ rows: 3, width: [70, 80, 60] }}
          title={false}
          active
        />
      </Spacer>
    </Card>
  );
}

export type PromotedTokensCardProps = {
  fallback: React.ReactNode;
  maxPromotions?: number;
};

export function PromotedTokensCard({
  fallback,
  maxPromotions = 4,
}: PromotedTokensCardProps) {
  const { t } = useTranslation(['home']);
  const { loading, data } = usePromotedTokensQuery({
    variables: {
      limit: maxPromotions,
    },
  });
  const promotedTokens = data?.token ?? [];

  if (!loading && promotedTokens.length === 0) {
    return <>{fallback}</>;
  }

  return (
    <Card className={classes.cardsContainer}>
      <Card.Header level={2} title={`💸 ${t('home:token-bounties.title')}`} />
      <Spacer space={2}>
        {loading ? (
          <>
            <PromotedTokenSkeleton />
            <PromotedTokenSkeleton />
            <PromotedTokenSkeleton />
            <PromotedTokenSkeleton />
          </>
        ) : null}
        {promotedTokens.map(
          ({ id, name, reference, symbol, image, bounties }) => (
            <PromotedToken
              key={id}
              name={name}
              reference={reference}
              symbol={symbol}
              image={image ?? undefined}
              bonus={bounties.active?.bonus?.value ?? undefined}
              promotedUntil={
                bounties.active?.until?.date
                  ? new Date(bounties.active.until.date)
                  : undefined
              }
            />
          ),
        )}
      </Spacer>
    </Card>
  );
}
