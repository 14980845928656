import React from 'react';
import { DashboardRewards } from 'business/dashboard/components/dashboard-rewards';
import { DashboardAchievements } from 'business/dashboard/components/dashboard-achievements';
import { DashboardProfile } from 'business/dashboard/components/dashboard-profile';
import { DashboardOtherActions } from 'business/dashboard/components/dashboard-other-actions';
import { InviteAndEarn } from 'business/dashboard/components/invite-and-earn';

import classes from './index.module.scss';

export function DashboardPage() {
  return (
    <div className={classes.grid}>
      <div className={classes.profile}>
        <DashboardProfile />
      </div>
      <div className={classes.rewards}>
        <DashboardRewards />
      </div>

      <div className={classes['invite-and-earn']} id="invite-and-earn">
        <InviteAndEarn />
      </div>

      <div className={classes['other-actions']}>
        <DashboardOtherActions />
      </div>

      <div className={classes.achievements}>
        <DashboardAchievements />
      </div>
    </div>
  );
}
