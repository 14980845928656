import React from 'react';
import { useField } from 'formik';
import { Form, Input, InputNumberProps, Select, Tag } from 'antd';
import classNames from 'classnames';
import { SizeType } from 'antd/lib/config-provider/SizeContext';
import { Spacer } from 'ui/spacer';
import { RecursiveFormNames } from '../types';
import { R, useFieldTranslation } from '../translations';
import styles from './index.module.scss';

export type TextFieldSelectProps<InputValues, SelectValues> = {
  label: string;
  inputName: RecursiveFormNames<InputValues>;
  selectName: RecursiveFormNames<SelectValues>;
  id: string;
  options: {
    label: string;
    value: string;
  }[];
  size: SizeType;
  className?: string;
  children?: React.ReactNode;
  onChange?(value: { text: string; selection: string }): void;
};

export default function TextFieldSelect<InputValues, SelectValues>({
  label,
  inputName,
  selectName,
  id,
  options,
  size,
  children,
  className,
  onChange,
}: TextFieldSelectProps<InputValues, SelectValues>) {
  const { t } = useFieldTranslation();
  const [{ value: inputValue }, inputMeta, inputHelpers] = useField({
    name: inputName,
  });
  const [{ value: selectValue }, selectMeta, selectHelpers] = useField({
    name: selectName,
  });

  const inputError = inputMeta.touched && inputMeta.error;
  const selectError = selectMeta.touched && selectMeta.error;

  const status = inputError || selectError ? 'error' : 'success';
  let message: R = '\u00A0';
  if (selectError) {
    message = t(selectMeta.error as any);
  }
  if (inputError) {
    message = t(inputMeta.error as any);
  }

  return (
    <Form.Item
      htmlFor={id}
      label={label}
      validateStatus={status}
      help={message}
      className={classNames(styles.form, className)}
    >
      <Spacer direction="horizontal" space={1} align="center">
        <Input
          value={inputValue}
          className={styles.input}
          onChange={(event) => {
            onChange?.({ text: event.target.value, selection: selectValue });
            inputHelpers.setValue(event.target.value);
          }}
          onBlur={() => inputHelpers.setTouched(true)}
          size={size}
          addonAfter={
            <Select
              value={selectValue}
              options={options}
              size={size}
              className={styles.selectInput}
              onChange={(newValue) => {
                onChange?.({ text: inputValue, selection: newValue });
                selectHelpers.setValue(newValue);
              }}
              onBlur={() => selectHelpers.setTouched(true)}
            />
          }
        />
        {children}
      </Spacer>
    </Form.Item>
  );
}
