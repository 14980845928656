import React from 'react';
import { PeerReviewAnswersQuestion } from 'business/peer-review/types';
import { Spacer } from 'ui/spacer';
import { CardPeerReview } from '../card-peer-review';

export interface PeerReviewFormWizardProps {
  answers: PeerReviewAnswersQuestion[];
  actionButtons?: (answerId: string, answerHasSources: boolean) => JSX.Element;
  backgroundColor?: 'white' | 'well';
}

export function PeerReviewFormWizard({
  answers,
  actionButtons,
  backgroundColor = 'well',
}: PeerReviewFormWizardProps) {
  // order the answers
  const sorted = answers
    .concat()
    .sort((a, b) => (a.question.order >= b.question.order ? 1 : -1));

  return (
    <Spacer direction="vertical" space={2}>
      {sorted.map((answer) => {
        return (
          <CardPeerReview
            answer={answer}
            key={answer.id}
            actionButtons={actionButtons}
            type={backgroundColor === 'white' ? 'default' : 'well'}
          />
        );
      })}
    </Spacer>
  );
}
