import { AllocatedToken } from 'generated/graphql';

type PartialAllocatedToken = Pick<AllocatedToken, 'tokenId' | 'percentage'>;

export const orderAllocatedToken = (
  allocatedTokens: PartialAllocatedToken[],
) => {
  return [...allocatedTokens].sort((allocatedTokenA, allocatedTokenB) => {
    return allocatedTokenA.tokenId.localeCompare(allocatedTokenB.tokenId);
  });
};

const compareFilteredAllocatedTokens = (
  allocatedTokensA: PartialAllocatedToken[],
  allocatedTokensB: PartialAllocatedToken[],
) => {
  if (allocatedTokensA.length !== allocatedTokensB.length) {
    return false;
  }

  return allocatedTokensA.every(
    (allocatedToken, index) =>
      allocatedToken.tokenId === allocatedTokensB[index].tokenId &&
      allocatedToken.percentage === allocatedTokensB[index].percentage,
  );
};

/**
 * Return true if two allocatedTokens array have the same token allocation (same token and same percentage)
 * @param allocatedTokensA
 * @param allocatedTokensB
 */
export const compareAllocatedTokens = (
  allocatedTokensA: PartialAllocatedToken[],
  allocatedTokensB: PartialAllocatedToken[],
) => {
  if (allocatedTokensA.length !== allocatedTokensB.length) {
    return false;
  }
  // filter a and b according to token id to ensure same order
  const filteredAllocatedTokenA = orderAllocatedToken(allocatedTokensA);
  const filteredAllocatedTokenB = orderAllocatedToken(allocatedTokensB);

  return compareFilteredAllocatedTokens(
    filteredAllocatedTokenA,
    filteredAllocatedTokenB,
  );
};
