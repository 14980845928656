import { Typography } from 'antd';
import { useAppContext } from 'business/app-bootstrapper';
import { useSelectUserAchievementsQuery } from 'generated/graphql';
import { useTranslation } from 'react-i18next';
import { useCurrentTab } from 'technical/router/url-param/use-current-tab';
import { Values } from 'technical/types';
import { Card } from 'ui/cards';
import { Spacer } from 'ui/spacer';
import { AchievementCard } from '../achievement-card';
import classes from './index.module.scss';

const DashboardTabsEnum = {
  ongoing: 'ongoing',
  achieved: 'achieved',
} as const;

type DashboardTabsEnumTabsEnum = Values<typeof DashboardTabsEnum>;

export function DashboardAchievements() {
  const { t } = useTranslation(['dashboard']);
  const { user } = useAppContext();
  const [currentTab, setTab] = useCurrentTab<typeof DashboardTabsEnum>({
    defaultTab: DashboardTabsEnum.ongoing,
    tabs: DashboardTabsEnum,
  });

  const { data: achievementsData } = useSelectUserAchievementsQuery({
    variables: {
      userId: user?.id,
    },
  });

  if (!achievementsData?.user_achievements) {
    return null;
  }

  const { achievements } = achievementsData.user_achievements;

  return (
    <Card>
      <Card.Header title={t('dashboard:achievements')} level={2} />
      {
        // TODO: this sub title is currently a lorem ipsum
        // <p>{t('dashboard:achievements_text')}</p>
      }

      <Spacer direction="horizontal" className={classes.achivments}>
        <div className={classes.grid}>
          {achievements.length === 0 ? (
            <Typography.Text>
              {t('dashboard:achievements', { context: 'empty' })}
            </Typography.Text>
          ) : (
            achievements.map((achievement) => (
              <div key={`key_${achievement.title}`} className={classes.card}>
                <AchievementCard achievement={achievement} />
              </div>
            ))
          )}
        </div>
      </Spacer>
    </Card>
  );
}
