import React from 'react';
import { useField } from 'formik';
import { ColProps, Form, Radio, RadioGroupProps } from 'antd';
import { RecursiveFormNames } from '../../types';
import { useFieldTranslation } from '../../translations';
import classNames from 'classnames';
import classes from './index.module.scss';

export type RadioFieldProps<FormValues> = RadioGroupProps & {
  label: React.ReactNode;
  name: RecursiveFormNames<FormValues>;
  id: string;
  labelCol?: ColProps;
  required?: boolean;
};

export function RadioField<FormValues>({
  label,
  name,
  id,
  labelCol,
  required = false,
  ...rest
}: RadioFieldProps<FormValues>) {
  const { t } = useFieldTranslation();
  const [{ value }, meta, helpers] = useField({ name });

  const status = meta.touched && meta.error ? 'error' : 'success';
  const message = status === 'error' ? t(meta.error as any) : '\u00A0';

  return (
    <Form.Item
      htmlFor={id}
      label={label}
      validateStatus={status}
      help={message}
      labelCol={labelCol}
      required={required}
    >
      <Radio.Group
        {...rest}
        id={id}
        value={value}
        onChange={(event) => {
          rest.onChange?.(event);
          helpers.setTouched(true);
          helpers.setValue(event.target.value);
        }}
        className={classes.radio}
      />
    </Form.Item>
  );
}
