import React from 'react';
import { Typography } from 'antd';
import { Spacer } from 'ui/spacer';
import { Card } from 'ui/cards';
import { useSelectUserProfileQuery } from 'generated/graphql';
import { useMediaQuery } from 'technical/utils/use-mediaquery';
import { UserStatistic } from 'business/dashboard/components/dashboard-profile/user-statistic';
import { LongSocialButtons } from 'business/social-network/components/long-social-buttons';
import { SimpleSocialButtons } from 'business/social-network/components/simple-social-buttons';
import { SocialNetwork } from 'business/social-network/types';
import { AvatarPlaceHolder } from 'business/user/avatar';
import classes from './index.module.scss';
import { DescriptionV2 } from '../profile-banner/description';

interface NewProfileBannerProps {
  userId: string;
  noUsernameText: string;
  noDescriptionText: string;
  noSocialNetworksText: string;
  position: number | string;
}

export const NewProfileBanner = ({
  userId,
  noUsernameText,
  noDescriptionText,
  noSocialNetworksText,
  position,
}: NewProfileBannerProps) => {
  const { data } = useSelectUserProfileQuery({
    variables: {
      userId,
    },
  });

  const isOverMd = useMediaQuery('(min-width: 768px)');

  if (!data) {
    return null;
  }

  const {
    displayName,
    description,
    discord,
    reddit,
    telegram,
    twitter,
    youtube,

    contributions,
    validatedScorings,
    rejectedScorings,

    success,
    message,
  } = data.userProfile ?? {};

  const socialNetworks: SocialNetwork[] = [
    { key: 'discord', value: discord, isUsername: true },
    { key: 'reddit', value: reddit },
    { key: 'telegram', value: telegram },
    { key: 'twitter', value: twitter },
    { key: 'youtube', value: youtube },
  ].filter((sn): sn is SocialNetwork => !!sn.value);

  if (!success) {
    return (
      <Card>
        <Typography.Text>{message}</Typography.Text>
      </Card>
    );
  }

  return (
    <Spacer
      space={2}
      direction={{ default: 'vertical', md: 'horizontal' }}
      className={classes.wrapper}
    >
      <Spacer direction="vertical" space={2}>
        <Spacer
          align="center"
          justify="start"
          space={3}
          direction={{ default: 'vertical', sm: 'horizontal' }}
        >
          <AvatarPlaceHolder />

          <Spacer
            align={{ default: 'center', sm: 'start' }}
            direction="vertical"
            space={2}
          >
            <Typography.Text className={classes.name}>
              {displayName ?? noUsernameText}
            </Typography.Text>
            {socialNetworks.length > 0 ? (
              <Spacer
                space={2}
                align={{ default: 'center', sm: 'start', xl: 'center' }}
                direction={{ default: 'vertical', xl: 'horizontal' }}
              >
                <LongSocialButtons
                  socialNetworks={socialNetworks}
                  size="small"
                />
                <Spacer
                  direction="horizontal"
                  align="center"
                  space={2}
                  justify={{ default: 'center', sm: 'start' }}
                >
                  <SimpleSocialButtons
                    socialNetworks={socialNetworks}
                    size="small"
                  />
                </Spacer>
              </Spacer>
            ) : (
              <Typography.Text italic>{noSocialNetworksText}</Typography.Text>
            )}
          </Spacer>
        </Spacer>
        <UserStatistic
          contributions={contributions ?? 0}
          validatedScorings={validatedScorings ?? 0}
          rejectedScorings={rejectedScorings ?? 0}
          position={position}
        />
      </Spacer>
      <Spacer direction="vertical">
        <DescriptionV2
          description={description}
          noDescriptionText={noDescriptionText}
        />
      </Spacer>
    </Spacer>
  );
};
