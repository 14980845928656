import React, { useState } from 'react';
import { Select, SelectProps, Spin, Typography } from 'antd';
import { SearchOutlined } from '@ant-design/icons';
import { Token as TokenData, useGetTokenSelectQuery } from 'generated/graphql';
import { CoinGeckoTokenImage } from 'ui/coin-gecko-token-image';
import { Spacer } from 'ui/spacer';
import { useDebounce } from 'technical/hooks/use-debounce';
import { DefaultOptionType } from 'antd/lib/select';
import { SelectValue } from 'technical/form/types';
import { selectValueToList } from 'technical/form/select-value-to-list';
import styles from './index.module.scss';

type Token = Pick<
  TokenData,
  'id' | 'name' | 'symbol' | 'reference' | 'coinGeckoImage'
>;

const generateOptions = (tokens: Token[], field: 'id' | 'reference') => {
  return tokens.map((token) => ({
    key: token[field],
    value: token[field],
    // title is displayed for the selected value, no link
    title: token.name,
    selectedLabel: <Typography.Text>{token.name}</Typography.Text>,
    // is displayed in dropdown
    label: (
      <Typography className={styles.tokenRow}>
        <Spacer direction="horizontal" space={1} align="center">
          <CoinGeckoTokenImage
            width={20}
            size="thumb"
            coinGeckoImage={token.coinGeckoImage}
          />
          <Typography.Text>{token.name}</Typography.Text>
        </Spacer>
        <Typography.Text>{token.symbol.toUpperCase()}</Typography.Text>
      </Typography>
    ),
  }));
};

export interface TokenSelectProps
  extends Omit<SelectProps<SelectValue>, 'mode'> {
  field?: 'id' | 'reference';
  mode?: 'multiple'; // no 'tags' mode
}

/**
 * Dropdown to select one or multiple tokens
 * @param field default is 'id', but it can be setup to be the reference of the token by setting to 'reference'
 */
const TokenSelect: React.VFC<TokenSelectProps> = ({
  value,
  onChange,
  field = 'id',
  mode,
  ...otherProps
}) => {
  const [searchText, setSearchText] = useState('');
  const debouncedValue = useDebounce(searchText);

  const tokenFields = selectValueToList(value);

  const { data, previousData, loading } = useGetTokenSelectQuery({
    variables: {
      searchText: `%${debouncedValue}%`,
      searchResultFilter:
        field === 'id'
          ? { id: { _nin: tokenFields } }
          : { reference: { _nin: tokenFields } },
      tokenFilter:
        field === 'id'
          ? { id: { _in: tokenFields } }
          : { reference: { _in: tokenFields } },
    },
  });

  const searchResults =
    data?.searchResults ?? previousData?.searchResults ?? [];
  const tokens = data?.tokens ?? previousData?.tokens ?? [];

  return (
    <Select<SelectValue>
      showSearch
      mode={mode}
      optionLabelProp="selectedLabel"
      defaultActiveFirstOption={false}
      filterOption={false}
      allowClear
      loading={loading}
      onChange={(
        newValue: SelectValue,
        option: DefaultOptionType | DefaultOptionType[],
      ) => {
        setSearchText('');
        onChange?.(newValue, option);
      }}
      value={tokens.length ? value : undefined}
      options={generateOptions([...tokens, ...searchResults], field)}
      onSearch={(str) => setSearchText(str)}
      notFoundContent={loading ? <Spin /> : null}
      suffixIcon={<SearchOutlined />}
      {...otherProps}
    />
  );
};

export default TokenSelect;
