import { router } from 'business/router';
import Routes from 'business/router/routes';
import classnames from 'classnames';
import React from 'react';
import Button from 'ui/button';
import { Card } from 'ui/cards';
import SuccessIllustration from 'ui/illustrations/success';
import { Spacer } from 'ui/spacer';
import styles from './index.module.scss';

interface Props {
  title: string;
  hint: string;
  className?: string;
  dataTestId?: string;
  handleSubmit?: () => void;
  btnText?: string;
  children?: React.ReactElement;
}

export default function ActionCard({
  title,
  hint,
  className,
  dataTestId,
  btnText,
  children,
  handleSubmit = () => router.navigate(Routes.Home),
}: Props) {
  return (
    <Card
      className={classnames(styles.card, className)}
      dataTestId={dataTestId}
    >
      <Spacer direction="vertical" align="center">
        <h1 className={styles.title}>{title}</h1>
        <p className={styles.hint}>{hint}</p>
        <div className={styles.successIllustration}>
          {children || <SuccessIllustration />}
        </div>
        <Button
          id="submit-button"
          data-test-id="submit-button"
          type="primary"
          onClick={handleSubmit}
          block
        >
          {btnText}
        </Button>
      </Spacer>
    </Card>
  );
}
