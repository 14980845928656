import React from 'react';
import { Card } from 'ui/cards';
import { Spacer } from 'ui/spacer';
import { useTranslation } from 'react-i18next';
import { Typography } from 'antd';
import { NumberFormats, NumericValue } from 'ui/numeric';
import classnames from 'classnames';
import classes from './index.module.scss';

interface PeerReviewGeneralOpinionProps {
  comment: string;
  prediction: number;
  type?: 'well' | 'default';
}

export function PeerReviewGeneralOpinion({
  comment,
  prediction,
  type = 'default',
}: PeerReviewGeneralOpinionProps) {
  const { t } = useTranslation(['review']);
  return (
    <div className={classes.grid}>
      <Spacer direction="vertical" space={1} className={classes.comment}>
        <Typography.Text strong>
          {t('review:global-opinion.comment')}
        </Typography.Text>
        <Card
          className={classnames(
            classes.card,
            type === 'default' ? classes.greyCard : classes.wellCard,
          )}
        >
          {comment}
        </Card>
      </Spacer>
      <Spacer direction="vertical" space={1} className={classes.prediction}>
        <Typography.Text strong>
          {t('review:global-opinion.prediction')}
        </Typography.Text>
        <Card
          className={
            type === 'default' ? classes.greyCardRight : classes.wellCardRight
          }
        >
          <NumericValue format={NumberFormats.currency} value={prediction} />
        </Card>
      </Spacer>
    </div>
  );
}
