import React from 'react';
import logger from 'technical/logger';
import Button from 'ui/button';
import { useThemes } from 'ui/theme';
import classnames from 'classnames';
import config from 'config';
import { useTranslation } from 'react-i18next';
import { Spacer } from 'ui/spacer';
import { ReactComponent as MoonIcon } from 'assets/icons/moon.svg';
import { ReactComponent as SunIcon } from 'assets/icons/sun.svg';
import classes from './theme-switcher.module.scss';

const ThemeIcons: {
  light: React.FunctionComponent<React.SVGProps<SVGSVGElement>>;
  dark: React.FunctionComponent<React.SVGProps<SVGSVGElement>>;
} = {
  light: SunIcon,
  dark: MoonIcon,
};

interface ThemeSwitcherProps {
  size?: 'default' | 'large';
}

export default function ThemeSwitcher({
  size = 'default',
}: ThemeSwitcherProps) {
  const [{ current, themes }, onThemeChange] = useThemes();
  const inverse = current === 'light' ? 'dark' : 'light';
  const { t } = useTranslation(['common']);

  const handleOnMouseEnter = () =>
    themes
      .find((theme) => theme.key === inverse)
      ?.preload()
      .catch((err) => {
        logger.warn({ message: 'could not preload theme', err });
      });

  const Icon = ThemeIcons[inverse];

  if (size === 'large') {
    return (
      <Button
        type="primary"
        ghost
        htmlType="button"
        onClick={() => onThemeChange(inverse)}
        onMouseEnter={handleOnMouseEnter}
        className={classes.button}
        size="large"
      >
        <Spacer direction="horizontal" space={1} align="center">
          {t('header.account-menu.switch-theme', {
            context: current,
            defaultValue: current,
          })}
          <Icon />
        </Spacer>
      </Button>
    );
  }

  return (
    <div
      className={classnames(classes.themeSwitchWrapper, {
        [classes.oldHeaderSwitchWrapper]: !config.features.useNewHeader,
      })}
    >
      <Button
        type="text"
        htmlType="button"
        onClick={() => onThemeChange(inverse)}
        onMouseEnter={handleOnMouseEnter}
        shape="circle"
        className={classes.switcher}
      >
        <Icon />
      </Button>
    </div>
  );
}
