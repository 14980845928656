import React, { useState } from 'react';
import { FilterValue } from 'antd/lib/table/interface';
import { TablePaginationConfig } from 'antd';
import { researchTableOnChange } from 'business/research/services';
import { researchPageOptions } from 'business/token/common-values';
import {
  ResearchResearchStatusEnumEnum,
  useSelectUserResearchesQuery,
} from 'generated/graphql';
import { isNotNullOrUndefined } from 'technical/utils/is-not-null-or-undefined';

interface UseResearchHistoryTableProps {
  authorId?: string;
  includeStatus: ResearchResearchStatusEnumEnum[];
}

function useResearchHistoryTable({
  authorId,
  includeStatus,
}: UseResearchHistoryTableProps) {
  const [limit, setLimit] = useState(researchPageOptions.defaultPageSize);
  const [tokens, setTokens] = useState<string[] | null>(null);
  const [currentPage, setCurrentPage] = useState(
    researchPageOptions.defaultPage,
  );
  const [hasEnoughReview, setHasEnoughReview] = useState<boolean | null>(null);
  const { data, previousData, loading, refetch } = useSelectUserResearchesQuery(
    {
      skip: !authorId,
      variables: {
        // @ts-expect-error
        authorId: authorId,
        includeStatus,
        _and: [
          {
            token: isNotNullOrUndefined(tokens)
              ? { reference: { _in: tokens } }
              : {},
            hasEnoughPeerReview: isNotNullOrUndefined(hasEnoughReview)
              ? { _eq: hasEnoughReview }
              : {},
          },
        ],
        limit,
        offset: (currentPage - 1) * limit,
      },
    },
  );

  const onTableChange = (
    pagination: TablePaginationConfig,
    filters: Record<string, FilterValue | null>,
  ) => {
    setCurrentPage(pagination.current ?? 1);
    setLimit(pagination.pageSize ?? 10);

    const tableFilters = researchTableOnChange(pagination, filters);
    setTokens(tableFilters.tokens);
    setHasEnoughReview(tableFilters.hasEnoughReviews);
  };

  const researches = (data?.researches ?? previousData?.researches ?? []).map(
    (research) => ({
      ...research,
      reviews: [],
    }),
  );

  return {
    onTableChange,
    refetch,
    researches,
    count: data?.research_aggregate.aggregate?.count,
    loading,
    currentPage,
    limit,
  };
}

export default useResearchHistoryTable;
