import { CountDown } from 'business/beta-waiting-screen/countdown/index';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { Spacer } from 'ui/spacer';
import { ReactComponent as WaitingPageLogo } from 'assets/illustrations/waiting-page-logo.svg';
import { ReactComponent as WaitingPageBgLogo } from 'assets/illustrations/waiting-page-bg-logo.svg';
import classNames from 'classnames';
import { useAppContext } from 'business/app-bootstrapper';
import { useNavigate } from 'react-router-dom';
import Routes from 'business/router/routes';
import { isAfter } from 'date-fns';
import classes from './index.module.scss';

const BETA_DATE = new Date('2022-10-10T18:00:00Z');
const BETA_END_DATE = new Date('2022-10-10T18:00:00Z');

export function BetaWaitingScreen() {
  const { t } = useTranslation(['landing']);
  const { isConnected } = useAppContext();
  const navigate = useNavigate();

  React.useEffect(() => {
    if (isConnected) {
      navigate(Routes.Home, { replace: true });
    }
  }, [isConnected]);

  return (
    <div className={classes.wrapper}>
      <div className={classes.floatingLogo}>
        <WaitingPageBgLogo width={325} height={325} />
      </div>
      <Spacer align="center" justify="between" className={classes.header}>
        <WaitingPageLogo width={235} height={56} />
        <div>
          <Spacer space={3} align="center" className={classes.alpha}>
            <h1>{t('landing:title')}</h1>
            <div className={classes.separator} />
            <div className={classes.text}>{t('landing:text')}</div>
          </Spacer>
        </div>
      </Spacer>
      <div className={classes.content}>
        {isAfter(new Date(), BETA_END_DATE) ? (
          <div className={classes.ended}>
            {t('landing:title-beta-end-1')}
            <br />
            {t('landing:title-beta-end-2')}
          </div>
        ) : (
          <CountDown endDate={BETA_DATE} />
        )}
        <section className={classNames(classes.infos)}>
          <div className={classes.infosWrapper}>
            <h2 className={classes.infosTitle}>
              {t('landing:discover-kryptview.title')}
            </h2>
            <div>
              <ul>
                <li>
                  <span>{t('landing:discover-kryptview.ul.li-1')}</span>
                </li>
                <li>
                  <span>{t('landing:discover-kryptview.ul.li-2')}</span>
                </li>
                <li>
                  <span>{t('landing:discover-kryptview.ul.li-3')}</span>
                </li>
              </ul>
            </div>
            <div className={classes.video}>
              <iframe
                src="https://www.youtube-nocookie.com/embed/trAmveGcOp4?controls=0"
                title="YouTube video player - Discover Krypview"
                frameBorder="0"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                allowFullScreen
              />
            </div>
          </div>
        </section>
      </div>
    </div>
  );
}
