import React from 'react';
import { Spacer } from 'ui/spacer';
import { Spin } from 'antd';
import styles from './index.module.scss';

interface Props {
  label?: string;
}

const Loader = ({ label }: Props) => (
  <Spacer className={styles.loader} direction="vertical">
    <Spin />
    <h1>{label}</h1>
  </Spacer>
);

export default Loader;
