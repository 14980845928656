import { Typography } from 'antd';
import Routes from 'business/router/routes';
import { powerSearchPreviewSchema } from 'business/token/services/advanced-search-schema';
import { usePowerSearchQuestionListQuery } from 'generated/graphql';
import { LinkAsButton } from 'ui/button';
import { Card } from 'ui/cards';
import { Form } from 'ui/form';
import { SelectField } from 'ui/form/fields';
import { Spacer } from 'ui/spacer';
import { QuestionList } from '../multi-criteria-selector';
import classes from './index.module.scss';

export function PowerSearchPreview() {
  const questionListQuery = usePowerSearchQuestionListQuery();
  const questions = questionListQuery.data?.questions ?? [];

  const groupedQuestions = questions.reduce((map, question) => {
    const list = map.get(question.category) || [];
    list.push(question);
    map.set(question.category, list);
    return map;
  }, new Map<string, QuestionList>());

  return (
    <Form
      onSubmit={() => {}}
      initialValues={{ questionId: '', choiceId: '' }}
      validationSchema={powerSearchPreviewSchema}
    >
      {({ values, setFieldValue, setFieldTouched, dirty, isValid }) => {
        const selection = questions.find(
          (question) => values.questionId === question.id,
        ) || {
          choices: [],
        };

        return (
          <Spacer direction={'vertical'} space={2}>
            <Typography.Text>
              Use Power Search and find the tokens that match exactly what you
              are looking for.
            </Typography.Text>
            <div>
              <Typography.Text style={{ fontWeight: 600, fontSize: 14 }}>
                Filter:
              </Typography.Text>
              <Card type="well" className={classes['no-padding']}>
                <Spacer
                  direction="vertical"
                  space={'none'}
                  className={classes.criteria}
                  data-loading={questionListQuery.loading}
                >
                  <SelectField<any>
                    id={`preview-question-id`}
                    name={`questionId`}
                    label="Question"
                    showSearch
                    optionFilterProp="label"
                    onChange={() => {
                      setFieldValue('choiceId', null, false);
                      setFieldTouched('choiceId', false);
                    }}
                    options={Array.from(groupedQuestions.entries()).map(
                      ([category, list]) => {
                        return {
                          label: category,
                          options: list.map((question) => ({
                            label: question.prompt,
                            value: question.id,
                          })),
                        };
                      },
                    )}
                  ></SelectField>
                  <SelectField<any>
                    id={`preview-choice-id`}
                    name={`choiceId`}
                    label="Answer"
                    showSearch
                    optionFilterProp="label"
                    options={selection.choices.map((choice) => ({
                      value: choice.id,
                      label: choice.value,
                    }))}
                  ></SelectField>
                </Spacer>
              </Card>
            </div>
            <Spacer justify={'end'}>
              <LinkAsButton
                type={'primary'}
                to={{
                  pathname: Routes.PowerSearch,
                  search:
                    dirty && isValid
                      ? new URLSearchParams({
                          q: JSON.stringify({
                            search: { and: [{ condition: values }] },
                            pagination: { page: 1, size: 25 },
                            sorting: { communityScore: 'desc' },
                          }),
                        }).toString()
                      : undefined,
                }}
              >
                Try out the Kryptview Power Search
              </LinkAsButton>
            </Spacer>
          </Spacer>
        );
      }}
    </Form>
  );
}
