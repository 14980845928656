import * as yup from 'yup';

import type {
  MixedLocale,
  StringLocale,
  NumberLocale,
  DateLocale,
  ObjectLocale,
  ArrayLocale,
  BooleanLocale,
} from 'yup/lib/locale';

// right now (2021-09-23) we need to do this because yup does not type the setLocale function
// but in a next version this should be fixed.
interface Locale {
  mixed?: MixedLocale;
  string?: StringLocale;
  number?: NumberLocale;
  date?: DateLocale;
  object?: ObjectLocale;
  array?: ArrayLocale;
  boolean?: BooleanLocale;
}

// with this we control all the yup error messages
const locale: Locale = {
  mixed: {
    default: { key: 'common.invalid' },
    required: { key: 'common.required' },
    notType: ({ type, value, originalValue }) => {
      const wasCasted = originalValue != null && originalValue !== value;
      if (originalValue == null) {
        return { key: 'common.required' };
      }
      return {
        key: 'common.type-error',
        options: {
          expected: { type },
          received: {
            value: originalValue,
            transformed: wasCasted ? value : undefined,
          },
        },
      };
    },
    defined: { key: 'common.undefined' },
    oneOf: ({ originalValue, values, value, type }) => {
      const wasCasted = originalValue != null && originalValue !== value;
      if (originalValue == null) {
        return { key: 'common.required' };
      }
      return {
        key: 'common.one-of',
        options: {
          expected: { type, list: values },
          received: {
            value: originalValue,
            transformed: wasCasted ? value : undefined,
          },
        },
      };
    },
  },
  date: {
    min: ({ min }) => ({ key: 'common.date.less-than', options: { min } }),
    max: ({ max }) => ({ key: 'common.date.greater-than', options: { max } }),
  },
  string: {
    min: ({ min }) => ({ key: 'common.string.too-short', options: { min } }),
    max: ({ max }) => ({ key: 'common.string.too-long', options: { max } }),
    length: ({ length }) => ({
      key: 'common.string.length',
      options: { length },
    }),
    url: { key: 'common.string.invalid-url' },
  },
  number: {
    min: ({ min }) => ({ key: 'common.number.too-small', options: { min } }),
    max: ({ max }) => ({ key: 'common.number.too-large', options: { max } }),
    positive: { key: 'common.number.not-positive' },
    integer: { key: 'common.number.not-integer' },
  },
  array: {
    min: ({ min }) => ({ key: 'common.array.too-small', options: { min } }),
    max: ({ max }) => ({ key: 'common.array.too-large', options: { max } }),
  },
};

yup.setLocale(locale);
