import React from 'react';
import { Spacer } from 'ui/spacer';
import { TokenMarkets } from 'generated/graphql';
import { NumericValue, NumberFormats } from 'ui/numeric';
import TagPercentage from 'ui/tag-percentage';
import classes from './index.module.scss';
import { TokenProgressBar } from '../token-progress-bar';
import { TokenHeaderActions } from '../token-header-actions';
import config from 'config';

interface TokenHeaderInfosProps
  extends Pick<
    TokenMarkets,
    'price' | 'h24Low' | 'h24High' | 'priceChangePercentages'
  > {
  tokenReference?: string;
  tokenId?: string;
  withAction?: boolean;
}

export function TokenHeaderInfos({
  tokenReference,
  tokenId,
  withAction = true,
  ...props
}: TokenHeaderInfosProps) {
  const { price, priceChangePercentages } = props;

  return (
    <Spacer
      direction={{ default: 'vertical', lg: 'horizontal' }}
      align="end"
      space={2}
    >
      <Spacer direction="vertical" space="none">
        <Spacer space={1} align="center">
          <NumericValue
            format={NumberFormats.currency}
            value={price}
            className={classes.tokenValue}
          />
          <TagPercentage value={priceChangePercentages?.h24} />
          {/* TODO: add period dropdown here to enable choosing the price variation period */}
        </Spacer>
        <TokenProgressBar {...props} />
      </Spacer>
      {withAction && !config.features.isInHibernation && (
        <TokenHeaderActions tokenReference={tokenReference} tokenId={tokenId} />
      )}
    </Spacer>
  );
}
