import { Menu, Typography } from 'antd';
import { Trans } from 'react-i18next';
import { Link } from 'react-router-dom';
import React from 'react';
import { Spacer } from 'ui/spacer';
import Button from '../button';
import classes from './account-menu.module.scss';
import { CloseIcon } from './icons';
import { WalletMenu } from './wallet-menu';
import ThemeSwitcher from './theme-switcher';
import Routes from '../../business/router/routes';
import authService from '../../business/user/services/authentication.gql';
import { Me } from '../../business/user/types/user';
import { getAccountLinks } from './account-links';
import { TFunction } from 'i18next';

interface OverlayAccountMenuProps {
  user: Me | undefined;
  t: TFunction<['common']>;
  closeHandler?: () => void;
}

export const OverlayAccountMenu = ({
  t,
  user,
  closeHandler,
}: OverlayAccountMenuProps) => {
  const accountLinks = user ? getAccountLinks(t) : [];

  return (
    <>
      <Menu.Item className={classes.closeAccountMenuBtnContainer}>
        <Button
          className={classes.closeAccountMenuBtn}
          type="text"
          icon={<CloseIcon />}
          onClick={closeHandler}
        />
      </Menu.Item>
      <Menu.Item className={classes.connectedAs}>
        <Trans
          t={t}
          i18nKey="common:header.account-menu.connected-as"
          values={{
            email: user?.email,
          }}
          components={{
            newline: <br />,
            bold: <Typography.Text strong />,
          }}
        />
      </Menu.Item>
      <Menu.Item>
        <Spacer justify="between">
          <WalletMenu user={user} />
          <ThemeSwitcher />
        </Spacer>
      </Menu.Item>
      <Menu.Divider />
      {accountLinks.map(({ hasDivider, ...props }) => (
        <React.Fragment key={`container-${props.key}`}>
          <Menu.Item>
            <Link {...props} />
          </Menu.Item>
          {hasDivider && <Menu.Divider />}
        </React.Fragment>
      ))}
      <Menu.Divider />
      <Menu.Item>
        <Link to={Routes.Leaderboard}>
          {t('common:header.account-menu.leaderboard')}
        </Link>
      </Menu.Item>
      <Menu.Divider />
      <Menu.Item onClick={authService.logout}>
        {t('common:header.account-menu.sign-out')}
      </Menu.Item>
    </>
  );
};
