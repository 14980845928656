import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Card } from 'ui/cards';
import {
  Maybe,
  OrderByLite,
  useGetValidatedResearchesQuery,
} from 'generated/graphql';
import {
  ValidatedResearchRow,
  ValidatedResearchTable,
} from 'business/research/components/validated-table';
import { SorterResult } from 'antd/es/table/interface';
import {
  researchTableOnChange,
  useResearchTableFilters,
} from 'business/research/services';
import { researchPageOptions } from 'business/token/common-values';

function getOrderFromSorter(
  sorter:
    | SorterResult<ValidatedResearchRow>
    | SorterResult<ValidatedResearchRow>[],
): {
  updated_at?: OrderByLite;
  authorRank?: OrderByLite;
  displayName?: OrderByLite;
  token?: OrderByLite;
} | null {
  if (
    !sorter ||
    Array.isArray(sorter) ||
    !sorter.field ||
    Array.isArray(sorter.field)
  ) {
    return null;
  }

  const order = sorter.order === 'ascend' ? OrderByLite.Asc : OrderByLite.Desc;
  const { columnKey } = sorter;

  if (columnKey) {
    return {
      [columnKey]: order,
    };
  }
  return null;
}

export function ValidatedCommunityResearchesTab() {
  const { t } = useTranslation(['research']);

  const { filtersValues, setFiltersValues } = useResearchTableFilters();
  const { page, pageSize, tokens } = filtersValues;

  const [order, setOrder] = useState<
    | Maybe<{
        publishedAt?: OrderByLite;
        authorRank?: OrderByLite;
        displayName?: OrderByLite;
        token?: OrderByLite;
      }>
    | undefined
  >(undefined);

  const { data, previousData, loading } = useGetValidatedResearchesQuery({
    variables: {
      page,
      pageSize,
      displayNameOrder: order?.displayName,
      rankOrder: order?.authorRank,
      tokenOrder: order?.token,
      publishedAtOrder: order?.publishedAt,
      tokens,
    },
  });

  const { count, researches } =
    data?.getValidatedResearches ?? previousData?.getValidatedResearches ?? {};

  return (
    <>
      <Card.Header
        level={2}
        title={t('research:validated-community-researches.title')}
      />
      <ValidatedResearchTable
        loading={loading}
        researches={researches ?? []}
        defaultFilters={{ tokenRefs: filtersValues.tokens ?? undefined }}
        pagination={{
          total: count,
          current: page,
          pageSize,
          pageSizeOptions: researchPageOptions.pageSizeOptions,
        }}
        onChange={(pagination, filters, sorters) => {
          setFiltersValues({
            ...filtersValues,
            ...researchTableOnChange(pagination, filters),
          });
          setOrder(getOrderFromSorter(sorters));
        }}
      />
    </>
  );
}
