import React from 'react';
import { AutoComplete, AutoCompleteProps, Input } from 'antd';
import styles from './index.module.scss';

interface SearchBarProps extends AutoCompleteProps<string> {
  placeholder?: string;
  isLoading: boolean;
}

const SearchBar: React.FC<SearchBarProps> = ({
  isLoading,
  placeholder,
  ...otherProps
}) => {
  return (
    <AutoComplete
      defaultActiveFirstOption={false}
      filterOption={false}
      className={styles.autoComplete}
      dropdownRender={(render) => (
        <div className={styles.optionContainer}>{render}</div>
      )}
      dropdownMatchSelectWidth={false}
      {...otherProps}
    >
      <Input.Search
        className={styles.searchBar}
        size="large"
        placeholder={placeholder}
        loading={isLoading}
        // suffix={<SearchOutlined size={30} />} not need as search input add a search button
        allowClear
      />
    </AutoComplete>
  );
};

export default SearchBar;
