import React from 'react';
import { useTranslation } from 'react-i18next';
import { Typography } from 'antd';

import classNames from 'classnames';
import classes from './link.module.scss';

// TODO: link as props with diffrents categories
// question: how to diferentiate a link from an email ?

interface PeerReviewLinkProps {
  source: string;
  type?: 'default' | 'well';
}

export function PeerReviewLink({
  source,
  type = 'default',
}: PeerReviewLinkProps) {
  return (
    <div className={classNames(classes.linkContainer, classes[type])}>
      <a
        href={source}
        target="_blank"
        rel="noreferrer"
        className={classes.link}
      >
        {source}
      </a>
    </div>
  );
}
