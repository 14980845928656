import React from 'react';
import { Typography } from 'antd';
import { Spacer } from 'ui/spacer';
import classes from './username-display-name.module.scss';

interface UsernameDisplayNameProps {
  displayName: string | undefined | null;
  noUsernameText: string;
}

export function UsernameDisplayName({
  displayName,
  noUsernameText,
}: UsernameDisplayNameProps) {
  return (
    <Spacer justify={{ default: 'center', sm: 'start' }}>
      <Typography.Text className={classes.primaryText}>
        {displayName ?? noUsernameText}
      </Typography.Text>
    </Spacer>
  );
}
