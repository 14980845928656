import React, { ReactNode } from 'react';
import { ValuesOf } from 'business/types/basic';
import { MenuLinkProps } from 'ui/header/menu-link';
import Routes from 'business/router/routes';
import config from 'config';
import { useTranslation } from 'react-i18next';
import { useAppContext } from 'business/app-bootstrapper';

import { ReactComponent as HomeSVG } from 'assets/icons/menu/home.svg';
import { ReactComponent as ScoringsSVG } from 'assets/icons/menu/scorings.svg';
import { ReactComponent as PortfolioSVG } from 'assets/icons/menu/portfolio.svg';
import { ReactComponent as LearningHubSVG } from 'assets/icons/menu/learning-hub.svg';
import { ReactComponent as LeaderboardSVG } from 'assets/icons/menu/leaderboard.svg';
import { ReactComponent as AboutSVG } from 'assets/icons/menu/bulb.svg';

const NavLinkTypesEnum = {
  main: 'main',
  profileSection: 'profileSection',
} as const;

export type NavLinkTypesEnumValues = ValuesOf<typeof NavLinkTypesEnum>;

export type NavLinkItem = MenuLinkProps & {
  type: NavLinkTypesEnumValues;
  Icon: React.FunctionComponent<
    React.SVGProps<SVGSVGElement> & { title?: string }
  >;
};

export const useNavLinks = (types: NavLinkTypesEnumValues[]) => {
  const { t } = useTranslation(['common']);
  const { isConnected } = useAppContext();

  const menuLinks: Array<NavLinkItem> = [
    {
      href: Routes.Home,
      label: t('common:header.homepage'),
      loggedOnly: false,
      type: NavLinkTypesEnum.main,
      Icon: HomeSVG,
    },
    {
      href: Routes.CommunityResearches,
      label: t('common:header.community-scorings'),
      loggedOnly: true,
      type: NavLinkTypesEnum.main,
      Icon: ScoringsSVG,
    },
    {
      href: Routes.CommunityPortfolios,
      label: t('common:header.community-portfolios'),
      loggedOnly: false,
      type: NavLinkTypesEnum.main,
      Icon: PortfolioSVG,
    },
    {
      href: Routes.Leaderboard,
      label: t('common:header.leaderboard'),
      loggedOnly: config.features.isInHibernation ? false : true,
      type: config.features.isInHibernation
        ? NavLinkTypesEnum.main
        : NavLinkTypesEnum.profileSection,
      Icon: LeaderboardSVG,
    },
    {
      href: config.links.learning,
      label: t('common:header.learning-hub'),
      loggedOnly: false,
      target: '_blank',
      type: NavLinkTypesEnum.main,
      Icon: LearningHubSVG,
    },
    {
      href: config.links.about,
      label: t('common:header.about'),
      loggedOnly: false,
      target: '_blank',
      type: NavLinkTypesEnum.main,
      Icon: AboutSVG,
    },
  ];

  return menuLinks
    .filter((menuLink) => types.includes(menuLink.type))
    .filter((menuLink) => {
      if (menuLink.loggedOnly) {
        return menuLink.loggedOnly === isConnected;
      }
      return true;
    });
};
