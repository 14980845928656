import React from 'react';
import { useGetUserPortfoliosQuery } from 'generated/graphql';
import { useAppContext } from 'business/app-bootstrapper';
import { Spacer } from 'ui/spacer';
import { AddPortfolio } from 'business/portfolio/components/add-portfolio';
import {
  PersonalPortfolio,
  PersonalPortfolioSkeleton,
} from 'business/portfolio/components/personal-portfolio';
import { transformQueryPortfolioToFormValues } from 'business/portfolio/utils/transform';
import { Empty } from 'ui/empty-state';
import Loader from 'ui/loader';
import { PublicPortfolio } from 'business/portfolio/components/public-portfolio';
import classes from './index.module.scss';

interface PublicPersonalPortfoliosProps {
  authorId: string;
}

export function PrivatePersonalPortfoliosComponent() {
  const { user } = useAppContext();

  const { data, loading } = useGetUserPortfoliosQuery({
    // @ts-expect-error
    variables: { authorId: user?.id, withPrivate: true },
  });

  const portfolios = data?.getUserPortfolios?.portfolios ?? [];

  if (loading) {
    return (
      <Spacer
        className={classes.portfolioContainer}
        direction={{ lg: 'horizontal', default: 'vertical' }}
        space={2}
      >
        {[0, 1, 2].map((key) => (
          <PersonalPortfolioSkeleton key={key} />
        ))}
      </Spacer>
    );
  }

  return (
    <Spacer
      className={classes.portfolioContainer}
      direction={{ lg: 'horizontal', default: 'vertical' }}
      space={2}
    >
      {portfolios.map((portfolio) => (
        <PersonalPortfolio
          key={portfolio.id}
          portfolio={transformQueryPortfolioToFormValues(portfolio)}
        />
      ))}
      {portfolios.length < 3 ? <AddPortfolio /> : null}
    </Spacer>
  );
}

export function PublicPersonalPortfolios({
  authorId,
}: PublicPersonalPortfoliosProps) {
  const { data, loading } = useGetUserPortfoliosQuery({
    variables: { authorId },
  });
  const portfolios = data?.getUserPortfolios?.portfolios ?? [];

  if (loading) {
    return (
      <Spacer align="center" justify="stretch">
        <Loader />
      </Spacer>
    );
  }

  if (!portfolios.length) {
    // TODO improve empty state UI & text
    return (
      <Spacer align="center" justify="center">
        <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
      </Spacer>
    );
  }

  return (
    <div className={classes.portfolioContainer}>
      {portfolios.map((portfolio) => (
        <PublicPortfolio key={portfolio.id} portfolio={portfolio} />
      ))}
    </div>
  );
}
