export const truncateCenter = (str: string, allowed: number): string => {
  const DOTS_SIZE = 5;
  const MIN_ALLOWED = 7;
  const charsLeftForEachSide = (allowed - DOTS_SIZE) / 2;

  if (str.length >= allowed && allowed >= MIN_ALLOWED) {
    return `${str.slice(0, charsLeftForEachSide)} ... ${str.slice(
      -1 * charsLeftForEachSide,
    )}`;
  }
  return str;
};
