import { Popover, Typography } from 'antd';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import Button from 'ui/button';
import { SocialIcon } from 'ui/social-networks/social-icon';
import { SocialNetwork } from 'business/social-network/types';
import { Spacer } from 'ui/spacer';
import classes from './index.module.scss';

interface LongSocialButtonsProps {
  socialNetworks: SocialNetwork[];
  size?: 'small' | 'medium';
}

export function LongSocialButtons({
  socialNetworks,
  size,
}: LongSocialButtonsProps) {
  const { t } = useTranslation(['user']);
  const [copied, setCopied] = useState(false);

  const socialNetworksWithDescriptions = socialNetworks.filter(
    (sn) => sn.isUsername,
  );
  return (
    <div className={classes.discordButton}>
      {socialNetworksWithDescriptions.map(({ key, value }) => (
        <Popover
          placement="bottom"
          content={copied ? t('user:copy-done') : t('user:copy-username')}
          onVisibleChange={(visible) => {
            if (!visible) {
              setTimeout(() => setCopied(false), 500);
            }
          }}
          destroyTooltipOnHide
          key={key}
        >
          <Button
            key={key}
            className={classes.username}
            onClick={() => {
              navigator.clipboard.writeText(value);
              setCopied(true);
            }}
          >
            <Spacer align="center" space={2}>
              <SocialIcon key={key} social={key} size={size} />
              <Typography.Text strong className={classes.usernameText}>
                {value}
              </Typography.Text>
            </Spacer>
          </Button>
        </Popover>
      ))}
    </div>
  );
}
