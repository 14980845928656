import React from 'react';
import { Spacer } from 'ui/spacer';
import { useTranslation } from 'react-i18next';
import { Image } from 'antd';
import classes from 'business/user/components/leaderboard/index.module.scss';
import { Me } from 'business/user/types/user';

interface SessionUserPositionProps {
  user: Me;
}

export const StaffPosition: React.VFC<SessionUserPositionProps> = ({
  user,
}) => {
  const { t } = useTranslation(['common']);

  return (
    <Spacer justify="center" space={1} direction="vertical" align="center">
      <Image
        preview={false}
        width={96}
        height={96}
        className={classes.rank}
        alt={`${user.rank?.name} icon`}
        src={`/ranks/${user.rank?.icon}`}
      />
      {t('common:leaderboard.not-ranked-staff')}
    </Spacer>
  );
};
