import React from 'react';
import classnames from 'classnames';
import AuthForm, { AuthFormProps } from 'business/user/components/auth-form';
import { Card } from 'ui/cards';
import styles from './index.module.scss';

interface AuthCardProps extends AuthFormProps {
  className?: string;
}

export default function AuthCard({ className, ...otherProps }: AuthCardProps) {
  return (
    <Card className={classnames([styles.authCard, className])}>
      <AuthForm {...otherProps} />
    </Card>
  );
}
