import React from 'react';
import { useTranslation } from 'react-i18next';
import { Card } from 'ui/cards';
import { Spacer } from 'ui/spacer';
import { HotTopicArticles } from 'business/article/components/hot-topic-articles';

export function HotTopicArticlesCard() {
  const { t } = useTranslation(['home']);

  return (
    <Card>
      <Spacer justify="between" align="baseline">
        <Card.Header level={2} title={`🔥 ${t('home:hot-topics')}`} />
        {/* TODO return when path is ready */}
        {/* <Button type="link" onClick={moreArticleClickHandler}> */}
        {/*  {t('home:see-more')} */}
        {/* </Button> */}
      </Spacer>
      <HotTopicArticles />
    </Card>
  );
}
