import classnames from 'classnames';
import React, { PropsWithChildren } from 'react';
import {
  PolymorphicComponentPropsWithRef,
  PolymorphicRef,
} from 'technical/polymorphic-component';
import classes from './index.module.scss';

type Breakpoints = 'xs' | 'sm' | 'md' | 'lg' | 'xl';

type Responsive<T extends string | number | boolean> = Partial<
  Record<Breakpoints, T>
> & { default: T };

type ResponsiveVariations<T extends string | number | boolean> =
  | T
  | Responsive<T>;

function isResponsiveConfig<T extends string | number | boolean>(
  config: ResponsiveVariations<T>,
): config is Responsive<T> {
  return typeof config === 'object';
}

function getResponsiveVariants<T extends string | number | boolean>(
  property: string,
  config?: ResponsiveVariations<T>,
) {
  if (config === undefined) {
    return undefined;
  }

  const variations = isResponsiveConfig(config) ? config : { default: config };

  return Object.entries(variations).map(
    ([breakpoint, value]) => classes[`${breakpoint}-${property}-${value}`],
  );
}

type SpacerProps<
  C extends React.ElementType
> = PolymorphicComponentPropsWithRef<
  C,
  PropsWithChildren<{
    space?: ResponsiveVariations<'none' | 0 | 1 | 2 | 3 | 4 | 5>;
    direction?: ResponsiveVariations<
      'vertical' | 'horizontal' | 'verticalReverse'
    >;
    justify?: ResponsiveVariations<
      | 'between'
      | 'evenly'
      | 'around'
      | 'start'
      | 'end'
      | 'center'
      | 'baseline'
      | 'stretch'
    >;
    align?: ResponsiveVariations<
      'start' | 'end' | 'center' | 'baseline' | 'stretch'
    >;
    wrap?: ResponsiveVariations<'none' | 'wrap' | 'reverse'>;
  }>
>;

type SpacerComponent = <C extends React.ElementType = 'div'>(
  props: SpacerProps<C>,
) => React.ReactElement | null;

export const Spacer: SpacerComponent = React.forwardRef(
  <C extends React.ElementType = 'div'>(
    {
      as,
      children,
      space = 'none',
      direction = 'horizontal',
      wrap,
      align,
      justify,
      className,
      ...rest
    }: SpacerProps<C>,
    ref?: PolymorphicRef<C>,
  ) => {
    const Component = as ?? 'div';
    return (
      <Component
        {...rest}
        className={classnames(
          classes.spacer,
          getResponsiveVariants('flex-direction', direction),
          getResponsiveVariants('gap', space),
          getResponsiveVariants('justify-content', justify),
          getResponsiveVariants('align-items', align),
          getResponsiveVariants('flex-wrap', wrap),
          className,
        )}
        ref={ref}
      >
        {children}
      </Component>
    );
  },
);
