import React from 'react';
import LoginCard from 'business/user/components/login-card';
import PageCenteredContent from 'ui/layouts/page-centered-content';

function SignInPage() {
  return (
    <PageCenteredContent>
      <LoginCard />
    </PageCenteredContent>
  );
}

export default SignInPage;
