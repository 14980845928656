import { enUS, enGB, fr } from 'date-fns/locale';
import { i18n } from 'translations';

export const getDateFnsLocale = () => {
  switch (i18n.language) {
    case 'fr':
      return fr;
    case 'en-gb':
      return enGB;
    case 'us':
    case 'en-us':
      return enUS;
    default:
      return enUS;
  }
};
