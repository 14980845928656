/* eslint-disable @typescript-eslint/no-use-before-define */
import React, { useRef } from 'react';
import { Card } from 'ui/cards';
import { useResearchForm } from './use-token-research-form';
import { Step1 } from './step-1';
import { Step2 } from './step-2';
import { Step3 } from './step-3';
import { ResearchFormError } from './error';

type ResearchFormProps = {
  tokenId: string;
  tokenReference: string;
};
export function ResearchForm({ tokenId, tokenReference }: ResearchFormProps) {
  const ref = useRef<HTMLDivElement>(null);
  const [state, send] = useResearchForm(tokenId, tokenReference, ref);

  const loading = state.matches('idle');

  if (loading) {
    // TODO: add skeleton UI
    return null;
  }

  return (
    <Card>
      <div id="research-form-top-anchor" hidden ref={ref} />
      {state.matches('some fatal error happened') ? (
        <ResearchFormError />
      ) : null}
      {state.matches('step 1') ? <Step1 state={state} send={send} /> : null}
      {state.matches('step 2') ||
      (state.matches('leave') && state.history?.matches('step 2')) ? (
        <Step2 state={state} send={send} />
      ) : null}
      {state.matches('step 3') ||
      (state.matches('leave') && state.history?.matches('step 3')) ? (
        <Step3 state={state} send={send} />
      ) : null}
    </Card>
  );
}
