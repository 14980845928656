import React from 'react';
import { Spacer } from 'ui/spacer';
import { useTranslation } from 'react-i18next';
import UserInfoLabel from 'business/user/components/user-info-label';
import {
  CheckIcon,
  EyeIcon,
  PeopleIcon,
  ThumbIcon,
} from 'business/user/components/new-profile-banner/icons';
import { formatOrdinals } from 'technical/utils/ordinal-format';
import classes from './index.module.scss';

interface UserStatisticProps {
  contributions: number;
  validatedScorings: number;
  rejectedScorings: number;
  position: number | string;
}

export function UserStatistic({
  contributions,
  validatedScorings,
  rejectedScorings,
  position,
}: UserStatisticProps) {
  const { t } = useTranslation(['user']);

  const validatedScoringsRatio =
    validatedScorings + rejectedScorings === 0
      ? 0
      : Number(
          (
            (validatedScorings / (validatedScorings + rejectedScorings)) *
            100
          ).toFixed(1),
        );

  return (
    <Spacer direction="horizontal" space={1} wrap="wrap">
      <UserInfoLabel
        icon={<PeopleIcon />}
        value={formatOrdinals(+position, t)}
        className={classes.labelWrapper}
        label={t('user:public-profile.position')}
      />
      <UserInfoLabel
        icon={<EyeIcon />}
        label={t('user:public-profile.scorings')}
        value={validatedScorings}
        className={classes.labelWrapper}
      />
      <UserInfoLabel
        icon={<CheckIcon />}
        label={t('user:public-profile.scorings-ratio')}
        value={`${validatedScoringsRatio}%`}
        className={classes.labelWrapper}
      />
      <UserInfoLabel
        icon={<ThumbIcon />}
        label={t('user:public-profile.contributions')}
        value={contributions}
      />
    </Spacer>
  );
}
