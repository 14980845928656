import React, { useMemo, useCallback, useState } from 'react';
import { AreaClosed, Line, Bar, LinePath } from '@visx/shape';
import { curveMonotoneX } from '@visx/curve';
import { scaleTime, scaleLinear } from '@visx/scale';
import { Tooltip, defaultStyles, useTooltip } from '@visx/tooltip';
import { localPoint } from '@visx/event';
import { LinearGradient } from '@visx/gradient';
import { extent, bisector } from 'd3-array';
import { Spacer } from 'ui/spacer';
import classNames from 'classnames';
import { Typography } from 'antd';
import { Empty } from 'ui/empty-state';
import { useTranslation } from 'react-i18next';
import { NumericValue, NumberFormats } from 'ui/numeric';
import variables from 'ui/theme/variables.module.scss';
import classes from './index.module.scss';

type Datum = { date: string; value: number };

export type HistoricalChartProps<PeriodValue> = {
  width: number;
  height: number;
  margin?: { top: number; right: number; bottom: number; left: number };
  data: Array<Datum>;
  loading?: boolean;
  periods: Array<{ name: string; value: PeriodValue }>;
  selectedPeriod?: PeriodValue;
  onPeriodChange: (period: PeriodValue) => void;
};

// accessors
const getDate = (d: Datum) => new Date(+d.date);
const getValue = (d: Datum) => d.value;
const bisectDate = bisector<Datum, Date>(getDate).left;

export function HistoricalChart<PeriodValue>({
  width,
  height,
  margin = { top: 0, right: 0, bottom: 0, left: 0 },
  data,
  loading = false,
  periods,
  selectedPeriod,
  onPeriodChange,
}: HistoricalChartProps<PeriodValue>) {
  if (width < 10) {
    return null;
  }

  const { t } = useTranslation(['token']);

  const {
    showTooltip,
    hideTooltip,
    tooltipData,
    tooltipTop = 0,
    tooltipLeft = 0,
  } = useTooltip<Datum>();

  // bounds
  const innerWidth = width - margin.left - margin.right;
  const innerHeight = height - margin.top - margin.bottom;

  // scales
  const dateScale = useMemo(
    () =>
      scaleTime({
        range: [margin.left, innerWidth + margin.left],
        domain: extent(data, getDate) as [Date, Date],
      }),
    [innerWidth, margin.left, data],
  );
  const valueScale = useMemo(
    () =>
      scaleLinear({
        range: [
          margin.top + innerHeight - innerHeight * 0.02,
          margin.top + innerHeight * 0.2,
        ],
        domain: extent(data, getValue) as [number, number],
        nice: true,
      }),
    [margin.top, innerHeight, data],
  );

  // tooltip handler
  const handleTooltip = useCallback(
    (
      event:
        | React.TouchEvent<SVGRectElement>
        | React.MouseEvent<SVGRectElement>,
    ) => {
      const { x } = localPoint(event) || { x: 0 };
      const x0 = dateScale.invert(x);
      const index = bisectDate(data, x0, 1);
      const d0 = data[index - 1];
      const d1 = data[index];
      if (!d0 || !d1) {
        return;
      }
      let d = d0;
      if (d1 && getDate(d1)) {
        d =
          x0.valueOf() - getDate(d0).valueOf() >
          getDate(d1).valueOf() - x0.valueOf()
            ? d1
            : d0;
      }
      showTooltip({
        tooltipData: d,
        tooltipLeft: x,
        tooltipTop: valueScale(getValue(d)),
      });
    },
    [showTooltip, valueScale, dateScale, data],
  );

  const hasNoData = !loading && data.length <= 1;

  const accentColor = loading ? variables.body : variables.primaryColor2;

  return (
    <div className={classes.chart}>
      <Spacer space={1} className={classes.buttons} align="center">
        {periods.map((period) => (
          <button
            key={`${period.value}`}
            type="button"
            disabled={hasNoData}
            className={classNames(
              classes.button,
              selectedPeriod === period.value && classes.selected,
            )}
            onClick={() => {
              onPeriodChange(period.value);
            }}
          >
            {period.name}
          </button>
        ))}
      </Spacer>
      {hasNoData ? (
        <Spacer justify="center" align="center" style={{ height }}>
          <Empty description={t('token:historical-chart.no-data')} />
        </Spacer>
      ) : (
        <svg width={width} height={height}>
          <LinearGradient
            id="area-gradient"
            from={accentColor}
            fromOpacity={0.45}
            to={accentColor}
            toOpacity={0}
          />
          <AreaClosed<Datum>
            data={data}
            x={(d) => dateScale(getDate(d)) ?? 0}
            y={(d) => valueScale(getValue(d)) ?? 0}
            yScale={valueScale}
            fill="url(#area-gradient)"
            curve={curveMonotoneX}
          />
          <LinePath<Datum>
            curve={curveMonotoneX}
            data={data}
            x={(d) => dateScale(getDate(d)) ?? 0}
            y={(d) => valueScale(getValue(d)) ?? 0}
            stroke={accentColor}
            strokeWidth={2}
          />
          <Bar
            x={margin.left}
            y={margin.top}
            width={innerWidth}
            height={innerHeight}
            fill="transparent"
            rx={14}
            onTouchStart={handleTooltip}
            onTouchMove={handleTooltip}
            onMouseMove={handleTooltip}
            onMouseLeave={() => hideTooltip()}
          />
          {tooltipData && (
            <g>
              <Line
                from={{ x: tooltipLeft, y: margin.top }}
                to={{ x: tooltipLeft, y: innerHeight + margin.top }}
                stroke={variables.primaryColor2}
                strokeWidth={1}
                pointerEvents="none"
              />
              <circle
                cx={tooltipLeft}
                cy={tooltipTop + 1}
                r={4}
                fill="black"
                fillOpacity={0.1}
                stroke="black"
                strokeOpacity={0.1}
                strokeWidth={2}
                pointerEvents="none"
              />
              <circle
                cx={tooltipLeft}
                cy={tooltipTop}
                r={4}
                fill={variables.primaryColor2}
                stroke="white"
                strokeWidth={2}
                pointerEvents="none"
              />
            </g>
          )}
        </svg>
      )}
      {tooltipData && (
        <div>
          <Tooltip
            key={Math.random()}
            top={tooltipTop - 10 - 52 / 2}
            left={tooltipLeft}
            style={{
              ...defaultStyles,
              backgroundColor: classes.body,
              whiteSpace: 'nowrap',
              height: 52,
            }}
          >
            <NumericValue
              format={NumberFormats.currency}
              strong
              value={getValue(tooltipData)}
              className={classes['tooltip-text']}
            />
            <Typography.Text className={classes['tooltip-text']}>
              {getDate(tooltipData).toLocaleString()}
            </Typography.Text>
          </Tooltip>
        </div>
      )}
    </div>
  );
}
