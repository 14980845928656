import { DiscordLogoSVGComponent } from 'ui/social-networks/icons/discord-logo';
import { FacebookLogoSVGComponent } from 'ui/social-networks/icons/facebook-logo';
import { RedditLogoSVGComponent } from 'ui/social-networks/icons/reddit-logo';
import { TelegramLogoSVGComponent } from 'ui/social-networks/icons/telegram-logo';
import { TwitterLogoSVGComponent } from 'ui/social-networks/icons/twitter-logo';
import { YoutubeLogoSVGComponent } from 'ui/social-networks/icons/youtube-logo';
import colors from 'ui/theme/variables.module.scss';
import Icon from '@ant-design/icons';
import classNames from 'classnames';
import React from 'react';
import classes from './index.module.scss';
import { Crew3LogoSVGComponent } from './icons/crew3-logo';

const SocialNetworkSVGRecord = {
  discord: DiscordLogoSVGComponent,
  facebook: FacebookLogoSVGComponent,
  reddit: RedditLogoSVGComponent,
  telegram: TelegramLogoSVGComponent,
  twitter: TwitterLogoSVGComponent,
  youtube: YoutubeLogoSVGComponent,
  crew3: Crew3LogoSVGComponent,
};

export const SocialIcon = ({
  social,
  className,
  size = 'medium',
  color = colors.primaryColor,
}: {
  className?: string;
  color?: string;
  size?: 'small' | 'medium';
  social: keyof typeof SocialNetworkSVGRecord;
}) => {
  const SocialSvg = SocialNetworkSVGRecord[social];
  return (
    <Icon
      className={classNames(
        classes.icon,
        classes[size],
        className,
        classes[social],
      )}
      component={() => <SocialSvg fill={color} />}
    />
  );
};
