import React from 'react';
import { Typography } from 'antd';

import { useTranslation } from 'react-i18next';
import { PrivatePersonalPortfoliosComponent } from 'business/user/components/personal-portfolios';

export function PersonalPortfoliosPage() {
  const { t } = useTranslation(['portfolio']);

  return (
    <>
      <Typography.Title>{t('portfolio:myPortfolios.title')}</Typography.Title>
      <PrivatePersonalPortfoliosComponent />
    </>
  );
}

export default PersonalPortfoliosPage;
