import React, { useState } from 'react';
import { Card } from 'ui/cards';
import { useTranslation } from 'react-i18next';
import { GlobalLeaderboard } from 'business/user/components/leaderboard/global-leaderboard';
import { GlobalUserPosition } from 'business/user/components/user-position/global-user-position';
import { Tabs, TabPane } from 'ui/tabs';
import { Typography } from 'antd';
import { SessionLeaderboard } from 'business/user/components/leaderboard/session-leaderboard';
import {
  LeaderboardSession,
  useGetLeaderboardSessionsQuery,
} from 'generated/graphql';
import { SessionUserPosition } from 'business/user/components/user-position/session-user-position';
import { useCurrentTab } from 'technical/router/url-param/use-current-tab';
import { LeaderboardSessionSelector } from 'business/user/components/leaderboard-session-selector';
import classes from './index.module.scss';

enum LeaderBoardTabEnum {
  GLOBAL_LEADERBOARD = 'global-leaderboard',
  SESSION_LEADERBOARD = 'session-leaderboard',
}

export const LeaderBoardPage = () => {
  const { t } = useTranslation(['common']);
  const [currentTab, setCurrentTab] = useCurrentTab<typeof LeaderBoardTabEnum>({
    defaultTab: LeaderBoardTabEnum.GLOBAL_LEADERBOARD,
    tabs: LeaderBoardTabEnum,
  });

  const [currentSession, setCurrentSession] = useState<
    LeaderboardSession | null | undefined
  >(null);
  const { data, loading } = useGetLeaderboardSessionsQuery({
    skip: currentTab !== LeaderBoardTabEnum.SESSION_LEADERBOARD,
    onCompleted: (fetchedData) => {
      setCurrentSession(fetchedData?.leaderboardSession[0]);
    },
  });

  return (
    <>
      <Typography.Title>{t('common:leaderboard.title')}</Typography.Title>
      <Tabs
        activeKey={currentTab}
        onChange={(tab) => setCurrentTab(tab as LeaderBoardTabEnum)}
      >
        <TabPane
          tab={t('common:leaderboard.global-leaderboard')}
          key={LeaderBoardTabEnum.GLOBAL_LEADERBOARD}
        >
          <div className={classes.grid}>
            <div className={classes.leaderboard}>
              <Card>
                <Card.Header title={t('common:leaderboard.top50')} level={2} />
                <GlobalLeaderboard limit={50} />
              </Card>
            </div>

            <div className={classes.user}>
              <Card>
                <Card.Header
                  title={t('common:leaderboard.my-position')}
                  level={2}
                />
                <GlobalUserPosition />
              </Card>
            </div>
          </div>
        </TabPane>
        <TabPane
          tab={t('common:leaderboard.session-leaderboard')}
          key={LeaderBoardTabEnum.SESSION_LEADERBOARD}
        >
          <div className={classes.grid}>
            <div className={classes.leaderboard}>
              <Card>
                <Card.Header
                  title={t('common:leaderboard.top50')}
                  level={2}
                  actions={
                    <LeaderboardSessionSelector
                      sessions={data?.leaderboardSession}
                      currentSession={currentSession}
                      onChange={setCurrentSession}
                      loading={loading}
                    />
                  }
                />
                <SessionLeaderboard
                  limit={50}
                  sessionId={currentSession?.id}
                  loading={loading}
                />
              </Card>
            </div>
            <div className={classes.user}>
              <Card>
                <Card.Header
                  title={t('common:leaderboard.my-position')}
                  level={2}
                />
                <SessionUserPosition sessionId={currentSession?.id} />
              </Card>
            </div>
          </div>
        </TabPane>
      </Tabs>
    </>
  );
};
