import React from 'react';
import { CardInfo } from 'ui/cards/card-info';
import { useTranslation } from 'react-i18next';
import Button from 'ui/button';
import { useAskForEmailVerificationMutation } from 'generated/graphql';
import { Spacer } from 'ui/spacer';
import { Typography } from 'antd';
import InputError from 'ui/form/input-error';
import SupportContactButtons from '../../support-contact-buttons';

interface ResendEmailCardProps {
  email: string;
}

export const ResendEmailCard: React.VFC<ResendEmailCardProps> = ({ email }) => {
  const { t } = useTranslation(['common']);
  const [
    refreshEmailValidation,
    { data, loading, error },
  ] = useAskForEmailVerificationMutation();

  const onRefreshEmailValidation = async () => {
    await refreshEmailValidation({ variables: { email } });
  };

  const success = data?.askForEmailVerification?.success;
  const message = data?.askForEmailVerification?.message;

  return (
    <CardInfo
      title={
        success
          ? t('pages.signIn.form.email-sent.title')
          : t('pages.signIn.form.resend-email.title')
      }
      description={
        success
          ? t('pages.signIn.form.email-sent.description')
          : t('pages.signIn.form.resend-email.description')
      }
      childrens={
        success ? (
          <Spacer direction="vertical" space={1}>
            <Typography.Text strong>
              {t('pages.signIn.form.email-sent.message')}
            </Typography.Text>
            <SupportContactButtons />
          </Spacer>
        ) : (
          <Spacer direction="vertical" space={1}>
            <Typography.Text>
              {t('pages.signIn.form.email-sent.time-request-limit')}
            </Typography.Text>
            <InputError
              error={
                message === 'email-token-send-not-long-ago'
                  ? t('pages.signIn.form.resend-email.emailTokenSendNotLongAgo')
                  : undefined
              }
            />
            <Spacer justify="start">
              <Button
                type="primary"
                size="large"
                disabled={loading}
                loading={loading}
                onClick={onRefreshEmailValidation}
              >
                {t('pages.signIn.form.resend-email.cta')}
              </Button>
            </Spacer>
          </Spacer>
        )
      }
    />
  );
};
