import React from 'react';
import { useField } from 'formik';
import { Form, InputNumber, InputNumberProps, Tag } from 'antd';
import * as yup from 'yup';
import { Spacer } from 'ui/spacer';
import { variationToPrice, priceToVariation } from 'technical/price-variations';
import {
  formatterNumber,
  parserNumber,
} from 'technical/input-parser-formatter/number';
import { scaleLinear, scaleQuantile } from '@visx/scale';
import { RecursiveFormNames } from '../types';
import { useFieldTranslation } from '../translations';
import styles from './index.module.scss';

export type PredictionFieldProps<FormValues> = InputNumberProps & {
  label: string;
  name: RecursiveFormNames<FormValues>;
  id: string;
  currentPrice: number;
};

function createTargetPriceChangeScale(
  config: Array<{ name: string; min: number; max: number }>,
) {
  const linear = scaleLinear({
    range: [
      ...Array.from(
        { length: config.length },
        (_, i) => (100 / config.length) * i,
      ),
      100,
    ],
    domain: [...config.map(({ min }) => min), config[config.length - 1].max],
    clamp: true,
  });

  const quantile = scaleQuantile({
    range: [...config.map(({ name }) => name)],
    domain: [...config.map(({ min }) => min)],
  });

  return (change: number) => ({
    linear: linear(change),
    type: quantile(change),
  });
}

export const targetPriceChangeScale = createTargetPriceChangeScale([
  { min: -1, max: -0.5, name: 'Crash' },
  { min: -0.5, max: -0.1, name: 'Bearish' },
  { min: -0.1, max: 0.1, name: 'Flat' },
  { min: 0.1, max: 0.5, name: 'Bullish' },
  { min: 0.5, max: 1.5, name: 'Gem' },
]);

const num = yup
  .number()
  .transform((value) => (Number.isNaN(value) ? 0 : value));

export function PredictionField<FormValues>({
  label,
  name,
  id,
  currentPrice,
  ...rest
}: PredictionFieldProps<FormValues>) {
  const { t } = useFieldTranslation();
  const [{ value = currentPrice }, meta, helpers] = useField({ name });

  const status = meta.touched && meta.error ? 'error' : 'success';
  const message = status === 'error' ? t(meta.error as any) : '\u00A0';

  if (typeof value !== 'number') {
    throw new Error('');
  }
  const variation = priceToVariation(value, currentPrice);
  const targetPriceChange = targetPriceChangeScale(variation);

  return (
    <Form.Item
      labelCol={{
        span: 24,
      }}
      htmlFor={`${id}-price`}
      label={label}
      validateStatus={status}
      help={message}
      className={styles.form}
    >
      <Spacer space={2} direction="horizontal">
        <InputNumber
          {...rest}
          className={styles.inputContainer}
          id={`${id}-price`}
          value={+value.toFixed(2)}
          // TODO: better parser/formatter
          formatter={formatterNumber}
          parser={parserNumber}
          onChange={(v, ...args) => {
            const newPrice = num.default(currentPrice).cast(v);
            rest.onChange?.(newPrice, ...args);
            helpers.setValue(newPrice);
          }}
          onBlur={(...args) => {
            rest.onBlur?.(...args);
            helpers.setTouched(true);
          }}
          prefix="$"
        />
        <InputNumber
          {...rest}
          className={styles.inputContainer}
          id={`${id}-percentage`}
          // TODO: better parser/formatter
          formatter={(v) => `${+(+(v ?? 0)).toFixed(2)}`}
          parser={(v) => (v ?? '0').toString()}
          value={variation * 100}
          min={-100}
          step={10}
          onChange={(v, ...args) => {
            const newVariation = num.default(0).cast(v) / 100;
            const newPrice = variationToPrice(newVariation, currentPrice);
            rest.onChange?.(newPrice, ...args);
            helpers.setValue(newPrice);
          }}
          onBlur={(...args) => {
            rest.onBlur?.(...args);
            helpers.setTouched(true);
          }}
          prefix="%"
        />
        <Tag
          className={`${styles.tag} ${
            targetPriceChange.linear >= 50 ? styles.greenTag : styles.redTag
          }`}
        >
          {targetPriceChange.type}
        </Tag>
      </Spacer>
    </Form.Item>
  );
}
