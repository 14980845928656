import Routes from 'business/router/routes';
import { i18n } from 'translations';
import { MenuLinkProps } from './menu-link';

// todo une links in config, deprecated anyway
const externalLinks = {
  learningHub: 'https://learn.kryptview.com/',
  about: 'https://corporate.kryptview.com/',
};

/**
 * @deprecated rm with old header
 */
export const menuLinks: Array<MenuLinkProps> = [
  {
    href: Routes.Home,
    label: i18n.t('common:header.homepage'),
    loggedOnly: false,
  },
  {
    href: Routes.CommunityResearches,
    label: i18n.t('common:header.community-scorings'),
    loggedOnly: true,
  },
  {
    href: Routes.CommunityPortfolios,
    label: i18n.t('common:header.community-portfolios'),
    loggedOnly: false,
  },
  {
    href: externalLinks.learningHub,
    label: i18n.t('common:header.learning-hub'),
    loggedOnly: false,
    target: '_blank',
  },
  {
    href: externalLinks.about,
    label: i18n.t('common:header.about'),
    loggedOnly: false,
    target: '_blank',
  },
  // lessons
];
