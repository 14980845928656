import React from 'react';
import { Card } from 'ui/cards';
import { useGetTokenInfosQuery } from 'generated/graphql';
import { CountInPortfolio } from 'business/token/components/count-in-portfolio';
import { TokenOverview } from 'business/token/components/token-overview';
import { PriceEvolutionCharts } from 'business/token/components/price-evolution-charts';
import { useTranslation } from 'react-i18next';
import { PriceChangePercentages } from 'business/token/components/price-change-percentages';
import { TokenMarketStatistics } from 'business/token/components/market-statistics';
import { defaultTokenEmptyValue } from 'business/token/common-values';
import { InfosPanel } from 'business/token/components/infos-panel';
import { ReactComponent as GraphIcon } from 'assets/illustrations/graph-icon.svg';
import { ReactComponent as CommunityScore } from 'assets/illustrations/community-score-icon.svg';
import { ReactComponent as ReferenceIcon } from 'assets/icons/lightning.svg';
import { Spacer } from 'ui/spacer';
import { TokenHeaderInfos } from 'business/token/components/token-header-infos';
import variables from 'ui/theme/variables.module.scss';
import { useAppContext } from 'business/app-bootstrapper';
import { Image, Typography } from 'antd';
import { BitcoinScoring } from 'business/token/pages/bitcoin-infos/bitcoin-scoring';
import tokenImg from 'assets/illustrations/bitcoin-illu.png';
import { HotTopicArticles } from 'business/article/components/hot-topic-articles';
import classes from './index.module.scss';
import {
  BlockchainSummary,
  PoweredByITB,
} from 'business/token/components/blockchain-summary';
import config from 'config';
import classNames from 'classnames';

const reference = 'bitcoin';

export function BitcoinInfosPage() {
  const { t } = useTranslation(['token']);
  const { user } = useAppContext();
  const { data, loading: queryLoading, previousData } = useGetTokenInfosQuery({
    variables: {
      reference,
    },
  });

  const loading = !previousData && queryLoading;
  const { identity, market, portfolioCount, about } =
    data?.getTokenInfos ?? previousData?.getTokenInfos ?? {};

  return (
    <div
      className={classNames(
        classes.grid,
        config.features.showITBData && classes.itb,
      )}
    >
      <div className={classes.tokenTitle}>
        <TokenOverview
          loading={loading}
          about={data?.getTokenInfos.about}
          token={identity}
          {...market}
        />
      </div>
      <div className={classes.tokenInfos}>
        <TokenHeaderInfos
          tokenReference={reference}
          tokenId={identity?.id}
          withAction={false}
          {...market}
        />
      </div>
      <Spacer
        className={classes.articles}
        justify={{ xl: 'end', default: 'center' }}
        space="none"
      >
        <HotTopicArticles className={classes.articlesWrapper} />
      </Spacer>

      <div className={classes.charts}>
        <PriceEvolutionCharts
          token={{
            reference,
            symbol: identity?.symbol.toUpperCase() ?? defaultTokenEmptyValue,
          }}
        />
      </div>

      <div className={classes.reference}>
        <Card className={classes.dispersionCard}>
          <Card.Header
            level={2}
            title={t('token:reference-token.title')}
            icon={<ReferenceIcon fill={variables.primaryColor2} />}
          />
          <Spacer
            direction="vertical"
            space={1}
            align="center"
            justify="center"
          >
            <Image
              src={tokenImg}
              width="100%"
              className={classes.bitcoinIllu}
              preview={false}
            />
            <Typography.Paragraph style={{ whiteSpace: 'pre-line' }}>
              {t('token:reference-token.description')}
            </Typography.Paragraph>
          </Spacer>
        </Card>
      </div>

      <div className={classes.infos}>
        <InfosPanel
          aboutClassName={classes.about}
          about={about ?? undefined}
          market={{ reference, volume: market?.volume }}
        />
      </div>

      <div className={classes.notation}>
        <Card>
          <Card.Header
            level={2}
            title={t('token:reference-token.notation.title')}
            icon={
              <CommunityScore
                width={20}
                height={14}
                fill={variables.primaryColor2}
              />
            }
          />

          <BitcoinScoring />
        </Card>
      </div>

      <div className={classes.evolution}>
        <Card>
          <PriceChangePercentages
            name={identity?.name ?? defaultTokenEmptyValue}
            priceChangePercentages={market?.priceChangePercentages}
          />
        </Card>
      </div>

      <div className={classes.portfolio}>
        <Card className={classes.portfolioCard}>
          <Card.Header
            icon={
              <GraphIcon
                width="13"
                height="13"
                fill={variables.primaryColor2}
              />
            }
            level={2}
            title={t('token:portfolios.title')}
          />
          <CountInPortfolio
            count={portfolioCount ?? 0}
            tokenRef={reference}
            loading={loading}
            blurred={!user}
          />
        </Card>
      </div>

      <div className={classes['market-statistics']}>
        <Card>
          <Card.Header level={2} title={t('token:market-statistics.title')} />
          <TokenMarketStatistics tokenId={identity?.id} {...market} />
        </Card>
      </div>

      {config.features.showITBData ? (
        <div className={classes['on-chain-metrics']}>
          <Card>
            <Card.Header
              level={2}
              title={t('token:itb-integration.title')}
              actions={<PoweredByITB />}
            />
            <BlockchainSummary token={identity?.itb_id} />
          </Card>
        </div>
      ) : null}
    </div>
  );
}
