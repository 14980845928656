import React, { useState } from 'react';
import { Button, Dropdown, Menu, MenuProps, Typography } from 'antd';
import { Trans, useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import authService from 'business/user/services/authentication.gql';
import { Me } from 'business/user/types/user';
import Routes from 'business/router/routes';
import { ItemType } from 'antd/lib/menu/hooks/useItems';
import config from 'config';
import classnames from 'classnames';
import { ProfileSectionMenu } from 'ui/new-header/profile-section-menu';
import { AccountLinks, getAccountLinks } from './account-links';
import classes from './account-menu.module.scss';
import { AccountIcon, AccountMenuArrow } from './icons';

interface AccountMenuProps {
  user: Me;
}

export function AccountMenu({ user }: AccountMenuProps) {
  const { t } = useTranslation(['common']);
  const links = getAccountLinks(t);
  const [open, setOpen] = useState(false);

  const linksUser: ItemType[] = links.reduce(
    (items: ItemType[], link: AccountLinks) => {
      const { hasDivider, ...props } = link;
      items.push({
        label: <Link {...props} />,
        key: `container-${props.key}`,
      });

      if (hasDivider) {
        items.push({ type: 'divider' });
      }

      return items;
    },
    [],
  );

  const items: ItemType[] = [
    {
      className: classes.connectedAs,
      label: (
        <Trans
          t={t}
          i18nKey="common:header.account-menu.connected-as"
          values={{
            email: user.email,
          }}
          components={{
            newline: <br />,
            bold: <Typography.Text strong />,
          }}
        />
      ),
      key: 'email',
    },
    {
      type: 'divider',
    },
    ...linksUser,
    {
      type: 'divider',
    },
    {
      label: (
        <Link to={Routes.Leaderboard}>
          {t('common:header.account-menu.leaderboard')}
        </Link>
      ),
      key: 'leaderboard',
    },
    {
      type: 'divider',
    },
    {
      label: t('common:header.account-menu.sign-out'),
      onClick: authService.logout,
      key: 'singout',
    },
  ];

  const handleOpenChange = (flag: boolean) => {
    setOpen(flag);
  };

  return (
    <Dropdown
      open={config.features.useNewHeader ? open : undefined}
      overlayClassName={classnames({
        [classes.dropDownOverFlow]: config.features.useNewHeader,
      })}
      overlay={
        config.features.useNewHeader ? (
          <ProfileSectionMenu
            onItemClick={() => {
              setOpen(false);
            }}
          />
        ) : (
          <Menu items={items} className={classes.desktopMenu} />
        )
      }
      onOpenChange={handleOpenChange}
      placement="bottomRight"
    >
      <Button
        className={classes.accountBtn}
        icon={<AccountIcon />}
        type="primary"
      >
        {t('common:header.account')} <AccountMenuArrow />
      </Button>
    </Dropdown>
  );
}
