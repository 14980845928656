import { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { useDebounce } from 'technical/hooks/use-debounce';

export interface FilterInURL<T> {
  defaultValue?: T;
  fromSearchParam: (searchParamValue: string | null) => T;
  toSearchParam: (value: T) => string | undefined;
}

/**
 * Custom hook to manage filters and automatically fetch (and update) from the URL
 * @param filters An object which keys correspond to each possible filter
 */
export const useFilters = <T extends { [key: string]: any }>(
  filters: { [name in keyof T]: FilterInURL<T[name]> },
) => {
  const navigate = useNavigate();
  const location = useLocation();
  // Get filters from the URL
  const filtersFromUrl = (search: string): T => {
    const params = new URLSearchParams(decodeURI(search));

    return Object.keys(filters).reduce<T>(
      (res: T, key: keyof T) => ({
        ...res,
        [key]: filters[key].fromSearchParam(params.get(key.toString())),
      }),
      {} as T,
    );
  };

  const [filterValues, setFilterValues] = useState<T>(
    filtersFromUrl(location.search),
  );

  const debouncedFormikValues = useDebounce(filterValues);

  // Write filters to the URL
  useEffect(() => {
    const params = new URLSearchParams(decodeURI(location.search));

    Object.keys(filters).forEach((key: keyof T) => {
      const value = filters[key].toSearchParam(filterValues[key]);
      if (value !== undefined) {
        params.set(key.toString(), value);
      } else {
        params.delete(key.toString());
      }
    });
    const newSearch = encodeURI(params.toString());

    // history.location.search add a "?" as first character
    if (location.search.substring(1) !== newSearch) {
      navigate({ search: newSearch }, { replace: true });
    }
  }, [debouncedFormikValues]);

  // when history.location.search changes, update the state accordingly
  useEffect(() => {
    const newFilterValues = filtersFromUrl(location.search);
    if (
      !Object.keys(filters).every(
        (key: keyof T) => filterValues[key] === newFilterValues[key],
      )
    ) {
      setFilterValues(newFilterValues);
    }
  }, [location.search]);

  const activeFilters = Object.keys(filters).filter(
    (key: keyof T) => filters[key].defaultValue !== filterValues[key],
  );

  return {
    noFilters: activeFilters.length === 0,
    activeFilters,
    filtersValues: filterValues,
    debouncedFiltersValues: debouncedFormikValues,
    setFiltersValues: setFilterValues,
  };
};
