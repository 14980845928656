import { Typography } from 'antd';
import React, { ReactElement } from 'react';
import { Spacer } from 'ui/spacer';
import { Card } from 'ui/cards';
import classes from './index.module.scss';

interface CardInfoProps {
  title: string;
  description: string;
  childrens: ReactElement;
}

export function CardInfo({ title, description, childrens }: CardInfoProps) {
  return (
    <Card type="info">
      <Typography.Title className={classes.title}>{title}</Typography.Title>
      <Spacer direction="vertical" space={1}>
        <Typography.Text>{description}</Typography.Text>
        {childrens}
      </Spacer>
    </Card>
  );
}
