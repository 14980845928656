import React from 'react';
import { Typography } from 'antd';
import { Spacer } from 'ui/spacer';
import { useTranslation } from 'react-i18next';
import styles from '../new-profile-banner/index.module.scss';

interface DescriptionProps {
  description: string | undefined | null;
  noDescriptionText: string;
}

export function Description({
  description,
  noDescriptionText,
}: DescriptionProps) {
  return (
    <Typography.Paragraph italic={!description}>
      {description ?? noDescriptionText}
    </Typography.Paragraph>
  );
}

export const DescriptionV2 = ({
  description,
  noDescriptionText,
}: DescriptionProps) => {
  const { t } = useTranslation(['user']);

  return (
    <>
      <h3 className={styles.title}>
        {t('user:public-profile.description-title')}
      </h3>
      <Spacer className={styles.descriptionBlock}>
        <Typography.Paragraph italic={!description}>
          {description ?? noDescriptionText}
        </Typography.Paragraph>
      </Spacer>
    </>
  );
};
