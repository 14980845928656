import { Rank } from 'generated/graphql';
import { Spacer } from 'ui/spacer';
import { Image } from 'antd';
import classes from 'business/user/components/leaderboard/index.module.scss';
import classNames from 'classnames';
import { generatePath, Link } from 'react-router-dom';
import Routes from 'business/router/routes';
import React from 'react';

interface UserPositionProps {
  position?: number | string | null;
  displayName?: string | null;
  rank?: Pick<Rank, 'name' | 'icon'> | null;
  userId?: string | null;
}

export function UserPosition({
  position = '-',
  displayName = '-',
  rank,
  userId,
}: UserPositionProps) {
  return (
    <Spacer justify="center" direction="vertical" align="center">
      <Image
        preview={false}
        width={96}
        height={96}
        className={classes.rank}
        alt={`${rank?.name} icon`}
        src={`/ranks/${rank?.icon}`}
      />
      <Spacer
        align="center"
        direction="vertical"
        className={classNames(classes['user-position'], classes['flex-none'])}
      >
        <span>#{position}</span>
      </Spacer>

      {userId ? (
        <Link
          className={classes.link}
          to={generatePath(Routes.PublicProfile, { id: userId })}
        >
          {displayName}
        </Link>
      ) : null}
    </Spacer>
  );
}
