import React, { useState } from 'react';
import { Spacer } from 'ui/spacer';
import { Layout } from 'antd';
import { Link } from 'react-router-dom';
import Routes from 'business/router/routes';
import KryptviewSVGComponent from 'ui/app-logo/kryptview';
import { MenuLink } from 'ui/header/menu-link';
import TokenSearchBar from 'business/token/components/token-search-bar';
import { AccountMenu } from 'ui/header/account-menu';
import Button from 'ui/button';
import { useAppContext } from 'business/app-bootstrapper';
import { useTranslation } from 'react-i18next';
import classNames from 'classnames';
import LoginModal from 'business/user/components/login-modal';
import { BurgerMenuIcon } from 'ui/header/icons';
import { MobileMenu } from 'ui/new-header/mobile-menu';
import ThemeSwitcher from 'ui/header/theme-switcher';
import { useNavLinks } from 'business/navigation/service/use-nav-links';
import classes from './header.module.scss';
import config from 'config';

export const Header: React.VFC = () => {
  const { t } = useTranslation(['common']);
  const { user } = useAppContext();
  const [loginModalIsOpen, setLoginModalIsOpen] = useState(false);
  const [isBurgerMenuVisible, setIsBurgerMenuVisible] = useState(false);
  const menuLinks = useNavLinks(['main']);

  return (
    <>
      <Layout.Header className={classes.header}>
        <Spacer space={1} align="center" className={classes.mainSpacer}>
          <Link className={classes.logoContainer} to={Routes.Home}>
            <KryptviewSVGComponent className={classes.logo} />
          </Link>

          <Spacer
            className={classNames(classes.desktopOnly, classes.menuItems)}
            justify="start"
          >
            {menuLinks.map((menu) => (
              <MenuLink key={menu.href} {...menu} />
            ))}
          </Spacer>

          <Spacer justify="end" space={1} align="center">
            <div className={classes.searchBarContainer}>
              <TokenSearchBar />
            </div>
            <div className={classes.desktopOnly}>
              <Spacer direction="horizontal" space={1}>
                <ThemeSwitcher />
                {user ? (
                  <AccountMenu user={user} />
                ) : (
                  <Button
                    type="primary"
                    onClick={() => setLoginModalIsOpen(true)}
                    hidden={config.features.isInHibernation}
                  >
                    {t('header.sign-in-or-register')}
                  </Button>
                )}
              </Spacer>
            </div>
            <Button
              className={classes.mobileOnly}
              type="text"
              icon={<BurgerMenuIcon />}
              onClick={() => setIsBurgerMenuVisible(true)}
            />
          </Spacer>
        </Spacer>
      </Layout.Header>

      <MobileMenu
        visible={isBurgerMenuVisible}
        onCloseMenu={() => setIsBurgerMenuVisible(false)}
      />

      <LoginModal isOpen={loginModalIsOpen} setIsOpen={setLoginModalIsOpen} />
    </>
  );
};
