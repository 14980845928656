import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { PortfolioContainer } from 'business/portfolio/components/portfolio-container';
import { Spacer } from 'ui/spacer';
import { AddPortfolioIcon } from 'business/portfolio/components/add-portfolio/icons';
import Button from 'ui/button';
import { PortfolioCreationModal } from 'business/portfolio/components/add-portfolio/portfolio-creation-modal';
import { GetUserPortfolios } from 'business/portfolio/services/queries.gql';
import { useCreatePortfolioMutation } from 'generated/graphql';
import { Typography } from 'antd';
import classes from 'business/portfolio/components/add-portfolio/index.module.scss';

export const AddPortfolio = () => {
  const { t } = useTranslation(['portfolio']);
  const [createPortfolio] = useCreatePortfolioMutation();
  const [
    isCreatePortfolioModalVisible,
    setIsCreatePortfolioModalVisible,
  ] = useState(false);

  return (
    <PortfolioContainer classsname={classes.containerForm}>
      <Spacer
        className={classes.container}
        direction="vertical"
        justify="center"
        align="center"
        space={2}
      >
        <AddPortfolioIcon />
        <Typography.Title level={5}>
          {t('portfolio:newPortfolio.title')}
        </Typography.Title>
        <Typography.Text strong type="secondary">
          {t('portfolio:newPortfolio.description')}
        </Typography.Text>
        <Button
          onClick={() => setIsCreatePortfolioModalVisible(true)}
          type="primary"
          size="large"
          disabled={isCreatePortfolioModalVisible}
        >
          {t('portfolio:addPortfolio.cta')}
        </Button>
      </Spacer>
      {/* the modal is inside the portfolioContainer because the modal contain a form that is detect by the grid css property and consider the modal like a visible component */}
      <PortfolioCreationModal
        visible={isCreatePortfolioModalVisible}
        onCancel={() => setIsCreatePortfolioModalVisible(false)}
        onSubmit={(values) => {
          createPortfolio({
            variables: values,
            refetchQueries: [GetUserPortfolios],
          });
          setIsCreatePortfolioModalVisible(false);
        }}
      />
    </PortfolioContainer>
  );
};
