import React from 'react';
import SignupCard from 'business/user/components/signup-card';
import PageCenteredContent from '../../../../ui/layouts/page-centered-content';

function SignUpPage() {
  return (
    <PageCenteredContent>
      <SignupCard />
    </PageCenteredContent>
  );
}

export default SignUpPage;
