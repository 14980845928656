import React from 'react';
import { Layout } from 'antd';
import { TopHeader } from 'ui/header/top';
import { HeadBand } from 'business/head-band';
import config from 'config';
import { Header } from 'ui/new-header';
import styles from './index.module.scss';

const { Content, Footer } = Layout;

interface Props {
  header?: React.ReactNode;
  children?: React.ReactNode;
  img?: { src: string; alt: string };
  footer?: React.ReactNode;
  subHeader?: React.ReactNode;
  topElement?: React.ReactNode;
}

function Page({
  children = null,
  footer = null,
  subHeader = null,
  topElement = null,
}: Props) {
  return (
    <Layout className={styles.layout}>
      <HeadBand />
      {config.features.useNewHeader ? <Header /> : <TopHeader />}
      <div className={styles.subHeader}>{subHeader}</div>
      <Content className={styles.content} id="main-content" tabIndex={-1}>
        {topElement}
        {children}
      </Content>
      <Footer className={styles.footer}>{footer}</Footer>
    </Layout>
  );
}

export default Page;
