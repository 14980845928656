import React, { useState } from 'react';
import { TableColumnProps, Typography } from 'antd';
import { ParentSize } from '@visx/responsive';
import {
  TokenIdentity,
  TokenMarkets,
  useGetAllTokensDetailsQuery,
} from 'generated/graphql';
import { SorterResult } from 'antd/es/table/interface';
import { useAppContext } from 'business/app-bootstrapper';
import Table from 'ui/table';
import { useTranslation } from 'react-i18next';
import { NumericValue, NumberFormats } from 'ui/numeric';
import { PercentageValue } from 'ui/percentage';
import { useReportTokenModal } from 'business/user/modals/use-report-token-modal';
import RatingTag from 'ui/rating-tag';
import { RatingType } from 'business/rating/types';
import LoginModal, {
  LoginModalFormTabs,
} from 'business/user/components/login-modal';
import Button from 'ui/button';
import classes from './index.module.scss';
import { SparklineChart } from '../sparkline-chart';
import { TokenIconAndName } from '../../../research/components/token-icon-and-name';

interface TokenTableRow
  extends Pick<
      TokenIdentity,
      | 'id'
      | 'symbol'
      | 'reference'
      | 'name'
      | 'validatedResearchCount'
      | 'currentTokenRating'
      | 'coinGeckoImage'
    >,
    Pick<
      TokenMarkets,
      | 'image'
      | 'price'
      | 'h24'
      | 'd7'
      | 'marketCap'
      | 'volume'
      | 'circulatingSupply'
      | 'sparkline'
    > {}

export enum TokenTableFiltersPrefix {
  lastResearched = 'last-researched',
  name = 'name',
  price = 'price',
  h24 = 'h24_change',
  marketCap = 'market_cap',
  volume = 'volume',
  avgRating = 'avg-rating',
  validatedResearchCount = 'validated-research-count',
}

export enum TokenTableFilters {
  lastResearched = 'last-researched',
  nameAsc = 'name_asc',
  nameDesc = 'name_desc',
  priceAsc = 'price_asc',
  priceDesc = 'price_desc',
  h24Asc = 'h24_change_asc',
  h24Desc = 'h24_change_desc',
  marketCapAsc = 'market_cap_asc',
  marketCapDesc = 'market_cap_desc',
  volumeAsc = 'volume_asc',
  volumeDesc = 'volume_desc',
  avgRatingAsc = 'avg-rating-asc',
  avgRatingDesc = 'avg-rating-desc',
  validatedResearchCountAsc = 'validated-research-count-asc',
  validatedResearchCountDesc = 'validated-research-count-desc',
}

const defaultFilter: TokenTableFilters = TokenTableFilters.avgRatingDesc;
const defaultPageSize = 20;
const pageSizeOptions = [20, 50, 100];

function getFilterFromSorter(
  sorter: SorterResult<TokenTableRow> | SorterResult<TokenTableRow>[],
) {
  if (
    !sorter ||
    Array.isArray(sorter) ||
    !sorter.field ||
    Array.isArray(sorter.field) ||
    !sorter.order
  ) {
    return defaultFilter;
  }

  const order = sorter.order === 'ascend' ? 'Asc' : 'Desc';
  const filterKey = (sorter.field + order) as keyof typeof TokenTableFilters;
  return TokenTableFilters[filterKey];
}

interface TokenTableProps {
  filter: TokenTableFilters;
  setFilter: (filter: TokenTableFilters) => void;
}

const getSorterFromFilter = (
  filter: TokenTableFilters,
  filterPrefix: string,
) => {
  if (filter.startsWith(filterPrefix)) {
    return filter.endsWith('asc') ? 'ascend' : 'descend';
  }
  return null;
};

export function TokenTable({ filter, setFilter }: TokenTableProps) {
  const [page, setPage] = useState(1);
  const { t } = useTranslation(['token']);
  const { ReportTokenModal } = useReportTokenModal();
  const [isLoginModalOpen, setIsProfileModalOpen] = useState(false);
  const [pageSize, setPageSize] = useState(defaultPageSize);
  const { data, loading, previousData } = useGetAllTokensDetailsQuery({
    variables: {
      page,
      pageSize,
      filter,
    },
  });

  const columns: TableColumnProps<TokenTableRow>[] = [
    {
      key: 'token-table-column-order',
      title: '#',
      align: 'center',
      fixed: 'left',
      render: (_, __, index) => (
        <Typography.Text strong>
          {(page - 1) * pageSize + index + 1}
        </Typography.Text>
      ),
    },
    {
      key: 'token-table-column-name',
      title: t('token:table.name'),
      dataIndex: 'name',
      sorter: true,
      sortOrder: getSorterFromFilter(filter, TokenTableFiltersPrefix.name),
      fixed: 'left',
      render: (_, record) => <TokenIconAndName token={record} />,
    },
    {
      key: 'token-table-column-price',
      title: t('token:table.price'),
      dataIndex: 'price',
      sorter: true,
      sortOrder: getSorterFromFilter(filter, TokenTableFiltersPrefix.price),
      sortDirections: ['descend', 'ascend'],
      render: (_, { price }) => (
        <NumericValue format={NumberFormats.currency} value={price} strong />
      ),
    },
    {
      key: 'token-table-column-h24',
      title: t('token:table.h24'),
      dataIndex: 'h24',
      sorter: true,
      sortOrder: getSorterFromFilter(filter, TokenTableFiltersPrefix.h24),
      sortDirections: ['descend', 'ascend'],
      render: (_, { h24 }) => <PercentageValue value={h24} strong />,
    },
    {
      title: t('token:table.d7'),
      dataIndex: 'd7',
      render: (_, { d7 }) => <PercentageValue value={d7} strong />,
    },
    {
      key: 'token-table-column-market-cap',
      title: t('token:table.marketCap'),
      dataIndex: 'marketCap',
      sorter: true,
      sortOrder: getSorterFromFilter(filter, TokenTableFiltersPrefix.marketCap),
      sortDirections: ['descend', 'ascend'],
      render: (_, { marketCap }) => (
        <NumericValue
          format={NumberFormats.currency}
          value={marketCap}
          strong
        />
      ),
    },
    {
      key: 'token-table-column-volume',
      title: t('token:table.volume'),
      dataIndex: 'volume',
      sorter: true,
      sortOrder: getSorterFromFilter(filter, TokenTableFiltersPrefix.volume),
      sortDirections: ['descend', 'ascend'],
      render: (_, { volume }) => <NumericValue value={volume} strong />,
    },
    {
      key: 'token-table-column-avg-rating',
      title: t('token:table.avgRating'),
      dataIndex: 'avgRating',
      className: classes.columnAvgRating,
      width: '120px',
      sortOrder: getSorterFromFilter(filter, TokenTableFiltersPrefix.avgRating),
      sorter: true,
      sortDirections: ['descend', 'ascend'],
      align: 'center',
      render: (_, { currentTokenRating }) => (
        <RatingTag
          rating={currentTokenRating?.rating?.name}
          type={currentTokenRating?.rating?.type as RatingType}
        />
      ),
    },
    {
      key: 'token-table-column-rating-count',
      title: t('token:table.ratingCount'),
      dataIndex: 'validatedResearchCount',
      sorter: true,
      sortOrder: getSorterFromFilter(
        filter,
        TokenTableFiltersPrefix.validatedResearchCount,
      ),
      align: 'center',
      sortDirections: ['descend', 'ascend'],
      render: (_, { currentTokenRating, validatedResearchCount }) =>
        currentTokenRating?.rating?.type === 'reference'
          ? t('common:common.na')
          : validatedResearchCount,
    },
    {
      key: 'token-table-column-last-7d',
      title: t('token:table.last7Days'),
      className: classes.columnLast7Days,
      render: (_, { sparkline }) => (
        <ParentSize>
          {({ width }) => (
            <SparklineChart data={sparkline ?? []} height={48} width={width} />
          )}
        </ParentSize>
      ),
    },
    {
      key: 'token-table-column-circulating-supply',
      title: t('token:table.circulatingSupply'),
      dataIndex: 'circulatingSupply',
      align: 'right',
      render: (_, { circulatingSupply }) => (
        <NumericValue
          value={circulatingSupply}
          options={{ maximumFractionDigits: 0, minimumFractionDigits: 0 }}
          strong
        />
      ),
    },
  ];

  const { count, tokens = [], authorized } = loading
    ? previousData?.getAllTokensDetails ?? {}
    : data?.getAllTokensDetails ?? {};

  const list =
    authorized || tokens.length === 0
      ? tokens
      : tokens.concat(
          Array.from({ length: pageSize - tokens.length }, (_, index) => {
            const token = tokens[Math.floor(Math.random() * tokens.length)];
            return {
              ...token,
              identity: {
                ...token.identity,
                // override reference on duplicated blurred item to avoid duplicated key in array
                reference: `mocked-${token.identity.reference}-${index}`,
              },
            };
          }),
        );

  return (
    <>
      <ReportTokenModal />
      <LoginModal
        isOpen={isLoginModalOpen}
        setIsOpen={setIsProfileModalOpen}
        startTab={LoginModalFormTabs.signup}
      />
      <Table<TokenTableRow>
        loading={loading}
        dataSource={list.map(({ identity, market }) => ({
          ...identity,
          ...market,
        }))}
        rowKey={({ reference }) => reference}
        columns={columns}
        scroll={{ x: true }}
        onRow={(_, index) => ({
          className:
            !authorized && (page >= 2 || (index && index >= 5))
              ? classes.hiddenRow
              : '',
        })}
        onChange={({ current: newPage, pageSize: newPageSize }, _, sorter) => {
          setPage(newPage || 1);
          setPageSize(newPageSize || defaultPageSize);
          if (
            (Array.isArray(sorter) && sorter.length > 0) ||
            Object.keys(sorter).length > 0
          ) {
            setFilter(getFilterFromSorter(sorter));
          }
        }}
        pagination={{
          total: count,
          current: page,
          pageSize,
          pageSizeOptions,
        }}
        className={classes.table}
      />
      {!authorized && !loading ? (
        <div className={classes['not-connected-cta']}>
          <Button type="primary" onClick={() => setIsProfileModalOpen(true)}>
            {t('token:table.cta')}
          </Button>
        </div>
      ) : null}
    </>
  );
}
