import React, { SVGProps } from 'react';

interface TwitterLogoSVGComponentProps extends SVGProps<SVGSVGElement> {}

export function TwitterLogoSVGComponent(props: TwitterLogoSVGComponentProps) {
  return (
    <svg
      width="16"
      height="13"
      viewBox="0 0 16 13"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M5.026 13.0007C11.064 13.0007 14.367 7.99769 14.367 3.66669C14.367 3.52669 14.367 3.38469 14.361 3.24469C15.0041 2.77918 15.5591 2.20284 16 1.54269C15.3993 1.80754 14.7628 1.9821 14.111 2.06069C14.7975 1.65033 15.3117 1.00466 15.558 0.243693C14.913 0.625817 14.207 0.894067 13.471 1.03669C12.9762 0.509717 12.3214 0.160611 11.6081 0.0434506C10.8948 -0.0737096 10.1627 0.0476146 9.52534 0.38863C8.88796 0.729646 8.38081 1.27132 8.08245 1.92975C7.78409 2.58818 7.71118 3.32663 7.875 4.03069C6.56974 3.96524 5.29282 3.62616 4.12704 3.03544C2.96127 2.44471 1.93268 1.61554 1.108 0.601693C0.68934 1.32478 0.561574 2.18009 0.750646 2.99396C0.939718 3.80782 1.43145 4.51922 2.126 4.98369C1.60554 4.96601 1.09652 4.82625 0.64 4.57569V4.62069C0.640897 5.37817 0.903307 6.11211 1.38287 6.69845C1.86244 7.28479 2.52975 7.68757 3.272 7.83869C2.99026 7.91631 2.69923 7.955 2.407 7.95369C2.20098 7.95432 1.99538 7.93523 1.793 7.89669C2.00279 8.54875 2.41127 9.11886 2.96125 9.52717C3.51122 9.93548 4.17513 10.1615 4.86 10.1737C3.69656 11.0875 2.2594 11.5831 0.78 11.5807C0.519321 11.5818 0.258823 11.5668 0 11.5357C1.50151 12.493 3.2453 13.0012 5.026 13.0007Z"
        fill="current"
      />
    </svg>
  );
}
