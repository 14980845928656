import { BgColorsOutlined } from '@ant-design/icons';
import { SocialNetwork } from 'business/social-network/types';
import React from 'react';
import Button from 'ui/button';
import { SocialIcon } from 'ui/social-networks/social-icon';
import colors from 'ui/theme/variables.module.scss';
import classes from './index.module.scss';

interface SimpleSocialButtonsProps {
  socialNetworks: SocialNetwork[];
  size?: 'small' | 'medium';
}

export function SimpleSocialButtons({
  socialNetworks,
  size,
}: SimpleSocialButtonsProps) {
  const socialNetworksWithoutDescriptions = socialNetworks.filter(
    (sn) => !sn.isUsername,
  );

  return (
    <>
      {socialNetworksWithoutDescriptions.map(({ key, value }) => (
        <Button
          type="link"
          key={key}
          href={value}
          target="_blank"
          rel="noopener noreferrer"
          className={classes.button}
        >
          <SocialIcon
            key={key}
            social={key}
            size={size}
            color={colors.primaryColor2}
          />
        </Button>
      ))}
    </>
  );
}
