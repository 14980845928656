import React from 'react';
import { Typography, TableProps, Popconfirm, Tooltip } from 'antd';
import Button, { LinkAsButton } from 'ui/button';
import { useTranslation } from 'react-i18next';
import { format } from 'technical/date-fns';
import { useAppContext } from 'business/app-bootstrapper';
import logger from 'technical/logger';
import { ReactComponent as CheckCircleOutlinedIcon } from 'assets/illustrations/check-circle-outline.svg';
import {
  PublicRating,
  ResearchFormCategory,
  ResearchPeerReview,
  ResearchPeerReviewOutcomeEnumEnum,
  ResearchPeerReviewStatusEnumEnum,
  ResearchResearch,
  Token,
  UserDraftSoftDeleteMutation,
} from 'generated/graphql';
import { TokenIconAndName } from 'business/research/components/token-icon-and-name';
import { TokenCategoriesIcons } from 'business/research/components/token-categories-icons';
import { Spacer } from 'ui/spacer';
import Table from 'ui/table';
import RatingTag from 'ui/rating-tag';

import {
  CloseCircleOutlined,
  DeleteOutlined,
  QuestionCircleOutlined,
} from '@ant-design/icons';
import { ColumnsType, ColumnType } from 'antd/es/table';
import { researchPageOptions } from 'business/token/common-values';
import { FetchResult } from '@apollo/client';
import Routes from 'business/router/routes';
import { generatePath } from 'react-router-dom';
import { compareAsc } from 'date-fns';
import { tokenFilterDropdown } from './filters/token-filter';
import { hasEnoughReviewsDropdown } from './filters/has-enough-review-filter';
import classes from './index.module.scss';
import config from 'config';

export type ResearchRow = Pick<
  ResearchResearch,
  | 'id'
  | 'publishedAt'
  | 'status'
  | 'authorId'
  | 'targetPrice'
  | 'comment'
  | 'status'
> & {
  hasEnoughReviews: ResearchResearch['hasEnoughPeerReview'];
} & {
  token: Pick<Token, 'id' | 'name' | 'symbol' | 'reference' | 'coinGeckoImage'>;
  selectedCategories: {
    questionCategory: Pick<ResearchFormCategory, 'id'>;
  }[];
  reviews: Pick<ResearchPeerReview, 'id' | 'status' | 'outcome'>[];
  rating?: Pick<PublicRating, 'name'> | null;
};
export type ResearchTableColumnKey = Extract<
  keyof ResearchRow,
  | 'token'
  | 'selectedCategories'
  | 'publishedAt'
  | 'rating'
  | 'status'
  | 'hasEnoughReviews'
>;
const defaultColumnSelection: ResearchTableColumnKey[] = [
  'token',
  'selectedCategories',
  'publishedAt',
  'rating',
  'hasEnoughReviews',
  'status',
];

export interface ResearchTableProps extends TableProps<ResearchRow> {
  researches: ResearchRow[];
  columnSelection?: ResearchTableColumnKey[];
  onClickReviewPending?: (research: ResearchRow) => void;
  onClickResumeDraft?: (research: ResearchRow) => void;
  onClickDeleteDraft?: (
    research: ResearchRow,
  ) => Promise<FetchResult<UserDraftSoftDeleteMutation>>;
  defaultFilters?: {
    tokenRefs?: string[];
    hasEnoughReviews?: boolean;
  };
}

export function ResearchTable({
  researches,
  columnSelection = defaultColumnSelection,
  onClickReviewPending,
  onClickResumeDraft,
  onClickDeleteDraft,
  pagination,
  defaultFilters,
  ...tableProps
}: ResearchTableProps) {
  const { user } = useAppContext();
  const { t } = useTranslation(['token']);

  const columnsRecord: Record<
    ResearchTableColumnKey,
    Pick<
      ColumnType<ResearchRow>,
      | 'render'
      | 'className'
      | 'filteredValue'
      | 'filterDropdown'
      | 'defaultFilteredValue'
      | 'align'
      | 'title'
      | 'sorter'
      | 'sortDirections'
      | 'dataIndex'
      | 'key'
    >
  > = {
    token: {
      render: (_value, research) => <TokenIconAndName token={research.token} />,
      filteredValue: defaultFilters?.tokenRefs,
      filterDropdown: tokenFilterDropdown(t),
    },
    selectedCategories: {
      align: 'left',
      render: (_value, research) => {
        const selectedCategoriesIconsIDs = research.selectedCategories.map(
          ({ questionCategory }) => questionCategory.id,
        );

        return (
          <TokenCategoriesIcons
            categoriesIconsIds={selectedCategoriesIconsIDs}
          />
        );
      },
    },
    publishedAt: {
      align: 'left',
      render: (date: ResearchResearch['publishedAt']) => (
        <Typography.Text strong>
          {date ? format(new Date(date), 'PPP') : '-'}
        </Typography.Text>
      ),
    },
    rating: {
      align: 'left',
      className: classes.columnRating,
      render: (rating) => {
        if (!rating) {
          return (
            <RatingTag rating={t('research:unknown-rating')} type="unknown" />
          );
        }
        const { name, type } = rating;
        return <RatingTag rating={name} type={type} />;
      },
    },
    hasEnoughReviews: {
      title: t('token:research.table.enough-reviews-header'),
      align: 'left',
      render(hasEnoughReviews: boolean) {
        return (
          <Typography.Text strong>
            {t(`token:research.table.enough-reviews`, {
              context:
                tableProps.showHeader === false
                  ? `${hasEnoughReviews}`
                  : `with-header_${hasEnoughReviews}`,
            })}
          </Typography.Text>
        );
      },
      defaultFilteredValue:
        defaultFilters?.hasEnoughReviews !== undefined
          ? [defaultFilters.hasEnoughReviews]
          : [],
      filterDropdown: hasEnoughReviewsDropdown(t),
    },
    status: {
      className: classes.columnStatus,
      align: 'right',
      render: (status, research) => {
        if (status === 'draft') {
          return (
            <Spacer align="center" justify="end">
              {!config.features.areContributionCreditsActive ||
              (user && user.contributionCredit.credit > 0) ? (
                <LinkAsButton
                  type="default"
                  to={generatePath(Routes.ResearchToken, {
                    reference: research.token.reference,
                  })}
                >
                  {t('token:research.actions.resume-research')}
                </LinkAsButton>
              ) : (
                <Tooltip
                  placement="top"
                  title={`You do not have enough credit to score a token. You need to review ${user?.contributionCredit.numberOfReviewNeededBeforeNextCredit} scorings to win your next credit.`}
                >
                  <span // span are needed or tooltip is not visible on disabled button ant-design/issues/1816
                    className={classes.disabledButtonWrapper}
                  >
                    <Button type="default" disabled>
                      {t('token:research.actions.resume-research')}
                    </Button>
                  </span>
                </Tooltip>
              )}
              <Popconfirm
                title="Are you sure?"
                icon={<QuestionCircleOutlined />}
                onConfirm={() => onClickDeleteDraft?.(research)}
              >
                <DeleteOutlined className={classes.deleteIcon} />
              </Popconfirm>
            </Spacer>
          );
        }

        if (status === 'published') {
          if (!user || user.id === research.authorId) {
            return (
              <Typography.Text strong>
                {t('token:research.status.pending')}
              </Typography.Text>
            );
          }

          if (
            research.reviews[0] &&
            research.reviews[0]?.status !==
              ResearchPeerReviewStatusEnumEnum.Draft
          ) {
            return (
              <Spacer
                direction="horizontal"
                space={1}
                align="center"
                justify="end"
              >
                {research.reviews[0].outcome ===
                  ResearchPeerReviewOutcomeEnumEnum.Validated && (
                  <CheckCircleOutlinedIcon
                    width={17}
                    height={17}
                    className={classes.approvedPicto}
                  />
                )}
                {research.reviews[0].outcome ===
                  ResearchPeerReviewOutcomeEnumEnum.Rejected && (
                  <CloseCircleOutlined className={classes.disapprovedPicto} />
                )}
                <Typography.Text strong className={classes.statusText}>
                  {t('token:research.status.reviewed', {
                    context: research.reviews[0].status,
                  })}
                </Typography.Text>
              </Spacer>
            );
          }

          // Todo: Enable review on any context ?
          if (!onClickReviewPending) {
            return (
              <Typography.Text strong type="secondary">
                {t('token:research.status.pending')}
              </Typography.Text>
            );
          }

          return (
            <Button
              type="default"
              onClick={() => onClickReviewPending(research)}
            >
              {t('token:research.actions.review-this-research')}
            </Button>
          );
        }

        if (status === 'validated') {
          return (
            <Typography.Text strong type="success">
              {t('token:research.status.validated')}
            </Typography.Text>
          );
        }

        if (status === 'rejected') {
          return (
            <Spacer
              direction="horizontal"
              space={1}
              align="center"
              justify="end"
            >
              {research.hasEnoughReviews ? (
                <Typography.Text strong type="danger">
                  {t('token:research.status.rejected')}
                </Typography.Text>
              ) : (
                <Typography.Text strong type="warning">
                  {t('token:research.status.timed-out')}
                </Typography.Text>
              )}
            </Spacer>
          );
        }

        logger.error({
          message: 'unknown-research-status',
          research,
        });

        return null;
      },
    },
  };

  const columns: ColumnsType<ResearchRow> = columnSelection.map((key) => {
    return {
      key,
      dataIndex: key,
      title: t('token:research.table-column', { context: key }),
      ...columnsRecord[key],
    };
  });

  return (
    <Table<ResearchRow>
      {...tableProps}
      columns={columns}
      dataSource={researches}
      scroll={{ x: true }}
      pagination={
        pagination ?? { pageSize: researchPageOptions.defaultPageSize }
      }
    />
  );
}
