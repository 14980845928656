import React from 'react';
import {
  LeaderBoardProps,
  LeaderBoardTable,
  UserLeaderBoard,
} from 'business/user/components/leaderboard/index';
import { useGetSessionLeaderboardBySessionIdQuery } from 'generated/graphql';
import { isNotNullOrUndefined } from 'technical/utils/is-not-null-or-undefined';
import { isDisplayableLeaderboardRow } from 'business/user/types/user';

interface SessionLeaderboardProps
  extends Pick<LeaderBoardProps, 'limit' | 'compact'> {
  sessionId?: number | null;
  loading: boolean;
}

export const SessionLeaderboard: React.VFC<SessionLeaderboardProps> = ({
  limit = 5,
  compact = false,
  loading,
  sessionId,
}) => {
  const {
    data: leaderboardData,
    loading: leaderboardLoading,
  } = useGetSessionLeaderboardBySessionIdQuery({
    skip: loading || !isNotNullOrUndefined(sessionId),
    variables: { sessionId: sessionId!, limit }, // we should skip query if session is loading, or we have no session
  });

  const users: UserLeaderBoard[] =
    leaderboardData?.leaderboardBySession
      .filter(isDisplayableLeaderboardRow)
      .map((row) => ({
        ...row.user,
        position: row.position,
      })) ?? [];

  return (
    <LeaderBoardTable
      users={users}
      loading={loading || leaderboardLoading}
      limit={limit}
      compact={compact}
    />
  );
};
