import { Result } from 'antd';
import React, { useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import Button from 'ui/button';
import errorReporting from 'technical/error-reporting';
import { useTranslation } from 'react-i18next';
import Routes from 'business/router/routes';

function ReportingNoMatch() {
  const location = useLocation();
  const navigate = useNavigate();
  const { t } = useTranslation(['common']);

  useEffect(() => {
    errorReporting.info(new Error('404'), { location: location.pathname });
  }, []);

  return (
    <Result
      status="404"
      title="404"
      subTitle={t('errors.404')}
      extra={
        <Button type="primary" onClick={() => navigate(Routes.Home)}>
          {t('common.backToHome')}
        </Button>
      }
    />
  );
}

export default ReportingNoMatch;
