import portfolio from './portfolio.json';
import common from './common.json';
import forms from './forms.json';
import formWizard from './form-wizard.json';
import token from './token.json';
import onboarding from './onboarding.json';
import home from './home.json';
import user from './user.json';
import review from './review.json';
import research from './research.json';
import rewardPoint from './reward-point.json';
import landing from './landing.json';
import dashboard from './dashboard.json';

// if adding new file update NS array in front/src/translations/index.ts
export default {
  common,
  home,
  forms,
  'form-wizard': formWizard,
  token,
  onboarding,
  portfolio,
  user,
  review,
  rewardPoint,
  research,
  landing,
  dashboard,
};
